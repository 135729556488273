import { Component, Input, ChangeDetectionStrategy } from "@angular/core";

@Component({
  selector: "roctavian-abstractions-two-factor-status",
  templateUrl: "./two-factor-status.component.html",
  styleUrls: ["./two-factor-status.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TwoFactorStatusComponent {
  @Input() public twoFactorEnabled: boolean;

  constructor() {}
}
