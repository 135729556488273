import { AppTranslationService } from '@roctavian-abstractions/core';
import { Component, OnInit, Input } from '@angular/core';
import { StakeholderTypes } from '../../enums';
import { Attestation } from '../../models';
import { StakeholderAttestationTypes } from '../../constants';

@Component({
  selector: 'view-attestation',
  templateUrl: './view-attestation.component.html',
  styleUrls: ['./view-attestation.component.css']
})
export class ViewAttestationComponent implements OnInit {
  @Input() title = this.translate.getTranslation('Common.ViewAttestation.Title');
  @Input() showTitle = false;

  @Input() attestationSubTitle = this.translate.getTranslation('Common.ViewAttestation.Title');
  @Input() showAttestationSubTitle = false;

  @Input() hasSignatureLabel = this.translate.getTranslation('Common.ViewAttestation.Signature');
  @Input() showHasSignature = true;

  @Input() signatureDateLabel = this.translate.getTranslation('Common.ViewAttestation.SignatureDate');
  @Input() showSignatureDate = true;

  @Input() guardianNameLabel = this.translate.getTranslation('Common.ViewStakeholder.Attestation.GuardianName');
  @Input() showGuardianName = false;

  @Input() hasConsentTitle = this.translate.getTranslation('Common.ViewStakeholder.Attestation.HasConsentTitle');
  @Input() showHasConsentTitle = false;
  @Input() hasConsentLabel = this.translate.getTranslation('Common.ViewStakeholder.Attestation.HasConsent');
  @Input() showHasConsent = false;

  @Input() changeReasonDescriptionLabel = this.translate.getTranslation(
    'Common.ViewAttestation.ChangeReasonDescription'
  );
  @Input() showChangeReasonDescription = false;

  @Input() attestationTypeLabel = this.translate.getTranslation('Common.ViewAttestation.AttestationType');
  @Input() showAttestationType = false;

  @Input() public stakeholderType: StakeholderTypes;
  @Input() public attestation: Attestation;

  public attestationTypes = StakeholderAttestationTypes;

  constructor(private translate: AppTranslationService) { }

  ngOnInit() { }

  public getAttestationTypeName(): string {
    if (!this.attestation) {
      return '';
    }

    const attestationType = this.attestationTypes.find(x => x.attestationType === this.attestation.attestationType);
    if (!attestationType) {
      return '';
    }

    return attestationType.name;
  }
}
