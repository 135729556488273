import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { AuthenticationGuard } from './guards/authentication.guard';
import { RegistrationGuard } from './guards/registration.guard';
import { AccountSettingsLayoutPageComponent } from './pages/account-settings-layout-page/account-settings-layout-page.component';
import { AccountSettingsPageComponent } from './pages/account-settings-page/account-settings-page.component';
import { ChangeExpiredPasswordPageComponent } from './pages/change-expired-password-page/change-expired-password-page.component';
import { ConfirmEmailAddressPageComponent } from './pages/confirm-email-address-page/confirm-email-address-page.component';
import { ForbiddenPageComponent } from './pages/forbidden-page/forbidden-page.component';
import { ForgotPasswordPageComponent } from './pages/forgot-password-page/forgot-password-page.component';
import { LoginPageComponent } from './pages/login-page/login-page.component';
import { NewAccountSetUpPageComponent } from './pages/new-account-set-up-page/new-account-set-up-page.component';
import { RegisterPageComponent } from './pages/register-page/register-page.component';
import { ResendConfirmationEmailPageComponent } from './pages/resend-confirmation-email-page/resend-confirmation-email-page.component';
import { ResetPasswordPageComponent } from './pages/reset-password-page/reset-password-page.component';
import { SecuritySettingsPageComponent } from './pages/security-settings-page/security-settings-page.component';
import { TwoFactorVerificationPageComponent } from './pages/two-factor-verification-page/two-factor-verification-page.component';

const ROUTES = [
  { path: 'login', component: LoginPageComponent },
  { path: 'forbidden', component: ForbiddenPageComponent },
  { path: 'forgot', component: ForgotPasswordPageComponent },
  { path: 'reset', component: ResetPasswordPageComponent },
  { path: 'expired', component: ChangeExpiredPasswordPageComponent },
  { path: 'confirm', component: ConfirmEmailAddressPageComponent },
  { path: 'resend', component: ResendConfirmationEmailPageComponent },
  { path: 'setup', component: NewAccountSetUpPageComponent },
  {
    path: 'register',
    component: RegisterPageComponent,
    canActivate: [RegistrationGuard],
  },
  { path: 'two-factor/verify', component: TwoFactorVerificationPageComponent },
  {
    path: 'settings',
    component: AccountSettingsLayoutPageComponent,
    canActivate: [AuthenticationGuard],
    children: [
      { path: '', redirectTo: 'account', pathMatch: 'full' },
      { path: 'account', component: AccountSettingsPageComponent },
      { path: 'security', component: SecuritySettingsPageComponent },
    ],
  },
];

@NgModule({
  imports: [CommonModule, RouterModule.forChild(ROUTES)],
  exports: [RouterModule],
})
export class IdentityRoutingModule {}
