import { SecurityModule } from "./../security/security.module";
import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { RouterModule } from "@angular/router";
import { MaterialModule, CoreModule } from "@roctavian-abstractions/core";
import { NgxCaptchaModule } from "ngx-captcha";
import { ForgotPasswordComponent } from "./components/forgot-password/forgot-password.component";
import { ForgotPasswordFormComponent } from "./components/forgot-password-form/forgot-password-form.component";
import { ChangePasswordFormComponent } from "./components/change-password-form/change-password-form.component";
import { ChangePasswordComponent } from "./components/change-password/change-password.component";
import { ResetPasswordComponent } from "./components/reset-password/reset-password.component";
import { ResetPasswordFormComponent } from "./components/reset-password-form/reset-password-form.component";
import { PasswordRulesComponent } from "./components/password-rules/password-rules.component";

@NgModule({
  imports: [
    CommonModule,
    CoreModule,
    FormsModule,
    MaterialModule,
    ReactiveFormsModule,
    RouterModule,
    SecurityModule,
    NgxCaptchaModule
  ],
  declarations: [ChangePasswordFormComponent,
    ChangePasswordComponent,
    ForgotPasswordFormComponent,
    ForgotPasswordComponent,
    ResetPasswordComponent,
    ResetPasswordFormComponent,
    PasswordRulesComponent],
  exports: [ChangePasswordFormComponent,
    ChangePasswordComponent,
    ForgotPasswordFormComponent,
    ForgotPasswordComponent,
    ResetPasswordComponent,
    ResetPasswordFormComponent,
    PasswordRulesComponent]
})
export class PasswordModule {}
