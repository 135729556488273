import { OnChanges, Input, Output, EventEmitter, SimpleChanges } from '@angular/core';
import { OnDestroy } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import { PagedQuery, UtilityService } from '../../../common';
import { Lab } from '../../../lab';
import { BehaviorSubject, Subscription, EMPTY } from 'rxjs';
import { PaginatedList } from '@roctavian-abstractions/web';
import { LabTestService } from '../../services/lab-test.service';
import { AppTranslationService } from '@roctavian-abstractions/core';
import { debounceTime, distinctUntilChanged, switchMap } from 'rxjs/operators';
import { PageEvent, MatSort } from '@angular/material';

@Component({
  selector: 'lab-test-lab-list',
  templateUrl: './lab-list.component.html',
  styleUrls: ['./lab-list.component.scss']
})
export class LabListComponent implements OnInit, OnDestroy, OnChanges {
  public pagedQuery = new PagedQuery(1, 10, null, 'id', true);
  public selectedLab: Lab;
  @Input() public selectedLabId: number;

  @Output() public labSelected = new EventEmitter<Lab>();

  public filterText$ = new BehaviorSubject<string>(null);
  public labPagedCollection: PaginatedList<Lab>;
  public isLoading = false;

  private subscriptions = new Array<Subscription>();

  public displayedColumns = [
    'id',
    'labName',
    'stakeholder.address.address1',
    'stakeholder.address.city',
    'stakeholder.address.postalCode',
    'stakeholder.address.country.name',
    'stakeholder.statuses.statusType'
  ];

  constructor(
    private labTestService: LabTestService,
    public utilityService: UtilityService
  ) { }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.selectedLabId && changes.selectedLabId.currentValue) {
      let selectedLab: Lab;
      if (this.labPagedCollection && this.labPagedCollection.items) {
        selectedLab = this.labPagedCollection.items.find(lab => lab.id == changes.selectedLabId.currentValue);
      }

      if (!selectedLab) {
        selectedLab = new Lab();
        selectedLab.id = changes.selectedLabId.currentValue;
      }

      this.selectedLab = selectedLab;
    } else if (changes.selectedLabId && !changes.selectedLabId.currentValue) {
      this.selectedLab = null;
    }
  }

  private refreshList() {
    this.labTestService.setLabPage(this.pagedQuery);
  }

  ngOnInit() {
    this.subscriptions.push(
      this.filterText$
        .pipe(
          debounceTime(1000),
          distinctUntilChanged(),
          switchMap(searchText => {
            if (this.pagedQuery.filterText == searchText) {
              return EMPTY;
            }

            this.pagedQuery.filterText = searchText;
            this.pagedQuery.pageIndex = 1;
            this.refreshList();
            return EMPTY;
          })
        )
        .subscribe()
    );

    this.subscriptions.push(
      this.labTestService.labPagedListSubject.subscribe(collection => {
        this.labPagedCollection = collection;
      })
    );

    this.subscriptions.push(
      this.labTestService.isLoadingSubject.subscribe(isLoading => {
        this.isLoading = isLoading;
      })
    );

    this.refreshList();
  }

  ngOnDestroy() {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
    this.labTestService.labPagedListSubject.next(new PaginatedList<Lab>());
  }

  public onRowSelected(lab: Lab) {
    if (this.selectedLab === lab) {
      this.selectedLab = null;
      this.labSelected.emit(null);
      return;
    }

    this.selectedLab = lab;
    this.labSelected.emit(this.selectedLab);
  }

  public onPageEvent(pageEvent: PageEvent) {
    this.pagedQuery.pageIndex = pageEvent.pageIndex + 1;
    this.pagedQuery.pageSize = pageEvent.pageSize;
    this.refreshList();
  }

  public onSortEvent(sort: MatSort) {
    switch (sort.active) {
      case 'id':
        this.pagedQuery.sortColumn = 'stakeholder.externalId';
        break;
      case 'stakeholder.statuses.statusType':
        this.pagedQuery.sortColumn = 'stakeholder.statuses.FirstOrDefault(x => x.IsCurrent).statusType';
        break;

      default:
        this.pagedQuery.sortColumn = sort.active;
        break;
    }

    this.pagedQuery.isSortDesc = sort.direction == 'desc';
    this.pagedQuery.pageIndex = 1;
    this.refreshList();
  }
}
