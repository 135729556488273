import { CommonModule, DatePipe } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MAT_DIALOG_DEFAULT_OPTIONS, MatProgressSpinnerModule } from '@angular/material';
import { RouterModule } from '@angular/router';
import { CoreDirectivesModule, MaterialModule, PipeModule } from '@roctavian-abstractions/core';
import { NgxMaterialTimepickerModule } from 'ngx-material-timepicker';
import { IdentityModule } from '../../../identity/identity.module';
import { SharedModule } from '../../../shared/shared.module';
import { StakeholderCommonModule } from '../common';
import { AddLabTestComponent } from './components/add-lab-test/add-lab-test.component';
import { LabListComponent } from './components/lab-list/lab-list.component';
import { LabTestFormComponent } from './components/lab-test-form/lab-test-form.component';
import { LabTestListComponent } from './components/lab-test-list/lab-test-list.component';
import { PatientListComponent } from './components/patient-list/patient-list.component';
import { ViewLabTestDialogComponent } from './components/view-lab-test-dialog/view-lab-test-dialog.component';
import { ViewLabTestComponent } from './components/view-lab-test/view-lab-test.component';
import { LabTestRoutingModule } from './lab-test-routing.model';
import { AddLabTestPageComponent } from './pages/add-lab-test-page/add-lab-test-page.component';
import { EditLabTestPageComponent } from './pages/edit-lab-test-page/edit-lab-test-page.component';
import { LabTestDetailMasterPageComponent } from './pages/lab-test-detail-master-page/lab-test-detail-master-page.component';
import { LabTestDocumentsPageComponent } from './pages/lab-test-documents-page/lab-test-documents-page.component';
import { LabTestListPageComponent } from './pages/lab-test-list-page/lab-test-list-page.component';
import { LabTestStatusPageComponent } from './pages/lab-test-status-page/lab-test-status-page.component';
import { LabTestSummaryPageComponent } from './pages/lab-test-summary-page/lab-test-summary-page.component';

@NgModule({
  declarations: [
    ViewLabTestDialogComponent,
    LabTestListPageComponent,
    AddLabTestPageComponent,
    LabTestDetailMasterPageComponent,
    LabTestSummaryPageComponent,
    LabTestStatusPageComponent,
    EditLabTestPageComponent,
    AddLabTestComponent,
    LabTestFormComponent,
    PatientListComponent,
    LabListComponent,
    LabTestListComponent,
    ViewLabTestComponent,
    LabTestDocumentsPageComponent
  ],
  imports: [
    CommonModule,
    IdentityModule,
    RouterModule,
    MaterialModule,
    FormsModule,
    HttpClientModule,
    ReactiveFormsModule,
    PipeModule,
    LabTestRoutingModule,
    CoreDirectivesModule,
    StakeholderCommonModule,
    SharedModule,
    NgxMaterialTimepickerModule,
    MatProgressSpinnerModule
  ],
  providers: [{ provide: MAT_DIALOG_DEFAULT_OPTIONS, useValue: { hasBackdrop: false } }, DatePipe],
  entryComponents: [ViewLabTestDialogComponent]
})
export class LabTestModule {}
