import { Component } from "@angular/core";

@Component({
  selector: "roctavian-abstractions-change-expired-password-page",
  templateUrl: "./change-expired-password-page.component.html",
  styleUrls: ["./change-expired-password-page.component.scss"]
})
export class ChangeExpiredPasswordPageComponent {
  constructor() {}
}
