import { Component, OnInit, Input } from '@angular/core';
import { AppTranslationService } from '@roctavian-abstractions/core';
import { AlternateContact } from '../../models';
import { UtilityService } from '../../services';

@Component({
  selector: 'view-alternate-contact',
  templateUrl: './view-alternate-contact.component.html',
  styleUrls: ['./view-alternate-contact.component.css']
})
export class ViewAltContactComponent implements OnInit {
  @Input() public title = this.translate.getTranslation('Common.ViewAltContact.Title');
  @Input() public showTitle = false;

  @Input() public firstNameLabel = this.translate.getTranslation('Common.ViewAltContact.FirstName');
  @Input() public showFirstName = false;
  @Input() public disableFirstName = false;

  @Input() public lastNameLabel = this.translate.getTranslation('Common.ViewAltContact.LastName');
  @Input() public showLastName = false;
  @Input() public disableLastName = false;

  @Input() public emailLabel = this.translate.getTranslation('Common.ViewAltContact.Email');
  @Input() public showEmail = false;

  @Input() public phoneLabel = this.translate.getTranslation('Common.ViewAltContact.Phone');
  @Input() public showPhone = false;

  @Input() public alternateContact: AlternateContact;

  constructor(public utilityService: UtilityService, private translate: AppTranslationService) { }

  ngOnInit() { }
}
