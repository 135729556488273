import { Auditable, Stakeholder } from '../../common';
import { Lab } from '../../lab';
import { Patient } from '../../patient';
import { Prescriber } from '../../prescriber';

export class LabTest extends Auditable {
  public id: string;

  public prescriberId: number;

  public prescriber: Prescriber;

  public labId: number;

  public lab: Lab;

  public appointmentDateTime: string;

  public patientId: number;

  public patient: Patient;

  public requiresSampleShippingKit: boolean;

  public labTestComplete: boolean;

  public trackingNumber: string;

  public numberOfSpecimensSubmitted: number;

  public specimenCollectionDateTime: string;

  public specimenTransportPickupDateTime: string;

  public specimenTransportDispatchDateTime: string;

  public estimatedSpecimenArrivalDateTime: string;

  public actualSpecimenArrivalDateTime: string;

  public trackingNumberSecondary: string;

  public specimenTransportPickupDateTimeSecondary: string;

  public specimenTransportDispatchDateTimeSecondary: string;

  public estimatedSpecimenArrivalDateTimeSecondary: string;

  public actualSpecimenArrivalDateTimeSecondary: string;

  public stakeholder: Stakeholder;

  public arupAccessionID: string;

  public arupPatientId: string;

  public arupOrderDate: Date;

  public arupReceivedDateTime: Date;

  public resultVerificationDate: Date;

  public resultNotificationSentToPrescriber: Date;

  public testResult: string;

  public testType: string;

  public cdcStatement: boolean;
}
