import { coerceBooleanProperty } from '@angular/cdk/coercion';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { Subscription } from 'rxjs';
import { filter, finalize, switchMap, tap } from 'rxjs/operators';

import { DocumentTypes } from '@app/core/enums';
import { documentTypeNames, FileUpload, UploadDocument } from '@app/core/models';
import { DocumentStorageService } from '@app/core/services';
import { DocumentTypeRecord } from '@app/core/types';
import { DocumentService } from '@app/stakeholder/services';

import { FileUploadMessageService } from './file-upload-message.service';

const FILE_EXTENSION_REGEX_PAT = /\.[0-9a-z]+$/i;

@Component({
  selector: 'app-file-upload',
  templateUrl: './file-upload.component.html',
  styleUrls: ['./file-upload.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FileUploadComponent implements OnInit, OnDestroy {
  private _disabled = false;
  private _multiple = false;
  private _placeholder: string;
  private selectedDocumentType: DocumentTypes;
  private upload$: Subscription;

  documentTypes: DocumentTypeRecord;
  upload: FileUpload | undefined;

  @Input() accept: string | null = null;

  @Input()
  get disabled(): boolean {
    return this._disabled;
  }
  set disabled(value: boolean) {
    if (this._disabled !== value) {
      this._disabled = coerceBooleanProperty(value);
      this.stateChanged();
    }
  }

  @Input()
  get multiple(): boolean | string {
    return this._multiple;
  }
  set multiple(value: boolean | string) {
    if (this._multiple !== value) {
      this._multiple = coerceBooleanProperty(value);
      this.stateChanged();
    }
  }

  @Input()
  get placeholder(): string {
    return this._placeholder;
  }
  set placeholder(value: string) {
    if (this._placeholder !== value) {
      this._placeholder = value;
      this.stateChanged();
    }
  }

  constructor(
    private readonly changeDetectorRef: ChangeDetectorRef,
    private readonly documentService: DocumentService,
    private readonly documentStorageService: DocumentStorageService,
    private readonly elementRef: ElementRef,
    private readonly fileUploadMessageService: FileUploadMessageService
  ) {}

  ngOnInit(): void {
    this.documentTypes = documentTypeNames;
  }

  ngOnDestroy(): void {
    this.reset();
  }

  cancelUpload() {
    this.reset();
  }

  /**
   * Get selected document type and trigger click event
   * of file upload HTML input.
   *
   * @param event The selected DocumentType.
   */
  documentTypeSelected(event: string) {
    this.selectedDocumentType = DocumentTypes[event];

    const input: HTMLInputElement = this.elementRef.nativeElement.querySelector('input');

    if (input) {
      input.click();
    }
  }

  /**
   * Upload the selected file(s) to the document storage.
   *
   * @param files The input selected files.
   */
  filesSelected(files: FileList | null) {
    if (files) {
      const file = files.item(0);
      const document: UploadDocument = {
        documentType: this.selectedDocumentType,
        file,
        fileName: file.name,
        relativePath: this.generateRelativePath(file.name),
      };

      this.uploadDocument(document);
    }
  }

  private generateRelativePath(fileName: string) {
    const ext = FILE_EXTENSION_REGEX_PAT.exec(fileName)[0];
    const now = Date.now();
    const date = new Date(now);
    const relativePath = `${date.getFullYear()}-${date
      .getMonth()
      .toString()
      .padStart(2, '0')}-${date.getDay().toString().padStart(2, '0')}/${now}${ext}`;

    return relativePath;
  }

  private reset() {
    if (this.upload$) {
      this.upload$.unsubscribe();
      this.upload$ = null;
    }
  }

  private stateChanged() {
    this.changeDetectorRef.markForCheck();
  }

  /**
   * Upload document to the document storage.
   * Display progress during upload and when
   * upload is done, create a new document
   * in the database.
   *
   * @private
   * @param document The document to upload.
   */
  private uploadDocument(document: UploadDocument) {
    this.upload$ = this.documentStorageService
      .upload(document)
      .pipe(
        tap(upload => (this.upload = upload)),
        filter(upload => upload.state === 'DONE'),
        switchMap(() => this.documentService.create(document)),
        tap(outcome => this.fileUploadMessageService.uploadComplete(outcome.value)),
        finalize(() => this.reset())
      )
      .subscribe();
  }
}
