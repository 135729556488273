import { AppTranslationService } from '@roctavian-abstractions/core';
import { Component, OnInit, Input } from '@angular/core';
import { FormGroupDirective, FormControl, Validators } from '@angular/forms';
import { UtilityService } from '../../../common';

@Component({
  selector: 'lab-form',
  templateUrl: './lab-form.component.html',
  styleUrls: ['./lab-form.component.css']
})
export class LabFormComponent implements OnInit {
  @Input() public title = this.translate.getTranslation('Lab.LabForm.Title');
  @Input() public showTitle = true;

  @Input() public nationalIdLabel = this.translate.getTranslation('Lab.LabForm.NationalId');
  @Input() public disableNationalId = false;

  @Input() public nameLabel = this.translate.getTranslation('Lab.LabForm.Name');
  @Input() public contactNameLabel = this.translate.getTranslation('Lab.LabForm.ContactName');

  @Input() public isVerifiedLabel = this.translate.getTranslation('Lab.LabForm.Verified');

  @Input() public showAuthorizedRepresentativeForm = true;

  @Input() public areFieldsRequired = false;

  constructor(
    public parentFormGroupDirective: FormGroupDirective,
    public utilityService: UtilityService,
    private translate: AppTranslationService
  ) { }

  ngOnInit() {
    this.buildForm();
  }

  private buildForm() {
    this.parentFormGroupDirective.form.addControl('nationalId', new FormControl(null, [Validators.required]));
    this.parentFormGroupDirective.form.addControl('verified', new FormControl(false));
    this.parentFormGroupDirective.form.addControl('labName', new FormControl(null, [Validators.required]));
  }
}
