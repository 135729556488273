import { Component, OnInit, Input, OnChanges, SimpleChanges } from '@angular/core';
import { FormGroup, FormBuilder, FormGroupDirective, FormControl, Validators } from '@angular/forms';
import { AppTranslationService } from '@roctavian-abstractions/core';

@Component({
  selector: 'alternate-contact-form',
  templateUrl: './alternate-contact-form.component.html',
  styleUrls: ['./alternate-contact-form.component.css']
})
export class AlternateContactFormComponent implements OnInit, OnChanges {
  @Input() public title = this.translate.getTranslation('Common.AltContactForm.Title');
  @Input() public showTitle = false;

  @Input() public firstNameLabel = this.translate.getTranslation('Common.AltContactForm.FirstName');
  @Input() public showFirstName = true;
  @Input() public disableFirstName = false;

  @Input() public lastNameLabel = this.translate.getTranslation('Common.AltContactForm.LastName');
  @Input() public showLastName = true;
  @Input() public disableLastName = false;

  @Input() public emailLabel = this.translate.getTranslation('Common.AltContactForm.Email');
  @Input() public showEmail = true;
  @Input() public disableEmail = false;
  @Input() public overrideRequireEmail = false;
  @Input() public overrideEmailIsOptional = false;

  @Input() public phoneLabel = this.translate.getTranslation('Common.AltContactForm.Phone');
  @Input() public showPhone = true;
  @Input() public disablePhone = false;
  @Input() public overrideIsPhoneOptional = false;

  @Input() public areFieldsRequired = false;
  @Input() public areFieldsSoftRequired = false;
  @Input() public form: FormGroup;
  @Input() public altContactFormGroupName = 'alternateContact';
  @Input() public areNameRequired = true;

  constructor(
    private formBuilder: FormBuilder,
    public parentFormGroupDirective: FormGroupDirective,
    private translate: AppTranslationService
  ) { }

  public ngOnInit() {
    this.buildForm();
  }

  private buildForm() {
    this.form = this.formBuilder.group({
      firstName: new FormControl(null, []),
      lastName: new FormControl(null, []),
      email: new FormControl(null, [Validators.email]),
      phone: new FormControl(null, [])
    });

    this.parentFormGroupDirective.form.addControl(this.altContactFormGroupName, this.form);
    this.form.setParent(this.parentFormGroupDirective.form);

    this.refreshValidations(this.areFieldsRequired);
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes.areFieldsRequired) {
      this.refreshValidations(changes.areFieldsRequired.currentValue);
    }
  }

  private refreshValidations(areFieldsRequired: boolean) {
    if (!this.form || !this.form.controls || !this.form.get('firstName')) {
      return;
    }

    this.form.get('email').setValidators([Validators.email]);
    this.form.get('phone').clearValidators();
    this.form.get('firstName').clearValidators();
    this.form.get('lastName').clearValidators();

    if (this.showFirstName && this.areNameRequired) {
      this.form.get('firstName').setValidators(Validators.required);
    }
    if (this.showLastName && this.areNameRequired) {
      this.form.get('lastName').setValidators(Validators.required);
    }

    if (this.isEmailRequired) {
      this.form.controls['email'].setValidators([Validators.required, Validators.email]);
    }

    if (!areFieldsRequired) {
      return;
    }

    if (this.showPhone && !this.overrideIsPhoneOptional) {
      this.form.controls['phone'].setValidators(Validators.required);
    }
  }

  public get isEmailRequired(): boolean {
    if (this.overrideEmailIsOptional || !this.showEmail) {
      return false;
    }

    return this.areFieldsRequired || this.overrideRequireEmail;
  }
}
