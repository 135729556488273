/**
 * This class encapsulates the parameters required
 * to change an authenticated user's username.
 */
export class ChangeUsernameModel {
  /**
   * Initializes a new instance of the change username model class.
   * @param newUsername The new username to set.
   */
  constructor(public newUsername: string) {}
}
