import { Component, OnInit, Inject } from '@angular/core';
import { LabTest } from '../../models/lab-test.model';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';

@Component({
  selector: 'roctavian-eumea-callcenter-portal-view-lab-test-dialog',
  templateUrl: './view-lab-test-dialog.component.html',
  styleUrls: ['./view-lab-test-dialog.component.scss']
})
export class ViewLabTestDialogComponent {
  public labTest: LabTest;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any, public reference: MatDialogRef<ViewLabTestDialogComponent>) {
    this.labTest = data.labTest;
  }
}
