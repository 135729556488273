import { Component, OnDestroy } from '@angular/core';
import { LabUser } from '../../models/labuser.model';
import { Subscription } from 'rxjs';
import { LabUserService } from '../../services';

@Component({
  selector: 'roctavian-eumea-callcenter-portal-lab-user-summary-page',
  templateUrl: './lab-user-summary-page.component.html',
  styleUrls: ['./lab-user-summary-page.component.scss']
})
export class LabUserSummaryPageComponent implements OnDestroy {
  public labUser: LabUser;
  private subscriptions = new Array<Subscription>();

  constructor(private labuserService: LabUserService) {
    this.subscriptions.push(this.labuserService.selectedLabUserSubject.subscribe(
      labUser => (this.labUser = labUser)
    ));
  }

  ngOnDestroy() {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }
}
