import { Component, OnInit, Inject } from '@angular/core';
import { LabKitOrder } from '../../models/lab-kit-order.model';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';

@Component({
  selector: 'roctavian-eumea-callcenter-portal-view-lab-kit-order-dialog',
  templateUrl: './view-lab-kit-order-dialog.component.html',
  styleUrls: ['./view-lab-kit-order-dialog.component.scss']
})
export class ViewLabKitOrderDialogComponent {
  public labKitOrder: LabKitOrder;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public reference: MatDialogRef<ViewLabKitOrderDialogComponent>
  ) {
    this.labKitOrder = data.labKitOrder;
  }
}
