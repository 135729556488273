import { GenderTypes, Stakeholder, Status } from '../../common';
import { LabTest } from '../../lab-test/models/lab-test.model';
import { Prescriber } from '../../prescriber';
import { PatientPrescriber } from './patient-prescriber.model';

export class Patient {
  public id: string;
  public stakeholder: Stakeholder;
  public patientsPrescribers: PatientPrescriber[];
  public birthDate: string;
  public gender: GenderTypes;
  public status: Status;
  public prescriber: Prescriber;
  public medicalRecordNumber: string;
  public currentLabTest: LabTest;
  public drugInclusion: boolean;

  public changeReasonDescription: string;
}
