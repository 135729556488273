import { PagedQuery, StatusTypes } from '../../common';

export class PatientPrescriberAssociatePagedQuery extends PagedQuery {
  constructor(
    public pageIndex: number,
    public pageSize: number,
    public filterText: string,
    public sortColumn: string,
    public isSortDesc: boolean,
    public prescriberId: number,
    public statusType: StatusTypes,
    public country: string
  ) {
    super(pageIndex, pageSize, filterText, sortColumn, isSortDesc);
  }
}
