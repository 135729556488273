import { OnDestroy } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import { LabKitOrder } from '../../models/lab-kit-order.model';
import { Subscription } from 'rxjs';
import { LabKitOrderService } from '../../services/lab-kit-order.service';

@Component({
  selector: 'roctavian-eumea-callcenter-portal-lab-kit-order-summary-page',
  templateUrl: './lab-kit-order-summary-page.component.html',
  styleUrls: ['./lab-kit-order-summary-page.component.scss']
})
export class LabKitOrderSummaryPageComponent implements OnInit, OnDestroy {
  public labKitOrder: LabKitOrder;
  private subscriptions = new Array<Subscription>();

  constructor(private labKitOrderService: LabKitOrderService) {}

  ngOnInit() {
    this.subscriptions.push(
      this.labKitOrderService.selectedLabKitOrderSubject.subscribe(labKitOrder => (this.labKitOrder = labKitOrder))
    );
  }

  ngOnDestroy() {
    this.subscriptions.forEach(subscription => {
      subscription.unsubscribe();
    });
  }
}
