import { Component, OnInit, Input, OnChanges, SimpleChanges, OnDestroy } from '@angular/core';
import { Attestation, PagedQuery } from '../../models';
import { AttestationPagedQuery } from '../../models/attestation-paged-query.model';
import { BehaviorSubject, Subscription, EMPTY } from 'rxjs';
import { PaginatedList } from '@roctavian-abstractions/web';
import { debounceTime, distinctUntilChanged, switchMap } from 'rxjs/operators';
import { PageEvent, MatSort } from '@angular/material';
import { AttestationService } from '../../services/attestation.service';
import { AppTranslationService } from '@roctavian-abstractions/core';

@Component({
  selector: 'attestation-list',
  templateUrl: './attestation-list.component.html',
  styleUrls: ['./attestation-list.component.css']
})
export class AttestationListComponent implements OnInit, OnChanges, OnDestroy {
  public pagedQuery = new AttestationPagedQuery(1, 10, null, 'id', true, null, null, null);

  public filterText$ = new BehaviorSubject<string>(null);
  public attestationPagedCollection: PaginatedList<Attestation>;
  public isLoading = false;
  private subscriptions = new Array<Subscription>();

  @Input() public stakeholderId: string;

  @Input() isCurrentLabel = this.translate.getTranslation('Common.AttestationList.IsCurrent');
  @Input() showIsCurrent = true;

  @Input() hasConsentLabel = this.translate.getTranslation('Common.AttestationList.HasConsent');
  @Input() showHasConsent = false;

  @Input() hasSignatureLabel = this.translate.getTranslation('Common.AttestationList.HasSignature');
  @Input() showHasSignature = true;

  @Input() signatureDateLabel = this.translate.getTranslation('Common.AttestationList.SignatureDate');
  @Input() showSignatureDate = true;

  @Input() changeReasonDescriptionLabel = this.translate.getTranslation(
    'Common.AttestationList.ChangeReasonDescription'
  );
  @Input() showChangeReasonDescription = true;

  @Input() guardianNameLabel = this.translate.getTranslation('Common.AttestationList.GuardianName');
  @Input() showGuardianName = false;

  @Input() addByUserLabel = this.translate.getTranslation('Common.AttestationList.AddByUser');
  @Input() showAddByUser = true;

  @Input() addDateLabel = this.translate.getTranslation('Common.AttestationList.AddDate');
  @Input() showAddDate = true;

  public displayedColumns = [];

  constructor(private service: AttestationService, private translate: AppTranslationService) { }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes.stakeholderId && changes.stakeholderId.currentValue) {
      this.pagedQuery.stakeholderId = changes.stakeholderId.currentValue;
      this.refreshList();
    }

    this.initializeColumns();
  }

  public ngOnDestroy() {
    this.subscriptions.forEach(subscription => {
      subscription.unsubscribe();
    });

    this.service.attestationPagedListSubject.next(new PaginatedList<Attestation>());
  }

  ngOnInit() {
    this.initializeColumns();

    this.subscriptions.push(this.filterText$
      .pipe(
        debounceTime(1000),
        distinctUntilChanged(),
        switchMap(searchText => {
          if (this.pagedQuery.filterText == searchText) {
            return EMPTY;
          }

          this.pagedQuery.filterText = searchText;
          this.pagedQuery.pageIndex = 1;
          this.refreshList();
          return EMPTY;
        })
      )
      .subscribe());

    this.subscriptions.push(this.service.attestationPagedListSubject.subscribe(collection => {
      this.attestationPagedCollection = collection;
    }));

    this.subscriptions.push(this.service.isLoadingSubject.subscribe(isLoading => {
      this.isLoading = isLoading;
    }));

    this.pagedQuery.stakeholderId = this.stakeholderId;
  }

  public onPageEvent(pageEvent: PageEvent) {
    this.pagedQuery.pageIndex = pageEvent.pageIndex + 1;
    this.pagedQuery.pageSize = pageEvent.pageSize;
    this.refreshList();
  }

  public onSortEvent(sort: MatSort) {
    this.pagedQuery.sortColumn = sort.active;
    this.pagedQuery.isSortDesc = sort.direction == 'desc';
    this.pagedQuery.pageIndex = 1;
    this.refreshList();
  }

  public refreshList() {
    this.service.setPage(this.pagedQuery);
  }

  private initializeColumns() {
    this.displayedColumns = [];
    if (this.showIsCurrent) {
      this.displayedColumns.push('isCurrent');
    }
    if (this.showHasConsent) {
      this.displayedColumns.push('hasConsent');
    }
    if (this.showHasSignature) {
      this.displayedColumns.push('hasSignature');
    }
    if (this.showSignatureDate) {
      this.displayedColumns.push('signatureDate');
    }
    if (this.showChangeReasonDescription) {
      this.displayedColumns.push('changeReasonDescription');
    }
    if (this.showGuardianName) {
      this.displayedColumns.push('guardianName');
    }
    if (this.showAddByUser) {
      this.displayedColumns.push('addUser.username');
    }
    if (this.showAddDate) {
      this.displayedColumns.push('addDate');
    }
  }
}
