import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'external-lab-test-list-page',
  templateUrl: './external-lab-test-list-page.component.html',
  styleUrls: ['./external-lab-test-list-page.component.scss']
})
export class ExternalLabTestListPageComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
