import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Role } from 'apps/roctavian-eumea-callcenter-portal/src/app/modules/identity/models';
import { User } from 'apps/roctavian-eumea-callcenter-portal/src/app/modules/identity/models/user';

export class EditUserSubmitEvent {
  public username: string;
  public email: string;
  public firstName: string;
  public lastName: string;
  public roles: string[];
}

@Component({
  selector: 'roctavian-abstractions-edit-user-form',
  templateUrl: './edit-user-form.component.html',
  styleUrls: ['./edit-user-form.component.scss']
})
export class EditUserFormComponent {
  public form: FormGroup;

  @Input() public disabled = false;

  @Input() public roles: Role[];

  @Input() public user: User;

  @Output() public saved = new EventEmitter<any>();

  constructor(private formBuilder: FormBuilder) {

    this.form = this.formBuilder.group({
      username: [this.user.username, Validators.required],
      email: [this.user.email, [Validators.required, Validators.email]],
      firstName: [this.user.firstName, Validators.required],
      lastName: [this.user.lastName, Validators.required],
      roles: [this.user.roles, Validators.required]
    });
  }

  public submit(form: FormGroup) {
    const event = new EditUserSubmitEvent();
    event.username = form.value.username;
    event.firstName = form.value.firstName;
    event.lastName = form.value.lastName;
    event.email = form.value.email;
    event.roles = form.value.roles;
    this.saved.emit(event);
  }
}
