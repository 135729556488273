import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { Endpoints } from '@app/core/enums';
import { Document, PagedQuery } from '@app/stakeholder/models';
import { UtilityService } from '@app/stakeholder/services';
import {
  Outcome,
  PaginatedList,
  ValueOutcome,
} from '@roctavian-abstractions/web';

import { LabUserLab } from '../../labuser/models/lab-user-lab.model';
import { Prescriber } from '../../prescriber';
import {
  DocumentLab,
  DocumentLabQuery,
  Lab,
  LabDuplicateCheckQuery,
  LabPrescriber,
  LabUser,
} from '../models';

@Injectable({
  providedIn: 'root',
})
export class LabClient {
  constructor(
    private readonly http: HttpClient,
    private readonly utilityService: UtilityService,
  ) {}

  getPagedList(
    pagedQuery: PagedQuery,
  ): Observable<ValueOutcome<PaginatedList<Lab>>> {
    return this.http.get<Outcome>(Endpoints.lab, {
      params: this.utilityService.toHttpParams(pagedQuery),
    });
  }

  createLab(lab: Lab): Observable<Outcome> {
    return this.http.post<Outcome>(Endpoints.lab, lab);
  }

  updateLab(lab: Lab): Observable<Outcome> {
    return this.http.put<Outcome>(Endpoints.lab, lab);
  }

  getLab(id: string): Observable<ValueOutcome<Lab>> {
    const url = `${Endpoints.lab}/${id}`;
    return this.http.get<Outcome>(url);
  }


  getLabDuplicates(
    pagedQuery: LabDuplicateCheckQuery,
  ): Observable<ValueOutcome<PaginatedList<Lab>>> {
    const url = `${Endpoints.lab}/duplicate-check`;
    return this.http.get<ValueOutcome<PaginatedList<Lab>>>(url, {
      params: this.utilityService.toHttpParams(pagedQuery),
    });
  }

  getLabUserListPaged(
    pagedQuery: PagedQuery,
    labId: string,
  ): Observable<ValueOutcome<PaginatedList<LabUserLab>>> {
    const url = `${Endpoints.lab}/${labId}/labuser`;
    return this.http.get<ValueOutcome<PaginatedList<LabUserLab>>>(url, {
      params: this.utilityService.toHttpParams(pagedQuery),
    });
  }

  getUnassociatedLabUsersPaged(
    pagedQuery: PagedQuery,
  ): Observable<ValueOutcome<PaginatedList<LabUser>>> {
    return this.http.get<Outcome>(`${Endpoints.lab}/LabUser/GetUnassociated`, {
      params: this.utilityService.toHttpParams(pagedQuery),
    });
  }

  getPrescriberLabListPaged(
    pagedQuery: PagedQuery,
    labId: string,
  ): Observable<ValueOutcome<PaginatedList<LabPrescriber>>> {
    const url = `${Endpoints.lab}/${labId}/prescribers`;
    return this.http.get<ValueOutcome<PaginatedList<LabPrescriber>>>(url, {
      params: this.utilityService.toHttpParams(pagedQuery),
    });
  }

  getDocumentsPagedList(
    pagedQuery: DocumentLabQuery,
  ): Observable<ValueOutcome<PaginatedList<DocumentLab>>> {
    const value = new PaginatedList<Document>();
    value.items = [];
    value.pageIndex = 1;
    value.totalCount = 0;
    value.totalPages = 1;

    return this.http.get<ValueOutcome<PaginatedList<DocumentLab>>>(
      `${Endpoints.documentLab}`,
      {
        params: this.utilityService.toHttpParams(pagedQuery),
      },
    );
  }

  addDocumentToLab(labId: string, document: Document): Observable<Outcome> {
    const value = new DocumentLab();
    value.labId = labId;
    value.document = document;

    return this.http.post<Outcome>(`${Endpoints.documentLab}`, value);
  }

  getPrescriber(prescriberId: string): Observable<ValueOutcome<Prescriber>> {
    const url = `${Endpoints.prescriber}/${prescriberId}`;
    return this.http.get<Outcome>(url);
  }

  createLabUserLabAssociation(labUserLab: LabUserLab): Observable<Outcome> {
    const url = `${Endpoints.lab}/labuser/associate`;
    return this.http.post<Outcome>(url, labUserLab);
  }

  disassociateLabUser(labId: string, labUserId: string): Observable<Outcome> {
    const url = `${Endpoints.lab}/${labId}/labuser/disassociate/${labUserId}`;
    return this.http.delete<Outcome>(url, {});
  }

  getExportFile() {
    const url = `${Endpoints.lab}/export`;
    return this.http.get(url, { responseType: 'blob' });
  }
}
