export class SecurityQuestionModel {
  /**
   * The security question identity.
   */
  public id: string;

  /**
   * The security question text.
   */
  public questionText: string;

  /**
   * The security question group.
   */
  public group: number;

  public localeId: string;
}
