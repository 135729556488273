import { Component } from '@angular/core';
import { ConfigService, GoogleAnalyticsService, LocalStorageService } from '@roctavian-abstractions/core';
import { AuthenticationService } from '../modules/identity/services/authentication.service';
import { SessionManagementService } from '../modules/identity/services/session-management.service';

@Component({
  selector: 'roctavian-eumea-callcenter-portal-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent {
  public isAuthenticated = false;

  public htmlPipeTest1: string =
    '<span style=\'color: #3998ff\'>This is text with inline style that has been sanitized.</span>';

  public htmlPipeTest2: string =
    '<span style=\'color: #3998ff\'>This is text with inline style passed through the safeHtml pipe.</span>';

  public timeout: string;

  constructor(
    private authService: AuthenticationService,
    private configService: ConfigService,
    private sessionService: SessionManagementService,
    private googleService: GoogleAnalyticsService,
    private localStorageService: LocalStorageService
  ) {
    this.authService.isLoggedIn().subscribe(data => {
      this.isAuthenticated = data;
    });
  }

  public isGoogleAnalyticsConfigured() {
    return this.googleService.isConfigured;
  }

  public hasSessionStarted() {
    return this.sessionService.sessionStatus;
  }

  public enableSessionTimeout() {
    if (this.timeout && !isNaN(+this.timeout)) {
      this.configService.update('sessionTimeout', this.timeout);
    }

    this.sessionService.enableSessionManagement();
  }

  public disableSessionTimeout() {
    this.sessionService.disableSessionManagement();
  }

  public logout(): void {
    this.authService.logout();
  }

  public sendTestGAEvent() {
    this.googleService.submitEvent('Testing Category', 'Testing Action', 'Testing Label');
  }
}
