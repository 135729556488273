import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { InstitutionService } from '../../services';
import { Subscription } from 'rxjs';
import { Institution } from '../../models';

@Component({
  selector: 'roctavian-eumea-callcenter-portal-institution-detail-master-page',
  templateUrl: './institution-detail-master-page.component.html',
  styleUrls: ['./institution-detail-master-page.component.css']
})
export class InstitutionDetailMasterPageComponent implements OnInit, OnDestroy {
  public institution: Institution;
  private subscriptions = new Array<Subscription>();

  constructor(private route: ActivatedRoute, private service: InstitutionService) {
    this.subscriptions.push(this.route.paramMap.subscribe(params => {
      const id = String(params.get('id'));
      this.service.loadInstitution(id);
    }));

    this.subscriptions.push(this.service.selectedInstitutionSubject.subscribe(
      institution => (this.institution = institution)
    ));
  }

  ngOnInit() { }

  ngOnDestroy() {
    this.subscriptions.forEach(subscription => {
      subscription.unsubscribe();
    });
    this.service.selectedInstitutionSubject.next(null);
  }

  public getSidenavMode(): string {
    this.setStyleBasedOnMainNavbarHeight();
    if (window.innerWidth < 750) {
      return 'over';
    }
    return 'side';
  }

  public setStyleBasedOnMainNavbarHeight() {
    const mainNavbar = document.getElementById('main-navbar');
    if (!mainNavbar) {
      return;
    }

    const element: any = document.getElementsByClassName('sidenav-container').item(0);
    element.style.top = `${mainNavbar.scrollHeight}px`;
  }
}
