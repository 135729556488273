import { OnDestroy } from '@angular/core';
import { Injectable } from '@angular/core';
import { Subscription, BehaviorSubject } from 'rxjs';
import { PrescriberDelegateClient } from '../clients/prescriber-delegate.client';
import { Outcome, PaginatedList } from '@roctavian-abstractions/web';
import { PrescriberDelegatePagedQuery } from '../models/prescriber-delegate-paged-query.model';
import { AppTranslationService } from '@roctavian-abstractions/core';
import { MessageService } from '@roctavian-abstractions/core';
import { PrescriberDelegate, PrescriberDelegatePrescriber } from '../models/prescriber-delegate.model';
import { PagedQuery } from '../../common';
import { ClearService } from '../../../../shared/interface/clear-service.interface';

@Injectable({
  providedIn: 'root'
})
export class PrescriberDelegateService implements OnDestroy, ClearService {
  public prescriberDelegatePagedListSubject = new BehaviorSubject<PaginatedList<PrescriberDelegate>>(
    new PaginatedList<PrescriberDelegate>()
  );
  public prescriberDelegatePrescriberPagedListSubject = new BehaviorSubject<
    PaginatedList<PrescriberDelegatePrescriber>
  >(new PaginatedList<PrescriberDelegatePrescriber>());

  public isLoadingSubject = new BehaviorSubject<boolean>(false);
  public selectedPrescriberDelegateSubject = new BehaviorSubject<PrescriberDelegate>(null);

  private subscriptions = new Array<Subscription>();

  constructor(
    private client: PrescriberDelegateClient,
    private messageService: MessageService,
    private translate: AppTranslationService
  ) { }

  ngOnDestroy() {
    this.clearSubscriptions();
  }

  public setPage(pagedQuery: PrescriberDelegatePagedQuery) {
    this.isLoadingSubject.next(true);

    this.clearSubscriptions();

    this.subscriptions.push(
      this.client.getPrescriberDelegateListPaged(pagedQuery).subscribe(
        outcome => {
          if (!outcome.success || outcome.failure) {
            this.messageService.open(outcome.messages[0], 5000);
            return;
          }

          this.prescriberDelegatePagedListSubject.next(outcome.value);
          this.isLoadingSubject.next(false);
        },
        (error: Outcome) => {
          this.messageService.open(this.translate.getTranslation('Common.ErrorProcessingRequest'), 5000);
          this.isLoadingSubject.next(false);
        }
      )
    );
  }

  public loadPrescriberDelegate(prescriberDelegateId: string, forceRefresh: boolean = false) {
    if (
      this.selectedPrescriberDelegateSubject.value &&
      this.selectedPrescriberDelegateSubject.value.id === prescriberDelegateId &&
      !forceRefresh
    ) {
      return;
    }

    this.clearSubscriptions();

    this.isLoadingSubject.next(true);
    this.subscriptions.push(
      this.client.getPrescriberDelegate(prescriberDelegateId).subscribe(
        outcome => {
          if (!outcome.success || outcome.failure) {
            this.messageService.open(outcome.messages[0], 5000);
            return;
          }

          this.selectedPrescriberDelegateSubject.next(outcome.value);
          this.isLoadingSubject.next(false);
        },
        (error: Outcome) => {
          this.messageService.open(this.translate.getTranslation('Common.ErrorProcessingRequest'), 5000);
          this.isLoadingSubject.next(false);
        }
      )
    );
  }

  public setPrescriberDelegatePrescriberPage(prescriberDelegateId: string, pagedQuery: PagedQuery) {
    this.isLoadingSubject.next(true);

    this.clearSubscriptions();

    this.subscriptions.push(
      this.client.getPrescriberDelegatePrescriberListPaged(prescriberDelegateId, pagedQuery).subscribe(
        outcome => {
          if (!outcome.success || outcome.failure) {
            this.messageService.open(outcome.messages[0], 5000);
            return;
          }

          this.prescriberDelegatePrescriberPagedListSubject.next(outcome.value);
          this.isLoadingSubject.next(false);
        },
        (error: Outcome) => {
          this.messageService.open(this.translate.getTranslation('Common.ErrorProcessingRequest'), 5000);
          this.isLoadingSubject.next(false);
        }
      )
    );
  }

  private clearSubscriptions() {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
    this.subscriptions = new Array<Subscription>();
  }

  public clearService() {
    this.prescriberDelegatePagedListSubject.next(new PaginatedList<PrescriberDelegate>());
    this.prescriberDelegatePrescriberPagedListSubject.next(new PaginatedList<PrescriberDelegatePrescriber>());
    this.selectedPrescriberDelegateSubject.next(null);
  }
}
