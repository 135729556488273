import { Component, OnInit, Inject } from '@angular/core';
import { LabUser } from '../../models/labuser.model';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';

@Component({
  selector: 'roctavian-eumea-callcenter-portal-view-lab-user-dialog',
  templateUrl: './view-lab-user-dialog.component.html',
  styleUrls: ['./view-lab-user-dialog.component.scss']
})
export class ViewLabUserDialogComponent implements OnInit {
  public labUser: LabUser;
  constructor(@Inject(MAT_DIALOG_DATA) public data: any, public reference: MatDialogRef<ViewLabUserDialogComponent>) {
    this.labUser = data.labUser;
  }

  ngOnInit() {}
}
