import { Stakeholder } from '../../common';

export class Institution {
  public id: string;
  public stakeholder: Stakeholder;
  public aggregationId: number;

  public isAnyAssociatedPrescriberRegistered: boolean;
  public isAnyAssociatedPrescriber: boolean;
  public changeReasonDescription: string;
}
