import { Injectable, OnDestroy } from '@angular/core';
import {
  AppTranslationService,
  MessageService,
} from '@roctavian-abstractions/core';
import { Outcome, PaginatedList } from '@roctavian-abstractions/web';
import { BehaviorSubject, Subscription } from 'rxjs';
import { ClearService } from '../../../../shared/interface/clear-service.interface';
import { Document, PagedQuery } from '../../common';
import { Institution } from '../../institution';
import { Lab } from '../../lab';
import { PatientPrescriber } from '../../patient';
import { PrescriberDelegate } from '../../prescriber-delegate/models/prescriber-delegate.model';
import { PrescriberClient } from '../clients';
import {
  DocumentPrescriber,
  DocumentPrescriberQuery,
  Prescriber,
  PrescriberDelegatePrescriber,
  PrescriberLab,
  PrescriberLabPagedQuery,
} from '../models';
import { LabUserDelegate } from '../models/lab-user-delegate.model';
import { PrescriberPagedQuery } from '../models/prescriber-paged-query.model';

@Injectable({
  providedIn: 'root',
})
export class PrescriberService implements OnDestroy, ClearService {
  public prescriberPagedListSubject = new BehaviorSubject<
    PaginatedList<Prescriber>
  >(new PaginatedList<Prescriber>());
  public prescriberDuplicatesPagedListSubject = new BehaviorSubject<
    PaginatedList<Prescriber>
  >(new PaginatedList<Prescriber>());
  public prescriberInstitutionPagedListSubject = new BehaviorSubject<
    PaginatedList<Institution>
  >(new PaginatedList<Institution>());
  public patientPrescriberPagedListSubject = new BehaviorSubject<
    PaginatedList<PatientPrescriber>
  >(new PaginatedList<PatientPrescriber>());
  public prescriberLabPagedListSubject = new BehaviorSubject<
    PaginatedList<PrescriberLab>
  >(new PaginatedList<PrescriberLab>());
  public associateLabPagedListSubject = new BehaviorSubject<PaginatedList<Lab>>(
    new PaginatedList<Lab>(),
  );
  public associatePrescriberDelegatePagedListSubject = new BehaviorSubject<
    PaginatedList<PrescriberDelegate>
  >(new PaginatedList<PrescriberDelegate>());
  public labUserDelegatePagedListSubject = new BehaviorSubject<
    PaginatedList<LabUserDelegate>
  >(new PaginatedList<LabUserDelegate>());
  public prescriberDelegatePagedListSubject = new BehaviorSubject<
    PaginatedList<PrescriberDelegatePrescriber>
  >(new PaginatedList<PrescriberDelegatePrescriber>());
  public pagedPrescriberDocumentsListSubject = new BehaviorSubject<
    PaginatedList<DocumentPrescriber>
  >(new PaginatedList<DocumentPrescriber>());

  public isLoadingSubject = new BehaviorSubject<boolean>(false);

  public selectedPrescriberSubject = new BehaviorSubject<Prescriber>(null);

  private subscriptions = new Array<Subscription>();

  private addDocumentToPrescriberSubscription: Subscription;
  public addDocumentToPrescriberSubject = new BehaviorSubject<Outcome>(null);
  private getPrescriberDocumentsListSubscription: Subscription;

  constructor(
    private prescriberClient: PrescriberClient,
    private messageService: MessageService,
    private translate: AppTranslationService,
  ) {}

  ngOnDestroy() {
    this.clearSubscriptions();
  }

  private clearSubscriptions() {
    this.subscriptions.forEach(subscription => {
      subscription.unsubscribe();
    });

    this.subscriptions = new Array<Subscription>();
  }

  public setPage(pagedQuery: PrescriberPagedQuery) {
    this.isLoadingSubject.next(true);

    this.clearSubscriptions();

    this.subscriptions.push(
      this.prescriberClient.getPrescriberListPaged(pagedQuery).subscribe(
        outcome => {
          if (!outcome.success || outcome.failure) {
            this.messageService.open(outcome.messages[0], 5000);
            return;
          }

          this.prescriberPagedListSubject.next(outcome.value);
          this.isLoadingSubject.next(false);
        },
        (error: Outcome) => {
          this.handleError(error);
        },
      ),
    );
  }

  public setPrescriberDuplicatesPage(pagedQuery: PrescriberPagedQuery) {
    this.isLoadingSubject.next(true);

    this.clearSubscriptions();

    this.subscriptions.push(
      this.prescriberClient.getPrescriberListPaged(pagedQuery).subscribe(
        outcome => {
          if (!outcome.success || outcome.failure) {
            this.messageService.open(outcome.messages[0], 5000);
            return;
          }

          this.prescriberDuplicatesPagedListSubject.next(outcome.value);
          this.isLoadingSubject.next(false);
        },
        (error: Outcome) => {
          this.handleError(error);
        },
      ),
    );
  }

  public setPatientPrescriberPage(
    pagedQuery: PagedQuery,
    prescriberId: string,
  ) {
    this.isLoadingSubject.next(true);

    this.clearSubscriptions();

    this.subscriptions.push(
      this.prescriberClient
        .getPatientPrescriberListPaged(pagedQuery, prescriberId)
        .subscribe(
          outcome => {
            if (!outcome.success || outcome.failure) {
              this.messageService.open(outcome.messages[0], 5000);
              return;
            }
            this.patientPrescriberPagedListSubject.next(outcome.value);
            this.isLoadingSubject.next(false);
          },
          (error: Outcome) => {
            this.handleError(error);
          },
        ),
    );
  }

  public setPrescriberDelegatePage(
    pagedQuery: PagedQuery,
    prescriberId: string,
  ) {
    this.isLoadingSubject.next(true);

    this.clearSubscriptions();

    this.subscriptions.push(
      this.prescriberClient
        .getPrescriberDelegateListPaged(pagedQuery, prescriberId)
        .subscribe(
          outcome => {
            if (!outcome.success || outcome.failure) {
              this.messageService.open(outcome.messages[0], 5000);
              return;
            }
            this.prescriberDelegatePagedListSubject.next(outcome.value);
            this.isLoadingSubject.next(false);
          },
          (error: Outcome) => {
            this.handleError(error);
          },
        ),
    );
  }

  public setPrescriberLabPage(pagedQuery: PagedQuery, prescriberId: string) {
    this.isLoadingSubject.next(true);

    this.clearSubscriptions();

    this.subscriptions.push(
      this.prescriberClient
        .gePrescriberLabListPaged(pagedQuery, prescriberId)
        .subscribe(
          outcome => {
            if (!outcome.success || outcome.failure) {
              this.messageService.open(outcome.messages[0], 5000);
              return;
            }

            this.prescriberLabPagedListSubject.next(outcome.value);
            this.isLoadingSubject.next(false);
          },
          (error: Outcome) => {
            this.handleError(error);
          },
        ),
    );
  }

  public setAssociateLabPage(
    pagedQuery: PrescriberLabPagedQuery,
    prescriberId: string,
  ) {
    this.isLoadingSubject.next(true);

    this.clearSubscriptions();

    this.subscriptions.push(
      this.prescriberClient
        .gePrescriberAssociateLabListPaged(pagedQuery, prescriberId)
        .subscribe(
          outcome => {
            if (!outcome.success || outcome.failure) {
              this.messageService.open(outcome.messages[0], 5000);
              return;
            }
            this.associateLabPagedListSubject.next(outcome.value);
            this.isLoadingSubject.next(false);
          },
          (error: Outcome) => {
            this.handleError(error);
          },
        ),
    );
  }

  public setAssociatePrescriberDelegatePage(
    prescriberDelegatePagedQuery: PagedQuery,
    prescriberId: string,
  ) {
    this.isLoadingSubject.next(true);

    this.clearSubscriptions();
    this.subscriptions.push(
      this.prescriberClient
        .getPrescriberAssociatePrescriberDelegateListPaged(
          prescriberDelegatePagedQuery,
          prescriberId,
        )
        .subscribe(
          outcome => {
            if (!outcome.success || outcome.failure) {
              this.messageService.open(outcome.messages[0], 5000);
              return;
            }
            this.associatePrescriberDelegatePagedListSubject.next(
              outcome.value,
            );
            this.isLoadingSubject.next(false);
          },
          (error: Outcome) => {
            this.handleError(error);
          },
        ),
    );
  }

  public loadPrescriber(prescriberId: string, forceRefresh: boolean = false) {
    if (
      this.selectedPrescriberSubject.value &&
      this.selectedPrescriberSubject.value.id === prescriberId &&
      !forceRefresh
    ) {
      return;
    }

    this.clearSubscriptions();

    this.isLoadingSubject.next(true);

    this.subscriptions.push(
      this.prescriberClient.getPrescriber(prescriberId).subscribe(
        outcome => {
          if (!outcome.success || outcome.failure) {
            this.messageService.open(outcome.messages[0], 5000);
            return;
          }

          this.selectedPrescriberSubject.next(outcome.value);
          this.isLoadingSubject.next(false);
        },
        (error: Outcome) => {
          this.handleError(error);
        },
      ),
    );
  }

  public setPrescriberInstitutionPage(pagedQuery: PagedQuery) {
    this.isLoadingSubject.next(true);

    this.clearSubscriptions();

    this.subscriptions.push(
      this.prescriberClient
        .gePrescriberInstitutionListPaged(pagedQuery)
        .subscribe(
          outcome => {
            if (!outcome.success || outcome.failure) {
              this.messageService.open(outcome.messages[0], 5000);
              return;
            }
            this.prescriberInstitutionPagedListSubject.next(outcome.value);
            this.isLoadingSubject.next(false);
          },
          (error: Outcome) => {
            this.handleError(error);
          },
        ),
    );
  }

  public setLabUserDelegatePage(pagedQuery: PagedQuery, prescriberId: string) {
    this.isLoadingSubject.next(true);

    this.clearSubscriptions();

    this.subscriptions.push(
      this.prescriberClient
        .geLabUserDelegateListPaged(pagedQuery, prescriberId)
        .subscribe(
          outcome => {
            if (!outcome.success || outcome.failure) {
              this.messageService.open(outcome.messages[0], 5000);
              return;
            }

            this.labUserDelegatePagedListSubject.next(outcome.value);
            this.isLoadingSubject.next(false);
          },
          (error: Outcome) => {
            this.handleError(error);
          },
        ),
    );
  }

  public setDocumentsPage(pagedQuery: DocumentPrescriberQuery) {
    this.isLoadingSubject.next(true);

    if (this.getPrescriberDocumentsListSubscription) {
      this.getPrescriberDocumentsListSubscription.unsubscribe();
      this.getPrescriberDocumentsListSubscription = null;
    }

    this.getPrescriberDocumentsListSubscription = this.prescriberClient
      .getDocumentsPagedList(pagedQuery)
      .subscribe(
        outcome => {
          if (!outcome.success || outcome.failure) {
            this.messageService.open(outcome.messages[0], 5000);
            return;
          }
          this.pagedPrescriberDocumentsListSubject.next(outcome.value);
          this.isLoadingSubject.next(false);
        },
        (error: Outcome) => {
          this.messageService.open(error.messages[0], 5000);
          this.isLoadingSubject.next(false);
        },
      );
  }

  public addDocumentToPrescriber(prescriberId: string, document: Document) {
    this.isLoadingSubject.next(true);

    if (this.addDocumentToPrescriberSubscription) {
      this.addDocumentToPrescriberSubscription.unsubscribe();
      this.addDocumentToPrescriberSubscription = null;
    }

    this.addDocumentToPrescriberSubscription = this.prescriberClient
      .addDocumentToPrescriber(prescriberId, document)
      .subscribe(
        outcome => {
          if (!outcome.success || outcome.failure) {
            this.messageService.open(outcome.messages[0], 5000);
            return;
          }

          this.addDocumentToPrescriberSubject.next(outcome);
          this.isLoadingSubject.next(false);
        },
        (error: Outcome) => {
          this.messageService.open(error.messages[0], 5000);
          this.isLoadingSubject.next(false);
        },
      );
  }

  private handleError(outcome: Outcome) {
    this.messageService.open(
      this.translate.getTranslation('Common.ErrorProcessingRequest'),
      5000,
    );
    this.isLoadingSubject.next(false);
  }

  public clearService() {
    this.prescriberPagedListSubject.next(new PaginatedList<Prescriber>());
    this.prescriberDuplicatesPagedListSubject.next(
      new PaginatedList<Prescriber>(),
    );
    this.prescriberInstitutionPagedListSubject.next(
      new PaginatedList<Institution>(),
    );
    this.patientPrescriberPagedListSubject.next(
      new PaginatedList<PatientPrescriber>(),
    );
    this.prescriberLabPagedListSubject.next(new PaginatedList<PrescriberLab>());
    this.associateLabPagedListSubject.next(new PaginatedList<Lab>());
    this.associatePrescriberDelegatePagedListSubject.next(
      new PaginatedList<PrescriberDelegate>(),
    );
    this.labUserDelegatePagedListSubject.next(
      new PaginatedList<LabUserDelegate>(),
    );
    this.prescriberDelegatePagedListSubject.next(
      new PaginatedList<PrescriberDelegatePrescriber>(),
    );
    this.selectedPrescriberSubject.next(null);
    this.pagedPrescriberDocumentsListSubject.next(
      new PaginatedList<DocumentPrescriber>(),
    );
    this.addDocumentToPrescriberSubject.next(null);
  }
}
