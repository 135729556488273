import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { saveAs } from 'file-saver';
import { Observable } from 'rxjs';

import { Endpoints } from '@app/core/enums';
import { Document, PagedQuery } from '@app/stakeholder/models';
import { UtilityService } from '@app/stakeholder/services';
import { Outcome, PaginatedList, ValueOutcome } from '@roctavian-abstractions/web';

import { Lab } from '../../lab';
import { Patient } from '../../patient';
import { DocumentLabTestQuery } from '../models/document-lab-test-paged-query.model';
import { DocumentLabTest } from '../models/document-lab-test.model';
import { LabTest } from '../models/lab-test.model';

@Injectable({
  providedIn: 'root',
})
export class LabTestClient {
  constructor(
    private readonly http: HttpClient,
    private readonly utilityService: UtilityService
  ) {}

  getLabTestListPaged(
    pagedQuery: PagedQuery
  ): Observable<ValueOutcome<PaginatedList<LabTest>>> {
    const url = `${Endpoints.labTest}/list`;
    return this.http.get<ValueOutcome<PaginatedList<LabTest>>>(url, {
      params: this.utilityService.toHttpParams(pagedQuery),
    });
  }

  createLabTest(labTest: LabTest): Observable<Outcome> {
    return this.http.post<Outcome>(Endpoints.labTest, labTest);
  }

  updateLabTest(labTest: LabTest): Observable<Outcome> {
    const url = `${Endpoints.labTest}/${labTest.id}`;
    return this.http.put<Outcome>(url, labTest);
  }

  getLabTest(labTestId: string): Observable<ValueOutcome<LabTest>> {
    const url = `${Endpoints.labTest}/${labTestId}`;
    return this.http.get<ValueOutcome<LabTest>>(url);
  }

  getLabTestResultDocumentStorageUri(
    labTestId: string
  ): Observable<ValueOutcome<string>> {
    const url = `${Endpoints.labTest}/${labTestId}/result/document-storage-uri`;
    return this.http.get<ValueOutcome<string>>(url);
  }

  getPatientListPaged(
    pagedQuery: PagedQuery
  ): Observable<ValueOutcome<PaginatedList<Patient>>> {
    const url = `${Endpoints.labTest}/null/patients`;
    return this.http.get<ValueOutcome<PaginatedList<Patient>>>(url, {
      params: this.utilityService.toHttpParams(pagedQuery),
    });
  }

  getLabListPaged(pagedQuery: PagedQuery): Observable<ValueOutcome<PaginatedList<Lab>>> {
    const url = `${Endpoints.labTest}/null/labs`;
    return this.http.get<ValueOutcome<PaginatedList<Lab>>>(url, {
      params: this.utilityService.toHttpParams(pagedQuery),
    });
  }

  getLabTestResultForPatient(labTestId: string): Observable<ValueOutcome<string>> {
    const url = `${Endpoints.patient}/${labTestId}/lab-test-result`;
    return this.http.get<ValueOutcome<string>>(url);
  }

  getExportFile() {
    const url = `${Endpoints.labTest}/export`;
    return this.http.get(url, { responseType: 'blob' });
  }

  getDocumentsPagedList(
    pagedQuery: DocumentLabTestQuery
  ): Observable<ValueOutcome<PaginatedList<DocumentLabTest>>> {
    const value = new PaginatedList<Document>();
    value.items = [];
    value.pageIndex = 1;
    value.totalCount = 0;
    value.totalPages = 1;

    return this.http.get<ValueOutcome<PaginatedList<DocumentLabTest>>>(
      `${Endpoints.documentLabTest}`,
      {
        params: this.utilityService.toHttpParams(pagedQuery),
      }
    );
  }

  addDocumentToLabTest(labTestId: string, document: Document): Observable<Outcome> {
    const labTest = new LabTest();
    labTest.id = labTestId;

    const value = new DocumentLabTest();
    value.labTest = labTest;
    value.document = document;

    return this.http.post<Outcome>(`${Endpoints.documentLabTest}`, value);
  }

  downloadShipmentLabel(labTestId: string) {
    const url = `${Endpoints.labTest}/${labTestId}/shipment-label`;
    return this.http.get(url, { responseType: 'blob' }).subscribe(blob => {
      saveAs(blob, new Date().getTime() + '_' + 'ShippingLabel', {
        type: 'application/pdf',
      });
    });
  }
}
