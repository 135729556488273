import { Component, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil, tap } from 'rxjs/operators';
import { WorkgroupClient } from '../../clients/workgroup.client';
import { Workgroup } from '../../models';

@Component({
  selector: 'roctavian-abstractions-manage-workgroup-page',
  templateUrl: './manage-workgroup-page.component.html',
  styleUrls: ['./manage-workgroup-page.component.scss'],
})
export class ManageWorkgroupPageComponent implements OnDestroy {
  private readonly destroyed$ = new Subject();
  private workgroupIdentity: string;

  currentTabName = 'Details';
  workgroup: Workgroup;

  constructor(private route: ActivatedRoute, private workgroupClient: WorkgroupClient) {
    this.route.params
      .pipe(
        takeUntil(this.destroyed$),
        tap(params => {
          this.workgroupIdentity = params['id'];

          this.workgroupClient.getWorkgroup(this.workgroupIdentity).subscribe(result => {
            this.workgroup = result;
          });
        })
      )
      .subscribe();
  }

  ngOnDestroy(): void {
    this.destroyed$.unsubscribe();
  }

  /**
   * Handler for when a tab changes on the workgroup-details component.
   *
   * @param tabName The name of the tab that was selected.
   */
  onTabChange(tabName: string) {
    this.currentTabName = tabName;
  }
}
