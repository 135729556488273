import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MaterialModule, CoreModule } from "@roctavian-abstractions/core";
import { UserDetailsComponent } from "./components/user-details/user-details.component";
import { UserDetailsFormComponent } from "./components/user-details-form/user-details-form.component";

@NgModule({
  imports: [CommonModule, CoreModule, FormsModule, MaterialModule, ReactiveFormsModule],
  declarations: [UserDetailsComponent, UserDetailsFormComponent],
  exports: [UserDetailsComponent, UserDetailsFormComponent]
})
export class DetailsModule {}
