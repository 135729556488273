import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";
import { RolesClient } from "../../identity/clients/roles.client";
import { Role } from "../../identity/models";

@Injectable({
  providedIn: "root"
})
export class RoleManagementService {
  public roles: Role[];
  public roles$ = new BehaviorSubject<Role[]>([]);
  public loading = new BehaviorSubject<boolean>(true);
  public initialized = false;
  private filterText = "";

  constructor(private client: RolesClient) {
    if (!this.initialized) {
      this.getRoles();
      this.initialized = true;
    }
  }

  public getFilterText() {
    return this.filterText;
  }

  public getRoles() {
    this.loading.next(true);
    this.client.getRoles().subscribe(roles => {
      this.roles = roles;
      this.roles$.next(roles);
      this.loading.next(false);
    });
  }

  public refreshRoles() {
    this.client.getRoles().subscribe(roles => {
      this.roles = roles;
      this.filterRoles(this.filterText);
    });
  }

  public filterRoles(text: string) {
    if (!text) {
      this.filterText = text;
      this.roles$.next(this.roles);
      return;
    }

    this.filterText = text;
    text = text.toLowerCase();
    const filteredRoles = this.roles.filter(role => role.name.toLowerCase().indexOf(text) > -1);
    this.roles$.next(filteredRoles);
  }
}
