import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthenticationService } from '../../identity/services/authentication.service';

@Injectable({
  providedIn: 'root',
})
export class AdminAuthenticationGuard implements CanActivate {
  constructor(private service: AuthenticationService) {}

  /**
   * Determines whether the currently authenticated user can activate admin routes.
   */
  public canActivate(): Observable<boolean> | Promise<boolean> | boolean {
    const principal = this.service.claimsPrincipal;
    if (!principal) {
      return false;
    }

    return principal.hasRole('Administrator');
  }
}
