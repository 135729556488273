import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { RouterModule } from "@angular/router";
import { MaterialModule, CoreModule } from "@roctavian-abstractions/core";
import { FacebookButtonComponent } from "./components/facebook-button/facebook-button.component";
import { LoginComponent } from "./components/login/login.component";
import { LoginFormComponent } from "./components/login-form/login-form.component";
import { GoogleButtonComponent } from "./components/google-button/google-button.component";
import { TwoFactorModule } from "./../two-factor/two-factor.module";

@NgModule({
  imports: [CommonModule, CoreModule, FormsModule, MaterialModule, ReactiveFormsModule, RouterModule, TwoFactorModule],
  declarations: [FacebookButtonComponent, GoogleButtonComponent, LoginComponent, LoginFormComponent],
  exports: [FacebookButtonComponent, GoogleButtonComponent, LoginComponent, LoginFormComponent]
})
export class LoginModule {}
