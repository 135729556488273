/**
 * A class used to encapsulate the parameters required to update a permission.
 */
export class UpdatePermissionModel {
  /**
   * Initializes a new instance of the update permission model class.
   * @param name The permission name.
   * @param description The permission description.
   * @param group The permission group.
   */
  constructor(public name: string, public description: string, public group: string) {}
}
