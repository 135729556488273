/* eslint-disable @typescript-eslint/adjacent-overload-signatures */
import { DatePipe } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnDestroy
} from '@angular/core';
import { ContentTypes, DocumentTypes } from '@app/core/enums';
import { DownloadDocument } from '@app/core/models';
import { DocumentStorageService } from '@app/core/services';
import { AppTranslationService } from '@roctavian-abstractions/core';
import { Subject } from 'rxjs';
import { takeUntil, tap } from 'rxjs/operators';
import { UtilityService } from '../../../common';
import { LabTestClient } from '../../clients/lab-test.client';
import { LabTest } from '../../models/lab-test.model';

@Component({
  selector: 'view-lab-test',
  templateUrl: './view-lab-test.component.html',
  styleUrls: ['./view-lab-test.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ViewLabTestComponent implements OnDestroy {
  private readonly destroyed$ = new Subject();
  public _labTest: LabTest;
  public cdcField: string;

  //#region inputs
  @Input() showTitle = true;
  @Input() title = this.translate.getTranslation('LabTest.ViewLabTest.Title');
  @Input() labTestStatusLabel = this.translate.getTranslation(
    'LabTest.ViewLabTest.Status'
  );
  @Input() labTestCompleteLabel = this.translate.getTranslation(
    'LabTest.ViewLabTest.LabTestComplete'
  );
  @Input() labNameLabel = this.translate.getTranslation('LabTest.ViewLabTest.LabName');
  @Input() labTitle = this.translate.getTranslation('LabTest.ViewLabTest.LabTitle');
  @Input() orderingPhysicianLabel = this.translate.getTranslation(
    'LabTest.ViewLabTest.OrderingPhysician'
  );
  @Input() resultNotificationDateLabel = this.translate.getTranslation(
    'LabTest.ViewLabTest.ResultNotificationDate'
  );
  @Input() numberOfSpecimensLabel = this.translate.getTranslation(
    'LabTest.ViewLabTest.NumberOfSpecimens'
  );
  @Input() appointmentDateLabel = this.translate.getTranslation(
    'LabTest.ViewLabTest.AppointmentDate'
  );
  @Input() appointmentTimeLabel = this.translate.getTranslation(
    'LabTest.ViewLabTest.AppointmentTime'
  );
  @Input() orderTitleLabel = this.translate.getTranslation(
    'LabTest.ViewLabTest.OrderTitle'
  );
  @Input() specimenCollectionDateLabel = this.translate.getTranslation(
    'LabTest.ViewLabTest.SpecimenCollectionDate'
  );
  @Input() specimenCollectionTimeLabel = this.translate.getTranslation(
    'LabTest.ViewLabTest.SpecimenCollectionTime'
  );
  @Input() initialShipmentInformationLabel = this.translate.getTranslation(
    'LabTest.ViewLabTest.InitialShipmentInformation'
  );
  @Input() secondaryShipmentInformationLabel = this.translate.getTranslation(
    'LabTest.ViewLabTest.SecondaryShipmentInformation'
  );
  @Input() specimenTransportPickupDateLabel = this.translate.getTranslation(
    'LabTest.ViewLabTest.SpecimenTransportPickupDate'
  );
  @Input() specimenTransportPickupTimeLabel = this.translate.getTranslation(
    'LabTest.ViewLabTest.SpecimenTransportPickupTime'
  );
  @Input() specimenTransportDispatchDateLabel = this.translate.getTranslation(
    'LabTest.ViewLabTest.SpecimenTransportDispatchDate'
  );
  @Input() specimenTransportDispatchTimeLabel = this.translate.getTranslation(
    'LabTest.ViewLabTest.SpecimenTransportDispatchTime'
  );
  @Input() estimatedTransportArrivalDateLabel = this.translate.getTranslation(
    'LabTest.ViewLabTest.EstimatedTransportArrivalDate'
  );
  @Input() estimatedTransportArrivalTimeLabel = this.translate.getTranslation(
    'LabTest.ViewLabTest.EstimatedTransportArrivalTime'
  );
  @Input() actualTransportArrivalDateLabel = this.translate.getTranslation(
    'LabTest.LabTestForm.ActualTransportArrivalDate'
  );
  @Input() actualTransportArrivalTimeLabel = this.translate.getTranslation(
    'LabTest.ViewLabTest.ActualTransportArrivalTime'
  );
  @Input() testResultLabel = this.translate.getTranslation(
    'LabTest.ViewLabTest.TestResult'
  );
  @Input() resultVerificationDateLabel = this.translate.getTranslation(
    'LabTest.ViewLabTest.ResultVerificationDate'
  );
  @Input() arupReceivedDateLabel = this.translate.getTranslation(
    'LabTest.ViewLabTest.ARUPReceivedDate'
  );
  @Input() arupReceivedTimeLabel = this.translate.getTranslation(
    'LabTest.ViewLabTest.ARUPReceivedTime'
  );
  @Input() arupAccessionIdLabel = this.translate.getTranslation(
    'LabTest.ViewLabTest.ARUPAccessionId'
  );
  @Input() arupPatientIdLabel = this.translate.getTranslation(
    'LabTest.ViewLabTest.ARUPPatientId'
  );
  @Input() arupOrderDateLabel = this.translate.getTranslation(
    'LabTest.ViewLabTest.ARUPOrderDate'
  );
  @Input() trackingNumberLabel = this.translate.getTranslation(
    'LabTest.ViewLabTest.TrackingNumber'
  );
  @Input() addUserLabel = this.translate.getTranslation('LabTest.ViewLabTest.AddUser');
  @Input() addDateLabel = this.translate.getTranslation('LabTest.ViewLabTest.AddDate');

  @Input() labTestId = this.translate.getTranslation('LabTest.LabTestList.LabTestId');
  @Input() patientId = this.translate.getTranslation('LabTest.LabTestEditPage.PatientId');
  @Input() patientFirstName = this.translate.getTranslation(
    'LabTest.LabTestEditPage.PatientFirstName'
  );
  @Input() patientLastName = this.translate.getTranslation(
    'LabTest.LabTestEditPage.PatientLastName'
  );
  @Input() patientCountry = this.translate.getTranslation(
    'LabTest.LabTestList.PatientCountry'
  );
  @Input() resultNotificationDate = this.translate.getTranslation(
    'LabTest.LabTestEditPage.ResultNotificationDate'
  );

  @Input() testTypeLabel = this.translate.getTranslation(
    'LabTest.LabTestEditPage.TestType'
  );

  @Input()
  get labTest(): LabTest {

    return this._labTest;
  }


  set labTest(value: LabTest) {
    if (this._labTest !== value) {
      this._labTest = value;
      this.testResultState =
        !value || !value.testResult || !value.resultVerificationDate ? 'clear' : 'done';
      this.changeDetectorRef.markForCheck();
    }
  }
  //#endregion
  public cdcStatementLabel = this.translate.getTranslation(
    'LabTest.LabTestForm.CDCStatementLabel'
  );

  testResultState: 'done' | 'clear' = 'clear';

  constructor(
    private readonly changeDetectorRef: ChangeDetectorRef,
    private readonly documentStorageService: DocumentStorageService,
    private readonly labTestClient: LabTestClient,
    public readonly translate: AppTranslationService,
    public readonly utilityService: UtilityService,
    public readonly datePipe: DatePipe
  ) {}



  ngOnDestroy(): void {
    this.destroyed$.unsubscribe();
  }

  printLabTest(id: string) {
    this.labTestClient.downloadShipmentLabel(id);
  }

  viewDocument(id: string) {
    this.labTestClient
      .getLabTestResultDocumentStorageUri(id)
      .pipe(
        takeUntil(this.destroyed$),
        tap(outcome => {
          const storageDocument: DownloadDocument = {
            contentType: ContentTypes.pdf,
            documentType: DocumentTypes.labTestResultPdf,
            fileName: outcome.value,
          };

          this.documentStorageService
            .download(storageDocument)
            .pipe(
              takeUntil(this.destroyed$),
              tap(url => window.open(url, '_blank'))
            )
            .subscribe();
        })
      )
      .subscribe();
  }
}
