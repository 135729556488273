import { Component, OnInit, Input } from '@angular/core';
import { Institution } from '../../models';
import { UtilityService } from '../../../common';
import { AppTranslationService } from '@roctavian-abstractions/core';

@Component({
  selector: 'view-institution',
  templateUrl: './view-institution.component.html',
  styleUrls: ['./view-institution.component.scss']
})
export class ViewInstitutionComponent implements OnInit {
  @Input() public title = this.translate.getTranslation('Institution.ViewInstitution.Title');
  @Input() public showTitle = true;

  @Input() public address1Label = this.translate.getTranslation('Institution.ViewInstitution.Address1');
  @Input() public address2Label = this.translate.getTranslation('Institution.ViewInstitution.Address2');
  @Input() public showAddress2 = true;

  @Input() public showAggregationId = true;
  @Input() public aggregationIdLabel = this.translate.getTranslation('Institution.ViewInstitution.AggregationId');

  @Input() public institution: Institution;

  constructor(public utilityService: UtilityService, private translate: AppTranslationService) { }

  public ngOnInit() { }
}
