import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { AppTranslationService, MessageService } from '@roctavian-abstractions/core';
import { Outcome } from '@roctavian-abstractions/web';
import { Subscription } from 'rxjs';
import { Attestation, AttestationTypes, StakeholderTypes } from '../../../common';
import { LabUserClient } from '../../clients';
import { LabUser } from '../../models/labuser.model';

@Component({
  selector: 'add-lab-user',
  templateUrl: './add-lab-user.component.html',
  styleUrls: ['./add-lab-user.component.scss']
})
export class AddLabUserComponent implements OnInit, OnDestroy {
  public form: FormGroup;
  public errorMessages: string[];
  public isProcessing = false;

  private subscriptions = new Array<Subscription>();

  constructor(
    private formBuilder: FormBuilder,
    private client: LabUserClient,
    private messageService: MessageService,
    private router: Router,
    private translate: AppTranslationService
  ) { }

  ngOnInit() {
    this.form = this.formBuilder.group({});
  }

  ngOnDestroy() {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }

  public getModel(): LabUser {
    const formValue = this.form.getRawValue();
    const labUser: LabUser = formValue;
    labUser.stakeholder.stakeholderType = StakeholderTypes.LaboratoryUser;

    const attestation: Attestation = formValue.stakeholder.attestation;
    let newDate = new Date(attestation.signatureDate)
    let currentTime = new Date();
    newDate.setHours(currentTime.getHours());
    newDate.setMinutes(currentTime.getMinutes());
    newDate.setSeconds(currentTime.getSeconds());
    newDate.setMilliseconds(currentTime.getMilliseconds());   
    newDate.setMinutes(newDate.getMinutes() + newDate.getTimezoneOffset());
    attestation.signatureDate = newDate;
   
    attestation.attestationType = AttestationTypes.LabUserRegistration;
    labUser.stakeholder.attestations = [attestation];

    return labUser;
  }

  public submit() {
    this.errorMessages = [];

    this.form.markAllAsTouched();
    if (this.form.invalid) {
      return;
    }

    this.isProcessing = true;
    this.subscriptions.push(
      this.client.createLabUser(this.getModel()).subscribe(
        outcome => {
          this.isProcessing = false;
          if (outcome.failure) {
            this.handleError(outcome);
            return;
          }
          this.messageService.open(this.translate.getTranslation('LabUser.AddLabUser.SuccessfullySaved'), 5000);
          this.router.navigate(['/labuser']);
        },
        (error: Outcome) => {
          this.isProcessing = false;
          this.handleError(error);
        }
      )
    );
  }

  private handleError(error: Outcome) {
    if (!error || !error.messages || error.messages.length === 0) {
      this.errorMessages = [this.translate.getTranslation('Common.ErrorProcessingRequest')];
    } else {
      this.errorMessages = error.messages;
    }
  }
}
