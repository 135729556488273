import { Component, Input } from '@angular/core';
import { Task, CreateTaskActionModel, UpdateTaskModel } from '../../../../models';
import { TaskClient } from './../../../../clients/task.client';

@Component({
  selector: 'roctavian-abstractions-task-details',
  templateUrl: './task-details.component.html',
  styleUrls: ['./task-details.component.scss']
})
export class TaskDetailsComponent {
  @Input() public task: Task;

  /**
   * Initializes a new instance of the component.
   *
   * @param taskClient The task client.
   */
  constructor(private taskClient: TaskClient) {}

  /**
   * Handles the submission of the task action form.
   *
   * @param model The create task action model.
   */
  public onNewTaskAction(model: CreateTaskActionModel): void {
    this.taskClient.addTaskAction(this.task.taskIdentity, model).subscribe(result => {
      if (result.success) {
        const actions = this.task.taskActionHistory;
        actions.forEach(a => (a.isCurrent = false));
        actions.push(result.value);

        this.task.taskActionHistory = actions.slice();
      }
    });
  }

  /**
   * Handles the submission of the task details form.
   *
   * @param model The update task model.
   */
  public onTaskUpdate(model: UpdateTaskModel): void {
    this.taskClient.updateTask(this.task.taskIdentity, model).subscribe(result => {
      if (result.success) {
        this.task.assignedUserIdentity = model.assignedUserIdentity;
        this.task.dueDate = model.dueDate;
        this.task.statusIdentity = model.statusIdentity;
        this.task.workgroupIdentity = model.workgroupIdentity;
      }
    });
  }
}
