import { Component, OnDestroy, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { AppTranslationService, BaseAppComponent, ConfigService, GoogleAnalyticsService } from '@roctavian-abstractions/core';
import { Subscription } from 'rxjs';
import { AuthenticationService } from './modules/identity/services/authentication.service';
import { SessionManagementService } from './modules/identity/services/session-management.service';
import { AttestationService } from './modules/stakeholder/modules/common/services/attestation.service';
import { StatusService } from './modules/stakeholder/modules/common/services/status.service';
import { ExternalLabTestService } from './modules/stakeholder/modules/external-lab-test/services/external-lab-test.service';
import { InstitutionService } from './modules/stakeholder/modules/institution';
import { LabService } from './modules/stakeholder/modules/lab';
import { LabKitOrderService } from './modules/stakeholder/modules/lab-kit-order/services/lab-kit-order.service';
import { LabTestService } from './modules/stakeholder/modules/lab-test/services/lab-test.service';
import { LabUserService } from './modules/stakeholder/modules/labuser/services';
import { PatientService } from './modules/stakeholder/modules/patient';
import { PrescriberService } from './modules/stakeholder/modules/prescriber';
import { PrescriberDelegateService } from './modules/stakeholder/modules/prescriber-delegate/services/prescriber-delegate.service';

@Component({
  selector: 'roctavian-eumea-callcenter-portal-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent extends BaseAppComponent implements OnInit, OnDestroy {
  public isAuthenticated = false;
  public isAdmin = false;
  private subscriptions = new Array<Subscription>();
  public timeout: string;

  constructor(
    private readonly authService: AuthenticationService,
    private readonly router: Router,
    private googleAnalyticsService: GoogleAnalyticsService,
    private prescriberDelegateService: PrescriberDelegateService,
    private prescriberService: PrescriberService,
    private patientService: PatientService,
    private labUserService: LabUserService,
    private labTestService: LabTestService,
    private externalLabTestService: ExternalLabTestService,
    private labKitOrderService: LabKitOrderService,
    private labService: LabService,
    private institutionService: InstitutionService,
    private statusService: StatusService,
    private attestationService: AttestationService,
    private sessionService: SessionManagementService,
    private configService: ConfigService,
    private translate: AppTranslationService
  ) {
    super();
  }

  public ngOnInit() {

    this.enableSessionTimeout();

    this.subscriptions.push(this.authService.isLoggedIn().subscribe(authenticated => {

      if (!authenticated) {
        this.clearAllServices();
      }

      this.isAuthenticated = authenticated;
      if (authenticated) {
        this.isAdmin = this.authService.claimsPrincipal.hasRole('Administrator');
      }
    }));

    this.subscriptions.push(this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.googleAnalyticsService.submitPageView(event.urlAfterRedirects);
      }
    }));

    this.translate.setDefaultLanguage('en');
    this.translate.setLocaleByCode('en-gb');
  }

  ngOnDestroy() {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
    this.clearAllServices();
  }

  public logout() {
    this.authService.logout();
    this.router.navigate(['/']);
  }

  private clearAllServices() {
    this.prescriberDelegateService.clearService();
    this.prescriberService.clearService();
    this.patientService.clearService();
    this.labUserService.clearService();
    this.labTestService.clearService();
    this.externalLabTestService.clearService();
    this.labKitOrderService.clearService();
    this.labService.clearService();
    this.institutionService.clearService();
    this.statusService.clearService();
    this.attestationService.clearService();
  }

  public hasSessionStarted() {
    return this.sessionService.sessionStatus;
  }

  public enableSessionTimeout() {
    if (this.timeout && !isNaN(+this.timeout)) {
      this.configService.update('sessionTimeout', this.timeout);
    }

    this.sessionService.enableSessionManagement();
  }

  public disableSessionTimeout() {
    this.sessionService.disableSessionManagement();
  }
}
