import { OnChanges, SimpleChanges } from '@angular/core';
import { OnDestroy, Output, EventEmitter, Input } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import { PagedQuery, UtilityService } from '../../../common';
import { BehaviorSubject, Subscription, EMPTY } from 'rxjs';
import { Patient } from '../../../patient';
import { PaginatedList } from '@roctavian-abstractions/web';
import { LabTestService } from '../../services/lab-test.service';
import { MessageService } from '@roctavian-abstractions/core';
import { AppTranslationService } from '@roctavian-abstractions/core';
import { debounceTime, distinctUntilChanged, switchMap } from 'rxjs/operators';
import { PageEvent, MatSort } from '@angular/material';
import { Prescriber } from '../../../prescriber';

@Component({
  selector: 'lab-test-patient-list',
  templateUrl: './patient-list.component.html',
  styleUrls: ['./patient-list.component.scss']
})
export class PatientListComponent implements OnInit, OnDestroy, OnChanges {
  public pagedQuery = new PagedQuery(1, 10, null, 'id', true);
  public selectedPatient: Patient;
  @Input() public selectedPatientId: number;

  @Output() public patientSelected = new EventEmitter<Patient>();

  public filterText$ = new BehaviorSubject<string>(null);
  public patientPagedCollection: PaginatedList<Patient>;
  public isLoading = false;

  private subscriptions = new Array<Subscription>();

  public displayedColumns = [
    'id',
    'stakeholder.firstName',
    'stakeholder.lastName',
    'birthDate',
    'stakeholder.address.postalCode',
    'stakeholder.address.country.name',
    'stakeholder.statuses.statusType',
    'patientsPrescribers.prescriber.firstName',
    'patientsPrescribers.prescriber.lastName',
    'patientsPrescribers.prescriber.institution.stakeholder.address.country.name'
  ];

  constructor(
    private labTestService: LabTestService,
    public utilityService: UtilityService
  ) { }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.selectedPatientId && changes.selectedPatientId.currentValue) {
      let selectedPatient: Patient;
      if (this.patientPagedCollection && this.patientPagedCollection.items) {
        selectedPatient = this.patientPagedCollection.items.find(
          patient => patient.id == changes.selectedPatientId.currentValue
        );
      }

      if (!selectedPatient) {
        selectedPatient = new Patient();
        selectedPatient.id = changes.selectedPatientId.currentValue;
      }

      this.selectedPatient = selectedPatient;
    } else if (changes.selectedPatientId && !changes.selectedPatientId.currentValue) {
      this.selectedPatient = null;
    }
  }

  private refreshList() {
    this.labTestService.setPatientPage(this.pagedQuery);
  }

  ngOnInit() {
    this.subscriptions.push(
      this.filterText$
        .pipe(
          debounceTime(1000),
          distinctUntilChanged(),
          switchMap(searchText => {
            if (this.pagedQuery.filterText == searchText) {
              return EMPTY;
            }

            this.pagedQuery.filterText = searchText;
            this.pagedQuery.pageIndex = 1;
            this.refreshList();
            return EMPTY;
          })
        )
        .subscribe()
    );

    this.subscriptions.push(
      this.labTestService.patientPagedListSubject.subscribe(collection => {
        this.patientPagedCollection = collection;
      })
    );

    this.subscriptions.push(
      this.labTestService.isLoadingSubject.subscribe(isLoading => {
        this.isLoading = isLoading;
      })
    );

    this.refreshList();
  }

  ngOnDestroy() {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
    this.labTestService.patientPagedListSubject.next(new PaginatedList<Patient>());
  }

  public onRowSelected(patient: Patient) {
    if (this.selectedPatient === patient) {
      this.selectedPatient = null;
      this.patientSelected.emit(null);
      return;
    }

    this.selectedPatient = patient;
    this.patientSelected.emit(this.selectedPatient);
  }

  public onPageEvent(pageEvent: PageEvent) {
    this.pagedQuery.pageIndex = pageEvent.pageIndex + 1;
    this.pagedQuery.pageSize = pageEvent.pageSize;
    this.refreshList();
  }

  public onSortEvent(sort: MatSort) {
    switch (sort.active) {
      case 'id':
        this.pagedQuery.sortColumn = 'stakeholder.externalId';
        break;
      case 'stakeholder.statuses.statusType':
        this.pagedQuery.sortColumn = 'stakeholder.statuses.FirstOrDefault(x => x.IsCurrent).statusType';
        break;

      case 'patientsPrescribers.prescriber.firstName':
        this.pagedQuery.sortColumn = 'patientsPrescribers.FirstOrDefault(x => x.IsCurrent).prescriber.stakeholder.firstName';
        break;

      case 'patientsPrescribers.prescriber.lastName':
        this.pagedQuery.sortColumn = 'patientsPrescribers.FirstOrDefault(x => x.IsCurrent).prescriber.stakeholder.lastName';
        break;

      case 'patientsPrescribers.prescriber.institution.stakeholder.address.country.name':
        this.pagedQuery.sortColumn = 'patientsPrescribers.FirstOrDefault(x => x.IsCurrent).prescriber.institution.stakeholder.address.country.name';
        break;

      case 'stakeholder.address.postalCode':
        this.pagedQuery.sortColumn = 'patientsPrescribers.FirstOrDefault(x => x.IsCurrent).prescriber.institution.stakeholder.address.postalCode';
        break;

      default:
        this.pagedQuery.sortColumn = sort.active;
        break;
    }

    this.pagedQuery.isSortDesc = sort.direction == 'desc';
    this.pagedQuery.pageIndex = 1;
    this.refreshList();
  }

  public getCurrentPrescriber(patient: Patient): Prescriber {
    if (!patient || !patient.patientsPrescribers) {
      return null;
    }

    const currentPatientPrescriber = patient.patientsPrescribers.find(patientPrescriber => patientPrescriber.isCurrent);
    return currentPatientPrescriber ? currentPatientPrescriber.prescriber : null;
  }
}
