import { Component, OnInit } from "@angular/core";
import { MatSnackBar } from "@angular/material";
import { Outcome } from "@roctavian-abstractions/web";
import { BehaviorSubject } from "rxjs";
import { ChangePasswordModel } from "../../../../models";
import { ChangePasswordFormSubmitEvent } from "../change-password-form/change-password-form.component";
import { IdentityConfigClient } from "../../../../clients/identity-config.client";
import { AccountManagementClient } from "../../../../clients/account-management.client";

@Component({
  selector: "roctavian-abstractions-change-password",
  templateUrl: "./change-password.component.html",
  styleUrls: ["./change-password.component.scss"]
})
export class ChangePasswordComponent implements OnInit {
  public showError = false;
  public errorMessages: Array<string> = [];
  public clearForm = new BehaviorSubject<boolean>(false);
  public loading = true;
  public passwordConfig: any;

  constructor(
    private snackbar: MatSnackBar,
    public readonly client: AccountManagementClient,
    private configClient: IdentityConfigClient
  ) {}

  /**
   * When the component is initialize, hit the identity config endpoint
   *  to determine how passwords are configured in the application.
   */
  public ngOnInit() {
    this.configClient.getIdentityConfig().subscribe(config => {
      this.passwordConfig = config.passwordConfig;
      this.loading = false;
    });
  }

  public handleSubmit(event: ChangePasswordFormSubmitEvent) {
    this.clearErrors();

    const model = new ChangePasswordModel(event.password, event.newPassword, event.confirmationPassword);

    this.client.changePassword(model).subscribe(
      () => {
        this.clearForm.next(true);
        this.snackbar.open("Password Changed", "", { duration: 5000, verticalPosition: "top" });
      },
      (error: Outcome) => {
        this.showError = true;
        this.errorMessages = error.messages;
      }
    );
  }

  private clearErrors(): void {
    this.showError = false;
    this.errorMessages = [];
  }
}
