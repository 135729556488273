import { Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject, Subscription } from 'rxjs';
import { PaginatedList, Outcome } from '@roctavian-abstractions/web';
import { MessageService } from '@roctavian-abstractions/core';
import { PagedQuery } from '../../common';
import { LabUser, LabuserDelegate } from '../models';
import { LabUserClient } from '../clients';
import { AppTranslationService } from '@roctavian-abstractions/core';
import { ClearService } from '../../../../shared/interface/clear-service.interface';

@Injectable({
  providedIn: 'root'
})
export class LabUserService implements OnDestroy, ClearService {
  public pagedListSubject = new BehaviorSubject<PaginatedList<LabUser>>(new PaginatedList<LabUser>());
  public duplicateLabUserPagedListSubject = new BehaviorSubject<PaginatedList<LabUser>>(new PaginatedList<LabUser>());
  public prescriberPagedListSubject = new BehaviorSubject<PaginatedList<LabuserDelegate>>(
    new PaginatedList<LabuserDelegate>()
  );
  public isLoadingSubject = new BehaviorSubject<boolean>(false);
  public selectedLabUserSubject = new BehaviorSubject<LabUser>(null);

  private subscriptions = new Array<Subscription>();

  constructor(
    private labUserClient: LabUserClient,
    private messageService: MessageService,
    private translate: AppTranslationService
  ) { }

  ngOnDestroy() {
    this.clearSubscriptions();
  }

  public setPage(pagedQuery: PagedQuery) {
    this.isLoadingSubject.next(true);

    this.clearSubscriptions();

    this.subscriptions.push(
      this.labUserClient.getPagedList(pagedQuery).subscribe(
        outcome => {
          if (!outcome.success || outcome.failure) {
            this.messageService.open(outcome.messages[0], 5000);
            return;
          }

          this.pagedListSubject.next(outcome.value);
          this.isLoadingSubject.next(false);
        },
        (error: Outcome) => {
          this.messageService.open(error.messages[0], 5000);
          this.isLoadingSubject.next(false);
        }
      )
    );
  }
  public selectLabUser(labUserId: string, forceRefresh: boolean = false) {
    if (this.selectedLabUserSubject.value && this.selectedLabUserSubject.value.id === labUserId && !forceRefresh) {
      return;
    }

    this.clearSubscriptions();

    this.isLoadingSubject.next(true);
    this.subscriptions.push(
      this.labUserClient.getLabUser(labUserId).subscribe(
        outcome => {
          if (!outcome.success || outcome.failure) {
            return;
          }

          this.selectedLabUserSubject.next(outcome.value);
          this.isLoadingSubject.next(false);
        },
        (error: Outcome) => {
          this.isLoadingSubject.next(false);
        }
      )
    );
  }

  public loadLabUser(labUserId: string, forceRefresh: boolean = false) {
    if (this.selectedLabUserSubject.value && this.selectedLabUserSubject.value.id === labUserId && !forceRefresh) {
      return;
    }

    this.clearSubscriptions();

    this.isLoadingSubject.next(true);
    this.subscriptions.push(
      this.labUserClient.getLabUser(labUserId).subscribe(
        outcome => {
          if (!outcome.success || outcome.failure) {
            this.messageService.open(outcome.messages[0], 5000);
            return;
          }

          this.selectedLabUserSubject.next(outcome.value);
          this.isLoadingSubject.next(false);
        },
        (error: Outcome) => {
          this.messageService.open(this.translate.getTranslation('Common.ErrorProcessingRequest'), 5000);
          this.isLoadingSubject.next(false);
        }
      )
    );
  }

  public setPrescriberListPage(pagedQuery: PagedQuery, prescriberId: string) {
    this.clearSubscriptions();
    this.isLoadingSubject.next(true);

    this.subscriptions.push(
      this.labUserClient.getPrescriberPagedList(pagedQuery, prescriberId).subscribe(
        outcome => {
          if (!outcome.success || outcome.failure) {
            this.messageService.open(outcome.messages[0], 5000);
            return;
          }

          this.prescriberPagedListSubject.next(outcome.value);
          this.isLoadingSubject.next(false);
        },
        (error: Outcome) => {
          this.messageService.open(error.messages[0], 5000);
          this.isLoadingSubject.next(false);
        }
      )
    );
  }

  private clearSubscriptions() {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
    this.subscriptions = new Array<Subscription>();
  }

  public clearService() {
    this.pagedListSubject.next(new PaginatedList<LabUser>());
    this.duplicateLabUserPagedListSubject.next(new PaginatedList<LabUser>());
    this.prescriberPagedListSubject.next(new PaginatedList<LabuserDelegate>());
    this.selectedLabUserSubject.next(null);
  }
}
