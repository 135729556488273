import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { RoctavianAuthenticationGuard } from '../../../shared/guards/roctavian-authentication.guard';
import { AddExternalLabTestPageComponent } from './pages/add-external-lab-test-page/add-external-lab-test-page.component';
import { EditExternalLabTestPageComponent } from './pages/edit-external-lab-test-page/edit-external-lab-test-page.component';
import { ExternalLabTestDetailMasterPageComponent } from './pages/external-lab-test-detail-master-page/external-lab-test-detail-master-page.component';
import { ExternalLabTestListPageComponent } from './pages/external-lab-test-list-page/external-lab-test-list-page.component';
import { ExternalLabTestSummaryPageComponent } from './pages/external-lab-test-summary-page/external-lab-test-summary-page.component';

const ROUTES = [
  {
    path: 'external-lab-test',
    canActivate: [RoctavianAuthenticationGuard],
    children: [
      { path: '', redirectTo: 'list', pathMatch: 'full' },
      { path: 'list', component: ExternalLabTestListPageComponent },
      { path: 'add', component: AddExternalLabTestPageComponent },
      {
        path: ':id',
        component: ExternalLabTestDetailMasterPageComponent,
        children: [
          { path: '', redirectTo: 'summary', pathMatch: 'full' },
          { path: 'summary', component: ExternalLabTestSummaryPageComponent },
          { path: 'edit', component: EditExternalLabTestPageComponent },
        ]
      }
    ]
  }
];

@NgModule({
  imports: [CommonModule, RouterModule.forChild(ROUTES)],
  exports: [RouterModule]
})
export class ExternalLabTestRoutingModule { }
