import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

@Component({
  selector: 'roctavian-abstractions-confirm-reset-dialog',
  templateUrl: './confirm-reset-dialog.component.html',
  styleUrls: ['./confirm-reset-dialog.component.scss']
})
export class ConfirmResetDialogComponent implements OnInit {

  constructor(public reference: MatDialogRef<ConfirmResetDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: number) { }

  ngOnInit() {
  }

  public confirmReset(choice: number){
    this.reference.close(choice);
  }

}
