import '../../extensions';

import { NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { MaterialModule, CoreModule } from '@roctavian-abstractions/core';
import { TasksRoutingModule } from './tasks-routing.module';
import { TaskModule } from './modules/task/task.module';
import { WorkgroupModule } from './modules/workgroup/workgroup.module';
import { TaskDashboardPageComponent } from './pages/task-dashboard-page/task-dashboard-page.component';
import { WorkgroupDashboardPageComponent } from './pages/workgroup-dashboard-page/workgroup-dashboard-page.component';
import { TaskManagerPageComponent } from './pages/task-manager-page/task-manager-page.component';
import { NewTaskPageComponent } from './pages/new-task-page/new-task-page.component';
import { EditTaskPageComponent } from './pages/edit-task-page/edit-task-page.component';
import { NewWorkgroupPageComponent } from './pages/new-workgroup-page/new-workgroup-page.component';
import { ManageWorkgroupPageComponent } from './pages/manage-workgroup-page/manage-workgroup-page.component';

@NgModule({
  imports: [
    CommonModule,
    TasksRoutingModule,
    MaterialModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    CoreModule,
    TaskModule,
    WorkgroupModule
  ],
  declarations: [
    TaskDashboardPageComponent,
    TaskManagerPageComponent,
    WorkgroupDashboardPageComponent,
    NewTaskPageComponent,
    EditTaskPageComponent,
    NewWorkgroupPageComponent,
    ManageWorkgroupPageComponent
  ],
  exports: [TaskModule, WorkgroupModule]
})
export class TasksModule {}
