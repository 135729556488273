import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {
  MatMenuModule,
  MatSortModule,
  MatTableModule,
  MAT_DIALOG_DEFAULT_OPTIONS,
} from '@angular/material';
import { CoreDirectivesModule, MaterialModule } from '@roctavian-abstractions/core';
import { SharedModule } from '../../../shared/shared.module';
import { PrescriberDelegateFormComponent } from '../prescriber-delegate/components/prescriber-delegate-form/prescriber-delegate-form.component';
import { ViewPrescriberDelegateComponent } from '../prescriber-delegate/components/view-prescriber-delegate/view-prescriber-delegate.component';
import {
  AddAttestationComponent,
  AddressFormComponent,
  AddStatusComponent,
  AlternateContactFormComponent,
  AttestationFormComponent,
  AttestationListComponent,
  ChangeReasonDescriptionFormControlComponent,
  ErrorComponent,
  ListLoadingIndicatorComponent,
  StakeholderFormComponent,
  StatusFormComponent,
  StatusListComponent,
  ViewAddressComponent,
  ViewAltContactComponent,
  ViewAttestationComponent,
  ViewStakeholderComponent,
  ViewSystemKeyComponent,
} from './components';
import { ConfirmationDialogComponent } from './components/confirmation-dialog/confirmation-dialog.component';
import { AttestationListPageComponent, StatusListPageComponent } from './pages';

const STAKEHOLDER_COMPONENTS = [
  StakeholderFormComponent,
  AddressFormComponent,
  AttestationFormComponent,
  StatusFormComponent,
  ViewAddressComponent,
  ViewAttestationComponent,
  ViewStakeholderComponent,
  ViewSystemKeyComponent,
  ErrorComponent,
  AttestationListPageComponent,
  AttestationListComponent,
  AddAttestationComponent,
  StatusListPageComponent,
  StatusListComponent,
  AddStatusComponent,
  ListLoadingIndicatorComponent,
  ChangeReasonDescriptionFormControlComponent,
  ConfirmationDialogComponent,
  ViewPrescriberDelegateComponent,
  PrescriberDelegateFormComponent,
  AlternateContactFormComponent,
  ViewAltContactComponent,
];

@NgModule({
  declarations: [...STAKEHOLDER_COMPONENTS],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MaterialModule,
    FormsModule,
    ReactiveFormsModule,
    CoreDirectivesModule,
    SharedModule,
    MatMenuModule,
    MatTableModule,
    MatSortModule,
  ],
  exports: [...STAKEHOLDER_COMPONENTS, MatTableModule, MatSortModule],
  providers: [{ provide: MAT_DIALOG_DEFAULT_OPTIONS, useValue: { hasBackdrop: false } }],
  entryComponents: [ConfirmationDialogComponent],
})
export class StakeholderCommonModule {}
