import { Component, OnInit } from '@angular/core';
import { Prescriber } from '../../models';

@Component({
  selector: 'roctavian-eumea-callcenter-portal-add-prescriber-page',
  templateUrl: './add-prescriber-page.component.html',
  styleUrls: ['./add-prescriber-page.component.css']
})
export class AddPrescriberPageComponent implements OnInit {
  public prescriber: Prescriber;

  constructor() {}

  ngOnInit() {}

  public onContinueWithPrescriber(prescriber: Prescriber) {
    this.prescriber = prescriber;
  }
}
