import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { LabKitOrderListPageComponent } from './pages/lab-kit-order-list-page/lab-kit-order-list-page.component';
import { AddLabKitOrderPageComponent } from './pages/add-lab-kit-order-page/add-lab-kit-order-page.component';
import { LabKitOrderDetailMasterPageComponent } from './pages/lab-kit-order-detail-master-page/lab-kit-order-detail-master-page.component';
import { LabKitOrderSummaryPageComponent } from './pages/lab-kit-order-summary-page/lab-kit-order-summary-page.component';
import { EditLabKitOrderPageComponent } from './pages/edit-lab-kit-order-page/edit-lab-kit-order-page.component';
import { RoctavianAuthenticationGuard } from '../../../shared/guards/roctavian-authentication.guard';

const ROUTES = [
  {
    path: 'lab-kit-order',
    canActivate: [RoctavianAuthenticationGuard],
    children: [
      { path: '', redirectTo: 'list', pathMatch: 'full' },
      { path: 'list', component: LabKitOrderListPageComponent },
      { path: 'add', component: AddLabKitOrderPageComponent },
      {
        path: ':id',
        component: LabKitOrderDetailMasterPageComponent,
        children: [
          { path: '', redirectTo: 'summary', pathMatch: 'full' },
          { path: 'summary', component: LabKitOrderSummaryPageComponent },
          { path: 'edit', component: EditLabKitOrderPageComponent }
        ]
      }
    ]
  }
];

@NgModule({
  imports: [CommonModule, RouterModule.forChild(ROUTES)],
  exports: [RouterModule]
})
export class LabKitOrderRoutingModule { }
