import { Component } from '@angular/core';

@Component({
  selector: 'roctavian-abstractions-task-manager-page',
  templateUrl: './task-manager-page.component.html',
  styleUrls: ['./task-manager-page.component.scss']
})
export class TaskManagerPageComponent {
  constructor() {}

  public getSidenavMode(): string {
    if (window.innerWidth < 750) {
      return 'over';
    }
    return 'side';
  }
}
