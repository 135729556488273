import { Component, EventEmitter, Input, OnInit, Output, ChangeDetectionStrategy } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ConfigService } from "@roctavian-abstractions/core";
import { ReCaptchaV3Service } from "ngx-captcha";

export class ForgotPasswordFormSubmitEvent {
  public username: string;
  public recaptchaToken: string;
  constructor(username: string, recaptchaToken?: string) {
    this.username = username;
    this.recaptchaToken = recaptchaToken;
  }
}

@Component({
  selector: "roctavian-abstractions-forgot-password-form",
  templateUrl: "./forgot-password-form.component.html",
  styleUrls: ["./forgot-password-form.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ForgotPasswordFormComponent implements OnInit {
  @Input() public disabled = false;
  @Input() public enableRecaptcha = false;
  @Input() public key: string;

  @Output() public submitted = new EventEmitter<ForgotPasswordFormSubmitEvent>();

  public form: FormGroup;

  constructor(
    private service: ConfigService,
    private builder: FormBuilder,
    private recaptchaService: ReCaptchaV3Service
  ) {
    this.key = this.service.get("recaptchaKey");
  }
  public ngOnInit() {
    this.form = this.builder.group({
      username: ["", Validators.required]
    });
  }

  /**
   * Handles the submission of the forgot password form.
   * @param form The form group.
   */
  public handleFormSubmission(form: FormGroup) {
    if (!form.valid) {
      return;
    }

    this.disabled = true;

    if (!this.enableRecaptcha) {
      const username = form.get("username").value;
      const event = new ForgotPasswordFormSubmitEvent(username);
      this.submitted.emit(event);
      return;
    }

    // Communicate with the Google's recaptcha service to get a response token:
    this.recaptchaService.execute(this.key, "homepage", token => {
      const username = form.get("username").value;
      const event = new ForgotPasswordFormSubmitEvent(username, token);
      this.submitted.emit(event);
    });
  }
}
