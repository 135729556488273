import { Component, OnInit, Output, EventEmitter, OnDestroy } from '@angular/core';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { AppTranslationService } from '@roctavian-abstractions/core';
import { Router } from '@angular/router';
import { UtilityService } from '../../../common';
import { Lab, LabDuplicateCheckQuery } from '../../models';
import { LabClient } from '../../clients';
import { Subscription, BehaviorSubject, EMPTY } from 'rxjs';
import { PaginatedList } from '@roctavian-abstractions/web';
import { LabService } from '../../services';
import { debounceTime, distinctUntilChanged, switchMap } from 'rxjs/operators';
import { MatSort, PageEvent } from '@angular/material';

@Component({
  selector: 'lab-lookup',
  templateUrl: './lab-lookup.component.html',
  styleUrls: ['./lab-lookup.component.css']
})
export class LabLookupComponent implements OnInit, OnDestroy {
  public form: FormGroup;
  @Output() public continueWithLab = new EventEmitter<Lab>();
  public duplicateLabPagedList = new PaginatedList<Lab>();
  public duplicateLabPagedQuery = new LabDuplicateCheckQuery(1, 10, null, 'id', true, null, null, null);
  public filterText$ = new BehaviorSubject<string>(null);
  public errorMessage: string;
  public warningMessage: string;
  public isLoading = false;
  public displayedColumns = [
    'nationalId',
    'labName',
    'stakeholder.address.address1',
    'stakeholder.address.postalCode',
    'stakeholder.address.country.name'
  ];
  public isNameRequired = false;
  public isNationalIdRequired = false;

  private subscriptions = new Array<Subscription>();

  constructor(
    private formBuilder: FormBuilder,
    public utilityService: UtilityService,
    private translate: AppTranslationService,
    private service: LabService,
    private router: Router
  ) { }

  public ngOnInit() {
    this.buildForm();
    this.subscriptions.push(this.form.valueChanges.subscribe(valueChange => {
      this.refreshValidations();
    }));

    this.subscriptions.push(this.service.duplicateLabPagedListSubject.subscribe(pagedList => {
      if (!this.form.get('labName').value && !this.form.get('nationalId').value) {
        return;
      }

      this.duplicateLabPagedList = pagedList;

      if (this.isDuplicateFound) {
        this.warningMessage = this.translate.getTranslation('Lab.LabLookup.LabExists');
      } else {
        this.continueWithLab.emit(this.form.getRawValue());
      }
    }));

    this.subscriptions.push(this.service.isLoadingSubject.subscribe(
      isLoading => (this.isLoading = isLoading)
    ));

    this.subscriptions.push(this.filterText$
      .pipe(
        debounceTime(1000),
        distinctUntilChanged(),
        switchMap(searchText => {
          if (this.duplicateLabPagedQuery.filterText == searchText) {
            return EMPTY;
          }

          this.duplicateLabPagedQuery.filterText = searchText;
          this.duplicateLabPagedQuery.pageIndex = 1;
          this.refreshDuplicateList();
          return EMPTY;
        })
      )
      .subscribe());
  }

  ngOnDestroy() {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());

    this.service.duplicateLabPagedListSubject.next(new PaginatedList<Lab>());
  }

  private buildForm() {
    this.form = this.formBuilder.group({
      nationalId: new FormControl(null),
      labName: new FormControl(null)
    });

    this.refreshValidations();
  }

  public submit() {
    this.errorMessage = null;
    this.warningMessage = null;

    this.form.markAllAsTouched();
    if (this.form.invalid) {
      return;
    }

    if (this.isDuplicateFound) {
      this.continueWithLab.emit(this.form.getRawValue());
      return;
    }

    this.duplicateLabPagedQuery.labName = this.form.get('labName').value;
    this.duplicateLabPagedQuery.nationalId = this.form.get('nationalId').value;
    this.refreshDuplicateList();
  }

  private refreshValidations() {
    const lastName = this.form.get('labName').value;
    const nationalId = this.form.get('nationalId').value;

    this.form.get('labName').setValidators(Validators.required);
    this.form.get('nationalId').setValidators(Validators.required);
    this.isNameRequired = true;
    this.isNationalIdRequired = true;

    if ((!lastName && !nationalId) || (lastName && nationalId)) {
      return;
    }

    if (lastName) {
      this.form.get('nationalId').clearValidators();
      this.isNationalIdRequired = false;
    } else if (nationalId) {
      this.form.get('labName').clearValidators();
      this.isNameRequired = false;
    }
  }

  public cancelLookup() {
    if (this.isDuplicateFound) {
      this.duplicateLabPagedList = new PaginatedList<Lab>();
      this.errorMessage = null;
      this.warningMessage = null;
      this.duplicateLabPagedQuery = new LabDuplicateCheckQuery(1, 10, null, 'id', true, null, null, null);
      this.filterText$.next(null);
      return;
    } else {
      this.router.navigate(['/lab']);
    }
  }

  private refreshDuplicateList() {
    this.service.setDuplicateLabPage(this.duplicateLabPagedQuery);
  }

  public get isDuplicateFound(): boolean {
    return (
      (this.duplicateLabPagedList && this.duplicateLabPagedList.items && this.duplicateLabPagedList.items.length > 0) ||
      this.duplicateLabPagedQuery.filterText !== null
    );
  }

  public onPageEvent(pageEvent: PageEvent) {
    this.duplicateLabPagedQuery.pageIndex = pageEvent.pageIndex;
    this.duplicateLabPagedQuery.pageSize = pageEvent.pageSize;
    this.refreshDuplicateList();
  }

  public onSortEvent(sort: MatSort) {
    this.duplicateLabPagedQuery.sortColumn = sort.active;
    this.duplicateLabPagedQuery.isSortDesc = sort.direction == 'desc';
    this.duplicateLabPagedQuery.pageIndex = 1;
    this.refreshDuplicateList();
  }
}
