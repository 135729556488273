import {
  ChangeDetectionStrategy, Component, EventEmitter, Input, Output
} from '@angular/core';
import { Permission } from 'apps/roctavian-eumea-callcenter-portal/src/app/modules/identity/models';

export class PermissionToggleEvent {
  constructor(permissionIdentity: string) {
    this.permissionIdentity = permissionIdentity;
  }

  public permissionIdentity: string;
}

export class PermissionViewModel {
  constructor(permission: Permission, enabled?: boolean) {
    this.permissionIdentity = permission.id;
    this.name = permission.name;
    this.description = permission.description;
    this.group = permission.group;
    if (enabled) {
      this.enabled = enabled;
    }
  }
  public permissionIdentity: string;
  public name: string;
  public description: string;
  public group: string;
  public enabled = false;
}

@Component({
  selector: 'roctavian-abstractions-permission-toggle',
  templateUrl: './permission-toggle.component.html',
  styleUrls: ['./permission-toggle.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PermissionToggleComponent {
  /** The permission view model. */
  @Input()
  public permission: PermissionViewModel;

  /** Event that is emitted when the permission is toggled. */
  @Output()
  public toggled = new EventEmitter<PermissionToggleEvent>();

  constructor() {}

  /**
   * Handles the change event of the slide toggle.
   * The permission toggled event is emitted from this method.
   */
  public handleChange() {
    this.toggled.emit(new PermissionToggleEvent(this.permission.permissionIdentity));
  }
}
