import { Component, OnInit, EventEmitter, Output, Input, OnChanges, SimpleChanges } from '@angular/core';
import { FormGroup, FormBuilder, FormGroupDirective, FormControl, Validators } from '@angular/forms';
import { AppTranslationService } from '@roctavian-abstractions/core';

@Component({
  selector: 'stakeholder-form',
  templateUrl: './stakeholder-form.component.html',
  styleUrls: ['./stakeholder-form.component.css']
})
export class StakeholderFormComponent implements OnInit, OnChanges {
  @Input() public addressTitle = this.translate.getTranslation('Common.StakeholderForm.Address.Title');
  @Input() public showAddressTitle = false;

  @Input() public countryLabel = this.translate.getTranslation('Common.StakeholderForm.Address.Country');
  @Input() public showCountry = true;
  @Input() public overrideRequireCountry = false;
  @Input() public overrideSoftRequireCountry = false;

  @Input() public addressNameLabel = this.translate.getTranslation('Common.StakeholderForm.Address.Name');
  @Input() public showAddressName = true;
  @Input() public overrideAddressNameSoftRequired = false;
  @Input() public overrideAddressRequireName = false;

  @Input() public address1Label = this.translate.getTranslation('Common.StakeholderForm.Address.Address1');
  @Input() public showAddress1 = true;

  @Input() public address2Label = this.translate.getTranslation('Common.StakeholderForm.Address.Address2');
  @Input() public showAddress2 = true;

  @Input() public cityLabel = this.translate.getTranslation('Common.StakeholderForm.Address.City');
  @Input() public showCity = true;

  @Input() public postalCodeLabel = this.translate.getTranslation('Common.StakeholderForm.Address.PostalCode');
  @Input() public showPostalCode = true;

  @Input() public addressFormGroupName = 'address';
  @Input() public areAddressFieldsRequired = false;
  @Input() public areAddressFieldsSoftRequired = false;

  @Input() showAttestation = true;
  @Input() attestationTitle = this.translate.getTranslation('Common.StakeholderForm.Attestation.Title');
  @Input() showAttestationTitle = false;

  @Input() hasSignatureLabel = this.translate.getTranslation('Common.StakeholderForm.Attestation.Signature');
  @Input() showHasSignature = true;

  @Input() signatureDateLabel = this.translate.getTranslation('Common.StakeholderForm.Attestation.SignatureDate');
  @Input() showSignatureDate = true;

  @Input() guardianNameLabel = this.translate.getTranslation('Common.StakeholderForm.Attestation.GuardianName');
  @Input() showGuardianName = false;

  @Input() hasConsentTitle = this.translate.getTranslation('Common.Stakeholder.Attestation.HasConsentTitle');
  @Input() showHasConsentTitle = false;
  @Input() hasConsentLabel = this.translate.getTranslation('Common.StakeholderForm.Attestation.HasConsent');
  @Input() showHasConsent = false;

  @Input() changeReasonDescriptionLabel = this.translate.getTranslation(
    'Common.StakeholderForm.Attestation.ChangeReasonDescription'
  );
  @Input() showChangeReasonDescription = false;

  @Input() attestationTypeLabel = this.translate.getTranslation('Common.StakeholderForm.Attestation.AttestationType');
  @Input() showAttestationType = false;
  @Input() areAttestationFieldsSoftRequired = false;

  @Input() public attestationFormGroupName = 'attestation';

  @Input() public title = this.translate.getTranslation('Common.StakeholderForm.Title');
  @Input() public showTitle = false;

  @Input() public firstNameLabel = this.translate.getTranslation('Common.StakeholderForm.FirstName');
  @Input() public showFirstName = true;
  @Input() public disableFirstName = false;

  @Input() public middleNameLabel = this.translate.getTranslation('Common.StakeholderForm.MiddleName');
  @Input() public showMiddleName = true;
  @Input() public disableMiddleName = false;

  @Input() public lastNameLabel = this.translate.getTranslation('Common.StakeholderForm.LastName');
  @Input() public showLastName = true;
  @Input() public disableLastName = false;

  @Input() public suffixLabel = this.translate.getTranslation('Common.StakeholderForm.Suffix');
  @Input() public showSuffix = false;
  @Input() public disableSuffix;

  @Input() public emailLabel = this.translate.getTranslation('Common.StakeholderForm.Email');
  @Input() public showEmail = true;
  @Input() public disableEmail = false;
  @Input() public overrideRequireEmail = false;
  @Input() public overrideEmailIsOptional = false;

  @Input() public phoneLabel = this.translate.getTranslation('Common.StakeholderForm.Phone');
  @Input() public showPhone = true;
  @Input() public disablePhone = false;
  @Input() public overrideIsPhoneOptional = false;

  @Input() public faxLabel = this.translate.getTranslation('Common.StakeholderForm.Fax');
  @Input() public showFax = false;
  @Input() public disableFax = false;
  @Input() public isFaxSoftRequired = true;

  @Input() public notesLabel = this.translate.getTranslation('Common.StakeholderForm.Notes');
  @Input() public showNotes = true;

  @Input() public showAddress = false;

  @Input() public areFieldsRequired = false;
  @Input() public areFieldsSoftRequired = false;
  @Input() public form: FormGroup;
  @Input() public stakeholderFormGroupName = 'stakeholder';

  @Output() public countryChanged = new EventEmitter<string>();


  constructor(
    private formBuilder: FormBuilder,
    public parentFormGroupDirective: FormGroupDirective,
    private translate: AppTranslationService
  ) { }

  public ngOnInit() {
    this.buildForm();
  }

  private buildForm() {
    this.form = this.formBuilder.group({
      firstName: new FormControl(null, []),
      middleName: new FormControl(null, []),
      lastName: new FormControl(null, []),
      suffix: new FormControl(null, []),
      email: new FormControl(null, [Validators.email]),
      phone: new FormControl(null, []),
      fax: new FormControl(null, []),
      notes: new FormControl(null, [])
    });

    this.parentFormGroupDirective.form.addControl(this.stakeholderFormGroupName, this.form);
    this.form.setParent(this.parentFormGroupDirective.form);

    this.refreshValidations(this.areFieldsRequired);
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes.areFieldsRequired) {
      this.refreshValidations(changes.areFieldsRequired.currentValue);
    }
  }

  private refreshValidations(areFieldsRequired: boolean) {
    if (!this.form || !this.form.controls || !this.form.get('firstName')) {
      return;
    }

    this.form.get('email').setValidators([Validators.email]);
    this.form.get('phone').clearValidators();
    this.form.get('fax').clearValidators();
    this.form.get('firstName').clearValidators();
    this.form.get('lastName').clearValidators();

    if (this.showFirstName) {
      this.form.get('firstName').setValidators(Validators.required);
    }
    if (this.showLastName) {
      this.form.get('lastName').setValidators(Validators.required);
    }

    if (this.isEmailRequired) {
      this.form.controls['email'].setValidators([Validators.required, Validators.email]);
    }

    if (!areFieldsRequired) {
      return;
    }

    if (this.isEmailRequired) {
      this.form.controls['email'].setValidators([Validators.required, Validators.email]);
    }

    if (this.showPhone && !this.overrideIsPhoneOptional) {
      this.form.controls['phone'].setValidators(Validators.required);
    }

    if (this.showFax) {
      this.form.controls['fax'].setValidators(Validators.required);
    }
  }

  public get isEmailRequired(): boolean {
    if (this.overrideEmailIsOptional || !this.showEmail) {
      return false;
    }

    return this.areFieldsRequired || this.overrideRequireEmail;
  }

  public onCountryChange(data) {
    this.countryChanged.emit(data);
  }
}
