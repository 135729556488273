import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { AppTranslationService } from '@roctavian-abstractions/core';
import { Subscription } from 'rxjs';
import { GenderTypes, UtilityService } from '../../../common';
import { Prescriber } from '../../../prescriber';
import { Patient } from '../../models';
import { PatientService, PatientUtilityService } from '../../services';

@Component({
  selector: 'view-patient',
  templateUrl: './view-patient.component.html',
  styleUrls: ['./view-patient.component.css']
})
export class ViewPatientComponent implements OnDestroy, OnInit {
  @Input() public title = this.translate.getTranslation('Patient.ViewPatient.Title');
  @Input() public showTitle = true;

  @Input() statusTitle = this.translate.getTranslation('Patient.ViewPatient.StatusTitle');
  @Input() showStatusTitle = true;
  @Input() statusTypeLabel = this.translate.getTranslation('Common.ViewStakeholder.Status.StatusType');
  @Input() showStatusType = true;
  @Input() statusDateLabel = this.translate.getTranslation('Common.ViewStakeholder.Status.StatusDate');
  @Input() showStatusDate = true;

  @Input() public showNationalId = false;

  @Input() public prescriberIdLabel = this.translate.getTranslation('Prescriber.ViewPrescriber.SystemId');
  @Input() public showPrescriberId = true;

  @Input() attestationSubTitle = this.translate.getTranslation(
    'Patient.ViewPatient.ViewAttestation.AttestationSubTitle'
  );
  @Input() showAttestationSubTitle = true;

  @Input() public showAddress = true;
  @Input() public showCountry = true;

  @Input() public showSuffix = true;

  @Input() public medicalRecordNumberLabel = this.translate.getTranslation('Patient.PatientForm.MedicalRecordNumber');
  @Input() public showMedicalRecordNumber = true;

  @Input() public birthDateLabel = this.translate.getTranslation('Patient.PatientForm.Birthdate');
  @Input() public disableBirthDate = true;

  @Input() public genderLabel = this.translate.getTranslation('Patient.PatientForm.Gender');
  @Input() public drugInclusion = this.translate.getTranslation('Patient.PatientForm.InclusionCriteriaVerified');

  @Input() public gender: GenderTypes;

  @Input() public showAlternateContactTitle = false;
  @Input() public showAlternateFirstName = false;
  @Input() public showAlternateLastName = false;
  @Input() public showAlternateEmail = false;
  @Input() public showAlternatePhone = false;
  @Input() public showPrescriberStatus = true;

  public patient: Patient;
  private subscriptions = new Array<Subscription>();

  constructor(
    public utilityService: UtilityService,
    public patientUtilityService: PatientUtilityService,
    private translate: AppTranslationService,
    private patientService: PatientService
  ){
}

  ngOnInit() {
    this.subscriptions.push(this.patientService.selectedPatientSubject.subscribe(patient => {
      this.patient = patient;
    }));
    this.patientService.selectPatient(this.patient.id, true);
  }


  public getCurrentPrescriber(patient: Patient): Prescriber {
    const patientPrescriber = this.patientUtilityService.getCurrentPatientPrescriberAssociation(
      patient.patientsPrescribers
    );
    if (!patientPrescriber) {
      return null;
    }

    return patientPrescriber.prescriber;
  }

  public getGenderName(genderType: GenderTypes): string {
    switch (genderType) {
      case GenderTypes.Unknown:
        return 'Unknown';
      case GenderTypes.Male:
        return 'Male';
      case GenderTypes.Female:
        return 'Female';
      case GenderTypes.Other:
        return 'Other';
      default:
        return '';
    }
  }

  public getDrugInclusion(responseType: boolean): string{
    return responseType ? 'Yes' : 'No';
  }

  ngOnDestroy() {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }
}
