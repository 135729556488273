import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Outcome } from '@roctavian-abstractions/web';
import { RegisterClient } from './../../../../clients/register.client';
import { SecurityQuestionClient } from './../../../../clients/security-question.client';
import { SecurityQuestionModel } from './../../../../models/security-question.model';

@Component({
  selector: 'roctavian-abstractions-registration',
  templateUrl: './registration.component.html',
  styleUrls: ['./registration.component.css']
})
export class RegistrationComponent implements OnInit {

  public form: FormGroup;
  public errorMessages: string[] = [];
  public displayUserAlreadyExistsMessage = false;
  public group1SecurityQuestions: SecurityQuestionModel[] = [];
  public group2SecurityQuestions: SecurityQuestionModel[] = [];
  public group3SecurityQuestions: SecurityQuestionModel[] = [];

  public securityQuestionOne: number;
  public securityQuestionOneAnswer: string;
  public securityQuestionTwo: number;
  public securityQuestionTwoAnswer: string;
  public securityQuestionThree: number;
  public securityQuestionThreeAnswer: string;

  constructor(private formBuilder: FormBuilder, private registerClient: RegisterClient, private securityQuestionClient: SecurityQuestionClient) { }

  ngOnInit() {
    this.form = this.formBuilder.group({
      firstName: new FormControl(null, Validators.required),
      lastName: new FormControl(null, Validators.required),
      email: new FormControl(null, [Validators.required, Validators.email]),
      securitySetup: this.formBuilder.group({
        securityQuestionOne: new FormControl(null, [Validators.required]),
        securityQuestionOneAnswer: new FormControl(null, [Validators.required]),
        securityQuestionTwo: new FormControl(null, [Validators.required]),
        securityQuestionTwoAnswer: new FormControl(null, [Validators.required]),
        securityQuestionThree: new FormControl(null, [Validators.required]),
        securityQuestionThreeAnswer: new FormControl(null, [Validators.required]),
        password: new FormControl(null, [Validators.required]),
        confirmPassword: new FormControl(null, [Validators.required])
      })
    });

    this.securityQuestionClient.getSecurityQuestions()
      .subscribe(questions => {
        this.group1SecurityQuestions = questions.filter(x => x.group === 1);
        this.group2SecurityQuestions = questions.filter(x => x.group === 2);
        this.group3SecurityQuestions = questions.filter(x => x.group === 3);
      });
  }

  public register() {
    this.form.markAllAsTouched();

    this.form.get("securitySetup.securityQuestionOne").setValue(this.securityQuestionOne);
    this.form.get("securitySetup.securityQuestionOneAnswer").setValue(this.securityQuestionOneAnswer);
    this.form.get("securitySetup.securityQuestionTwo").setValue(this.securityQuestionTwo);
    this.form.get("securitySetup.securityQuestionTwoAnswer").setValue(this.securityQuestionTwoAnswer);
    this.form.get("securitySetup.securityQuestionThree").setValue(this.securityQuestionThree);
    this.form.get("securitySetup.securityQuestionThreeAnswer").setValue(this.securityQuestionThreeAnswer);

    if (this.form.invalid) {
      return;
    }

    this.registerClient.register(this.form.getRawValue())
      .subscribe(response => {
        if (response.failure) {
          this.errorMessages = response.messages;
          return;
        }
      }, (error: Outcome) => {
        this.errorMessages = error.messages;
      });
  }
}
