import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { AppTranslationService, MessageService } from '@roctavian-abstractions/core';
import { Outcome } from '@roctavian-abstractions/web';
import { Subscription } from 'rxjs';
import { AttestationTypes, StakeholderTypes } from '../../../common';
import { PrescriberClient } from '../../clients';
import { Prescriber } from '../../models';

@Component({
  selector: 'add-prescriber',
  templateUrl: './add-prescriber.component.html',
  styleUrls: ['./add-prescriber.component.css']
})
export class AddPrescriberComponent implements OnInit, OnDestroy {
  public form: FormGroup;
  @Input() prefillPrescriber: Prescriber;
  public errorMessages: string[];
  public isProcessing = false;

  private subscriptions = new Array<Subscription>();

  constructor(
    private formBuilder: FormBuilder,
    private client: PrescriberClient,
    private messageService: MessageService,
    private router: Router,
    private translate: AppTranslationService
  ) { }

  public ngOnInit() {
    this.form = this.formBuilder.group({});
  }

  ngOnDestroy() {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }

  ngAfterViewInit(): void {
    this.prefillPrescriberData();
  }

  public submit() {
    this.errorMessages = [];

    this.form.markAllAsTouched();
    if (this.form.invalid) {
      return;
    }

    this.isProcessing = true;
    this.subscriptions.push(this.client.createPrescriber(this.getModel()).subscribe(
      outcome => {
        this.isProcessing = false;
        if (outcome.failure) {
          this.handleError(outcome);
          return;
        }

        this.messageService.open(this.translate.getTranslation('Prescriber.AddPrescriber.SuccessfullySaved'), 5000);
        this.router.navigate(['/prescriber']);
      },
      (error: Outcome) => {
        this.isProcessing = false;
        this.handleError(error);
      }
    ));
  }

  private prefillPrescriberData() {
    if (!this.prefillPrescriber) {
      return;
    }

    this.form.patchValue(this.prefillPrescriber);
  }

  public getModel(): Prescriber {
    const formValue = this.form.getRawValue();
    const prescriber: Prescriber = formValue;
    prescriber.stakeholder.stakeholderType = StakeholderTypes.Prescriber;

    const attestation = formValue['stakeholder']['attestation'];
    let newDate = new Date(attestation.signatureDate)
    let currentTime = new Date();
    newDate.setHours(currentTime.getHours());
    newDate.setMinutes(currentTime.getMinutes());
    newDate.setSeconds(currentTime.getSeconds());
    newDate.setMilliseconds(currentTime.getMilliseconds());   
    newDate.setMinutes(newDate.getMinutes() + newDate.getTimezoneOffset());
    attestation.signatureDate = newDate;
    attestation.attestationType = AttestationTypes.PrescriberRegistration;
    prescriber.stakeholder.attestations = [attestation];

    return prescriber;
  }

  private handleError(error: Outcome) {
    if (!error || !error.messages || error.messages.length === 0) {
      this.errorMessages = [this.translate.getTranslation('Common.ErrorProcessingRequest')];
    } else {
      this.errorMessages = error.messages;
    }
  }
}
