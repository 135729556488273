import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MatSelectChange } from '@angular/material';
import { Task, Workgroup, WorkgroupUser, UpdateTaskModel } from '../../../../models';
import { WorkgroupUserClient } from './../../../../clients/workgroup-user.client';
import { WorkgroupClient } from './../../../../clients/workgroup.client';

@Component({
  selector: 'roctavian-abstractions-task-details-form',
  templateUrl: './task-details-form.component.html',
  styleUrls: ['./task-details-form.component.scss']
})
export class TaskDetailsFormComponent implements OnInit {
  /**
   * The incoming task details. Sets the initial form values with any task details.
   */
  @Input()
  set task(task: Task) {
    if (task && this.form) {
      this.form.patchValue({
        statusIdentity: task.statusIdentity,
        workgroupIdentity: task.workgroupIdentity,
        dueDate: task.dueDate,
        assignedUserIdentity: task.assignedUserIdentity
      });

      if (task.workgroupIdentity) {
        this.workgroupUserClient.getWorkgroupUsers(task.workgroupIdentity).subscribe(outcome => {
          this.workgroupUsers = outcome.value;
        });
      }
    }
  }

  /**
   * Emits an update model.
   */
  @Output() public submitted: EventEmitter<UpdateTaskModel> = new EventEmitter<UpdateTaskModel>();

  public form: FormGroup;

  public workgroups: Workgroup[];
  public workgroupUsers: WorkgroupUser[];

  public statuses = [
    { identity: 1, display: 'Open' },
    { identity: 2, display: 'In Progress' },
    { identity: 3, display: 'Closed' }
  ];

  constructor(
    private builder: FormBuilder,
    private workgroupClient: WorkgroupClient,
    private workgroupUserClient: WorkgroupUserClient
  ) {}

  public ngOnInit(): void {
    this.workgroupClient.getWorkgroups().subscribe(result => {
      this.workgroups = result;
    });

    this.buildForm();
  }

  /**
   * Populates the "Assign To" form field with workgroup users associated to the selected workgroup.
   *
   * @param event the workgroup select change event
   */
  public onWorkgroupSelected(event: MatSelectChange): void {
    const workgroupIdentity = event.value;

    if (workgroupIdentity === null || workgroupIdentity === undefined) {
      this.workgroupUsers = this.workgroupUsers.slice(0, 0);
      return;
    }

    this.workgroupUserClient.getWorkgroupUsers(workgroupIdentity).subscribe(outcome => {
      this.workgroupUsers = outcome.value;
    });
  }

  /**
   * Maps to a model and emits it when the form is submitted.
   *
   * @param form the form to submit
   */
  public submit(form: FormGroup) {
    const model = new UpdateTaskModel();
    Object.keys(form.controls).forEach(key => {
      model[key] = form.get(key).value;
    });

    this.submitted.emit(model);
  }

  /**
   * Builds the details form.
   */
  private buildForm(): void {
    this.form = this.builder.group({
      statusIdentity: [null, Validators.required],
      workgroupIdentity: [null],
      dueDate: [null, Validators.required],
      assignedUserIdentity: [null]
    });
  }
}
