import { AppTranslationService } from '@roctavian-abstractions/core';
import { Component, Input } from '@angular/core';
import { UtilityService } from '../../../common';
import { Prescriber } from '../../models';

@Component({
  selector: 'view-prescriber',
  templateUrl: './view-prescriber.component.html',
  styleUrls: ['./view-prescriber.component.css']
})
export class ViewPrescriberComponent {

  @Input() public title = this.translate.getTranslation('Prescriber.ViewPrescriber.Title');
  @Input() public showTitle = true;

  @Input() public nationalIdLabel = this.translate.getTranslation('Prescriber.ViewPrescriber.NationalId');
  @Input() public disableNationalId = true;
  @Input() public showNationalId = true;

  @Input() public prescriberIdLabel = this.translate.getTranslation('Prescriber.ViewPrescriber.SystemId');
  @Input() public showPrescriberId = false;

  @Input() statusTypeLabel = this.translate.getTranslation('Common.ViewStakeholder.Status.StatusType');
  @Input() showStatusType = false;

  @Input() alternateContactTitle = this.translate.getTranslation('Common.ViewAltContact.Title');

  @Input() public showAlternateContactTitle = true;
  @Input() public showAlternateFirstName = true;
  @Input() public showAlternateLastName = true;
  @Input() public showAlternateEmail = true;
  @Input() public showAlternatePhone = true;

  @Input() public showEmail = true;
  @Input() public showPhone = true;
  @Input() public showNotes = true;

  @Input() public showAttestation = true;

  @Input() public showInstitutionAddress2 = true;

  @Input() public prescriber: Prescriber;

  @Input() public showInstitutionTitle = true;

  constructor(public utilityService: UtilityService, private translate: AppTranslationService) { }
}
