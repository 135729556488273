import { Component, OnDestroy, OnInit } from '@angular/core';
import { AppTranslationService } from '@roctavian-abstractions/core';
import { Subscription } from 'rxjs';
import { LabTest } from '../../models/lab-test.model';
import { LabTestService } from '../../services/lab-test.service';

@Component({
  selector: 'roctavian-eumea-callcenter-portal-lab-test-summary-page',
  templateUrl: './lab-test-summary-page.component.html',
  styleUrls: ['./lab-test-summary-page.component.scss']
})
export class LabTestSummaryPageComponent implements OnInit, OnDestroy {
  public labTest: LabTest;
  private subscriptions = new Array<Subscription>();

  constructor(private service: LabTestService, private translate: AppTranslationService) { }

  ngOnInit() {
    this.subscriptions.push(
      this.service.selectedLabTestSubject.subscribe(labTest => {
        this.labTest = labTest;
      })
    );
  }

  ngOnDestroy() {
    this.subscriptions.forEach(subscription => {
      subscription.unsubscribe();
    });
  }
}
