import { Component, OnInit, OnDestroy, Input, SimpleChanges, OnChanges } from '@angular/core';
import { PaginatedList } from '@roctavian-abstractions/web';
import { BehaviorSubject, Subscription, EMPTY } from 'rxjs';
import { debounceTime, distinctUntilChanged, switchMap } from 'rxjs/operators';
import { PageEvent, MatSort, MatDialog } from '@angular/material';
import { PagedQuery, UtilityService } from '../../../common';
import { Prescriber } from '../../../prescriber/models/';
import { LabPrescriber } from '../../models';
import { LabService } from '../../services';
import { Lab } from '../../models';

@Component({
  selector: 'lab-prescriber-list',
  templateUrl: './lab-prescriber-list.component.html',
  styleUrls: ['./lab-prescriber-list.component.scss']
})
export class LabPrescriberListComponent implements OnInit, OnChanges, OnDestroy {
  @Input() lab: Lab;

  public pagedQuery = new PagedQuery(1, 10, null, 'id', true);

  public prescriberPagedCollection: PaginatedList<LabPrescriber>;
  public isLoading = false;
  public filterText$ = new BehaviorSubject<string>(null);
  private subscriptions = new Array<Subscription>();

  public displayedColumns = [
    'id',
    'prescriber.stakeholder.firstName',
    'prescriber.stakeholder.lastName',
    'prescriber.nationalId',
    'prescriber.stakeholder.statuses.statusType',
    'prescriber.stakeholder.statuses.addDate',
    'addDate',
    'addUser.username',
    'isPrescriberOffice'
  ];

  constructor(public utilityService: UtilityService, private labService: LabService, private matDialog: MatDialog) {
    this.subscriptions.push(this.filterText$
      .pipe(
        debounceTime(1000),
        distinctUntilChanged(),
        switchMap(searchText => {
          if (this.pagedQuery.filterText == searchText) {
            return EMPTY;
          }

          this.pagedQuery.filterText = searchText;
          this.pagedQuery.pageIndex = 1;
          this.refreshList();
          return EMPTY;
        })
      )
      .subscribe());
  }

  ngOnDestroy() {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
    this.labService.prescriberPagedListSubject.next(new PaginatedList<LabPrescriber>());
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (!changes.lab || !changes.lab.currentValue || changes.lab.currentValue === changes.lab.previousValue) {
      return;
    }

    this.refreshList();
  }

  public refreshList() {
    if (!this.lab) {
      return;
    }
    this.labService.setPrescriberLabPage(this.pagedQuery, this.lab.id);
  }

  ngOnInit() {
    this.subscriptions.push(this.labService.prescriberPagedListSubject.subscribe(
      collection => {
        this.prescriberPagedCollection = collection;
      }
    ));

    this.subscriptions.push(this.labService.isLoadingSubject.subscribe(isLoading => {
      this.isLoading = isLoading;
    }));
  }

  public onPageEvent(pageEvent: PageEvent) {
    this.pagedQuery.pageIndex = pageEvent.pageIndex + 1;
    this.pagedQuery.pageSize = pageEvent.pageSize;
    this.refreshList();
  }

  public onSortEvent(sort: MatSort) {
    switch (sort.active) {
      case 'id':
        this.pagedQuery.sortColumn = 'prescriber.stakeholder.externalId';
        break;
      case 'prescriber.stakeholder.statuses.statusType':
        this.pagedQuery.sortColumn = 'prescriber.stakeholder.statuses.FirstOrDefault(x => x.IsCurrent).statusType';
        break;
      case 'prescriber.stakeholder.statuses.addDate':
        this.pagedQuery.sortColumn = 'prescriber.stakeholder.statuses.FirstOrDefault(x => x.IsCurrent).addDate';
        break;

      default:
        this.pagedQuery.sortColumn = sort.active;
        break;
    }

    this.pagedQuery.isSortDesc = sort.direction == 'desc';
    this.pagedQuery.pageIndex = 1;
    this.refreshList();
  }
}
