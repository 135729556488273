interface String {
    spacePascal(): string;
  }

  interface Date {
    addDays(days: number): Date;
  }

  /**
   * Adds a space between each word that in the string that is in PascalCase.
   * todo: need a better name
   **/
  String.prototype.spacePascal = function(): string {
    if (this === null || this === undefined) {
      return '';
    }

    return this.replace(/([A-Z])/g, ' $1').trim();
  };

  /**
   * Adds the provided number of days to the given date and returns it.
   */
  Date.prototype.addDays = function(days: number): Date {
    const date = new Date(this.valueOf());
    date.setDate(date.getDate() + days);
    return date;
  };
