import { PagedQuery } from '../../common';

export class LabUserDuplicateCheckQuery extends PagedQuery {
  constructor(
    public pageIndex: number,
    public pageSize: number,
    public filterText: string,
    public sortColumn: string,
    public isSortDesc: boolean
  ) {
    super(pageIndex, pageSize, filterText, sortColumn, isSortDesc);
  }
}
