import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import {
  AlertModule, CardModule, ErrorsModule, MaterialModule
} from '@roctavian-abstractions/core';
import { PermissionsModule } from '../permissions/permissions.module';
import { CreateRoleComponent } from './components/create-role/create-role.component';
import { EditRoleComponent } from './components/edit-role/edit-role.component';
import { RoleCardListComponent } from './components/role-card-list/role-card-list.component';
import { RoleCardComponent } from './components/role-card/role-card.component';
import { RoleFormComponent } from './components/role-form/role-form.component';

@NgModule({
  imports: [
    AlertModule,
    CommonModule,
    CardModule,
    MaterialModule,
    PermissionsModule,
    FormsModule,
    ErrorsModule,
  ],
  declarations: [
    RoleCardComponent,
    RoleCardListComponent,
    RoleFormComponent,
    CreateRoleComponent,
    EditRoleComponent,
  ],
  exports: [
    RoleCardComponent,
    RoleCardListComponent,
    RoleFormComponent,
    CreateRoleComponent,
    EditRoleComponent,
  ],
})
export class RolesModule {}
