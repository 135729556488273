import { Prescriber } from '../../prescriber';
import { Lab } from '.';

export class LabPrescriber {
  public id: string;
  public prescriber: Prescriber;
  public lab: Lab;
  public prescriberId: number;
  public labId: number;
  public IsPrescriberOffice: boolean;
}
