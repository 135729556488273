import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'roctavian-eumea-callcenter-portal-add-prescriber-delegate-page',
  templateUrl: './add-prescriber-delegate-page.component.html',
  styleUrls: ['./add-prescriber-delegate-page.component.scss']
})
export class AddPrescriberDelegatePageComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
