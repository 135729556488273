import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { UserClient } from '../../../identity/clients/user.client';
import { SimplifiedUser } from '../../../identity/models/simplified-user';

@Component({
  selector: 'roctavian-abstractions-user-edit-page',
  templateUrl: './user-edit-page.component.html',
  styleUrls: ['./user-edit-page.component.scss'],
})
export class UserEditPageComponent implements OnInit, OnDestroy {
  public identity: string;
  public unsubscribe: Subject<boolean> = new Subject<boolean>();
  public user: SimplifiedUser;
  public tempUser: SimplifiedUser;
  public disabled: boolean = false;

  constructor(private client: UserClient, private route: ActivatedRoute) {}

  public ngOnInit() {
    this.route.params.pipe(takeUntil(this.unsubscribe)).subscribe(params => {
      this.identity = params['identity'];
      this.client.getUser(this.identity).subscribe(
        user => {
          this.user = user;
        },
        () => {},
        () => this.getUser()
      );
    });
  }

  public getUser() {
    this.tempUser = this.user;
  }

  public ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }
}
