import { Validators } from '@angular/forms';
import { FormControl } from '@angular/forms';
import { Component, OnInit, Input } from '@angular/core';
import { LabKitOrder } from '../../models/lab-kit-order.model';
import { FormGroupDirective } from '@angular/forms';
import { UtilityService } from '../../../common';
import { AppTranslationService } from '@roctavian-abstractions/core';

@Component({
  selector: 'lab-kit-order-form',
  templateUrl: './lab-kit-order-form.component.html',
  styleUrls: ['./lab-kit-order-form.component.scss']
})
export class LabKitOrderFormComponent implements OnInit {
  @Input() public labKitOrder: LabKitOrder;

  @Input() public title = this.translate.getTranslation('LabKitOrder.LabKitOrderInformation');
  @Input() public nationalIdLabel = this.translate.getTranslation('LabKitOrder.NationalId');
  @Input() public quantityLabel = this.translate.getTranslation('LabKitOrder.Quantity');
  @Input() public labNameLabel = this.translate.getTranslation('LabKitOrder.LabName');
  @Input() public showProcessedCheckbox = false;
  @Input() public processedLabel = this.translate.getTranslation('LabKitOrder.Processed');
  @Input() public showTitle = true;

  constructor(
    public parentFormGroupDirective: FormGroupDirective,
    public utilityService: UtilityService,
    private translate: AppTranslationService
  ) { }

  ngOnInit() {
    this.parentFormGroupDirective.form.addControl('nationalId', new FormControl(null, Validators.required));
    this.parentFormGroupDirective.form.addControl('quantity', new FormControl(null, Validators.required));
    this.parentFormGroupDirective.form.addControl('processed', new FormControl(false, Validators.required));
  }
}
