import { userRole } from './user-role.model';

export class User {
  /**
   * The user's identity.
   */
  public id: string;

  /**
   * The user's username.
   */
  public username: string;

  /**
   * The user's first name.
   */
  public firstName: string;

  /**
   * The user's last name.
   */
  public lastName: string;

  /**
   * A flag indicating whether the user's account is currently locked.
   */
  public locked: boolean;

  /**
   * A date indicating how long the the user's account will be locked.
   */
  public lockoutEnd: Date;

  /**
   * The user's email.
   */
  public email: string;

  public addDate: Date;

  /**
   * The user's roles.
   */
  public roles: userRole[];
}
