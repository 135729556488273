import { Component, Output, EventEmitter } from "@angular/core";
import { TwoFactorClient } from "../../../../clients/two-factor.client";

@Component({
  selector: "roctavian-abstractions-two-factor-disable",
  templateUrl: "./two-factor-disable.component.html",
  styleUrls: ["./two-factor-disable.component.scss"]
})
export class TwoFactorDisableComponent {
  @Output() public twoFactorDisabled = new EventEmitter();

  /**
   * Initializes a new instance of the component.
   * @param twoFactorClient The two-factor authentication client.
   */
  constructor(public twoFactorClient: TwoFactorClient) {}

  /**
   * Disables two-factor authentication for the authenticated user.
   */
  public disableTwoFactor() {
    this.twoFactorClient.disableTwoFactorAuthentication().subscribe(data => {
      this.twoFactorDisabled.emit(data);
    });
  }
}
