import { OnDestroy } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import { PatientService } from '../../services';
import { Patient } from '../../models';
import { Subscription } from 'rxjs';

@Component({
  selector: 'roctavian-eumea-callcenter-portal-patient-status-page',
  templateUrl: './patient-status-page.component.html',
  styleUrls: ['./patient-status-page.component.css']
})
export class PatientStatusPageComponent implements OnInit, OnDestroy {
  public patient: Patient;
  private subscriptions = new Array<Subscription>();

  constructor(private service: PatientService) { }

  ngOnInit() {
    this.subscriptions.push(this.service.selectedPatientSubject.subscribe(patient => {
      this.patient = patient;
    }));
  }

  ngOnDestroy() {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }

  public onStatusSaved() {
    this.service.selectPatient(this.patient.id, true);
  }
}
