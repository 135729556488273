import { Component, OnInit } from "@angular/core";
import { LocalStorageService } from "@roctavian-abstractions/core";
import { TokenDecoder } from "../../../../services/token-decoder.service";
import { AuthenticationService } from "../../../../services/authentication.service";
import { ChangeUsernameFormSubmitEvent } from "./../change-username-form/change-username-form.component";
import { AccountManagementClient } from "../../../../clients/account-management.client";
import { ChangeUsernameModel } from "../../../../models";

@Component({
  selector: "roctavian-abstractions-change-username",
  templateUrl: "./change-username.component.html",
  styleUrls: ["./change-username.component.scss"]
})
export class ChangeUsernameComponent implements OnInit {
  public username: string;
  public showError = false;

  constructor(
    public readonly storageService: LocalStorageService,
    public readonly authenticationService: AuthenticationService,
    public readonly tokenDecoder: TokenDecoder,
    public readonly client: AccountManagementClient
  ) {}

  public ngOnInit(): void {
    const previouslyChangedUsername = this.storageService.get("updated-username");
    if (!previouslyChangedUsername) {
      const token = this.authenticationService.token;
      const decodedToken = this.tokenDecoder.decode(token);
      this.username = decodedToken.name;
    } else {
      this.username = previouslyChangedUsername;
    }
  }

  public handleSubmitRequest(event: ChangeUsernameFormSubmitEvent) {
    const model = new ChangeUsernameModel(event.newUsername);
    this.client.changeUsername(model).subscribe(
      data => {
        this.username = model.newUsername;
        this.storageService.set("updated-username", model.newUsername);
      },
      error => {
        this.showError = true;
      }
    );
  }

  public onErrorAlertClose() {
    this.showError = false;
  }
}
