/* eslint-disable @typescript-eslint/adjacent-overload-signatures */
import { DatePipe } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnDestroy
} from '@angular/core';
import { DocumentStorageService } from '@app/core/services';
import { AppTranslationService } from '@roctavian-abstractions/core';
import { Subject } from 'rxjs';
import { UtilityService } from '../../../common';
import { ExternalLabTestClient } from '../../clients/external-lab-test.client';
import { ExternalLabTest } from '../../models/external-lab-test.model';

@Component({
  selector: 'view-external-lab-test',
  templateUrl: './view-external-lab-test.component.html',
  styleUrls: ['./view-external-lab-test.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ViewExternalLabTestComponent implements OnDestroy {
  private readonly destroyed$ = new Subject();
  public _externalLabTest: ExternalLabTest;

  //#region inputs
  @Input() ageRange = this.translate.getTranslation(
    'ExternalLabTest.ViewExternalLabTest.AgeRange'
  );
  @Input() testResult = this.translate.getTranslation(
    'ExternalLabTest.ViewExternalLabTest.TestResult'
  );
  @Input() testType = this.translate.getTranslation(
    'ExternalLabTest.ViewExternalLabTest.TestType'
  );
  @Input() addUserLabel = this.translate.getTranslation('ExternalLabTest.ViewExternalLabTest.AddUser');
  @Input() addDateLabel = this.translate.getTranslation('ExternalLabTest.ViewExternalLabTest.AddDate');

  @Input() externalLabTestId = this.translate.getTranslation('ExternalLabTest.ExternalLabTestList.ExternalLabTestId');

  @Input()
  get externalLabTest(): ExternalLabTest {

    return this._externalLabTest;
  }
  set externalLabTest(value: ExternalLabTest) {
    if (this._externalLabTest !== value) {
      this._externalLabTest = value;
      this.changeDetectorRef.markForCheck();
    }
  }

  constructor(
    private readonly changeDetectorRef: ChangeDetectorRef,
    private readonly documentStorageService: DocumentStorageService,
    private readonly externalLabTestClient: ExternalLabTestClient,
    public readonly translate: AppTranslationService,
    public readonly utilityService: UtilityService,
    public readonly datePipe: DatePipe
  ) {}
  ngOnDestroy(): void {
    this.destroyed$.unsubscribe();
  }
}
