import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Outcome, PaginatedList } from '@roctavian-abstractions/web';
import { WorkgroupModel, Workgroup } from '../models';
import { ConfigService } from '@roctavian-abstractions/core';

@Injectable({
  providedIn: 'root'
})
export class WorkgroupClient {
  constructor(private client: HttpClient, private configService: ConfigService) {}

  /**
   * Returns all workgroups found in the system.
   */
  public getWorkgroups(): Observable<Array<Workgroup>> {
    return this.client.get<Array<Workgroup>>(`${this.configService.get('apiUrl')}/task/workgroup`);
  }

  /**
   * Returns a paged collection of workgroups.
   *
   * @param page The page.
   * @param size The page size
   * @param filterText The text to filter the workgroups.
   */
  public getWorkgroupsPaged(page: number, size: number, filterText: string) {
    const url = `${this.configService.get(
      'apiUrl'
    )}/task/workgroup/paged?page=${page}&size=${size}&filterText=${filterText}`;
    return this.client.get<PaginatedList<Workgroup>>(url);
  }

  /**
   * Returns the workgroup with the associated identity.
   *
   * @param workgroupIdentity The identity of the workgroup.
   */
  public getWorkgroup(workgroupIdentity: string): Observable<Workgroup> {
    return this.client.get<Workgroup>(`${this.configService.get('apiUrl')}/task/workgroup/${workgroupIdentity}`);
  }

  /**
   * Creates a new workgroup in the system.
   *
   * @param model The workgroup to create.
   */
  public createWorkgroup(model: WorkgroupModel): Observable<Outcome> {
    return this.client.post<Outcome>(`${this.configService.get('apiUrl')}/task/workgroup`, model);
  }

  /**
   * Updates the workgroup.
   *
   * @param model The workgroup to update
   */
  public updateWorkgroup(model: WorkgroupModel): Observable<Outcome> {
    return this.client.put<Outcome>(
      `${this.configService.get('apiUrl')}/task/workgroup/${model.workgroupIdentity}`,
      model
    );
  }
}
