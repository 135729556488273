import { Component, OnInit, Input } from '@angular/core';
import { AppTranslationService } from '@roctavian-abstractions/core';
import { Stakeholder } from '../../models';
import { UtilityService } from '../../services';

@Component({
  selector: 'view-stakeholder',
  templateUrl: './view-stakeholder.component.html',
  styleUrls: ['./view-stakeholder.component.css']
})
export class ViewStakeholderComponent implements OnInit {
  @Input() public addressTitle = this.translate.getTranslation('Common.ViewStakeholder.Address.Title');
  @Input() public showAddressTitle = false;

  @Input() public countryLabel = this.translate.getTranslation('Common.ViewStakeholder.Address.Country');
  @Input() public showCountry = true;

  @Input() public addressNameLabel = this.translate.getTranslation('Common.ViewStakeholder.Address.Name');
  @Input() public showAddressName = true;

  @Input() public address1Label = this.translate.getTranslation('Common.ViewStakeholder.Address.Address1');
  @Input() public showAddress1 = true;

  @Input() public address2Label = this.translate.getTranslation('Common.ViewStakeholder.Address.Address2');
  @Input() public showAddress2 = true;

  @Input() public cityLabel = this.translate.getTranslation('Common.ViewStakeholder.Address.City');
  @Input() public showCity = true;

  @Input() public postalCodeLabel = this.translate.getTranslation('Common.ViewStakeholder.Address.PostalCode');
  @Input() public showPostalCode = true;

  @Input() attestationTitle = this.translate.getTranslation('Common.ViewStakeholder.Attestation.Title');
  @Input() showAttestationTitle = false;

  @Input() attestationSubTitle = this.translate.getTranslation('Common.ViewStakeholder.Attestation.Title');
  @Input() showAttestationSubTitle = false;

  @Input() hasSignatureLabel = this.translate.getTranslation('Common.ViewStakeholder.Attestation.Signature');
  @Input() showHasSignature = true;

  @Input() signatureDateLabel = this.translate.getTranslation('Common.ViewStakeholder.Attestation.SignatureDate');
  @Input() showSignatureDate = true;

  @Input() guardianNameLabel = this.translate.getTranslation('Common.ViewStakeholder.Attestation.GuardianName');
  @Input() showGuardianName = false;

  @Input() hasConsentTitle = this.translate.getTranslation('Common.ViewStakeholder.Attestation.HasConsentTitle');
  @Input() showHasConsentTitle = false;
  @Input() hasConsentLabel = this.translate.getTranslation('Common.ViewStakeholder.Attestation.HasConsent');
  @Input() showHasConsent = false;

  @Input() changeReasonDescriptionLabel = this.translate.getTranslation(
    'Common.ViewStakeholder.Attestation.ChangeReasonDescription'
  );
  @Input() showChangeReasonDescription = false;

  @Input() attestationTypeLabel = this.translate.getTranslation('Common.ViewStakeholder.Attestation.AttestationType');
  @Input() showAttestationType = false;

  @Input() showAttestation = true;

  @Input() public title = this.translate.getTranslation('Common.ViewStakeholder.Stakeholder.Title');
  @Input() public showTitle = false;

  @Input() public firstNameLabel = this.translate.getTranslation('Common.ViewStakeholder.Stakeholder.FirstName');
  @Input() public showFirstName = true;
  @Input() public disableFirstName = false;

  @Input() public middleNameLabel = this.translate.getTranslation('Common.ViewStakeholder.Stakeholder.MiddleName');
  @Input() public showMiddleName = true;
  @Input() public disableMiddleName = false;

  @Input() public lastNameLabel = this.translate.getTranslation('Common.ViewStakeholder.Stakeholder.LastName');
  @Input() public showLastName = true;
  @Input() public disableLastName = false;

  @Input() public suffixLabel = this.translate.getTranslation('Common.ViewStakeholder.Stakeholder.Suffix');
  @Input() public showSuffix = false;
  @Input() public disableSuffix;

  @Input() public emailLabel = this.translate.getTranslation('Common.ViewStakeholder.Stakeholder.Email');
  @Input() public showEmail = true;

  @Input() public phoneLabel = this.translate.getTranslation('Common.ViewStakeholder.Stakeholder.Phone');
  @Input() public showPhone = true;

  @Input() public faxLabel = this.translate.getTranslation('Common.ViewStakeholder.Stakeholder.Fax');
  @Input() public showFax = false;

  @Input() public notesLabel = this.translate.getTranslation('Common.ViewStakeholder.Stakeholder.Notes');
  @Input() public showNotes = true;

  @Input() public showAddress = false;

  @Input() public stakeholder: Stakeholder;

  constructor(public utilityService: UtilityService, private translate: AppTranslationService) { }

  ngOnInit() { }
}
