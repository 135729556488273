/**
 * A class that encapsulates the parameters required to update a role.
 */
export class UpdateRoleModel {
  /**
   * Initializes a new instance of the update role model class.
   * @param name The role name.
   * @param permissions The associated permissions.
   * @param concurrencyStamp The concurrency stamp.
   */
  constructor(public name: string, public permissions: string[], public concurrencyStamp: string) {}
}
