import { Component, OnInit } from "@angular/core";
import { AuthenticationService } from "./../../services/authentication.service";

@Component({
  selector: "roctavian-abstractions-security-settings-page",
  templateUrl: "./security-settings-page.component.html",
  styleUrls: ["./security-settings-page.component.scss"]
})
export class SecuritySettingsPageComponent implements OnInit {
  public identity: number;
  constructor(private service: AuthenticationService) {}

  public ngOnInit() {
    this.identity = this.service.claimsPrincipal.getClaimValue("sub");
  }
}
