/**
 * Class used to represent a task type.
 */
export class TaskType {
  public taskTypeIdentity: number;
  public name: string;
  public description: string;
  public order: number;
  public isGeneral: boolean;
  public isCompliance: boolean;
  public addDate: Date;
  public editDate: Date;
}
