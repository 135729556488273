import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { Endpoints } from '@app/core/enums';

import { UploadDocument } from '@app/core/models';
import { ValueOutcome } from '@roctavian-abstractions/web/lib/models';

import { Document } from '../models';

@Injectable({
  providedIn: 'root',
})
export class DocumentService {
  constructor(private readonly http: HttpClient) {}

  create(document: UploadDocument): Observable<ValueOutcome<Document>> {
    return this.http.post<ValueOutcome<Document>>(Endpoints.document, document);
  }
}
