import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { RolesClient } from 'apps/roctavian-eumea-callcenter-portal/src/app/modules/identity/clients/roles.client';
import { Role, UpdateRoleModel } from 'apps/roctavian-eumea-callcenter-portal/src/app/modules/identity/models';
import { PermissionToggleGroupListComponent } from '../../../permissions/components';
import { RoleFormSubmitEvent } from '../role-form/role-form.component';

@Component({
  selector: 'roctavian-abstractions-edit-role',
  templateUrl: './edit-role.component.html',
  styleUrls: ['./edit-role.component.scss'],
})
export class EditRoleComponent implements OnInit {
  @Input() public role: Role;

  @Output() public updated = new EventEmitter();

  @ViewChild('permissions', { static: false }) toggle: PermissionToggleGroupListComponent;

  public messages: string[] = [];
  public disabled = false;

  constructor(private client: RolesClient) {}

  public ngOnInit() {}

  public getRolePermissionIdentities() {
    const permissions = this.role.rolePermissions;
    return permissions.map(p => p.id);
  }

  /**
   * Handles the submission event of the role form by
   * executing a web request to update the role.
   *
   * @param event The role form submit event.
   */
  public handleFormSubmission(event: RoleFormSubmitEvent) {
    const permissions = this.toggle.getSelectedPermissionIdentities();
    const model = new UpdateRoleModel(
      event.name,
      permissions,
      this.role.concurrencyStamp
    );
    this.client.updateRole(this.role.id, model).subscribe(
      outcome => {
        this.disabled = false;
        if (!outcome.failure) {
          this.updated.emit();
        }
      },
      response => {
        this.disabled = false;
        this.messages = response.error.messages;
      }
    );
  }
}
