import { CreateTaskActionModel } from './CreateTaskActionModel';

/**
 * Model used when creating a new task.
 */
export class CreateTaskModel {
  public entityTypeIdentity: string;
  public taskEntityName: string;
  public taskTypeIdentity: string;
  public workgroupIdentity?: string;
  public assignedUserIdentity?: string;
  public priority: number;
  public dueDate?: Date;
  public taskAction: CreateTaskActionModel;
}
