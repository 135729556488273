import { Claim } from './claim';
import { Permission } from './permission';

export class Role {
  public id: string;
  public name: string;
  public normalizedName: string;
  public concurrencyStamp: string;
  public claims: Claim[];
  public rolePermissions: Permission[];
}
