import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'roctavian-eumea-callcenter-portal-add-lab-kit-order-page',
  templateUrl: './add-lab-kit-order-page.component.html',
  styleUrls: ['./add-lab-kit-order-page.component.scss']
})
export class AddLabKitOrderPageComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
