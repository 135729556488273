import { Injectable, OnDestroy } from '@angular/core';
import { AppTranslationService, MessageService } from '@roctavian-abstractions/core';
import { Outcome, PaginatedList } from '@roctavian-abstractions/web';
import { BehaviorSubject, Subscription } from 'rxjs';
import { ClearService } from '../../../../shared/interface/clear-service.interface';
import { AttestationClient } from '../clients';
import { Attestation } from '../models';
import { AttestationPagedQuery } from '../models/attestation-paged-query.model';

@Injectable({
  providedIn: 'root'
})
export class AttestationService implements OnDestroy, ClearService {
  public attestationPagedListSubject = new BehaviorSubject<PaginatedList<Attestation>>(
    new PaginatedList<Attestation>()
  );
  public signatureDateSubject = new BehaviorSubject<Date>(null);
  public isLoadingSubject = new BehaviorSubject<boolean>(false);
  private getAttestationListPagedSubscription: Subscription;

  constructor(
    private client: AttestationClient,
    private messageService: MessageService,
    private translate: AppTranslationService
  ) { }

  public ngOnDestroy() {
    if (this.getAttestationListPagedSubscription) {
      this.getAttestationListPagedSubscription.unsubscribe();
      this.getAttestationListPagedSubscription = null;
    }
  }

  public setPage(pagedQuery: AttestationPagedQuery) {
    this.isLoadingSubject.next(true);

    if (this.getAttestationListPagedSubscription) {
      this.getAttestationListPagedSubscription.unsubscribe();
      this.getAttestationListPagedSubscription = null;
    }

    this.getAttestationListPagedSubscription = this.client.getAttestationListPaged(pagedQuery).subscribe(
      outcome => {
        if (!outcome.success || outcome.failure) {
          this.handleError(outcome);
          return;
        }

        this.attestationPagedListSubject.next(outcome.value);
        this.isLoadingSubject.next(false);
      },
      (error: Outcome) => {
        this.isLoadingSubject.next(false);
        this.handleError(error);
      }
    );
  }

  private handleError(error: Outcome) {
    if (error && error.messages && error.messages.length > 0) {
      this.messageService.open(error.messages[0], 5000);
    } else {
      this.messageService.open(this.translate.getTranslation('Common.ErrorProcessingRequest'), 5000);
    }
  }

  public clearService() {
    this.attestationPagedListSubject.next(new PaginatedList<Attestation>());
  }
}
