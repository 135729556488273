import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ConfigService } from '@roctavian-abstractions/core';
import { Outcome } from '@roctavian-abstractions/web';
import { Observable } from 'rxjs';
import { CreatePermissionModel, UpdatePermissionModel } from '../models';
import { Permission } from '../models/permission';

/**
 * A client used to communicate with the permissions API.
 */
@Injectable({
  providedIn: 'root'
})
export class PermissionClient {
  /**
   * Initializes a new instance of the permission client.
   *
   * @param client The http client.
   */
  constructor(private client: HttpClient, private configService: ConfigService) {
  }

  /**
   * Gets the permissions defined in the application.
   *
   * @returns An array of permissions.
   */
  public getPermissions(): Observable<Permission[]> {
    return this.client.get<Permission[]>(`${this.configService.get('apiUrl')}/identity/permission`);
  }

  /**
   * Gets the permission with the specified identity.
   *
   * @param identity The permission identity.
   * @returns The matching permission if it exists.
   */
  public getPermission(identity: number): Observable<Permission> {
    return this.client.get<Permission>(`${this.configService.get('apiUrl')}/identity/permission/${identity}`);
  }

  /**
   * Creates a new permission.
   *
   * @param model The create permission model.
   * @returns An outcome indicating whether the operation was successful.
   */
  public createPermission(model: CreatePermissionModel): Observable<Outcome> {
    return this.client.post<Outcome>(`${this.configService.get('apiUrl')}/identity/permission`, model);
  }

  /**
   * Updates the permission.
   *
   * @param identity The permission identity.
   * @param model The update permission model.
   * @returns An outcome indicating whether the operation was successful.
   */
  public updatePermission(identity: string, model: UpdatePermissionModel): Observable<Outcome> {
    return this.client.put<Outcome>(`${this.configService.get('apiUrl')}/identity/permission/${identity}`, model);
  }

  /**
   * Deletes the permission.
   *
   * @param identity The permission identity.
   * @returns An outcome indicating whether the operation was successful.
   */
  public deletePermission(identity: string): Observable<Outcome> {
    return this.client.delete<Outcome>(`${this.configService.get('apiUrl')}/identity/permission/${identity}`);
  }
}
