import { Component, ViewChild, OnInit, AfterViewInit } from '@angular/core';
import { Workgroup } from '../../../../models';
import { Router } from '@angular/router';
import { WorkgroupManagementService } from '../../../../services/workgroup-management.service';
import { MatPaginator, PageEvent } from '@angular/material';

@Component({
  selector: 'roctavian-abstractions-workgroup-card-list',
  templateUrl: './workgroup-card-list.component.html',
  styleUrls: ['./workgroup-card-list.component.scss']
})
export class WorkgroupCardListComponent implements AfterViewInit {
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;

  public page = 1;
  public workgroups = new Array<Workgroup>();
  public total = 0;
  public isTableLoading = false;

  public filterText: string;
  public size = 5;

  /**
   * Initializes the component and sets-up subscriptions for paging.
   *
   * @param service The workgroup management service.
   * @param router The router.
   */
  constructor(private service: WorkgroupManagementService, private router: Router) {
    this.filterText = this.service.currentFilterText;
    this.page = this.service.currentPage;
    this.size = this.service.pageSize;

    this.service.loading.subscribe(loading => {
      this.isTableLoading = loading;
    });

    this.service.total.subscribe(total => {
      this.total = total;
    });

    this.service.workgroups.subscribe(workgroups => {
      this.workgroups = workgroups;
    });

    this.service.setPage(this.page, this.size, this.filterText);
  }

  public ngAfterViewInit() {
    this.paginator._intl.itemsPerPageLabel = 'Workgroups Per Page:';
  }

  /**
   * Filters the card list by the provided text.
   *
   * @param text the text to filter
   */
  public handleKeyup(text: string) {
    this.page = 1;
    this.filterText = text;
    this.service.setPage(this.page, this.size, text);
  }

  /**
   * Requests a new page of workgroups.
   *
   * @param event the page event
   */
  public handlePage(event: PageEvent) {
    this.page = event.pageIndex + 1;
    this.service.setPage(event.pageIndex + 1, this.size, this.filterText);
  }

  /**
   * Navigate to the management page of the selected workgroup.
   *
   * @param workgroup the workgroup card that was selected
   */
  public handleCardSelected(workgroup: Workgroup) {
    this.router.navigate([`/tasks/workgroup/${workgroup.workgroupIdentity}`]);
  }
}
