import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { StakeholderCommonModule } from './modules/common';
import { ExternalLabTestModule } from './modules/external-lab-test/external-lab-test';
import { InstitutionModule } from './modules/institution';
import { LabModule } from './modules/lab';
import { LabKitOrderModule } from './modules/lab-kit-order/lab-kit-order.module';
import { LabTestModule } from './modules/lab-test/lab-test.module';
import { LabUserModule } from './modules/labuser/labuser.module';
import { PatientModule } from './modules/patient';
import { PrescriberModule } from './modules/prescriber';
import { PrescriberDelegateModule } from './modules/prescriber-delegate/prescriber-delegate.module';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    PrescriberModule,
    PatientModule,
    LabModule,
    InstitutionModule,
    StakeholderCommonModule,
    LabUserModule,
    PrescriberDelegateModule,
    LabKitOrderModule,
    LabTestModule,
    ExternalLabTestModule
  ],
  exports: []
})
export class StakeholderModule {}
