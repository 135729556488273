import { OnDestroy } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { LabKitOrder } from '../../models/lab-kit-order.model';
import { Subscription } from 'rxjs';
import { LabKitOrderService } from '../../services/lab-kit-order.service';
import { LabKitOrderClient } from '../../clients/lab-kit-order.client';
import { MessageService } from '@roctavian-abstractions/core';
import { AppTranslationService } from '@roctavian-abstractions/core';
import { UtilityService } from '../../../common';
import { Router } from '@angular/router';
import { Outcome } from '@roctavian-abstractions/web';

@Component({
  selector: 'roctavian-eumea-callcenter-portal-edit-lab-kit-order-page',
  templateUrl: './edit-lab-kit-order-page.component.html',
  styleUrls: ['./edit-lab-kit-order-page.component.scss']
})
export class EditLabKitOrderPageComponent implements OnInit, OnDestroy {
  public form: FormGroup;
  public labKitOrder: LabKitOrder;
  public isProcessing = false;
  public errorMessages = new Array<string>();

  private subscriptions = new Array<Subscription>();

  constructor(
    private formBuilder: FormBuilder,
    private labKitOrderService: LabKitOrderService,
    private labKitOrderClient: LabKitOrderClient,
    private messageService: MessageService,
    private translate: AppTranslationService,
    public utilityService: UtilityService,
    private router: Router
  ) { }

  ngOnDestroy() {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }

  ngOnInit() {
    this.form = this.formBuilder.group({});

    this.subscriptions.push(
      this.labKitOrderService.selectedLabKitOrderSubject.subscribe(labKitOrder => {
        this.labKitOrder = labKitOrder;
        if (!this.labKitOrder) {
          return;
        }

        setTimeout(() => {
          this.form.patchValue({
            ...this.labKitOrder
          });
        });
      })
    );
  }

  public submit() {
    this.form.markAllAsTouched();

    if (this.form.invalid) {
      return;
    }

    this.isProcessing = true;
    this.errorMessages = [];
    const updatedLabKitOrder: LabKitOrder = this.form.getRawValue();
    updatedLabKitOrder.id = this.labKitOrder.id;

    this.subscriptions.push(
      this.labKitOrderClient.updateLabKitOrder(updatedLabKitOrder).subscribe(
        outcome => {
          this.isProcessing = false;
          if (outcome.failure) {
            this.handleError(outcome);
            return;
          }

          this.messageService.open(this.translate.getTranslation('LabKitOrder.SuccessfullyUpdated'), 5000);
          this.labKitOrderService.selectLabKitOrder(this.labKitOrder.id, true);
          this.router.navigate(['/lab-kit-order', this.labKitOrder.id]);
        },
        (error: Outcome) => {
          this.isProcessing = false;
          this.handleError(error);
        }
      )
    );
  }

  private handleError(error: Outcome) {
    if (!error || !error.messages || error.messages.length === 0) {
      this.errorMessages = [this.translate.getTranslation('Common.ErrorProcessingRequest')];
    } else {
      this.errorMessages = error.messages;
    }
  }
}
