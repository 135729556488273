/* eslint-disable @typescript-eslint/member-ordering */
import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { RolesClient } from 'apps/roctavian-eumea-callcenter-portal/src/app/modules/identity/clients/roles.client';
import { CreateRoleModel } from 'apps/roctavian-eumea-callcenter-portal/src/app/modules/identity/models';
import { PermissionToggleGroupListComponent } from '../../../permissions/components';
import { RoleFormSubmitEvent } from '../role-form/role-form.component';

@Component({
  selector: 'roctavian-abstractions-create-role',
  templateUrl: './create-role.component.html',
  styleUrls: ['./create-role.component.scss'],
})
export class CreateRoleComponent implements OnInit {
  @Output() public saved = new EventEmitter();

  public messages: string[] = [];
  public disabled = false;

  @ViewChild('permissions', { static: false })
  public toggle: PermissionToggleGroupListComponent;

  constructor(private client: RolesClient) {}

  public ngOnInit() {}

  public handleFormSubmission(event: RoleFormSubmitEvent) {
    this.disabled = true;

    const permissions = this.toggle.getSelectedPermissionIdentities();
    const model = new CreateRoleModel(event.name, permissions);

    this.client.createRole(model).subscribe(
      outcome => {
        this.disabled = false;
        if (!outcome.failure) {
          this.saved.emit();
        }
      },
      response => {
        this.disabled = false;
        this.messages = response.error.messages;
      }
    );
  }
}
