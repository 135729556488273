import { Component, OnInit, Input, OnChanges, SimpleChanges } from '@angular/core';
import { FormGroupDirective, FormControl, Validators } from '@angular/forms';
import { AppTranslationService } from '@roctavian-abstractions/core';

@Component({
  selector: 'change-reason-description-form-control',
  templateUrl: './change-reason-description-form-control.component.html',
  styleUrls: ['./change-reason-description-form-control.component.css']
})
export class ChangeReasonDescriptionFormControlComponent implements OnInit, OnChanges {
  @Input() changeReasonDescriptionLabel = this.translate.getTranslation(
    'Common.ChangeReasonDescriptionFormControl.ChangeReasonDescription'
  );

  @Input() public formControlName = 'changeReasonDescription';
  @Input() public isFieldRequired = true;

  constructor(public parentFormGroupDirective: FormGroupDirective, private translate: AppTranslationService) { }

  ngOnInit() {
    this.buildForm();
  }

  private buildForm() {
    this.parentFormGroupDirective.form.addControl(this.formControlName, new FormControl(null, []));

    this.refreshValidations(this.isFieldRequired);
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (!this.parentFormGroupDirective.form || !this.parentFormGroupDirective.form.get(this.formControlName)) {
      return;
    }

    this.refreshValidations(changes.areFieldsRequired.currentValue);
  }

  private refreshValidations(areFieldsRequired: boolean) {
    this.parentFormGroupDirective.form.get(this.formControlName).clearValidators();

    if (!areFieldsRequired) {
      return;
    }

    if (this.isFieldRequired) {
      this.parentFormGroupDirective.form.get(this.formControlName).setValidators(Validators.required);
    }
  }
}
