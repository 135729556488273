import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { Endpoints } from '@app/core/enums';
import { UtilityService } from '@app/stakeholder/services';
import { Outcome, PaginatedList, ValueOutcome } from '@roctavian-abstractions/web';

import { LabKitOrderPagedQuery } from '../models/lab-kit-order-paged-query.model';
import { LabKitOrder } from '../models/lab-kit-order.model';

@Injectable({
  providedIn: 'root',
})
export class LabKitOrderClient {
  constructor(private readonly http: HttpClient, private readonly utilityService: UtilityService) {}

  getLabKitOrderListPaged(pagedQuery: LabKitOrderPagedQuery): Observable<ValueOutcome<PaginatedList<LabKitOrder>>> {
    return this.http.get<ValueOutcome<PaginatedList<LabKitOrder>>>(Endpoints.labKitOrder, {
      params: this.utilityService.toHttpParams(pagedQuery),
    });
  }

  createLabKitOrder(labKitOrder: LabKitOrder): Observable<Outcome> {
    return this.http.post<Outcome>(Endpoints.labKitOrder, labKitOrder);
  }

  updateLabKitOrder(labKitOrder: LabKitOrder): Observable<Outcome> {
    return this.http.put<Outcome>(Endpoints.labKitOrder, labKitOrder);
  }

  getLabKitOrder(labKitOrderId: string): Observable<Outcome> {
    const url = `${Endpoints.labKitOrder}/${labKitOrderId}`;
    return this.http.get<Outcome>(url);
  }

  deleteLabKitOrder(labKitOrderId: string): Observable<Outcome> {
    const url = `${Endpoints.labKitOrder}/${labKitOrderId}`;
    return this.http.delete<Outcome>(url);
  }

  getExportFile() {
    const url = `${Endpoints.labKitOrder}/export`;
    return this.http.get(url, { responseType: 'blob' });
  }
}
