import { Component, OnInit, ViewChild, Output, EventEmitter, Input } from '@angular/core';
import { Stakeholder } from '../../models';
import { AttestationListComponent } from '../../components';
import { AppTranslationService } from '@roctavian-abstractions/core';

@Component({
  selector: 'attestation-list-page',
  templateUrl: './attestation-list-page.component.html',
  styleUrls: ['./attestation-list-page.component.css']
})
export class AttestationListPageComponent implements OnInit {
  //public showAddAttestationForm = false;
  @Input() public showAddAttestationForm = false;

  @Output() public attestationSaved = new EventEmitter();
  @Input() public stakeholder: Stakeholder;

  @Input() attestationFormTitle = this.translate.getTranslation('Common.AttestationForm.Title');
  @Input() showAttestationFormTitle = false;

  @Input() attestationFormHasSignatureLabel = this.translate.getTranslation('Common.AttestationForm.Signature');
  @Input() showAttestationFormHasSignature = true;

  @Input() attestationFormSignatureDateLabel = this.translate.getTranslation('Common.AttestationForm.SignatureDate');
  @Input() showAttestationFormSignatureDate = true;

  @Input() attestationFormGuardianNameLabel = this.translate.getTranslation('Common.AttestationForm.PrintedName');
  @Input() showAttestationFormGuardianName = false;

  @Input() attestationFormHasConsentTitle = this.translate.getTranslation(
    'Common.Stakeholder.Attestation.HasConsentTitle'
  );
  @Input() showAttestationFormHasConsentTitle = false;
  @Input() attestationFormHasConsentLabel = this.translate.getTranslation('Common.AttestationForm.HasConsent');
  @Input() showAttestationFormHasConsent = false;

  @Input() attestationFormChangeReasonDescriptionLabel = this.translate.getTranslation(
    'Common.AttestationForm.ChangeReasonDescription'
  );
  @Input() showAttestationFormChangeReasonDescription = false;

  @Input() attestationFormAttestationTypeLabel = this.translate.getTranslation(
    'Common.AttestationForm.AttestationType'
  );
  @Input() showAttestationFormAttestationType = false;

  @Input() attestationListIsCurrentLabel = this.translate.getTranslation('Common.AttestationList.IsCurrent');
  @Input() showAttestationListIsCurrent = true;

  @Input() attestationListHasConsentLabel = this.translate.getTranslation('Common.AttestationList.HasConsent');
  @Input() showAttestationListHasConsent = false;

  @Input() attestationListHasSignatureLabel = this.translate.getTranslation('Common.AttestationList.HasSignature');
  @Input() showAttestationListHasSignature = true;

  @Input() attestationListSignatureDateLabel = this.translate.getTranslation('Common.AttestationList.SignatureDate');
  @Input() showAttestationListSignatureDate = true;

  @Input() attestationListChangeReasonDescriptionLabel = this.translate.getTranslation(
    'Common.AttestationList.ChangeReasonDescription'
  );
  @Input() showAttestationListChangeReasonDescription = true;

  @Input() attestationListGuardianNameLabel = this.translate.getTranslation('Common.AttestationList.GuardianName');
  @Input() showAttestationListGuardianName = false;

  @Input() attestationListAddByUserLabel = this.translate.getTranslation('Common.AttestationList.AddByUser');
  @Input() showAttestationListAddByUser = true;

  @Input() attestationListAddDateLabel = this.translate.getTranslation('Common.AttestationList.AddDate');
  @Input() showAttestationListAddDate = true;

  @ViewChild('attestationList', { static: false }) attestationListComponent: AttestationListComponent;

  constructor(private translate: AppTranslationService) { }

  ngOnInit() { }

  public beginAddAttestation() {
    this.showAddAttestationForm = true;
  }

  public onAttestationSaved() {
    this.attestationSaved.emit();
    this.showAddAttestationForm = false;
    this.attestationListComponent.refreshList();
  }

  public onCancelAddAttestation() {
    this.showAddAttestationForm = false;
  }
}
