import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'add-external-lab-test-page',
  templateUrl: './add-external-lab-test-page.component.html',
  styleUrls: ['./add-external-lab-test-page.component.scss']
})
export class AddExternalLabTestPageComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
