import { User } from "../../../../identity/models";

export class Auditable {
  public addUser: User;

  public addDate: Date;

  public editUser: User;

  public editDate: Date;
}
