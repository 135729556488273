import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { Endpoints } from '@app/core/enums';
import { Document, PagedQuery } from '@app/stakeholder/models';
import { UtilityService } from '@app/stakeholder/services';
import {
  Outcome,
  PaginatedList,
  ValueOutcome,
} from '@roctavian-abstractions/web';

import { LabTest } from '../../lab-test/models/lab-test.model';
import { Prescriber } from '../../prescriber/models/prescriber.model';
import {
  DocumentPatient,
  DocumentPatientQuery,
  Patient,
  PatientDuplicateCheckPagedQuery,
  PatientPagedQuery,
  PatientPrescriber,
  PatientPrescriberAssociatePagedQuery,
} from '../models';

@Injectable({
  providedIn: 'root',
})
export class PatientClient {
  constructor(
    private readonly http: HttpClient,
    private readonly utilityService: UtilityService,
  ) {}

  getPagedList(
    pagedQuery: PatientPagedQuery,
  ): Observable<ValueOutcome<PaginatedList<Patient>>> {
    return this.http.get<Outcome>(`${Endpoints.patient}`, {
      params: this.utilityService.toHttpParams(pagedQuery),
    });
  }

  getPatientPrescriberListPaged(
    pagedQuery: PagedQuery,
    prescriberId: string,
  ): Observable<ValueOutcome<PaginatedList<PatientPrescriber>>> {
    const url = `${Endpoints.prescriber}/${prescriberId}/prescriber-patient`;
    return this.http.get<ValueOutcome<PaginatedList<PatientPrescriber>>>(url, {
      params: this.utilityService.toHttpParams(pagedQuery),
    });
  }

  getPatientDuplicates(
    pagedQuery: PatientDuplicateCheckPagedQuery,
  ): Observable<ValueOutcome<PaginatedList<Patient>>> {
    const params = this.utilityService.toHttpParams(pagedQuery);

    const url = `${Endpoints.patient}/duplicate-check`;
    return this.http.get<Outcome>(url, { params });
  }

  getPatientPrescribersAssociateList(
    pagedQuery: PatientPrescriberAssociatePagedQuery,
    patientId: string,
  ): Observable<ValueOutcome<PaginatedList<Prescriber>>> {
    let params = this.utilityService.toHttpParams(pagedQuery);
    if (patientId) {
      params = params.append('patientId', patientId.toString());
    }

    const url = `${Endpoints.patient}/associate-prescriber-list`;
    return this.http.get<ValueOutcome<PaginatedList<Prescriber>>>(url, {
      params,
    });
  }

  createPatient(patient: Patient): Observable<Outcome> {
    return this.http.post<Outcome>(Endpoints.patient, patient);
  }

  updatePatient(patient: Patient): Observable<Outcome> {
    return this.http.put<Outcome>(Endpoints.patient, patient);
  }

  getPatient(patientId: string): Observable<ValueOutcome<Patient>> {
    const url = `${Endpoints.patient}/${patientId}`;
    return this.http.get<Outcome>(url);
  }

  getPatientsPrescribersByPatientId(
    patientId: string,
    pagedQuery: PagedQuery,
  ): Observable<ValueOutcome<PaginatedList<PatientPrescriber>>> {
    const params = this.utilityService.toHttpParams(pagedQuery);

    const url = `${Endpoints.patient}/${patientId}/patient-prescriber`;
    return this.http.get<ValueOutcome<PaginatedList<PatientPrescriber>>>(url, {
      params,
    });
  }

  associatePatientPrescriber(
    patientId: string,
    prescriberId: string,
  ): Observable<Outcome> {
    const url = `${Endpoints.patient}/${patientId}/associateprescriber/${prescriberId}`;
    return this.http.post<Outcome>(url, {});
  }

  getDocumentsPagedList(
    pagedQuery: DocumentPatientQuery,
  ): Observable<ValueOutcome<PaginatedList<DocumentPatient>>> {
    const value = new PaginatedList<Document>();
    value.items = [];
    value.pageIndex = 1;
    value.totalCount = 0;
    value.totalPages = 1;

    return this.http.get<ValueOutcome<PaginatedList<DocumentPatient>>>(
      `${Endpoints.documentPatient}`,
      {
        params: this.utilityService.toHttpParams(pagedQuery),
      },
    );
  }

  addDocumentToPatient(
    patientId: string,
    document: Document,
  ): Observable<Outcome> {
    const patient = new Patient();
    patient.id = patientId;

    const value = new DocumentPatient();
    value.patient = patient;
    value.document = document;

    return this.http.post<Outcome>(`${Endpoints.documentPatient}`, value);
  }

  getPatientLabTestsByPatientId(
    patientId: string,
    pagedQuery: PagedQuery,
  ): Observable<ValueOutcome<PaginatedList<LabTest>>> {
    const params = this.utilityService.toHttpParams(pagedQuery);

    const url = `${Endpoints.patient}/${patientId}/lab-test`;
    return this.http.get<ValueOutcome<PaginatedList<LabTest>>>(url, {
      params,
    });
  }

  getExportFile() {
    const url = `${Endpoints.patient}/export`;
    return this.http.get(url, { responseType: 'blob' });
  }
}
