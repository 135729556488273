import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { LoggingService } from '@roctavian-abstractions/core';
import { AuthenticationEventHandler } from './modules/identity/authentication-event.handler';
import { AuthenticationService } from './modules/identity/services/authentication.service';

@Injectable()
export class RoctavianAuthenticationEventHandler extends AuthenticationEventHandler {
    constructor(private logger: LoggingService,
        private router: Router,
        private authService: AuthenticationService) {
        super();
    }

    public handleSuccessfulLogin() {

        if (this.authService.claimsPrincipal.hasRole('Administrator')) {
            this.router.navigate(['/settings']);
        } else {
            this.router.navigate(['/prescriber']);
        }
    }

    public handleExpiredPassword() {
        this.router.navigate(['/expired']);
    }

    public handleInitialLockout() {
        const message = 'Create and register a custom AuthenticationEventHandler to handle the initial lockout event.';
        this.logger.log(message);
    }

    public handleAccountLocked() {
        const message = 'Create and register a custom AuthenticationEventHandler to handle the account locked event.';
        this.logger.log(message);
    }

    public handleEmailNotConfirmed() {
        const message = 'Create and register a custom AuthenticationEventHandler to handle the  email not confirmed event.';
        this.logger.log(message);
    }
}
