import { Subscription } from 'rxjs';
import { Component, OnInit, EventEmitter, Output, Input, OnDestroy } from '@angular/core';
import { MessageService } from '@roctavian-abstractions/core';
import { AppTranslationService } from '@roctavian-abstractions/core';
import { PrescriberDelegate } from '../../../prescriber-delegate/models/prescriber-delegate.model';
import { Prescriber, PrescriberDelegatePrescriber } from '../../models';
import { PrescriberClient } from '../../clients';

@Component({
  selector: 'prescriber-associate-prescriber-delegate',
  templateUrl: './associate-prescriber-delegate.component.html',
  styleUrls: ['./associate-prescriber-delegate.component.scss']
})
export class AssociatePrescriberDelegateComponent implements OnDestroy {
  public errorMessages: string[] = [];
  @Output() public associated = new EventEmitter();
  @Output() public cancelAssociation = new EventEmitter();
  @Input() public prescriber: Prescriber;
  public selectedPrescriberDelegate: PrescriberDelegate;

  private subscriptions = new Array<Subscription>();

  constructor(
    private prescriberClient: PrescriberClient,
    private messageService: MessageService,
    private translate: AppTranslationService
  ) { }

  ngOnDestroy() {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }

  public saveAssociation() {
    this.errorMessages = [];

    if (!this.selectedPrescriberDelegate) {
      this.errorMessages = ['Please select a prescriber delegate for association'];
      return;
    }

    const prescriberDelegatePrescriber = new PrescriberDelegatePrescriber();
    prescriberDelegatePrescriber.prescriberId = this.prescriber.id;
    prescriberDelegatePrescriber.prescriberDelegateId = this.selectedPrescriberDelegate.id;

    this.subscriptions.push(this.prescriberClient
      .createPrescriberDelegateAssociation(prescriberDelegatePrescriber)
      .subscribe(
        outcome => {
          if (outcome.failure) {
            this.errorMessages = outcome.messages;
            return;
          }

          this.messageService.open(
            this.translate.getTranslation('Prescriber.AssociatePrescriberDelegate.SuccessfullyAssociated'),
            5000
          );
          this.associated.emit();
        },
        error => {
          this.errorMessages = [this.translate.getTranslation('Common.ErrorProcessingRequest')];
        }
      ));
  }

  public cancelPrescriberDelegateAssociation() {
    this.cancelAssociation.emit();
  }

  public onPrescriberDelegateSelected(prescriberDelegate: PrescriberDelegate) {
    this.selectedPrescriberDelegate = prescriberDelegate;
  }
}
