import {
  ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output
} from '@angular/core';
import { Role } from 'apps/roctavian-eumea-callcenter-portal/src/app/modules/identity/models';

@Component({
  selector: 'roctavian-abstractions-role-card',
  templateUrl: './role-card.component.html',
  styleUrls: ['./role-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RoleCardComponent implements OnInit {
  @Input() public role: Role;

  @Output() public selected = new EventEmitter<string>();

  constructor() {}

  public ngOnInit() {}

  public handleClick() {
    this.selected.emit(this.role.id);
  }
}
