/* eslint-disable @typescript-eslint/member-ordering */
import { Injectable, OnDestroy } from '@angular/core';
import {
  AppTranslationService,
  MessageService
} from '@roctavian-abstractions/core';
import { Outcome, PaginatedList } from '@roctavian-abstractions/web';
import { BehaviorSubject, Subscription } from 'rxjs';
import { ClearService } from '../../../../shared/interface/clear-service.interface';
import { Document, PagedQuery } from '../../common';
import { Lab } from '../../lab';
import { Patient } from '../../patient';
import { LabTestClient } from '../clients/lab-test.client';
import { DocumentLabTestQuery } from '../models/document-lab-test-paged-query.model';
import { DocumentLabTest } from '../models/document-lab-test.model';
import { LabTest } from '../models/lab-test.model';

@Injectable({
  providedIn: 'root',
})
export class LabTestService implements OnDestroy, ClearService {
  public labTestPagedListSubject = new BehaviorSubject<PaginatedList<LabTest>>(
    new PaginatedList<LabTest>(),
  );
  public patientPagedListSubject = new BehaviorSubject<PaginatedList<Patient>>(
    new PaginatedList<Patient>(),
  );
  public labPagedListSubject = new BehaviorSubject<PaginatedList<Lab>>(
    new PaginatedList<Lab>(),
  );
  public pagedLabTestDocumentsListSubject = new BehaviorSubject<
    PaginatedList<DocumentLabTest>
  >(new PaginatedList<DocumentLabTest>());
  public isLoadingSubject = new BehaviorSubject<boolean>(false);

  public selectedLabTestSubject = new BehaviorSubject<LabTest>(null);

  private subscriptions = new Array<Subscription>();

  private getLabTestDocumentsListSubscription: Subscription;
  private addDocumentToLabTestSubscription: Subscription;
  public addDocumentToLabTestSubject = new BehaviorSubject<Outcome>(null);

  constructor(
    private labTestClient: LabTestClient,
    private messageService: MessageService,
    private translate: AppTranslationService,
  ) {}

  ngOnDestroy() {
    this.clearSubscriptions();
  }

  private clearSubscriptions() {
    this.subscriptions.forEach(subscription => {
      subscription.unsubscribe();
    });

    this.subscriptions = new Array<Subscription>();
  }

  public setPage(pagedQuery: PagedQuery) {
    this.isLoadingSubject.next(true);

    this.subscriptions.push(
      this.labTestClient.getLabTestListPaged(pagedQuery).subscribe(
        outcome => {
          if (!outcome.success || outcome.failure) {
            this.handleError(outcome);
            return;
          }

          this.labTestPagedListSubject.next(outcome.value);
          this.isLoadingSubject.next(false);
        },
        (error: Outcome) => {
          this.handleError(error);
        },
      ),
    );
  }

  public setPatientPage(pagedQuery: PagedQuery) {
    this.isLoadingSubject.next(true);

    this.subscriptions.push(
      this.labTestClient.getPatientListPaged(pagedQuery).subscribe(
        outcome => {
          if (!outcome.success || outcome.failure) {
            this.handleError(outcome);
            return;
          }

          this.patientPagedListSubject.next(outcome.value);
          this.isLoadingSubject.next(false);
        },
        (error: Outcome) => {
          this.handleError(error);
        },
      ),
    );
  }

  public setLabPage(pagedQuery: PagedQuery) {
    this.isLoadingSubject.next(true);

    this.subscriptions.push(
      this.labTestClient.getLabListPaged(pagedQuery).subscribe(
        outcome => {
          if (!outcome.success || outcome.failure) {
            this.handleError(outcome);
            return;
          }

          this.labPagedListSubject.next(outcome.value);
          this.isLoadingSubject.next(false);
        },
        (error: Outcome) => {
          this.handleError(error);
        },
      ),
    );
  }

  public loadLabTest(labTestId: string, forceRefresh: boolean = false) {
    if (
      this.selectedLabTestSubject.value &&
      this.selectedLabTestSubject.value.id === labTestId &&
      !forceRefresh
    ) {
      return;
    }

    this.clearSubscriptions();

    this.isLoadingSubject.next(true);

    this.subscriptions.push(
      this.labTestClient.getLabTest(labTestId).subscribe(
        outcome => {
          if (!outcome.success || outcome.failure) {
            this.messageService.open(outcome.messages[0], 5000);
            return;
          }

          this.selectedLabTestSubject.next(outcome.value);
          this.isLoadingSubject.next(false);
        },
        (error: Outcome) => {
          this.handleError(error);
        },
      ),
    );
  }

  public setDocumentsPage(pagedQuery: DocumentLabTestQuery) {
    this.isLoadingSubject.next(true);

    if (this.getLabTestDocumentsListSubscription) {
      this.getLabTestDocumentsListSubscription.unsubscribe();
      this.getLabTestDocumentsListSubscription = null;
    }

    this.getLabTestDocumentsListSubscription = this.labTestClient
      .getDocumentsPagedList(pagedQuery)
      .subscribe(
        outcome => {
          if (!outcome.success || outcome.failure) {
            this.messageService.open(outcome.messages[0], 5000);
            return;
          }
          this.pagedLabTestDocumentsListSubject.next(outcome.value);
          this.isLoadingSubject.next(false);
        },
        (error: Outcome) => {
          this.messageService.open(error.messages[0], 5000);
          this.isLoadingSubject.next(false);
        },
      );
  }

  public addDocumentToLabTest(labTestId: string, document: Document) {
    this.isLoadingSubject.next(true);

    if (this.addDocumentToLabTestSubscription) {
      this.addDocumentToLabTestSubscription.unsubscribe();
      this.addDocumentToLabTestSubscription = null;
    }

    this.addDocumentToLabTestSubscription = this.labTestClient
      .addDocumentToLabTest(labTestId, document)
      .subscribe(
        outcome => {
          if (!outcome.success || outcome.failure) {
            this.messageService.open(outcome.messages[0], 5000);
            return;
          }

          this.addDocumentToLabTestSubject.next(outcome);
          this.isLoadingSubject.next(false);
        },
        (error: Outcome) => {
          this.messageService.open(error.messages[0], 5000);
          this.isLoadingSubject.next(false);
        },
      );
  }

  private handleError(outcome: Outcome) {
    this.messageService.open(
      this.translate.getTranslation('Common.ErrorProcessingRequest'),
      5000,
    );
    this.isLoadingSubject.next(false);
  }

  public clearService() {
    this.labTestPagedListSubject.next(new PaginatedList<LabTest>());
    this.patientPagedListSubject.next(new PaginatedList<Patient>());
    this.labPagedListSubject.next(new PaginatedList<Lab>());
    this.pagedLabTestDocumentsListSubject.next(
      new PaginatedList<DocumentLabTest>(),
    );
    this.selectedLabTestSubject.next(null);
    this.addDocumentToLabTestSubject.next(null);
  }
}
