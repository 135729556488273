import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { MessageService } from "@roctavian-abstractions/core";

@Component({
  selector: "roctavian-abstractions-reset-password-page",
  templateUrl: "./reset-password-page.component.html",
  styleUrls: ["./reset-password-page.component.scss"]
})
export class ResetPasswordPageComponent implements OnInit {
  public username: string;
  public token: string;

  constructor(private route: ActivatedRoute, private router: Router, private service: MessageService) {}

  public ngOnInit() {
    // Grab the reset token and username which should be provided in the url:
    this.route.queryParams.subscribe(params => (this.username = params["username"]));
    this.route.queryParams.subscribe(params => (this.token = params["token"]));
  }

  public handleSuccessfulReset() {
    this.service.open("Your password has been reset successfully", 5000);
    this.router.navigate(["/login"]);
  }
}
