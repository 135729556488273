import { OnDestroy } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { PrescriberDelegate } from '../../models/prescriber-delegate.model';
import { StatusTypes, UtilityService } from '../../../common';
import { Subscription } from 'rxjs';
import { PrescriberDelegateService } from '../../services/prescriber-delegate.service';
import { PrescriberDelegateClient } from '../../clients/prescriber-delegate.client';
import { MessageService } from '@roctavian-abstractions/core';
import { AppTranslationService } from '@roctavian-abstractions/core';
import { Router } from '@angular/router';
import { Outcome } from '@roctavian-abstractions/web';

@Component({
  selector: 'roctavian-eumea-callcenter-portal-edit-prescriber-delegate-page',
  templateUrl: './edit-prescriber-delegate-page.component.html',
  styleUrls: ['./edit-prescriber-delegate-page.component.scss']
})
export class EditPrescriberDelegatePageComponent implements OnInit, OnDestroy {
  public form: FormGroup;
  public prescriberDelegate: PrescriberDelegate;
  public isProcessing = false;
  public errorMessages = new Array<string>();
  public statusTypes = StatusTypes;

  private subscriptions = new Array<Subscription>();

  constructor(
    private formBuilder: FormBuilder,
    private prescriberDelegateService: PrescriberDelegateService,
    private prescriberDelegateClient: PrescriberDelegateClient,
    private messageService: MessageService,
    private translate: AppTranslationService,
    public utilityService: UtilityService,
    private router: Router
  ) { }

  ngOnDestroy() {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }

  ngOnInit() {
    this.form = this.formBuilder.group({});

    this.subscriptions.push(
      this.prescriberDelegateService.selectedPrescriberDelegateSubject.subscribe(prescriberDelegate => {
        this.prescriberDelegate = prescriberDelegate;
        if (!this.prescriberDelegate) {
          return;
        }

        setTimeout(() => {
          this.form.patchValue({
            ...this.prescriberDelegate
          });
        });
      })
    );
  }

  public submit() {
    this.form.markAllAsTouched();

    if (this.form.invalid) {
      return;
    }

    this.isProcessing = true;
    this.errorMessages = [];
    const updatedPrescriberDelegate: PrescriberDelegate = this.form.getRawValue();
    updatedPrescriberDelegate.id = this.prescriberDelegate.id;

    this.subscriptions.push(
      this.prescriberDelegateClient.updatePrescriberDelegate(updatedPrescriberDelegate).subscribe(
        outcome => {
          this.isProcessing = false;
          if (outcome.failure) {
            this.handleError(outcome);
            return;
          }

          this.messageService.open(
            this.translate.getTranslation('PrescriberDelegate.PrescriberDelegateEditPage.SuccessfullyUpdated'),
            5000
          );
          this.prescriberDelegateService.loadPrescriberDelegate(this.prescriberDelegate.id, true);
          this.router.navigate(['/prescriber-delegate', this.prescriberDelegate.id]);
        },
        (error: Outcome) => {
          this.isProcessing = false;
          this.handleError(error);
        }
      )
    );
  }

  private handleError(error: Outcome) {
    if (!error || !error.messages || error.messages.length === 0) {
      this.errorMessages = [this.translate.getTranslation('Common.ErrorProcessingRequest')];
    } else {
      this.errorMessages = error.messages;
    }
  }
}
