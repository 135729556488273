import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class FileExportService {
  constructor() {}

  downloadFile(data: any, fileType: string, fileName: string) {
    const parsedResponse = data;
    const blob = new Blob([parsedResponse], { type: fileType });
    const url = window.URL.createObjectURL(blob);

    if (navigator.msSaveOrOpenBlob) {
      navigator.msSaveBlob(blob, fileName);
    } else {
      const a = document.createElement('a');
      a.href = url;
      a.download = fileName;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    }
    window.URL.revokeObjectURL(url);
  }
}
