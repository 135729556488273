import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { MessageService } from "@roctavian-abstractions/core";

@Component({
  selector: "roctavian-abstractions-new-account-set-up-page",
  templateUrl: "./new-account-set-up-page.component.html",
  styleUrls: ["./new-account-set-up-page.component.scss"]
})
export class NewAccountSetUpPageComponent implements OnInit {
  public username: string;
  public token: string;

  constructor(private route: ActivatedRoute, private router: Router, private service: MessageService) { }

  public ngOnInit() {
    // Grab the reset token and username which should be provided in the url:
    this.route.queryParams.subscribe(params => (this.username = params["username"]));
    this.route.queryParams.subscribe(params => (this.token = params["token"]));
  }

  public handleSuccessfulReset() {
    this.service.open(
      "Your account has been configured successfully. Please log into the application using your new password.",
      5000
    );
    this.router.navigate(["/login"]);
  }
}
