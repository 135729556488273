import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { LabService } from '../../services';
import { Subscription } from 'rxjs';
import { Lab } from '../../models';

@Component({
  selector: 'roctavian-eumea-callcenter-portal-lab-detail-master-page',
  templateUrl: './lab-detail-master-page.component.html',
  styleUrls: ['./lab-detail-master-page.component.css']
})
export class LabDetailMasterPageComponent implements OnDestroy {
  public lab: Lab;
  private subscriptions = new Array<Subscription>();

  constructor(private route: ActivatedRoute, private labService: LabService, private router: Router,) {
    this.subscriptions.push(this.route.paramMap.subscribe(params => {
      const id = String(params.get('id'));

      if (!id) {
        return;
      }

      this.labService.selectLab(id);
    }));

    this.subscriptions.push(this.labService.selectedLabSubject.subscribe(lab => (this.lab = lab)));
  }

  ngOnDestroy() {

    this.labService.selectedLabSubject.next(null);

    this.subscriptions.forEach(subscription => {
      subscription.unsubscribe();
    });
  }
  public refreshPage() {
    this.labService.selectLab(this.lab.id, true);
    this.router.navigate(['/lab', this.lab.id]);
  }

  public getSidenavMode(): string {
    this.setStyleBasedOnMainNavbarHeight();
    if (window.innerWidth < 750) {
      return 'over';
    }
    return 'side';
  }

  public setStyleBasedOnMainNavbarHeight() {
    const mainNavbar = document.getElementById('main-navbar');
    if (!mainNavbar) {
      return;
    }

    const element: any = document.getElementsByClassName('sidenav-container').item(0);
    element.style.top = `${mainNavbar.scrollHeight}px`;
  }
}
