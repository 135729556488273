import { Component, ChangeDetectionStrategy, Input } from "@angular/core";
import { PasswordRule } from "../../../../models";

@Component({
  selector: "roctavian-abstractions-password-rules",
  templateUrl: "./password-rules.component.html",
  styleUrls: ["./password-rules.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PasswordRulesComponent {
  /**
   * An array containing all the active password rules.
   */
  @Input() public rules: PasswordRule[] = [];

  /**
   * An array that stores the keys of password rules that are unsatisfied.
   */
  @Input() public unsatisfiedRuleKeys: string[] = [];

  /**
   * An array that stores the keys of password rules that are satisfied.
   */
  @Input() public satisfiedRuleKeys: string[] = [];

  /**
   * Method that indicates if the specified password rule is invalid.
   * @param key The password rule key.
   */
  public isInvalid(key: string) {
    return this.unsatisfiedRuleKeys.indexOf(key) > -1;
  }

  /**
   * Method that indicates if the specified password rule is valid.
   * @param key The password rule key.
   */
  public isValid(key: string) {
    return this.satisfiedRuleKeys.indexOf(key) > -1;
  }
}
