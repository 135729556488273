import { Auditable } from '../../common/models/auditable.model';
import { PrescriberDelegate } from './../../prescriber-delegate/models/prescriber-delegate.model';
import { Prescriber } from '../models/prescriber.model';

export class PrescriberDelegatePrescriber extends Auditable {
  public id: string;
  public prescriberDelegateId: string;
  public prescriberDelegate: PrescriberDelegate;
  public prescriberId: string;
  public prescriber: Prescriber;
  public isCurrent: boolean;
}
