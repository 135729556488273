import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthenticationService } from '../services/authentication.service';

@Injectable({
  providedIn: 'root'
})
export class RegistrationGuard implements CanActivate {
  constructor(private router: Router, private service: AuthenticationService) { }

  public canActivate(): Observable<boolean> | Promise<boolean> | boolean {
    if (this.service.authenticated) {
      this.router.navigate(['/']);
      return false;
    }
    return true;
  }
}
