import { LabUser } from '../../labuser/models/labuser.model';
import { Prescriber } from './prescriber.model';
import { Auditable } from '../../common';

export class LabUserDelegate extends Auditable {
  public id: string;
  public labUserId: number;
  public labUser: LabUser;
  public prescriberId: number;
  public prescriber: Prescriber;
}
