export class Permission {
  /**
   * The permission identity.
   */
  public id: string;

  /**
   * The permission name.
   */
  public name: string;

  /**
   * The permission description.
   */
  public description: string;

  /**
   * The permission group.
   */
  public group: string;

  /**
   * The date the permission was added.
   */
  public addDate: Date;
}
