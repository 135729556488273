import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GeneralTasksPipe } from './general-tasks.pipe';
import { EntityTasksPipe } from './entity-tasks.pipe';

@NgModule({
  imports: [CommonModule],
  declarations: [EntityTasksPipe, GeneralTasksPipe],
  exports: [EntityTasksPipe, GeneralTasksPipe]
})
export class PipeModule {}
