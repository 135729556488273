/* eslint-disable @typescript-eslint/member-ordering */
/* eslint-disable prefer-arrow/prefer-arrow-functions */
/* eslint-disable @typescript-eslint/no-use-before-define */
export class ClaimsPrincipal {
  private decodedToken: any;

  constructor(decodedToken: any) {
    this.decodedToken = decodedToken;
  }

  /**
   * Determines whether the specified role exists within the decoded token.
   *
   * @param role The role.
   */
  public hasRole(role: string): boolean {
    if (isNullOrEmpty(role) || !this.tokenHasProperty('role')) {
      return false;
    }

    role = role.toLowerCase();

    const propertyValue = this.decodedToken['role'];

    // If the user has more than one role the property value will be an array:
    if (propertyValue instanceof Array) {
      const roles = propertyValue as string[];
      return roles.map(r => r.toLowerCase()).indexOf(role) > -1;
    }

    // If the user only has one role the property value will be a string:
    if (typeof propertyValue === 'string') {
      return role === propertyValue.toLocaleLowerCase();
    }

    return false;
  }

  /**
   * Determines whether the specified permission is found within the decoded token.
   *
   * @param permission The permission to validate.
   */
  public hasPermission(permission: string): boolean {
    if (isNullOrEmpty(permission) || !this.tokenHasProperty('permission')) {
      return false;
    }

    permission = permission.toLowerCase();

    const propertyValue = this.decodedToken['permission'];

    // If the user has more than one permission the property value will be an array:
    if (propertyValue instanceof Array) {
      const permissions = propertyValue as string[];
      return permissions.map(p => p.toLowerCase()).indexOf(permission) > -1;
    }

    // If the user only has one permission the property value will be a string:
    if (typeof propertyValue === 'string') {
      return permission === propertyValue.toLocaleLowerCase();
    }

    return false;
  }

  private tokenHasProperty(property: string): boolean {
    if (!this.decodedToken) {
      return false;
    }

    return this.decodedToken.hasOwnProperty(property);
  }

  /**
   * Gets the value of the specified claim.
   *
   * @param claimType The claim type.
   */
  public getClaimValue(claimType: string): any {
    // Determine if the property exists on the decoded token:
    if (!this.decodedToken.hasOwnProperty(claimType)) {
      return null;
    }

    const value = this.decodedToken[claimType];

    if (!value) {
      return null;
    }

    return value;
  }
}

function isNullOrEmpty(value: string) {
  return !value || value === '';
}
