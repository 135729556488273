import { Injectable } from '@angular/core';
import { LocalStorageService } from '@roctavian-abstractions/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { TokenClient } from '../clients/token.client';
import { TokenStorage, UserInfoStorage } from '../constants';
import { TokenResponse, UserDetailsModel } from '../models';
import { ClaimsPrincipal } from './../claims-principal';
import { TokenDecoder } from './token-decoder.service';

@Injectable({
  providedIn: 'root',
})
export class AuthenticationService {
  constructor(
    private storage: LocalStorageService,
    private client: TokenClient,
    private decoder: TokenDecoder
  ) {}

  get token(): string {
    return this.storage.get(TokenStorage.Token);
  }

  get authenticated(): boolean {
    return this.isTokenValid();
  }

  get claimsPrincipal(): ClaimsPrincipal {
    if (!this.isTokenValid()) {
      return null;
    }
    const decodedToken = this.decoder.decode(this.token);
    return new ClaimsPrincipal(decodedToken);
  }

  get userInfo(): UserDetailsModel {
    if (this.isTokenValid) {
      const storedInfo = JSON.parse(this.storage.get(UserInfoStorage.UserInfo));
      const userInfo = new UserDetailsModel(
        storedInfo.username,
        storedInfo.firstName,
        storedInfo.lastName,
        storedInfo.email
      );
      return userInfo;
    }
  }

  /**
   * A behavior subject to keep track of when a user is authenticated or not. The default value
   * comes from whether or not a token is still in storage, and if it's valid.
   */
  private authSubject = new BehaviorSubject<boolean>(this.isTokenValid());

  /**
   * Logs a user into the application. If a successful token response is received, the
   * token is stored in local storage and the authentication subject is updated.
   *
   * @param username The username.
   * @param password The password.
   */
  public login(username: string, password: string, token: string): Observable<any> {
    return this.client.requestToken(username, password, token).pipe(
      map(tokenResponse => {
        this.storeToken(tokenResponse);
        this.storeUserInfo(tokenResponse);
      }),
      tap(() => this.authSubject.next(true))
    );
  }

  /**
   * Returns an observable representation of the authentication status of the user.
   */
  public isLoggedIn(): Observable<boolean> {
    return this.authSubject.asObservable();
  }

  /**
   * Effectively logs a user out of the application by removing
   * their authentication token from local storage.
   */
  public logout() {
    this.storage.removeMultiple([
      TokenStorage.Token,
      TokenStorage.Expiration,
      UserInfoStorage.UserInfo,
    ]);
    this.authSubject.next(false);
  }

  /**
   * Retrieves the token from the token response
   * and stores it in local storage.
   *
   * @param tokenResponse The token response.
   */
  private storeToken(tokenResponse: TokenResponse): void {
    this.storage.set(TokenStorage.Token, tokenResponse.token);
    const expirationDate = Date.parse(tokenResponse.expiration);
    this.storage.set(TokenStorage.Expiration, expirationDate.toString());
  }

  /**
   * Retrieves the user information from the token
   * response and stores it in local storage.
   *
   * @param tokenResponse The token response.
   */
  private storeUserInfo(tokenResponse: TokenResponse): void {
    const decodedToken = this.decoder.decode(tokenResponse.token);

    const userInfo = new UserDetailsModel(
      decodedToken.preferred_username,
      decodedToken.given_name,
      decodedToken.family_name,
      decodedToken.email
    );

    this.storage.set(UserInfoStorage.UserInfo, JSON.stringify(userInfo));
  }

  /**
   * Determines whether or not a token in storage is valid.
   */
  private isTokenValid(): boolean {
    const token = this.storage.get(TokenStorage.Token);
    if (!token) {
      //this.authSubject.next(false);
      return false;
    }
    const tokenExpirationDate = parseInt(this.storage.get(TokenStorage.Expiration), 10);
    if (!tokenExpirationDate || isNaN(tokenExpirationDate)) {
      //this.authSubject.next(false);
      return false;
    }
    const currentUtcDate = this.getCurrentUtcDate();
    return currentUtcDate.valueOf() < tokenExpirationDate;
  }

  /**
   * Returns the current date as a UTC date.
   */
  private getCurrentUtcDate(): Date {
    const now = new Date();
    const utcDate = new Date(
      now.getUTCFullYear(),
      now.getUTCMonth(),
      now.getUTCDate(),
      now.getUTCHours(),
      now.getUTCMinutes(),
      now.getUTCSeconds()
    );

    return utcDate;
  }
}
