import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { AppTranslationService, MessageService } from '@roctavian-abstractions/core';
import { Subscription } from 'rxjs';
import { AttestationClient } from '../../clients';
import { StatusTypes } from '../../enums';
import { Attestation, Stakeholder } from '../../models';
import { AttestationService } from '../../services/attestation.service';

@Component({
  selector: 'add-attestation',
  templateUrl: './add-attestation.component.html',
  styleUrls: ['./add-attestation.component.css']
})
export class AddAttestationComponent implements OnInit, OnDestroy {
  public form: FormGroup;
  public errorMessages: string[] = [];
  @Output() public attestationSaved = new EventEmitter();
  @Output() public cancelAddAttestation = new EventEmitter();
  @Input() public stakeholder: Stakeholder;

  @Input() attestationFormTitle = this.translate.getTranslation('Common.AttestationForm.Title');
  @Input() showAttestationFormTitle = false;

  @Input() attestationFormHasSignatureLabel = this.translate.getTranslation('Common.AttestationForm.Signature');
  @Input() showAttestationFormHasSignature = true;

  @Input() attestationFormSignatureDateLabel = this.translate.getTranslation('Common.AttestationForm.SignatureDate');
  @Input() showAttestationFormSignatureDate = true;

  @Input() attestationFormGuardianNameLabel = this.translate.getTranslation('Common.AttestationForm.PrintedName');
  @Input() showAttestationFormGuardianName = false;

  @Input() attestationFormHasConsentTitle = this.translate.getTranslation(
    'Common.Stakeholder.Attestation.HasConsentTitle'
  );
  @Input() showAttestationFormHasConsentTitle = false;
  @Input() attestationFormHasConsentLabel = this.translate.getTranslation('Common.AttestationForm.HasConsent');
  @Input() showAttestationFormHasConsent = false;

  @Input() attestationFormChangeReasonDescriptionLabel = this.translate.getTranslation(
    'Common.AttestationForm.ChangeReasonDescription'
  );
  @Input() showAttestationFormChangeReasonDescription = false;

  @Input() attestationFormAttestationTypeLabel = this.translate.getTranslation(
    'Common.AttestationForm.AttestationType'
  );
  @Input() showAttestationFormAttestationType = false;

  private subscriptions = new Array<Subscription>();

  constructor(
    private formBuilder: FormBuilder,
    private attestationClient: AttestationClient,
    private messageService: MessageService,
    private translate: AppTranslationService,
    private attService: AttestationService
  ) { }

  ngOnInit() {
    this.form = this.formBuilder.group({});
  }

  ngOnDestroy() {
    this.subscriptions.forEach(subscription => {
      subscription.unsubscribe();
    });
  }

  public saveAttestation() {
    console.log("current date in browser is: ", new Date())
    this.errorMessages = [];

    this.form.markAllAsTouched();
    if (this.form.invalid) {
      return;
    }

    const attestation: Attestation = this.form.get('attestation').value;
    attestation.stakeholderId = this.stakeholder.id;
    this.subscriptions.push(this.attService.signatureDateSubject.subscribe(val=>{
      attestation.signatureDate = val;
      
      this.subscriptions.push(this.attestationClient.saveAttestation(attestation).subscribe(
        outcome => {
          if (outcome.failure) {
            this.errorMessages = outcome.messages;
            return;
          }
  
          this.messageService.open(this.translate.getTranslation('Common.AddAttestation.SuccessfullyAdded'), 5000);
          this.attestationSaved.emit();
        },
        error => {
          this.errorMessages = [this.translate.getTranslation('Common.ErrorProcessingRequest')];
        }
      ));
    }))
   
  }

  public cancelAddingAttestation() {
    this.cancelAddAttestation.emit();
  }

  public areFieldsRequired(): boolean {
    if (!this.stakeholder || !this.stakeholder.statuses) {
      return false;
    }

    const currentStatus = this.stakeholder.statuses.find(s => s.isCurrent);
    if (!currentStatus) {
      return false;
    }

    if (currentStatus.statusType === StatusTypes.Registered) {
      return true;
    }

    return false;
  }
}
