import { DocumentTypes } from '@app/core/enums';
import { Auditable } from './auditable.model';

export class Document extends Auditable {
  public id: string;

  public documentType: DocumentTypes;

  public documentPath: string;
}
