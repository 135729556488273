import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgIdleModule } from '@ng-idle/core';
import { CoreModule } from '@roctavian-abstractions/core';
import { IdentityRoutingModule } from './identity-routing.module';
import { InterceptorModule } from './interceptor.module';
import { DetailsModule } from './modules/details/details.module';
import { EmailModule } from './modules/email/email.module';
import { HistoryModule } from './modules/history/history.module';
import { LoginModule } from './modules/login/login.module';
import { PasswordModule } from './modules/password/password.module';
import { RegisterModule } from './modules/register/register.module';
import { SecurityModule } from './modules/security/security.module';
import { TwoFactorModule } from './modules/two-factor/two-factor.module';
import { UsernameModule } from './modules/username/username.module';
import { AccountSettingsLayoutPageComponent } from './pages/account-settings-layout-page/account-settings-layout-page.component';
import { AccountSettingsPageComponent } from './pages/account-settings-page/account-settings-page.component';
import { ChangeExpiredPasswordPageComponent } from './pages/change-expired-password-page/change-expired-password-page.component';
import { ConfirmEmailAddressPageComponent } from './pages/confirm-email-address-page/confirm-email-address-page.component';
import { ForbiddenPageComponent } from './pages/forbidden-page/forbidden-page.component';
import { ForgotPasswordPageComponent } from './pages/forgot-password-page/forgot-password-page.component';
import { LoginPageComponent } from './pages/login-page/login-page.component';
import { NewAccountSetUpPageComponent } from './pages/new-account-set-up-page/new-account-set-up-page.component';
import { RegisterPageComponent } from './pages/register-page/register-page.component';
import { ResendConfirmationEmailPageComponent } from './pages/resend-confirmation-email-page/resend-confirmation-email-page.component';
import { ResetPasswordPageComponent } from './pages/reset-password-page/reset-password-page.component';
import { SecuritySettingsPageComponent } from './pages/security-settings-page/security-settings-page.component';
import { TwoFactorVerificationPageComponent } from './pages/two-factor-verification-page/two-factor-verification-page.component';

@NgModule({
  imports: [
    CommonModule,
    CoreModule,
    IdentityRoutingModule,
    InterceptorModule,
    NgIdleModule.forRoot(),
    DetailsModule,
    LoginModule,
    PasswordModule,
    TwoFactorModule,
    UsernameModule,
    EmailModule,
    FormsModule,
    ReactiveFormsModule,
    SecurityModule,
    HistoryModule,
    RegisterModule,
  ],
  declarations: [
    LoginPageComponent,
    ForbiddenPageComponent,
    ForgotPasswordPageComponent,
    ResetPasswordPageComponent,
    AccountSettingsLayoutPageComponent,
    AccountSettingsPageComponent,
    ChangeExpiredPasswordPageComponent,
    ConfirmEmailAddressPageComponent,
    ResendConfirmationEmailPageComponent,
    SecuritySettingsPageComponent,
    NewAccountSetUpPageComponent,
    TwoFactorVerificationPageComponent,
    RegisterPageComponent,
  ],
  exports: [
    DetailsModule,
    PasswordModule,
    LoginModule,
    TwoFactorModule,
    UsernameModule,
    SecurityModule,
    HistoryModule,
    RegisterModule,
  ],
})
export class IdentityModule {}
