import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { LabUser } from '../../models/labuser.model';
import { StatusTypes, UtilityService, AttestationTypes, AttestationClient, Attestation } from '../../../common';
import { Subscription } from 'rxjs';
import { LabUserService } from '../../services';
import { LabUserClient } from '../../clients';
import { MessageService } from '@roctavian-abstractions/core';
import { AppTranslationService } from '@roctavian-abstractions/core';
import { Router } from '@angular/router';
import { Outcome } from '@roctavian-abstractions/web';

@Component({
  selector: 'roctavian-eumea-callcenter-portal-lab-user-edit-page',
  templateUrl: './lab-user-edit-page.component.html',
  styleUrls: ['./lab-user-edit-page.component.scss']
})
export class LabUserEditPageComponent implements OnInit, OnDestroy {
  public form: FormGroup;
  public labUser: LabUser;
  public isProcessing = false;
  public errorMessages = new Array<string>();
  public statusTypes = StatusTypes;

  private subscriptions = new Array<Subscription>();

  constructor(
    private formBuilder: FormBuilder,
    private labUserService: LabUserService,
    private labUserClient: LabUserClient,
    private messageService: MessageService,
    private translate: AppTranslationService,
    public utilityService: UtilityService,
    private router: Router,
    private attestationClient: AttestationClient
  ) {
    this.subscriptions.push(
      this.labUserService.selectedLabUserSubject.subscribe(labUser => {
        this.labUser = labUser;

        if (!this.labUser) {
          return;
        }
        setTimeout(() => {
          this.form.patchValue(this.labUser);
        });
      })
    );
  }

  ngOnInit() {
    this.form = this.formBuilder.group({});
  }
  ngOnDestroy() {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }

  public submit() {
    this.form.markAllAsTouched();
    if (this.form.invalid) {
      return;
    }
    this.isProcessing = true;
    this.errorMessages = [];
    const formValue = this.form.getRawValue();
    const labUser: LabUser = formValue;
    labUser.id = this.labUser.id;

    this.subscriptions.push(
      this.labUserClient.updateLabUser(labUser).subscribe(
        outcome => {
          this.isProcessing = false;
          if (outcome.failure) {
            this.errorMessages = outcome.messages;
            return;
          }
          this.messageService.open(this.translate.getTranslation('LabUser.LabUserEditPage.SuccessfullyUpdated'), 5000);
          this.labUserService.selectLabUser(this.labUser.id, true);
          this.router.navigate(['/labuser', this.labUser.id]);
        },
        (error: Outcome) => {
          this.isProcessing = false;
          this.errorMessages = error.messages;
        }
      )
    );
  }
}
