import { Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject, Subscription } from 'rxjs';
import { PaginatedList, Outcome } from '@roctavian-abstractions/web';
import { Status } from '../models';
import { StatusClient } from '../clients';
import { MessageService } from '@roctavian-abstractions/core';
import { AppTranslationService } from '@roctavian-abstractions/core';
import { StatusPagedQuery } from '../models/status-paged-query.model';
import { ClearService } from '../../../../shared/interface/clear-service.interface';

@Injectable({
  providedIn: 'root'
})
export class StatusService implements OnDestroy, ClearService {
  public statusPagedListSubject = new BehaviorSubject<PaginatedList<Status>>(new PaginatedList<Status>());
  public isLoadingSubject = new BehaviorSubject<boolean>(false);
  private getStatusListPagedSubscription: Subscription;

  constructor(
    private client: StatusClient,
    private messageService: MessageService,
    private translate: AppTranslationService
  ) { }

  public ngOnDestroy() {
    if (this.getStatusListPagedSubscription) {
      this.getStatusListPagedSubscription.unsubscribe();
      this.getStatusListPagedSubscription = null;
    }
  }

  public setPage(pagedQuery: StatusPagedQuery) {
    this.isLoadingSubject.next(true);

    if (this.getStatusListPagedSubscription) {
      this.getStatusListPagedSubscription.unsubscribe();
      this.getStatusListPagedSubscription = null;
    }

    this.getStatusListPagedSubscription = this.client.getStatusListPaged(pagedQuery).subscribe(
      outcome => {
        if (!outcome.success || outcome.failure) {
          this.handleError(outcome);
          return;
        }

        this.statusPagedListSubject.next(outcome.value);
        this.isLoadingSubject.next(false);
      },
      (error: Outcome) => {
        this.isLoadingSubject.next(false);
        this.handleError(error);
      }
    );
  }

  private handleError(error: Outcome) {
    if (error && error.messages && error.messages.length > 0) {
      this.messageService.open(error.messages[0], 5000);
    } else {
      this.messageService.open(this.translate.getTranslation('Common.ErrorProcessingRequest'), 5000);
    }
  }

  public clearService() {
    this.statusPagedListSubject.next(new PaginatedList<Status>());
  }
}
