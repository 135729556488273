import { Subscription } from 'rxjs';
import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { Lab } from '../../models';
import { LabService } from '../../services';
import { LabPrescriberListComponent } from '../../components';

@Component({
  selector: 'roctavian-eumea-callcenter-portal-lab-prescriber-list-page',
  templateUrl: './lab-prescriber-list-page.component.html',
  styleUrls: ['./lab-prescriber-list-page.component.scss']
})
export class LabPrescriberListPageComponent implements OnDestroy {
  public lab: Lab;
  private subscriptions = new Array<Subscription>();
  @ViewChild(LabPrescriberListComponent, { static: false })
  public labPrescriberListComponent: LabPrescriberListComponent;

  constructor(private labService: LabService) {
    this.subscriptions.push(this.labService.selectedLabSubject.subscribe(lab => (this.lab = lab)));
  }

  ngOnDestroy() {
    this.subscriptions.forEach(subscription => {
      subscription.unsubscribe();
    });
  }
}
