import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { Endpoints } from '@app/core/enums';
import { UtilityService } from '@app/stakeholder/services';
import { Outcome, PaginatedList, ValueOutcome } from '@roctavian-abstractions/web';

import { Institution, InstitutionPagedQuery } from '../models';

@Injectable({
  providedIn: 'root',
})
export class InstitutionClient {
  constructor(private readonly http: HttpClient, private readonly utilityService: UtilityService) {}

  getInstitutionListPaged(pagedQuery: InstitutionPagedQuery): Observable<ValueOutcome<PaginatedList<Institution>>> {
    return this.http.get<ValueOutcome<PaginatedList<Institution>>>(Endpoints.institution, {
      params: this.utilityService.toHttpParams(pagedQuery),
    });
  }

  createInstitution(institution: Institution): Observable<Outcome> {
    return this.http.post<Outcome>(Endpoints.institution, institution);
  }

  updateInstitution(institution: Institution): Observable<Outcome> {
    return this.http.put<Outcome>(Endpoints.institution, institution);
  }

  getInstitution(institutionId: string): Observable<ValueOutcome<Institution>> {
    const url = `${Endpoints.institution}/${institutionId}`;
    return this.http.get<ValueOutcome<Institution>>(url);
  }

  deleteInstitution(id: string, changeReasonDescription: string): Observable<Outcome> {
    const url = `${Endpoints.institution}/${id}?changeReasonDescription=${changeReasonDescription}`;
    return this.http.delete<Outcome>(url);
  }

  getExportFile() {
    const url = `${Endpoints.institution}/export`;
    return this.http.get(url, { responseType: 'blob' });
  }
}
