import { Component } from "@angular/core";

@Component({
  selector: "roctavian-abstractions-account-settings-layout-page",
  templateUrl: "./account-settings-layout-page.component.html",
  styleUrls: ["./account-settings-layout-page.component.scss"]
})
export class AccountSettingsLayoutPageComponent {
  constructor() {}

  public getNavigationMode(): string {
    if (window.innerWidth < 750) {
      return "over";
    }
    return "side";
  }
}
