import { PrescriberListPageComponent } from './pages/prescriber-list-page/prescriber-list-page.component';
import { PrescriberDelegateStatusPageComponent } from './pages/prescriber-delegate-status-page/prescriber-delegate-status-page.component';
import { AddPrescriberDelegatePageComponent } from './pages/add-prescriber-delegate-page/add-prescriber-delegate-page.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { PrescriberDelegateListPageComponent } from './pages/prescriber-delegate-list-page/prescriber-delegate-list-page.component';
import { PrescriberDelegateDetailMasterPageComponent } from './pages/prescriber-delegate-detail-master-page/prescriber-delegate-detail-master-page.component';
import { PrescriberDelegateSummaryPageComponent } from './pages/prescriber-delegate-summary-page/prescriber-delegate-summary-page.component';
import { EditPrescriberDelegatePageComponent } from './pages/edit-prescriber-delegate-page/edit-prescriber-delegate-page.component';
import { PrescriberDelegateAttestationPageComponent } from './pages/prescriber-delegate-attestation-page/prescriber-delegate-attestation-page.component';
import { RoctavianAuthenticationGuard } from '../../../shared/guards/roctavian-authentication.guard';

const ROUTES = [
  {
    path: 'prescriber-delegate',
    canActivate: [RoctavianAuthenticationGuard],
    children: [
      { path: '', redirectTo: 'list', pathMatch: 'full' },
      { path: 'list', component: PrescriberDelegateListPageComponent },
      { path: 'add', component: AddPrescriberDelegatePageComponent },
      {
        path: ':id',
        component: PrescriberDelegateDetailMasterPageComponent,
        children: [
          { path: '', redirectTo: 'summary', pathMatch: 'full' },
          { path: 'summary', component: PrescriberDelegateSummaryPageComponent },
          { path: 'edit', component: EditPrescriberDelegatePageComponent },
          { path: 'attestation', component: PrescriberDelegateAttestationPageComponent },
          { path: 'status', component: PrescriberDelegateStatusPageComponent },
          { path: 'prescriber', component: PrescriberListPageComponent }
        ]
      }
    ]
  }
];

@NgModule({
  imports: [CommonModule, RouterModule.forChild(ROUTES)],
  exports: [RouterModule]
})
export class PrescriberDelegateRoutingModule { }
