import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { CreateTaskModel } from '../../../../models';
import { TaskClient } from './../../../../clients/task.client';

@Component({
  selector: 'roctavian-abstractions-new-task',
  templateUrl: './new-task.component.html',
  styleUrls: ['./new-task.component.scss']
})
export class NewTaskComponent {
  public allWorkgroupUsers: any;
  public autocompleteUsers: any;

  /**
   * Initializes a new instance of the component.
   *
   * @param taskClient The task client.
   * @param router The router.
   */
  constructor(private taskClient: TaskClient, private router: Router) {}

  /**
   * Adds the task to the system and navigates back to the dashboard.
   *
   * @param model The create task model.
   */
  public onNewTask(model: CreateTaskModel) {
    this.taskClient.addTask(model).subscribe(result => {
      this.router.navigate(['tasks']);
    });
  }
}
