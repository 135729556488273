import { Component, OnInit, Input, SimpleChanges, OnDestroy } from '@angular/core';
import { Patient, PatientPrescriber } from '../../models';
import { PagedQuery, UtilityService } from '../../../common';
import { PaginatedList } from '@roctavian-abstractions/web';
import { BehaviorSubject, Subscription, EMPTY } from 'rxjs';
import { PatientService } from '../../services';
import { debounceTime, distinctUntilChanged, switchMap } from 'rxjs/operators';
import { PageEvent, MatSort } from '@angular/material';
import { LabTest } from '../../../lab-test/models/lab-test.model';
import { AppTranslationService } from '@roctavian-abstractions/core';

@Component({
  selector: 'patient-lab-test-list',
  templateUrl: './patient-lab-test-list.component.html',
  styleUrls: ['./patient-lab-test-list.component.scss']
})
export class PatientLabTestListComponent implements OnInit, OnDestroy {
  @Input() public patient: Patient;

  public pagedQuery = new PagedQuery(1, 10, null, 'id', true);

  public labTestPagedCollection: PaginatedList<LabTest>;
  public isLoading = false;
  public filterText$ = new BehaviorSubject<string>(null);

  private subscriptions = new Array<Subscription>();

  public displayedColumns = [
    'id',
    'stakeholder.statuses.statusType',
    'lab.labName',
    'lab.stakeholder.address.address1',
    'lab.stakeholder.address.city',
    'lab.stakeholder.address.country.description',
    'orderingPhysician',
    'appointmentDate',
    'appointmentTime',
    'addUser.username',
    'addDate'
  ];

  constructor(
    public utilityService: UtilityService,
    private service: PatientService,
    private translate: AppTranslationService
  ) {
    this.subscriptions.push(this.filterText$
      .pipe(
        debounceTime(1000),
        distinctUntilChanged(),
        switchMap(searchText => {
          if (this.pagedQuery.filterText == searchText) {
            return EMPTY;
          }

          this.pagedQuery.filterText = searchText;
          this.pagedQuery.pageIndex = 1;
          this.refreshList();
          return EMPTY;
        })
      )
      .subscribe());
  }

  ngOnDestroy() {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
    this.service.pagedLabTestListSubject.next(new PaginatedList<LabTest>());
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (!changes.patient) {
      return;
    }

    if (changes.patient.currentValue) {
      this.refreshList();
    }
  }

  public refreshList() {
    if (!this.patient) {
      return;
    }

    this.service.setPatientLabTestPage(this.patient.id, this.pagedQuery);
  }

  ngOnInit() {
    this.subscriptions.push(this.service.pagedLabTestListSubject.subscribe(collection => {
      this.labTestPagedCollection = collection;
    }));

    this.subscriptions.push(this.service.isLoadingSubject.subscribe(isLoading => {
      this.isLoading = isLoading;
    }));
  }

  public onPageEvent(pageEvent: PageEvent) {
    this.pagedQuery.pageIndex = pageEvent.pageIndex + 1;
    this.pagedQuery.pageSize = pageEvent.pageSize;
    this.refreshList();
  }

  public onSortEvent(sort: MatSort) {
    switch (sort.active) {
      case 'id':
        this.pagedQuery.sortColumn = 'stakeholder.externalId';
        break;
      case 'stakeholder.statuses.statusType':
        this.pagedQuery.sortColumn = 'stakeholder.statuses.FirstOrDefault(x => x.IsCurrent).statusType';
        break;
      case 'appointmentDate':
        this.pagedQuery.sortColumn = 'appointmentDateTime';
        break;
      case 'orderingPhysician':
        this.pagedQuery.sortColumn = 'prescriber.stakeholder.firstName';
        break;
      default:
        this.pagedQuery.sortColumn = sort.active;
        break;
    }

    this.pagedQuery.isSortDesc = sort.direction == 'desc';
    this.pagedQuery.pageIndex = 1;
    this.refreshList();
  }
}
