import { Pipe, PipeTransform } from '@angular/core';
import { TaskAction } from '../../../models';
import { directions, actionTypes, results } from '../constants';

@Pipe({ name: 'parseTaskAction' })
export class ParseTaskActionPipe implements PipeTransform {
  transform(action: TaskAction, field: string): string {
    switch (field) {
      case 'direction':
        return directions.find(d => d.identity === action.directionId).display;
      case 'actionType':
        return actionTypes.find(a => a.identity === action.actionTypeId).display;
      case 'result':
        return results.find(r => r.identity === action.resultId).display;
      default:
        return '';
    }
  }
}
