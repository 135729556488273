import { Component, OnInit, Output, EventEmitter, OnDestroy, Inject } from '@angular/core';
import { Subscription } from 'rxjs';
import { LabUserLab } from '../../../labuser/models/lab-user-lab.model';
import { FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { MessageService } from '@roctavian-abstractions/core';
import { AppTranslationService } from '@roctavian-abstractions/core';
import { Outcome } from '@roctavian-abstractions/web';
import { LabClient } from '../../clients';
import { LabService } from '../../services';

@Component({
  selector: 'roctavian-eumea-callcenter-portal-disassociate-lab-user-dialog',
  templateUrl: './disassociate-lab-user-dialog.component.html',
  styleUrls: ['./disassociate-lab-user-dialog.component.scss']
})
export class DisassociateLabUserDialogComponent implements OnInit, OnDestroy {
  public labUserLab: LabUserLab;
  public form = new FormGroup({});
  @Output() public labUserDisassociated = new EventEmitter();
  public errorMessages: string[] = [];
  public subscriptions = new Array<Subscription>();

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public reference: MatDialogRef<DisassociateLabUserDialogComponent>,
    private client: LabClient,
    private labService: LabService,
    private messageService: MessageService,
    private translate: AppTranslationService
  ) {
    this.labUserLab = data.labUserLab;
  }

  public ngOnInit() { }

  ngOnDestroy() {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }

  public removeAssociation() {

    this.errorMessages = [];

    this.subscriptions.push(this.client
      .disassociateLabUser(this.labUserLab.labId, this.labUserLab.labUserId)
      .subscribe(
        outcome => {
          if (outcome.success) {
            this.messageService.open(
              this.translate.getTranslation('Lab.DisassociateLabUserDialog.SuccessfullyDisassociated'),
              3000
            );
            this.labUserDisassociated.emit();
            this.labService.selectLab(this.labUserLab.labId, true);
            this.reference.close();
            return;
          }

          this.errorMessages = outcome.messages;
        },
        (error: Outcome) => {
          this.messageService.open(this.translate.getTranslation('Common.ErrorProcessingRequest'), 5000);
        }
      ));
  }
}
