/* eslint-disable @angular-eslint/use-lifecycle-interface */
/* eslint-disable @typescript-eslint/member-ordering */
import { DatePipe } from '@angular/common';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { AppTranslationService, MessageService } from '@roctavian-abstractions/core';
import { Outcome } from '@roctavian-abstractions/web';
import { Subscription } from 'rxjs';
import { AttestationTypes, StakeholderTypes } from '../../../common';
import { PatientClient } from '../../clients';
import { Patient } from '../../models';

@Component({
  selector: 'add-patient',
  templateUrl: './add-patient.component.html',
  styleUrls: ['./add-patient.component.scss']
})
export class AddPatientComponent implements OnInit, OnDestroy {
  public form: FormGroup;
  @Input() prefillPatient: Patient;
  public errorMessages: string[];
  public isProcessing = false;

  private subscriptions = new Array<Subscription>();

  constructor(
    private formBuilder: FormBuilder,
    private client: PatientClient,
    private messageService: MessageService,
    private router: Router,
    private translate: AppTranslationService,
    private datePipe: DatePipe
  ) { }

  public ngOnInit() {
    this.form = this.formBuilder.group({});
  }

  ngOnDestroy() {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }

  ngAfterViewInit(): void {
    this.prefillPatientData();
  }

  public submit() {
    this.errorMessages = [];

    this.form.markAllAsTouched();
    if (this.form.invalid) {
      return;
    }

    this.isProcessing = true;
    this.subscriptions.push(this.client.createPatient(this.getModel()).subscribe(
      outcome => {
        this.isProcessing = false;
        if (outcome.failure) {
          this.errorMessages = outcome.messages;
          return;
        }

        this.messageService.open(this.translate.getTranslation('Patient.AddPatient.SuccessfullySaved'), 5000);
        this.router.navigate(['/patient']);
      },
      (error: Outcome) => {
        this.isProcessing = false;
        this.errorMessages = error.messages;
      }
    ));
  }

  private prefillPatientData() {
    if (!this.prefillPatient) {
      return;
    }

    this.form.patchValue(this.prefillPatient);
  }

  public getModel(): Patient {
    const formValue = this.form.getRawValue();
    const attestation = formValue['stakeholder']['attestation'];
    let newDate = new Date(attestation.signatureDate)
    let currentTime = new Date();
    newDate.setHours(currentTime.getHours());
    newDate.setMinutes(currentTime.getMinutes());
    newDate.setSeconds(currentTime.getSeconds());
    newDate.setMilliseconds(currentTime.getMilliseconds());   
    newDate.setMinutes(newDate.getMinutes() + newDate.getTimezoneOffset());
    attestation.signatureDate = newDate;
    attestation.attestationType = AttestationTypes.PatientRegistration;
    formValue.stakeholder.attestation = null;

    const patient: Patient = formValue;
    patient.birthDate = this.datePipe.transform(formValue.birthDate, 'MM/dd/yyyy');
    patient.stakeholder.stakeholderType = StakeholderTypes.Patient;
    if (attestation.guardianName !== null || attestation.signatureDate !== null || attestation.hasSignature !== false) {
      patient.stakeholder.attestations = [attestation];
    }
    return patient;
  }

  private handleError(error: Outcome) {
    if (!error || !error.messages || error.messages.length === 0) {
      this.errorMessages = [this.translate.getTranslation('Common.ErrorProcessingRequest')];
    } else {
      this.errorMessages = error.messages;
    }
  }
}
