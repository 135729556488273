export const priorities = [
  { identity: 1, display: 'Low' },
  { identity: 2, display: 'Medium' },
  { identity: 3, display: 'High' }
];

export const directions = [
  { identity: 1, display: 'Incoming' },
  { identity: 2, display: 'Outgoing' },
  { identity: 3, display: 'Other' }
];

export const actionTypes = [
  { identity: 1, display: 'Create Task' },
  { identity: 2, display: 'Call' },
  { identity: 3, display: 'Email' },
  { identity: 4, display: 'Fax' },
  { identity: 5, display: 'Mail' }
];

export const results = [
  { identity: 1, display: 'Information Provided' },
  { identity: 2, display: 'Follow-Up Needed' },
  { identity: 3, display: 'No Action Taken' },
  { identity: 4, display: 'Unable To Contact' }
];
