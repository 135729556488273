export enum StakeholderTypes {
  Unknown = -1,

  Laboratory = 1,

  Prescriber,

  Patient,

  LaboratoryUser,

  Institution,

  LabTest,

  PrescriberDelegate,

  LaboratoryUserDelegate,

  LabKitOrder,

  ExternalLabTest
}
