import { Router } from '@angular/router';
import { AppTranslationService } from '@roctavian-abstractions/core';
import { Component, OnInit, Output, EventEmitter, OnDestroy } from '@angular/core';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';

import { UtilityService } from '../../../common';
import { Prescriber } from '../../models';
import { Subscription, BehaviorSubject, EMPTY } from 'rxjs';
import { PrescriberPagedQuery } from '../../models/prescriber-paged-query.model';
import { PaginatedList, Outcome } from '@roctavian-abstractions/web';
import { debounceTime, distinctUntilChanged, switchMap } from 'rxjs/operators';
import { PrescriberService } from '../../services/prescriber.service';
import { PageEvent, MatSort } from '@angular/material';
import { PrescriberClient } from '../../clients';

@Component({
  selector: 'prescriber-lookup',
  templateUrl: './prescriber-lookup.component.html',
  styleUrls: ['./prescriber-lookup.component.css']
})
export class PrescriberLookupComponent implements OnInit, OnDestroy {
  public form: FormGroup;
  @Output() public continueWithPrescriber = new EventEmitter<Prescriber>();
  public pagedQuery = new PrescriberPagedQuery(1, 10, null, 'id', true, null, null, null);
  public isNationalIdDuplicateFound = false;

  public errorMessage: string;
  public displayedColumns = [
    'nationalId',
    'stakeholder.firstName',
    'stakeholder.lastName',
    'institution.stakeholder.address.city',
    'institution.stakeholder.address.country.name',
    'institution.stakeholder.address.postalCode'
  ];

  public filterText$ = new BehaviorSubject<string>(null);
  public prescriberPagedCollection: PaginatedList<Prescriber>;
  public isLoading = false;
  private subscriptions = new Array<Subscription>();

  constructor(
    private formBuilder: FormBuilder,
    private prescriberService: PrescriberService,
    private prescriberClient: PrescriberClient,
    public utilityService: UtilityService,
    private translate: AppTranslationService,
    private router: Router
  ) { }

  public ngOnInit() {
    this.subscriptions.push(
      this.filterText$
        .pipe(
          debounceTime(1000),
          distinctUntilChanged(),
          switchMap(searchText => {
            if (this.pagedQuery.filterText == searchText) {
              return EMPTY;
            }

            this.pagedQuery.filterText = searchText;
            this.pagedQuery.pageIndex = 1;
            this.refreshList();
            return EMPTY;
          })
        )
        .subscribe()
    );

    this.subscriptions.push(
      this.prescriberService.prescriberDuplicatesPagedListSubject.subscribe(collection => {
        this.prescriberPagedCollection = collection;

        if (!this.areDuplicatesFound && this.form) {
          this.continueWithPrescriber.emit(this.form.getRawValue());
          return;
        }
      })
    );

    this.subscriptions.push(
      this.prescriberService.isLoadingSubject.subscribe(isLoading => {
        this.isLoading = isLoading;
      })
    );

    this.buildForm();
  }

  ngOnDestroy() {
    this.subscriptions.forEach(subscription => {
      subscription.unsubscribe();
    });

    this.prescriberService.prescriberDuplicatesPagedListSubject.next(new PaginatedList<Prescriber>());
  }

  private buildForm() {
    this.form = this.formBuilder.group({
      nationalId: new FormControl(null, Validators.required),
      stakeholder: this.formBuilder.group({
        lastName: new FormControl(null)
      })
    });
  }

  public submit() {
    this.errorMessage = null;

    this.form.markAllAsTouched();
    if (this.form.invalid) {
      return;
    }

    if (this.areDuplicatesFound && !this.isNationalIdDuplicateFound) {
      this.continueWithPrescriber.emit(this.form.getRawValue());
      return;
    }

    this.subscriptions.push(
      this.prescriberClient.getNationalIdDuplicateCheck(this.form.get('nationalId').value).subscribe(outcome => {
        if (!outcome.success) {
          this.handleError(outcome);
          return;
        }

        this.isNationalIdDuplicateFound = outcome.value;

        if (!this.isNationalIdDuplicateFound) {
          this.pagedQuery.filterText = null;
          this.pagedQuery.lastName = this.form.get('stakeholder.lastName').value;

          this.refreshList();
        }
      })
    );
  }

  public cancelLookup() {
    if (this.areDuplicatesFound) {
      this.pagedQuery = new PrescriberPagedQuery(1, 10, null, 'id', true, null, null, null);
      this.errorMessage = null;
      this.prescriberPagedCollection = null;
      this.isNationalIdDuplicateFound = false;
      return;
    } else {
      this.router.navigate(['/prescriber']);
    }
  }

  public onPageEvent(pageEvent: PageEvent) {
    this.pagedQuery.pageIndex = pageEvent.pageIndex + 1;
    this.pagedQuery.pageSize = pageEvent.pageSize;
    this.refreshList();
  }

  public onSortEvent(sort: MatSort) {
    this.pagedQuery.sortColumn = sort.active;
    this.pagedQuery.isSortDesc = sort.direction == 'desc';
    this.pagedQuery.pageIndex = 1;
    this.refreshList();
  }

  private refreshList() {
    this.prescriberService.setPrescriberDuplicatesPage(this.pagedQuery);
  }

  public get areDuplicatesFound(): boolean {
    const hasItems =
      this.prescriberPagedCollection != null &&
      this.prescriberPagedCollection.items &&
      this.prescriberPagedCollection.items.length > 0;
    const hasFilterText = this.pagedQuery.filterText != null && this.pagedQuery.filterText.length > 0;
    const hasNationalIdFilter = this.pagedQuery.lastName != null && this.pagedQuery.lastName.length > 0;

    const result = hasNationalIdFilter && (hasItems || hasFilterText);
    return result;
  }

  private handleError(outcome: Outcome) {
    this.errorMessage = this.translate.getTranslation('Common.ErrorProcessingRequest');
  }
}
