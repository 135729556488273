import { Component, OnInit, Input, OnChanges, OnDestroy, SimpleChanges } from '@angular/core';
import { BehaviorSubject, Subscription, EMPTY } from 'rxjs';
import { debounceTime, distinctUntilChanged, switchMap } from 'rxjs/operators';
import { PageEvent, MatSort, MatDialog } from '@angular/material';
import { PagedQuery, UtilityService } from '../../../common';
import { Lab } from '../../models';
import { LabService } from '../../services';
import { PaginatedList } from '@roctavian-abstractions/web';
import { DisassociateLabUserDialogComponent } from '../disassociate-lab-user-dialog/disassociate-lab-user-dialog.component';
import { LabUserLab } from '../../../labuser/models/lab-user-lab.model';

@Component({
  selector: 'lab-user-list',
  templateUrl: './lab-user-list.component.html',
  styleUrls: ['./lab-user-list.component.scss']
})
export class LabUserListComponent implements OnInit, OnChanges, OnDestroy {
  @Input() public lab: Lab;

  public pagedQuery = new PagedQuery(1, 10, null, 'id', true);

  public labUserPagedCollection: PaginatedList<LabUserLab>;
  public isLoading = false;
  public filterText$ = new BehaviorSubject<string>(null);
  private subscriptions = new Array<Subscription>();

  public displayedColumns = [
    'labUser.stakeholder.firstName',
    'labUser.stakeholder.lastName',
    'labUser.stakeholder.address.country.name',
    'labUser.stakeholder.email',
    'labUser.stakeholder.phone',
    'labUser.stakeholder.statuses.statusType',
    'labUser.stakeholder.statuses.addDate',
    'addDate',
    'isAuthorizedRep',
    'addUser.username',
    'menu'
  ];

  constructor(public utilityService: UtilityService, private labService: LabService, private matDialog: MatDialog) { }

  ngOnDestroy() {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
    this.labService.labUserPagedListSubject.next(new PaginatedList<LabUserLab>());
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (!changes.lab || !changes.lab.currentValue || changes.lab.currentValue === changes.lab.previousValue) {
      return;
    }
    this.refreshList();
  }


  public refreshList() {
    if (!this.lab) {
      return;
    }

    this.labService.setLabUserPage(this.pagedQuery, this.lab.id);
  }

  ngOnInit() {
    this.subscriptions.push(
      this.filterText$
        .pipe(
          debounceTime(1000),
          distinctUntilChanged(),
          switchMap(searchText => {
            if (this.pagedQuery.filterText == searchText) {
              return EMPTY;
            }

            this.pagedQuery.filterText = searchText;
            this.pagedQuery.pageIndex = 1;
            this.refreshList();
            return EMPTY;
          })
        )
        .subscribe()
    );

    this.subscriptions.push(
      this.labService.labUserPagedListSubject.subscribe(collection => {
        this.labUserPagedCollection = collection;
      })
    );

    this.subscriptions.push(
      this.labService.isLoadingSubject.subscribe(isLoading => {
        this.isLoading = isLoading;
      })
    );
  }

  public onPageEvent(pageEvent: PageEvent) {
    this.pagedQuery.pageIndex = pageEvent.pageIndex + 1;
    this.pagedQuery.pageSize = pageEvent.pageSize;
    this.refreshList();
  }

  public onSortEvent(sort: MatSort) {
    switch (sort.active) {
      case 'labUser.labUserLabs.lab.firstName':
        this.pagedQuery.sortColumn = 'labUser.labUserLabs.FirstOrDefault().lab.firstName';
        break;
      case 'labUser.stakeholder.statuses.statusType':
        this.pagedQuery.sortColumn = 'labUser.stakeholder.statuses.FirstOrDefault(x => x.IsCurrent).statusType';
        break;
      case 'labUser.stakeholder.statuses.addDate':
        this.pagedQuery.sortColumn = 'labUser.stakeholder.statuses.FirstOrDefault(x => x.IsCurrent).addDate';
        break;
      case 'isAuthorizedRep':
        this.pagedQuery.sortColumn = 'isAuthorizedRepresentative';
        break;
      default:
        this.pagedQuery.sortColumn = sort.active;
        break;
    }

    this.pagedQuery.isSortDesc = sort.direction == 'desc';
    this.pagedQuery.pageIndex = 1;
    this.refreshList();
  }

  public disassociateLabUser(labUserLab: LabUserLab) {

    labUserLab.lab = this.lab;
    const dialogRef = this.matDialog.open(DisassociateLabUserDialogComponent, {
      hasBackdrop: true,
      disableClose: true,
      data: {
        labUserLab
      },
      width: '800px'
    });
    this.subscriptions.push(dialogRef.componentInstance.labUserDisassociated.subscribe(() => {
      this.refreshList();
    }));
  }
}
