import { DatePipe } from '@angular/common';
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { AppTranslationService, MessageService } from '@roctavian-abstractions/core';
import { Outcome } from '@roctavian-abstractions/web';
import { Subscription } from 'rxjs';
import { StatusTypes, UtilityService } from '../../../common';
import { LabTestClient } from '../../clients/lab-test.client';
import { LabTestFormComponent } from '../../components/lab-test-form/lab-test-form.component';
import { LabTest } from '../../models/lab-test.model';
import { LabTestService } from '../../services/lab-test.service';

@Component({
  selector: 'roctavian-eumea-callcenter-portal-edit-lab-test-page',
  templateUrl: './edit-lab-test-page.component.html',
  styleUrls: ['./edit-lab-test-page.component.scss']
})
export class EditLabTestPageComponent implements OnInit, OnDestroy {
  @ViewChild(LabTestFormComponent, { static: false }) labTestForm: LabTestFormComponent;

  public form: FormGroup;
  public labTest: LabTest;
  public isProcessing = false;
  public errorMessages = new Array<string>();
  public statusTypes = StatusTypes;

  private subscriptions = new Array<Subscription>();

  constructor(
    private formBuilder: FormBuilder,
    private labTestService: LabTestService,
    private labTestClient: LabTestClient,
    private messageService: MessageService,
    private translate: AppTranslationService,
    public utilityService: UtilityService,
    private router: Router,
    private datePipe: DatePipe
  ) { }

  ngOnDestroy() {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }

  ngOnInit() {
    this.form = this.formBuilder.group({});

    this.subscriptions.push(this.labTestService.selectedLabTestSubject.subscribe(labTest => (this.labTest = labTest)));
  }

  public submit() {
    this.form.markAllAsTouched();

    if (this.form.invalid) {
      return;
    }

    this.isProcessing = true;
    this.errorMessages = [];
    const updatedLabTest = this.labTestForm.getModel();
    this.subscriptions.push(
      this.labTestClient.updateLabTest(updatedLabTest).subscribe(
        outcome => {
          this.isProcessing = false;
          if (outcome.failure) {
            this.handleError(outcome);
            return;
          }

          this.messageService.open(this.translate.getTranslation('LabTest.LabTestEditPage.SuccessfullyUpdated'),
            5000);
          this.labTestService.loadLabTest(this.labTest.id, true);
          this.router.navigate(['/lab-test', this.labTest.id]);
        },
        (error: Outcome) => {
          this.isProcessing = false;
          this.handleError(error);
        }
      )
    );
  }

  private handleError(error: Outcome) {
    if (!error || !error.messages || error.messages.length === 0) {
      this.errorMessages = [this.translate.getTranslation('Common.ErrorProcessingRequest')];
    } else {
      this.errorMessages = error.messages;
    }
  }
}
