import { Auditable, Stakeholder } from '../../common/models';
import { PatientPrescriber } from '../../patient';
import { Institution } from '../../institution';
import { PrescriberDelegatePrescriber } from '../../prescriber-delegate/models/prescriber-delegate.model';
import { AlternateContact } from '../../common/models/alternate-contact.model';

export class Prescriber extends Auditable {
  public id: string;

  public nationalId: string;

  public stakeholder: Stakeholder;

  public institution: Institution;

  public patientsPrescribers: PatientPrescriber[];

  public prescriberDelegates: PrescriberDelegatePrescriber[];

  public alternateContact: AlternateContact;
}
