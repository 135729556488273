import { Component, OnInit, Input, OnDestroy, OnChanges, SimpleChanges, Output, EventEmitter } from '@angular/core';
import { PaginatedList } from '@roctavian-abstractions/web';
import { BehaviorSubject, Subscription, EMPTY } from 'rxjs';
import { debounceTime, distinctUntilChanged, switchMap } from 'rxjs/operators';
import { PageEvent, MatSort, MatCheckboxChange } from '@angular/material';
import { Lab } from '../../../lab';
import { PagedQuery, UtilityService } from '../../../common';
import { PrescriberService } from '../../services';
import { Prescriber, PrescriberLabPagedQuery } from '../../models';

@Component({
  selector: 'prescriber-associate-new-lab-list',
  templateUrl: './associate-new-lab-list.component.html',
  styleUrls: ['./associate-new-lab-list.component.css']
})
export class AssociateNewLabListComponent implements OnInit, OnDestroy, OnChanges {
  @Input() prescriber: Prescriber;
  @Output() public labSelected = new EventEmitter<Lab>();
  public selectedLab: Lab;

  public pagedQuery = new PrescriberLabPagedQuery(1, 10, null, 'id', true, this.prescriberCountry, null, null);

  public labPagedCollection: PaginatedList<Lab>;
  public isLoading = false;
  public filterText$ = new BehaviorSubject<string>(null);

  private subscriptions = new Array<Subscription>();

  public displayedColumns = [
    'id',
    'labName',
    'stakeholder.address.country.name',
    'stakeholder.address.address1',
    'stakeholder.address.city',
    'stakeholder.address.postalCode',
    'stakeholder.phone',
    'stakeholder.statuses.statusType',
    'stakeholder.statuses.addDate',
    'isPrescriberOffice'
  ];

  constructor(public utilityService: UtilityService, private prescriberService: PrescriberService) {
    this.subscriptions.push(this.filterText$
      .pipe(
        debounceTime(1000),
        distinctUntilChanged(),
        switchMap(searchText => {
          if (this.pagedQuery.filterText == searchText) {
            return EMPTY;
          }

          this.pagedQuery.filterText = searchText;
          this.pagedQuery.pageIndex = 1;
          this.refreshList();
          return EMPTY;
        })
      )
      .subscribe());
  }

  ngOnDestroy() {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
    this.prescriberService.associateLabPagedListSubject.next(new PaginatedList<Lab>());
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (
      !changes.prescriber ||
      !changes.prescriber.currentValue ||
      changes.prescriber.currentValue === changes.prescriber.previousValue
    ) {
      return;
    }

    this.refreshList();
  }

  private refreshList() {
    if (!this.prescriber) {
      return;
    }

    this.pagedQuery.country = this.prescriberCountry;

    this.prescriberService.setAssociateLabPage(this.pagedQuery, this.prescriber.id);
  }

  private get prescriberCountry(): string {
    if (
      this.prescriber &&
      this.prescriber.institution &&
      this.prescriber.institution.stakeholder &&
      this.prescriber.institution.stakeholder.address &&
      this.prescriber.institution.stakeholder.address.country
    ) {
      return this.prescriber.institution.stakeholder.address.countryId;
    }
    return null;
  }

  ngOnInit() {
    this.subscriptions.push(this.prescriberService.associateLabPagedListSubject.subscribe(
      collection => {
        this.labPagedCollection = collection;
      }
    ));

    this.subscriptions.push(this.prescriberService.isLoadingSubject.subscribe(isLoading => {
      this.isLoading = isLoading;
    }));
  }

  public onPageEvent(pageEvent: PageEvent) {
    this.pagedQuery.pageIndex = pageEvent.pageIndex + 1;
    this.pagedQuery.pageSize = pageEvent.pageSize;
    this.refreshList();
  }

  public onSortEvent(sort: MatSort) {
    switch (sort.active) {
      case 'stakeholder.statuses.statusType':
        this.pagedQuery.sortColumn = 'stakeholder.statuses.FirstOrDefault(x => x.IsCurrent).statusType';
        break;
      case 'stakeholder.statuses.addDate':
        this.pagedQuery.sortColumn = 'stakeholder.statuses.FirstOrDefault(x => x.IsCurrent).addDate';
        break;

      default:
        this.pagedQuery.sortColumn = sort.active;
        break;
    }

    this.pagedQuery.isSortDesc = sort.direction == 'desc';
    this.pagedQuery.pageIndex = 1;
    this.refreshList();
  }

  public selectLab(lab: Lab) {
    if (this.selectedLab === lab) {
      this.selectedLab = null;
      this.labSelected.emit(null);
      return;
    }

    this.selectedLab = lab;
    this.labSelected.emit(this.selectedLab);
  }

  public isPrescriberOfficeChanged(matCheckboxChange: MatCheckboxChange, lab: Lab) {
    this.labPagedCollection.items.forEach(labListItem => {
      const labListItemIsPrescriberOffice = labListItem.isPrescriberOffice;

      if (labListItem.id === lab.id) {
        labListItem.isPrescriberOffice = matCheckboxChange.checked;
      }

      if (matCheckboxChange.checked && labListItem.id !== lab.id) {
        labListItem.isPrescriberOffice = false;
      }

      if (
        this.selectedLab &&
        this.selectedLab.id === labListItem.id &&
        this.selectedLab.isPrescriberOffice !== labListItemIsPrescriberOffice
      ) {
        this.selectedLab = labListItem;
        this.labSelected.emit(this.selectedLab);
      }
    });
  }
}
