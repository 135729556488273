import { WorkgroupUser } from './WorkgroupUser';

/**
 * Class used to represent a workgroup.
 */
export class Workgroup {
  constructor(
    workgroupIdentity?: string,
    name?: string,
    description?: string,
    addDate?: Date,
    editDate?: Date,
    workgroupUsers?: WorkgroupUser[],
    availableWorkgroupUsers?: WorkgroupUser[]
  ) {
    this.workgroupIdentity = workgroupIdentity;
    this.name = name;
    this.description = description;
    this.addDate = addDate;
    this.editDate = editDate;
    this.workgroupUsers = workgroupUsers;
    this.availableWorkgroupUsers = availableWorkgroupUsers;
  }

  public workgroupIdentity?: string;
  public name?: string;
  public description?: string;
  public addDate?: Date;
  public editDate?: Date;
  public workgroupUsers?: WorkgroupUser[];
  public availableWorkgroupUsers?: WorkgroupUser[];
}
