import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { Endpoints } from '@app/core/enums';
import { PagedQuery } from '@app/stakeholder/models';
import { UtilityService } from '@app/stakeholder/services';
import { Outcome, PaginatedList, ValueOutcome } from '@roctavian-abstractions/web';

import { LabUser, LabuserDelegate } from '../models';
import { LabUserDuplicateCheckQuery } from '../models/labuser-duplicate-check-query.model';

@Injectable({
  providedIn: 'root',
})
export class LabUserClient {
  constructor(private readonly http: HttpClient, private readonly utilityService: UtilityService) {}

  getPagedList(pagedQuery: PagedQuery): Observable<ValueOutcome<PaginatedList<LabUser>>> {
    return this.http.get<Outcome>(Endpoints.labUser, {
      params: this.utilityService.toHttpParams(pagedQuery),
    });
  }

  getPrescriberPagedList(
    pagedQuery: PagedQuery,
    prescriberId: string,
  ): Observable<ValueOutcome<PaginatedList<LabuserDelegate>>> {
    const url = `${Endpoints.labUser}/${prescriberId}/Prescriber/Get`;
    return this.http.get<ValueOutcome<PaginatedList<LabuserDelegate>>>(url, {
      params: this.utilityService.toHttpParams(pagedQuery),
    });
  }

  getPrescriber(prescriberId: string): Observable<Outcome> {
    const url = `${Endpoints.prescriber}/${prescriberId}`;
    return this.http.get<Outcome>(url);
  }

  createLabUser(labuser: LabUser): Observable<Outcome> {
    return this.http.post<Outcome>(Endpoints.labUser, labuser);
  }

  updateLabUser(labUser: LabUser): Observable<Outcome> {
    return this.http.put<Outcome>(Endpoints.labUser, labUser);
  }

  getLabUser(id: string): Observable<ValueOutcome<LabUser>> {
    const url = `${Endpoints.labUser}/${id}`;
    return this.http.get<Outcome>(url);
  }

  getLabUserDuplicates(pagedQuery: LabUserDuplicateCheckQuery): Observable<ValueOutcome<PaginatedList<LabUser>>> {
    const url = `${Endpoints.labUser}/duplicate-check`;
    return this.http.get<ValueOutcome<PaginatedList<LabUser>>>(url, {
      params: this.utilityService.toHttpParams(pagedQuery),
    });
  }

  getExportFile() {
    const url = `${Endpoints.labUser}/export`;
    return this.http.get(url, { responseType: 'blob' });
  }
}
