import { Auditable, Stakeholder } from '../../common';
import { PatientPrescriber } from '../../patient';
import { OfficeContact } from './office-contact.model';
import { Institution } from '../../institution';
import { LabUser, Prescriber } from './';

export class LabuserDelegate extends Auditable {
  public id: string;
  public labUserId: number;

  public labUser: LabUser;
  public prescriberId: number;

  public prescriber: Prescriber;
  public stakeholder: Stakeholder;
}
