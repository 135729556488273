import { AppTranslationService } from '@roctavian-abstractions/core';
import { Component, OnInit, Inject, EventEmitter, Output, OnDestroy } from '@angular/core';
import { Institution } from '../../models';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { FormGroup } from '@angular/forms';
import { InstitutionClient } from '../../clients';
import { MessageService } from '@roctavian-abstractions/core';
import { Subscription } from 'rxjs';

@Component({
  selector: 'roctavian-eumea-callcenter-portal-delete-institution-dialog',
  templateUrl: './delete-institution-dialog.component.html',
  styleUrls: ['./delete-institution-dialog.component.scss']
})
export class DeleteInstitutionDialogComponent implements OnInit, OnDestroy {
  public institution: Institution;
  public form = new FormGroup({});
  @Output() public institutionDeleted = new EventEmitter();

  private subscriptions = new Array<Subscription>();

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public reference: MatDialogRef<DeleteInstitutionDialogComponent>,
    private client: InstitutionClient,
    private messageService: MessageService,
    private translate: AppTranslationService
  ) {
    this.institution = data.institution;
  }

  public ngOnInit() { }

  ngOnDestroy() {
    this.subscriptions.forEach(subscription => {
      subscription.unsubscribe();
    });
  }

  public continue() {
    if (this.institution.isAnyAssociatedPrescriber) {
      this.reference.close();
      return;
    }

    this.form.markAllAsTouched();
    if (this.form.invalid) {
      return;
    }

    this.subscriptions.push(this.client
      .deleteInstitution(this.institution.id, this.form.get('changeReasonDescription').value)
      .subscribe(
        outcome => {
          if (!outcome.success) {
            this.messageService.open(outcome.messages[0], 3000);
            return;
          }

          this.messageService.open(
            this.translate.getTranslation('Institution.DeleteInstitutionDialog.SuccessfullyDeleted'),
            3000
          );
          this.institutionDeleted.emit();
          this.reference.close();
        },
        error => {
          this.messageService.open(this.translate.getTranslation('Common.ErrorProcessingRequest'), 3000);
        }
      ));
  }
}
