import { OnDestroy, Output, EventEmitter } from '@angular/core';
import { Component, OnInit, Input } from '@angular/core';
import { Patient } from '../../models';
import { Prescriber } from '../../../prescriber';
import { Subscription } from 'rxjs';
import { PatientClient } from '../../clients';
import { MessageService } from '@roctavian-abstractions/core';
import { AppTranslationService } from '@roctavian-abstractions/core';

@Component({
  selector: 'patient-associate-prescriber',
  templateUrl: './patient-associate-prescriber.component.html',
  styleUrls: ['./patient-associate-prescriber.component.scss']
})
export class PatientAssociatePrescriberComponent implements OnDestroy {
  public errorMessages: string[] = [];
  @Output() public associated = new EventEmitter();
  @Output() public cancelAssociation = new EventEmitter();
  @Input() public patient: Patient;
  public selectedPrescriber: Prescriber;

  private subscriptions = new Array<Subscription>();

  constructor(
    private client: PatientClient,
    private messageService: MessageService,
    private translate: AppTranslationService
  ) { }

  ngOnDestroy() {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }

  public saveAssociation() {
    this.errorMessages = [];

    if (!this.selectedPrescriber) {
      this.errorMessages = [this.translate.getTranslation('Patient.AssociatePrescriber.SelectionRequired')];
      return;
    }

    this.subscriptions.push(
      this.client.associatePatientPrescriber(this.patient.id, this.selectedPrescriber.id).subscribe(
        outcome => {
          if (outcome.failure) {
            this.errorMessages = outcome.messages;
            return;
          }

          this.messageService.open(
            this.translate.getTranslation('Patient.AssociatePrescriber.SuccessfullyAssociated'),
            5000
          );
          this.associated.emit();
        },
        error => {
          this.errorMessages = [this.translate.getTranslation('Common.ErrorProcessingRequest')];
        }
      )
    );
  }

  public cancelPrescriberAssociation() {
    this.cancelAssociation.emit();
  }

  public onSelected(prescriber: Prescriber) {
    this.selectedPrescriber = prescriber;
  }
}
