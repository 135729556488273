import { Subscription } from 'rxjs';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { PrescriberService } from '../../services';
import { Prescriber } from '../../models';

@Component({
  selector: 'roctavian-eumea-callcenter-portal-prescriber-detail-master-page',
  templateUrl: './prescriber-detail-master-page.component.html',
  styleUrls: ['./prescriber-detail-master-page.component.css']
})
export class PrescriberDetailMasterPageComponent implements OnDestroy {
  public prescriber: Prescriber;
  private subscriptions = new Array<Subscription>();

  constructor(private route: ActivatedRoute, private prescriberService: PrescriberService) {
    this.subscriptions.push(this.route.paramMap.subscribe(params => {
      const id = String(params.get('id'));
      this.prescriberService.loadPrescriber(id);
    }));

    this.subscriptions.push(this.prescriberService.selectedPrescriberSubject.subscribe(
      prescriber => (this.prescriber = prescriber)
    ));
  }

  ngOnDestroy() {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
    this.prescriberService.selectedPrescriberSubject.next(null);
  }

  public getSidenavMode(): string {
    this.setStyleBasedOnMainNavbarHeight();
    if (window.innerWidth < 750) {
      return 'over';
    }
    return 'side';
  }

  public setStyleBasedOnMainNavbarHeight() {
    const mainNavbar = document.getElementById('main-navbar');
    if (!mainNavbar) {
      return;
    }

    const element: any = document.getElementsByClassName('sidenav-container').item(0);
    element.style.top = `${mainNavbar.scrollHeight}px`;
  }
}
