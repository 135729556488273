import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  ChangeDetectionStrategy,
  OnChanges,
  SimpleChanges
} from '@angular/core';
import { WorkgroupUser } from '../../../../models';
import { FormGroup, FormBuilder } from '@angular/forms';
import { Observable } from 'rxjs';
import { startWith, map } from 'rxjs/operators';

@Component({
  selector: 'roctavian-abstractions-available-user-form',
  templateUrl: './available-user-form.component.html',
  styleUrls: ['./available-user-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AvailableUserFormComponent implements OnInit, OnChanges {
  @Input()
  set availableUsers(users: WorkgroupUser[]) {
    this.users = users;
  }

  @Output() public submitted: EventEmitter<WorkgroupUser> = new EventEmitter<WorkgroupUser>();

  public users: WorkgroupUser[];

  public form: FormGroup;

  public filteredUsers: Observable<WorkgroupUser[]>;

  public error: boolean = false;

  constructor(private builder: FormBuilder) {}

  public ngOnInit() {
    this.form = this.builder.group({
      user: ['']
    });

    this.filteredUsers = this.form.get('user').valueChanges.pipe(
      startWith(''),
      map(value => this.filterUsers(value))
    );
  }

  public ngOnChanges(changes: SimpleChanges) {
    // update the users and sort the array
    this.users = changes.availableUsers.currentValue;
    this.users.sort((u1, u2) => {
      if (u1.username > u2.username) {return 1;}
      if (u1.username < u2.username) {return -1;}
      return 0;
    });

    // the input has changed, reset the input field
    if (this.form) {
      this.form.patchValue({
        user: ''
      });
    }

    this.error = false;
  }

  /**
   * Finds the user, removes it from the available list, and emits it to the parent component.
   *
   * @param form the form
   */
  public onSubmit(form: FormGroup) {
    const username = form.get('user').value;
    const workgroupUser = this.users.find(u => u.username === username);

    if (!workgroupUser) {
      this.error = true;
      return;
    }

    this.submitted.emit(workgroupUser);
  }

  /**
   *
   * @param value the value to filter the array by
   */
  private filterUsers(value: string) {
    const filterValue = value.toLowerCase();
    const filteredUsers = this.users
      .filter(user => user.username.toLowerCase().includes(filterValue))
      .map(user => user);

    return filteredUsers;
  }
}
