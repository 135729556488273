import { Component, OnInit, Input, OnChanges, OnDestroy, SimpleChanges } from '@angular/core';
import { Prescriber } from '../../models';
import { PagedQuery, UtilityService } from '../../../common';
import { LabUserDelegate } from '../../models/lab-user-delegate.model';
import { PaginatedList } from '@roctavian-abstractions/web';
import { BehaviorSubject, Subscription, EMPTY } from 'rxjs';
import { PrescriberService } from '../../services';
import { debounceTime, distinctUntilChanged, switchMap } from 'rxjs/operators';
import { PageEvent, MatSort } from '@angular/material';
import { Lab } from '../../../lab';

@Component({
  selector: 'prescriber-lab-user-delegate-list',
  templateUrl: './lab-user-delegate-list.component.html',
  styleUrls: ['./lab-user-delegate-list.component.scss']
})
export class LabUserDelegateListComponent implements OnInit, OnChanges, OnDestroy {
  @Input() prescriber: Prescriber;

  public pagedQuery = new PagedQuery(1, 10, null, 'id', true);

  public labUserDelegatePagedCollection: PaginatedList<LabUserDelegate>;
  public isLoading = false;
  public filterText$ = new BehaviorSubject<string>(null);
  private subscriptions = new Array<Subscription>();

  public displayedColumns = [
    'labUser.labUserLabs.lab.id',
    'labUser.labUserLabs.lab.labName',
    'labUser.stakeholder.firstName',
    'labUser.stakeholder.lastName',
    'labUser.stakeholder.address.country.name',
    'labUser.stakeholder.email',
    'labUser.stakeholder.phone',
    'labUser.stakeholder.statuses.statusType',
    'labUser.stakeholder.statuses.addDate',
    'addDate',
    'addUser.username'
  ];

  constructor(public utilityService: UtilityService, private prescriberService: PrescriberService) { }

  ngOnDestroy() {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
    this.prescriberService.labUserDelegatePagedListSubject.next(new PaginatedList<LabUserDelegate>());
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (
      !changes.prescriber ||
      !changes.prescriber.currentValue ||
      changes.prescriber.currentValue === changes.prescriber.previousValue
    ) {
      return;
    }

    this.refreshList();
  }

  public refreshList() {
    if (!this.prescriber) {
      return;
    }

    this.prescriberService.setLabUserDelegatePage(this.pagedQuery, this.prescriber.id);
  }

  ngOnInit() {
    this.subscriptions.push(
      this.filterText$
        .pipe(
          debounceTime(1000),
          distinctUntilChanged(),
          switchMap(searchText => {
            if (this.pagedQuery.filterText == searchText) {
              return EMPTY;
            }

            this.pagedQuery.filterText = searchText;
            this.pagedQuery.pageIndex = 1;
            this.refreshList();
            return EMPTY;
          })
        )
        .subscribe()
    );

    this.subscriptions.push(
      this.prescriberService.labUserDelegatePagedListSubject.subscribe(collection => {
        this.labUserDelegatePagedCollection = collection;
      })
    );

    this.subscriptions.push(
      this.prescriberService.isLoadingSubject.subscribe(isLoading => {
        this.isLoading = isLoading;
      })
    );
  }

  public onPageEvent(pageEvent: PageEvent) {
    this.pagedQuery.pageIndex = pageEvent.pageIndex + 1;
    this.pagedQuery.pageSize = pageEvent.pageSize;
    this.refreshList();
  }

  public onSortEvent(sort: MatSort) {
    switch (sort.active) {
      case 'labUser.labUserLabs.lab.id':
        this.pagedQuery.sortColumn = 'labUser.labUserLabs.FirstOrDefault().lab.stakeholder.externalId';
        break;
      case 'labUser.labUserLabs.lab.labName':
        this.pagedQuery.sortColumn = 'labUser.labUserLabs.FirstOrDefault().lab.labName';
        break;
      case 'labUser.stakeholder.statuses.statusType':
        this.pagedQuery.sortColumn = 'labUser.stakeholder.statuses.FirstOrDefault(x => x.IsCurrent).statusType';
        break;
      case 'labUser.stakeholder.statuses.addDate':
        this.pagedQuery.sortColumn = 'labUser.stakeholder.statuses.FirstOrDefault(x => x.IsCurrent).addDate';
        break;

      default:
        this.pagedQuery.sortColumn = sort.active;
        break;
    }

    this.pagedQuery.isSortDesc = sort.direction == 'desc';
    this.pagedQuery.pageIndex = 1;
    this.refreshList();
  }

  public getLab(labUserDelegate: LabUserDelegate): Lab {
    if (
      !labUserDelegate ||
      !labUserDelegate.labUser ||
      !labUserDelegate.labUser.labUserLabs ||
      labUserDelegate.labUser.labUserLabs.length === 0
    ) {
      return null;
    }

    return labUserDelegate.labUser.labUserLabs[0].lab;
  }
}
