import { AppTranslationService } from '@roctavian-abstractions/core';
import { Component, OnInit, Input } from '@angular/core';
import { AuthorizedRepresentative, Lab } from '../../models';
import { Attestation } from '../../../common';
import { LabService } from '../../services';


@Component({
  selector: 'view-lab',
  templateUrl: './view-lab.component.html',
  styleUrls: ['./view-lab.component.css']
})
export class ViewLabComponent implements OnInit {
  @Input() public title = this.translate.getTranslation('Lab.ViewLab.Title');
  @Input() public showTitle = true;

  @Input() public nationalIdLabel = this.translate.getTranslation('Lab.ViewLab.NationalId');

  @Input() public nameLabel = this.translate.getTranslation('Lab.ViewLab.Name');
  @Input() public contactNameLabel = this.translate.getTranslation('Lab.ViewLab.ContactName');

  @Input() public isVerifiedLabel = this.translate.getTranslation('Lab.ViewLab.Verified');

  @Input() public lab: Lab;

  public mostRecentAR: AuthorizedRepresentative;
  public arWithAttestation: AuthorizedRepresentative[];

  public displayARs: boolean = false;

  constructor(private translate: AppTranslationService, private labService: LabService) { }

  ngOnInit() {
    if (this.lab.authorizedRepresantatives.length > 0) {
      this.displayARs = true;
      this.getARWithMostRecentAttestation();
    }
  }

  public getARWithMostRecentAttestation() {
    this.arWithAttestation = this.lab.authorizedRepresantatives.filter(rep => rep.labUser.stakeholder.attestations.length > 0);
    this.mostRecentAR = this.arWithAttestation.sort((rep1, rep2) => this.getLatestAttestation(rep1.labUser.stakeholder.attestations).addDate < this.getLatestAttestation(rep2.labUser.stakeholder.attestations).addDate ? 1 : -1)[0];
  }

  public getLatestAttestation(attestations: Attestation[]): Attestation {
    if (attestations.length > 0) {
      return attestations.sort((att1, att2) => att1.addDate < att2.addDate ? 1 : -1)[0];
    }
    return null;
  };
}

