import { AppTranslationService } from '@roctavian-abstractions/core';
import { Component, OnInit, Input } from '@angular/core';
import { AuthorizedRepresentative } from '../../models/authorized-representative.model';
import { UtilityService } from '../../../common/services/utility.service';

@Component({
  selector: 'view-authorized-representative',
  templateUrl: './view-authorized-representative.component.html',
  styleUrls: ['./view-authorized-representative.component.css']
})
export class ViewAuthorizedRepresentativeComponent implements OnInit {
  @Input() public title = this.translate.getTranslation('Lab.ViewAuthorizedRepresentative.Title');
  @Input() public showTitle = true;

  @Input() public firstNameLabel = this.translate.getTranslation('Lab.ViewAuthorizedRepresentative.FirstName');
  @Input() public middleInitialLabel = this.translate.getTranslation('Lab.ViewAuthorizedRepresentative.MiddleInitial');
  @Input() public lastNameLabel = this.translate.getTranslation('Lab.ViewAuthorizedRepresentative.LastName');
  @Input() public phoneLabel = this.translate.getTranslation('Lab.ViewAuthorizedRepresentative.Phone');
  @Input() public altPhoneLabel = this.translate.getTranslation('Lab.ViewAuthorizedRepresentative.AltPhone');
  @Input() public emailLabel = this.translate.getTranslation('Lab.ViewAuthorizedRepresentative.Email');

  @Input() public authorizedRepresentative: AuthorizedRepresentative;
  public isARavailable = false;
  constructor(private translate: AppTranslationService, public utilityService: UtilityService) { }

  ngOnInit() {
    if (this.authorizedRepresentative) {
      this.isARavailable = true;
    }
  }
}
