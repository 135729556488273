import { Auditable } from './auditable.model';
import { State } from './state.model';
import { Country } from './country.model';

export class Address extends Auditable {
  public id: string;

  public countryId: string;
  public country: Country;

  public name: string;

  public address1: string;

  public address2: string;

  public city: string;

  public stateId: string;
  public state: State;

  public postalCode: string;
}
