import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { Patient } from '../../models';

@Component({
  selector: 'roctavian-eumea-callcenter-portal-view-patient-dialog',
  templateUrl: './view-patient-dialog.component.html',
  styleUrls: ['./view-patient-dialog.component.css']
})
export class ViewPatientDialogComponent {
  public patient: Patient;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any, public reference: MatDialogRef<ViewPatientDialogComponent>) {
    this.patient = data.patient;
  }
}
