import { OnDestroy, ViewChild } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import { Prescriber } from '../../models';
import { Subscription } from 'rxjs';
import { PrescriberService } from '../../services';
import { LabUserDelegateListComponent } from '../../components/lab-user-delegate-list/lab-user-delegate-list.component';

@Component({
  selector: 'roctavian-eumea-callcenter-portal-prescriber-lab-user-delegate-list-page',
  templateUrl: './prescriber-lab-user-delegate-list-page.component.html',
  styleUrls: ['./prescriber-lab-user-delegate-list-page.component.scss']
})
export class PrescriberLabUserDelegateListPageComponent implements OnInit, OnDestroy {
  public prescriber: Prescriber;
  private subscriptions = new Array<Subscription>();
  @ViewChild(LabUserDelegateListComponent, { static: false })
  public labUserDelegateListComponent: LabUserDelegateListComponent;

  constructor(private prescriberService: PrescriberService) {}

  ngOnInit() {
    this.subscriptions.push(
      this.prescriberService.selectedPrescriberSubject.subscribe(prescriber => (this.prescriber = prescriber))
    );
  }

  ngOnDestroy() {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }
}
