import { SecurityQuestionAnswerModel } from './security-question-answer.model';

export class ForgotPasswordRequestModel {
  constructor() {
    this.answers = [];
  }
  public username: string;
  public recaptchaToken: string;
  public answers: SecurityQuestionAnswerModel[];
  public localeIdentifier: string;
}
