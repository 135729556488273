import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";

@Component({
  selector: "roctavian-abstractions-security-question",
  templateUrl: "./security-question.component.html",
  styleUrls: ["./security-question.component.scss"]
})
export class SecurityQuestionComponent implements OnInit {
  @Input() public questions: any[];
  @Input() public selectedQuestion: number;
  @Input() public answer: string;

  @Output() public answerChange = new EventEmitter<string>();
  @Output() public selectedQuestionChange = new EventEmitter<number>();

  constructor() {}

  public ngOnInit() {}
}
