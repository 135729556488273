import { AppTranslationService } from '@roctavian-abstractions/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { MessageService } from '@roctavian-abstractions/core';
import { Outcome } from '@roctavian-abstractions/web';
import { StatusTypes, UtilityService } from '../../../common';
import { Prescriber } from '../../models/prescriber.model';
import { PrescriberService } from '../../services';
import { PrescriberClient } from '../../clients';
import { Subscription } from 'rxjs';
import { Router } from '@angular/router';

@Component({
  selector: 'roctavian-eumea-callcenter-portal-prescriber-edit-page',
  templateUrl: './prescriber-edit-page.component.html',
  styleUrls: ['./prescriber-edit-page.component.css']
})
export class PrescriberEditPageComponent implements OnInit, OnDestroy {
  public form: FormGroup;
  public prescriber: Prescriber;
  public isProcessing = false;
  public errorMessages = new Array<string>();
  public statusTypes = StatusTypes;

  private subscriptions = new Array<Subscription>();

  constructor(
    private formBuilder: FormBuilder,
    private prescriberService: PrescriberService,
    private prescriberClient: PrescriberClient,
    private messageService: MessageService,
    private translate: AppTranslationService,
    public utilityService: UtilityService,
    private router: Router
  ) { }

  ngOnDestroy() {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }

  ngOnInit() {
    this.form = this.formBuilder.group({});

    this.subscriptions.push(
      this.prescriberService.selectedPrescriberSubject.subscribe(prescriber => {
        this.prescriber = prescriber;
        if (!this.prescriber) {
          return;
        }

        setTimeout(() => {
          this.form.patchValue({
            ...this.prescriber
            // officeContact: this.prescriber.officeContact || {}
          });
        });
      })
    );
  }

  public submit() {
    this.form.markAllAsTouched();

    if (this.form.invalid) {
      return;
    }

    this.isProcessing = true;
    this.errorMessages = [];
    const updatedPrescriber: Prescriber = this.form.getRawValue();
    updatedPrescriber.id = this.prescriber.id;

    this.subscriptions.push(
      this.prescriberClient.updatePrescriber(updatedPrescriber).subscribe(
        outcome => {
          this.isProcessing = false;
          if (outcome.failure) {
            this.handleError(outcome);
            return;
          }

          this.messageService.open(
            this.translate.getTranslation('Prescriber.PrescriberEditPage.SuccessfullyUpdated'),
            5000
          );
          this.prescriberService.loadPrescriber(this.prescriber.id, true);
          this.router.navigate(['/prescriber', this.prescriber.id]);
        },
        (error: Outcome) => {
          this.isProcessing = false;
          this.handleError(error);
        }
      )
    );
  }

  private handleError(error: Outcome) {
    if (!error || !error.messages || error.messages.length === 0) {
      this.errorMessages = [this.translate.getTranslation('Common.ErrorProcessingRequest')];
    } else {
      this.errorMessages = error.messages;
    }
  }
}
