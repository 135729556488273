import { Subscription } from 'rxjs';
import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { Prescriber } from '../../models';
import { PrescriberService } from '../../services';

@Component({
  selector: 'prescriber-patient-list-page',
  templateUrl: './prescriber-patient-list-page.component.html',
  styleUrls: ['./prescriber-patient-list-page.component.css']
})
export class PrescriberPatientListPageComponent implements OnDestroy {
  @Input() prescriber: Prescriber;
  private subscriptions = new Array<Subscription>();

  constructor(private prescriberService: PrescriberService) {
    this.subscriptions.push(this.prescriberService.selectedPrescriberSubject.subscribe(
      prescriber => (this.prescriber = prescriber)
    ));
  }

  ngOnDestroy() {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }
}
