import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TaskType, CreateTaskModel, Workgroup } from '../../models';
import { TaskClient } from '../../clients/task.client';
import { TaskTypeClient } from '../../clients/task-type.client';
import { WorkgroupClient } from '../../clients/workgroup.client';

@Component({
  selector: 'roctavian-abstractions-new-task-page',
  templateUrl: './new-task-page.component.html',
  styleUrls: ['./new-task-page.component.scss']
})
export class NewTaskPageComponent implements OnInit {
  public taskTypes: TaskType[] = [];
  public workgroups: Workgroup[] = [];
  constructor(
    private taskClient: TaskClient,
    private taskTypeClient: TaskTypeClient,
    private workgroupClient: WorkgroupClient,
    private router: Router
  ) {}

  /**
   * Initializes the task types and workgroups.
   */
  public ngOnInit() {
    this.taskTypeClient.getTaskTypes().subscribe(result => {
      this.taskTypes = result.value;
    });
    this.workgroupClient.getWorkgroups().subscribe(result => {
      this.workgroups = result;
    });
  }

  /**
   * Adds the task to the system and navigates back to the dashboard.
   *
   * @param model the task to create
   */
  public onNewTask(model: CreateTaskModel) {
    this.taskClient.addTask(model).subscribe(result => {
      this.router.navigate(['tasks']);
    });
  }
}
