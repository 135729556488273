import { Component, EventEmitter, Output, ViewChild } from '@angular/core';

import { RolesClient } from 'apps/roctavian-eumea-callcenter-portal/src/app/modules/identity/clients/roles.client';
import { UserClient } from 'apps/roctavian-eumea-callcenter-portal/src/app/modules/identity/clients/user.client';
import { CreateUserModel, Role } from 'apps/roctavian-eumea-callcenter-portal/src/app/modules/identity/models';
import { PermissionToggleGroupListComponent } from '../../../permissions/components';
import { CreateUserSubmitEvent } from '../create-user-form/create-user-form.component';

@Component({
  selector: 'roctavian-abstractions-create-user',
  templateUrl: './create-user.component.html',
  styleUrls: ['./create-user.component.scss'],
})
export class CreateUserComponent {
  public roles: Role[] = [];
  public disabled = false;
  public messages: string[] = [];
  @Output() public saved = new EventEmitter<any>();

  @ViewChild('permissions', { static: false })
  public toggle: PermissionToggleGroupListComponent;

  constructor(private userClient: UserClient, private roleClient: RolesClient) {
    this.roleClient.getRoles().subscribe(roles => {
      this.roles = roles;
    });
  }

  public handleCreateUser(user: CreateUserSubmitEvent) {
    this.disabled = true;

    const model = new CreateUserModel();
    model.username = user.username;
    model.firstName = user.firstName;
    model.lastName = user.lastName;
    model.email = user.email;
    model.roles = user.roles;

    const selectedPermissions = this.toggle.getSelectedPermissionIdentities();

    model.permissions = selectedPermissions;

    this.userClient.createUser(model).subscribe(
      outcome => {
        this.disabled = false;
        if (!outcome.failure) {
          this.saved.emit();
        }
      },
      response => {
        this.disabled = false;
        this.messages = response.error.messages;
      }
    );
  }
}
