import { Component, OnInit, OnDestroy } from '@angular/core';
import { BehaviorSubject, Subscription, EMPTY } from 'rxjs';
import { UtilityService } from '../../../common';
import { LabUser } from '../../models/labuser.model';
import { debounceTime, distinctUntilChanged, switchMap } from 'rxjs/operators';
import { LabUserService } from '../../services/labuser.service';
import { MessageService } from '@roctavian-abstractions/core';
import { AppTranslationService } from '@roctavian-abstractions/core';
import { Router } from '@angular/router';
import { MatDialog, PageEvent, MatSort } from '@angular/material';
import { LabUserClient } from '../../clients/labuser.client';
import { PaginatedList, Outcome } from '@roctavian-abstractions/web';
import { FileExportService } from '../../../common/services/file-export.service';
import { ViewLabUserDialogComponent } from '../view-lab-user-dialog/view-lab-user-dialog.component';
import { LabuserPagedQuery } from '../../models';

@Component({
  selector: 'labuser-list',
  templateUrl: './labuser-list.component.html',
  styleUrls: ['./labuser-list.component.scss']
})
export class LabUserListComponent implements OnInit, OnDestroy {
  public pagedQuery = new LabuserPagedQuery(1, 10, null, 'id', true, null, null, null);

  public filterText$ = new BehaviorSubject<string>(null);
  public pagedCollection: PaginatedList<LabUser>;
  public isLoading = false;

  private subscriptions = new Array<Subscription>();
  private exportFileName = this.translate.getTranslation('LabUser.LabUserList.ExportFileName');
  public exportLabel = this.translate.getTranslation('Common.Export');

  public displayedColumns = [
    'stakeholder.firstName',
    'stakeholder.lastName',
    'stakeholder.address.country.name',
    'stakeholder.email',
    'stakeholder.phone',
    'stakeholder.statuses.statusType',
    'stakeholder.statuses.addDate',
    'addDate',
    'addUser.username',
    'menu'
  ];
  constructor(
    private labUserService: LabUserService,
    private router: Router,
    public utilityService: UtilityService,
    private matDialog: MatDialog,
    private labUserClient: LabUserClient,
    private messageService: MessageService,
    private translate: AppTranslationService,
    private fileExportService: FileExportService
  ) { }

  private refreshList() {
    this.labUserService.setPage(this.pagedQuery);
  }

  ngOnInit() {
    this.subscriptions.push(this.filterText$
      .pipe(
        debounceTime(1000),
        distinctUntilChanged(),
        switchMap(searchText => {
          if (this.pagedQuery.filterText == searchText) {
            return EMPTY;
          }

          this.pagedQuery.filterText = searchText;
          this.pagedQuery.pageIndex = 1;
          this.refreshList();
          return EMPTY;
        })
      )
      .subscribe());

    this.subscriptions.push(this.labUserService.pagedListSubject.subscribe(collection => {
      this.pagedCollection = collection;
    }));

    this.subscriptions.push(this.labUserService.isLoadingSubject.subscribe(isLoading => {
      this.isLoading = isLoading;
    }));

    this.refreshList();
  }

  ngOnDestroy() {
    this.subscriptions.forEach(subscription => {
      subscription.unsubscribe();
    });

    this.labUserService.pagedListSubject.next(new PaginatedList<LabUser>());
  }
  public onRowSelected(labuser: LabUser) {
    this.router.navigate(['/labuser', labuser.id]);
  }

  public viewLabUser(id: string) {

    this.subscriptions.push(this.labUserClient.getLabUser(id).subscribe(
      outcome => {
        const dialogRef = this.matDialog.open(ViewLabUserDialogComponent, {
          hasBackdrop: true,
          disableClose: true,
          data: {
            labUser: outcome.value
          },
          width: '1000px'
        });
      },
      error => {
        this.messageService.open(this.translate.getTranslation('Common.ErrorProcessingRequest'), 3000);
      }
    ));
  }

  public onPageEvent(pageEvent: PageEvent) {
    this.pagedQuery.pageIndex = pageEvent.pageIndex + 1;
    this.pagedQuery.pageSize = pageEvent.pageSize;
    this.refreshList();
  }

  public onSortEvent(sort: MatSort) {
    switch (sort.active) {
      case 'stakeholder.statuses.statusType':
        this.pagedQuery.sortColumn = 'stakeholder.statuses.FirstOrDefault(x => x.IsCurrent).statusType';
        break;
      case 'stakeholder.statuses.addDate':
        this.pagedQuery.sortColumn = 'stakeholder.statuses.FirstOrDefault(x => x.IsCurrent).addDate';
        break;

      default:
        this.pagedQuery.sortColumn = sort.active;
        break;
    }
    this.pagedQuery.isSortDesc = sort.direction == 'desc';
    this.pagedQuery.pageIndex = 1;
    this.refreshList();
  }

  public downloadExportFile() {
    this.subscriptions.push(
      this.labUserClient.getExportFile().subscribe(
        data => this.fileExportService.downloadFile(data, 'text/csv', this.exportFileName),
        (error: Outcome) => {
          if (!error.messages[0] == null || !error.messages[0] == undefined || error.messages.length !== 0) {
            this.messageService.open(error.messages[0], 3000);
          }
        }
      )
    );
  }
}
