import { Pipe, PipeTransform } from '@angular/core';
import { TaskType } from '../models';

/**
 * Pipe used to filter a TaskType array to show any non-general tasks included in it.
 */
@Pipe({ name: 'entityTasks' })
export class EntityTasksPipe implements PipeTransform {
  public transform(taskTypes: TaskType[]): TaskType[] {
    return taskTypes.filter(tt => !tt.isGeneral);
  }
}
