import { Component } from "@angular/core";
import { Router } from "@angular/router";
import { AuthenticationEventHandler } from "../../authentication-event.handler";
import { LoginPageSettings, LoginPageService } from "../../services/login-page.service";
import { TwoFactorVerificationRequiredEvent } from "../../modules/login/components";

@Component({
  selector: "roctavian-abstractions-login-page",
  templateUrl: "./login-page.component.html",
  styleUrls: ["./login-page.component.scss"]
})
export class LoginPageComponent {
  public settings: LoginPageSettings;
  constructor(private router: Router, private service: LoginPageService, private handler: AuthenticationEventHandler) {
    this.settings = this.service.getSettings();
  }

  /**
   * Handles a successful login attempt event.
   */
  public handleLoginSuccess() {
    this.handler.handleSuccessfulLogin();
  }

  /**
   * Handles the initial account lock event.
   */
  public handleInitialAccountLock() {
    this.handler.handleInitialLockout();
  }

  /**
   * Handles the account locked event. This
   * event will be fired when the user's login
   * attempt results in a locked event, after
   * the initial lock event.
   */
  public handleLockedAccount() {
    this.handler.handleAccountLocked();
  }

  /**
   * Handles the password expired event.
   */
  public handleExpiredPassword() {
    this.handler.handleExpiredPassword();
  }

  /**
   * Handles the email not confirmed event.
   */
  public handleEmailNotConfirmed() {
    this.handler.handleEmailNotConfirmed();
  }

  /**
   * Handles the two-factor verification required event.
   */
  public handleTwoFactorRequired(event: TwoFactorVerificationRequiredEvent) {
    this.router.navigateByUrl("/two-factor/verify", { state: { username: event.username, password: event.password } });
  }
}
