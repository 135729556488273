import { Component, OnInit, OnChanges, OnDestroy, Input, SimpleChanges } from '@angular/core';
import { Prescriber, PrescriberDelegatePrescriber } from '../../models';
import { PaginatedList } from '@roctavian-abstractions/web';
import { BehaviorSubject, Subscription, EMPTY } from 'rxjs';
import { PrescriberService } from '../../services';
import { debounceTime, distinctUntilChanged, switchMap } from 'rxjs/operators';
import { PageEvent, MatSort, MatDialog } from '@angular/material';
import { PagedQuery, UtilityService } from '../../../common';
import { DisassociatePrescriberDelegateDialogComponent } from './../disassociate-prescriber-delegate-dialog/disassociate-prescriber-delegate-dialog.component';

@Component({
  selector: 'prescriber-delegate-list',
  templateUrl: './prescriber-delegate-list.component.html',
  styleUrls: ['./prescriber-delegate-list.component.scss']
})
export class PrescriberDelegateListComponent implements OnInit, OnChanges, OnDestroy {
  @Input() public prescriber: Prescriber;

  public pagedQuery = new PagedQuery(1, 10, null, 'id', true);

  public prescriberDelegatePagedCollection: PaginatedList<PrescriberDelegatePrescriber>;
  public isLoading = false;
  public filterText$ = new BehaviorSubject<string>(null);
  private subscriptions = new Array<Subscription>();

  public displayedColumns = [
    'prescriberDelegateId',
    'prescriberDelegate.stakeholder.firstName',
    'prescriberDelegate.stakeholder.lastName',
    'prescriberDelegate.stakeholder.address.country.name',
    'prescriberDelegate.stakeholder.email',
    'prescriberDelegate.stakeholder.phone',
    'prescriberDelegate.stakeholder.statuses.statusType',
    'prescriberDelegate.stakeholder.statuses.addDate',
    'addDate',
    'addUser.username',
    'menu'
  ];

  constructor(
    public utilityService: UtilityService,
    private prescriberService: PrescriberService,
    private matDialog: MatDialog
  ) { }

  ngOnDestroy() {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
    this.prescriberService.prescriberDelegatePagedListSubject.next(new PaginatedList<PrescriberDelegatePrescriber>());
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (
      !changes.prescriber ||
      !changes.prescriber.currentValue ||
      changes.prescriber.currentValue === changes.prescriber.previousValue
    ) {
      return;
    }

    this.refreshList();
  }

  public refreshList() {
    if (!this.prescriber) {
      return;
    }

    this.prescriberService.setPrescriberDelegatePage(this.pagedQuery, this.prescriber.id);
  }

  ngOnInit() {
    this.subscriptions.push(
      this.filterText$
        .pipe(
          debounceTime(1000),
          distinctUntilChanged(),
          switchMap(searchText => {
            if (this.pagedQuery.filterText == searchText) {
              return EMPTY;
            }

            this.pagedQuery.filterText = searchText;
            this.pagedQuery.pageIndex = 1;
            this.refreshList();
            return EMPTY;
          })
        )
        .subscribe()
    );

    this.subscriptions.push(
      this.prescriberService.prescriberDelegatePagedListSubject.subscribe(collection => {
        this.prescriberDelegatePagedCollection = collection;
      })
    );

    this.subscriptions.push(
      this.prescriberService.isLoadingSubject.subscribe(isLoading => {
        this.isLoading = isLoading;
      })
    );
  }

  public onPageEvent(pageEvent: PageEvent) {
    this.pagedQuery.pageIndex = pageEvent.pageIndex + 1;
    this.pagedQuery.pageSize = pageEvent.pageSize;
    this.refreshList();
  }

  public onSortEvent(sort: MatSort) {
    switch (sort.active) {
      case 'prescriberDelegate.stakeholder.statuses.statusType':
        this.pagedQuery.sortColumn =
          'prescriberDelegate.stakeholder.statuses.FirstOrDefault(x => x.IsCurrent).statusType';
        break;
      case 'prescriberDelegate.stakeholder.statuses.addDate':
        this.pagedQuery.sortColumn = 'prescriberDelegate.stakeholder.statuses.FirstOrDefault(x => x.IsCurrent).addDate';
        break;

      default:
        this.pagedQuery.sortColumn = sort.active;
        break;
    }

    this.pagedQuery.isSortDesc = sort.direction == 'desc';
    this.pagedQuery.pageIndex = 1;
    this.refreshList();
  }

  public disassociatePrescriberDelegate(prescriberDelegatePrescriber: PrescriberDelegatePrescriber) {

    prescriberDelegatePrescriber.prescriber = this.prescriber;
    const dialogRef = this.matDialog.open(DisassociatePrescriberDelegateDialogComponent, {
      hasBackdrop: true,
      disableClose: true,
      data: {
        prescriberDelegatePrescriber
      },
      width: '800px'
    });
    this.subscriptions.push(dialogRef.componentInstance.prescriberDelegateDisassociated.subscribe(
      () => {
        this.refreshList();
      }
    ));
  }
}
