import { Component, Input } from "@angular/core";

@Component({
  selector: "roctavian-abstractions-two-factor-recovery-codes",
  templateUrl: "./two-factor-recovery-codes.component.html",
  styleUrls: ["./two-factor-recovery-codes.component.scss"]
})
export class TwoFactorRecoveryCodesComponent {
  @Input() public recoveryCodes: Array<string>;

  constructor() {}
}
