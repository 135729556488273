import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { NgModule } from '@angular/core';
import {
  PatientListPageComponent,
  AddPatientPageComponent,
  PatientDetailMasterPageComponent,
  PatientSummaryPageComponent,
  PatientStatusPageComponent,
  PatientEditPageComponent
} from './pages';
import { PatientPrescriberPageComponent } from './pages/patient-prescriber-page/patient-prescriber-page.component';
import { PatientAttestationPageComponent } from './pages/patient-attestation-page/patient-attestation-page.component';
import { PatientDocumentsPageComponent } from './pages/patient-documents-page/patient-documents-page.component';
import { PatientLabTestPageComponent } from './pages/patient-lab-test-page/patient-lab-test-page.component';
import { RoctavianAuthenticationGuard } from '../../../shared/guards/roctavian-authentication.guard';

const ROUTES = [
  {
    path: 'patient',
    canActivate: [RoctavianAuthenticationGuard],
    children: [
      { path: '', redirectTo: 'list', pathMatch: 'full' },
      { path: 'list', component: PatientListPageComponent },
      { path: 'add', component: AddPatientPageComponent },
      {
        path: ':id',
        component: PatientDetailMasterPageComponent,
        children: [
          { path: '', redirectTo: 'summary', pathMatch: 'full' },
          { path: 'summary', component: PatientSummaryPageComponent },
          { path: 'status', component: PatientStatusPageComponent },
          { path: 'edit', component: PatientEditPageComponent },
          { path: 'prescriber', component: PatientPrescriberPageComponent },
          { path: 'attestation', component: PatientAttestationPageComponent },
          { path: 'document', component: PatientDocumentsPageComponent },
          { path: 'lab-test', component: PatientLabTestPageComponent }
        ]
      }
    ]
  }
];

@NgModule({
  imports: [CommonModule, RouterModule.forChild(ROUTES)],
  exports: [RouterModule]
})
export class PatientRoutingModule { }
