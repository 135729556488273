import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { UserClient } from '../../identity/clients/user.client';
import { SimplifiedUser } from '../../identity/models';

@Injectable({
  providedIn: 'root',
})
export class UserManagementService {
  private page = 1;
  private size = 15;
  private filterText = '';
  public users = new BehaviorSubject<SimplifiedUser[]>([]);
  public total = new BehaviorSubject<number>(0);
  public loading = new BehaviorSubject<boolean>(true);

  /**
   * Returns the current page.
   */
  public getPage(): number {
    return this.page;
  }

  /**
   * Returns the current page size.
   */
  public getPageSize(): number {
    return this.size;
  }

  public getFilterText(): string {
    return this.filterText;
  }

  constructor(private client: UserClient) {}

  /**
   * Executes a request to the paged users endpoint.
   * The users, total, and loading behavior
   * subjects are updated when the call completed.
   * @param page The requested page.
   * @param size The requested page size.
   * @param filterText Filtering text that can be applied.
   */
  public setPage(page: number, size: number, filterText: string) {
    this.filterText = filterText;
    this.loading.next(true);
    this.client.getUsersPaged(page, size, filterText).subscribe(collection => {
      this.page = page;
      this.size = size;
      this.total.next(collection.totalCount);
      this.users.next(collection.items);
      this.loading.next(false);
    });
  }
}
