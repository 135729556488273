import { OnDestroy, ViewChild, OnInit } from '@angular/core';
import { Component } from '@angular/core';
import { Subscription } from 'rxjs';
import { LabUserListComponent } from '../../components/lab-user-list/lab-user-list.component';
import { LabService } from '../../services';
import { Lab } from '../../models';

@Component({
  selector: 'roctavian-eumea-callcenter-portal-lab-user-list-page',
  templateUrl: './lab-user-list-page.component.html',
  styleUrls: ['./lab-user-list-page.component.scss']
})
export class LabUserListPageComponent implements OnInit, OnDestroy {
  public lab: Lab;
  private subscriptions = new Array<Subscription>();
  @ViewChild(LabUserListComponent, { static: false }) public labLabUserListComponent: LabUserListComponent;

  constructor(private labService: LabService) {
    this.subscriptions.push(this.labService.selectedLabSubject.subscribe(lab => {
      this.lab = lab;
    }));
  }

  ngOnInit() {

  }

  ngOnDestroy() {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }

  public showAssociationScreen = false;

  public beginAssociation() {
    this.showAssociationScreen = true;
  }

  public onLabUserAssociated() {
    this.showAssociationScreen = false;

    this.labService.selectLab(this.lab.id, true);
    if (this.labLabUserListComponent) {
      this.labLabUserListComponent.refreshList();
    }
  }

  public onCancelAssociation() {
    this.showAssociationScreen = false;
    if (this.labLabUserListComponent) {
      this.labLabUserListComponent.refreshList();
    }
  }
}
