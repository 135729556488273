import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'roctavian-eumea-callcenter-portal-institution-add-page',
  templateUrl: './institution-add-page.component.html',
  styleUrls: ['./institution-add-page.component.scss']
})
export class InstitutionAddPageComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
