import { Stakeholder } from '../../common';
import { AuthorizedRepresentative, LabPrescriber } from '.';

export class Lab {
  public id: string;
  public nationalId: string;
  public verified: boolean;
  public labName: string;

  public stakeholder: Stakeholder;

  public labPrescribers: LabPrescriber[];

  public changeReasonDescription: string;

  public authorizedRepresantatives: AuthorizedRepresentative[];

  public isPrescriberOffice: boolean;
}
