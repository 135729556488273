import { Stakeholder } from '../../common/models/stakeholder.model';
import { Auditable } from '../../common/models/auditable.model';
import { Prescriber } from '../../prescriber';

export class PrescriberDelegate extends Auditable {
  public id: string;
  public stakeholder: Stakeholder;
}

export class PrescriberDelegatePrescriber extends Auditable {
  public id: string;

  public isCurrent?: boolean;

  public prescriberDelegateId?: number;

  public prescriberId?: number;

  public prescriberDelegate?: PrescriberDelegate;
  public prescriber: Prescriber;
}
