import { Component, Input, OnInit } from '@angular/core';
import { AuthenticationHistoryClient } from 'apps/roctavian-eumea-callcenter-portal/src/app/modules/identity/clients/authentication-history.client';
import { AuthenticationHistoryRecord } from 'apps/roctavian-eumea-callcenter-portal/src/app/modules/identity/models';

@Component({
  selector: 'roctavian-abstractions-admin-authentication-history',
  templateUrl: './admin-authentication-history.component.html',
  styleUrls: ['./admin-authentication-history.component.scss'],
})
export class AdminAuthenticationHistoryComponent implements OnInit {
  @Input() public identity: number;
  public records: AuthenticationHistoryRecord[] = [];

  constructor(private client: AuthenticationHistoryClient) {}

  public ngOnInit() {
    this.client.GetAuthenticationHistory(1, 10, this.identity).subscribe(collection => {
      this.records = collection.items;
    });
  }
}
