import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'roctavian-eumea-callcenter-portal-prescriber-delegate-list-page',
  templateUrl: './prescriber-delegate-list-page.component.html',
  styleUrls: ['./prescriber-delegate-list-page.component.scss']
})
export class PrescriberDelegateListPageComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
