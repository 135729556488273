import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { Endpoints } from '@app/core/enums';
import { Attestation, AttestationPagedQuery } from '@app/stakeholder/models';
import { UtilityService } from '@app/stakeholder/services';
import { Outcome, PaginatedList, ValueOutcome } from '@roctavian-abstractions/web';

@Injectable({
  providedIn: 'root',
})
export class AttestationClient {
  constructor(private readonly http: HttpClient, private readonly utilityService: UtilityService) {}

  saveAttestation(attestation: Attestation): Observable<Outcome> {
    return this.http.post<Outcome>(Endpoints.attestation, attestation);
  }

  getAttestationListPaged(pagedQuery: AttestationPagedQuery): Observable<ValueOutcome<PaginatedList<Attestation>>> {
    return this.http.get<ValueOutcome<PaginatedList<Attestation>>>(Endpoints.attestation, {
      params: this.utilityService.toHttpParams(pagedQuery),
    });
  }
}
