import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Outcome } from '@roctavian-abstractions/web';
import { ConfigService } from '@roctavian-abstractions/core';

@Injectable({
  providedIn: 'root'
})
export class WorkgroupUserClient {
  /**
   * Initializes a new instance of the workgroup user service.
   *
   * @param client The http client.
   */
  constructor(private client: HttpClient, private configService: ConfigService) {}

  /**
   * Returns the users assigned to the given workgroup.
   *
   * @param workgroupIdentity The workgroup identity.
   * @returns An outcome containing the assigned users.
   */
  public getWorkgroupUsers(workgroupIdentity: string): Observable<Outcome> {
    return this.client.get<Outcome>(`${this.configService.get('apiUrl')}/task/workgroup/users/${workgroupIdentity}`);
  }

  /**
   * Adds a user to the specified workgroup.
   *
   * @param workgroupIdentity The workgroup identity.
   * @returns An outcome indicating whether the user was added to the workgroup successfully.
   */
  public addWorkgroupUser(workgroupIdentity: string, userIdentity: string): Observable<Outcome> {
    return this.client.post<Outcome>(`${this.configService.get('apiUrl')}/task/workgroup/users`, {
      workgroupIdentity,
      userIdentity
    });
  }

  /**
   * Removes the user from the workgroup.
   *
   * @param workgroupUserIdentity The workgroup user identity.
   * @returns An outcome indicating whether the user was removed from the workgroup successfully.
   */
  public removeWorkgroupUser(workgroupUserIdentity: string): Observable<Outcome> {
    return this.client.delete<Outcome>(
      `${this.configService.get('apiUrl')}/task/workgroup/users/${workgroupUserIdentity}`
    );
  }
}
