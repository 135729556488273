import { Component, OnInit, OnDestroy } from '@angular/core';
import { Institution } from '../../models';
import { InstitutionService } from '../../services';
import { Subscription } from 'rxjs';

@Component({
  selector: 'roctavian-eumea-callcenter-portal-institution-summary-page',
  templateUrl: './institution-summary-page.component.html',
  styleUrls: ['./institution-summary-page.component.scss']
})
export class InstitutionSummaryPageComponent implements OnDestroy {
  public institution: Institution;
  private subscriptions = new Array<Subscription>();

  constructor(private service: InstitutionService) {
    this.subscriptions.push(this.service.selectedInstitutionSubject.subscribe(
      institution => (this.institution = institution)
    ));
  }

  ngOnDestroy() {
    this.subscriptions.forEach(subscription => {
      subscription.unsubscribe();
    });
  }
}
