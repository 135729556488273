import { NgModule } from '@angular/core';
import { MaterialModule } from '@roctavian-abstractions/core';

import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { ReactiveFormsModule } from '@angular/forms';
import { MAT_DIALOG_DEFAULT_OPTIONS } from '@angular/material';
import { RouterModule } from '@angular/router';
import { IdentityModule } from '../../../identity/identity.module';
import { SharedModule } from '../../../shared/shared.module';
import { StakeholderCommonModule } from '../common';
import { AddLabUserComponent } from './components/add-lab-user/add-lab-user.component';
import { LabUserFormComponent } from './components/lab-user-form/lab-user-form.component';
import { LabUserListComponent } from './components/labuser-list/labuser-list.component';
import { PrescriberListComponent } from './components/prescriber-list/prescriber-list.component';
import { ViewLabUserDialogComponent } from './components/view-lab-user-dialog/view-lab-user-dialog.component';
import { ViewLabUserComponent } from './components/view-lab-user/view-lab-user.component';
import { LabUserRoutingModule } from './labuser-routing.module';
import { AddLabUserPageComponent } from './pages/add-lab-user-page/add-lab-user-page.component';
import { LabUserAttestationPageComponent } from './pages/lab-user-attestation-page/lab-user-attestation-page.component';
import { LabUserDetailMasterPageComponent } from './pages/lab-user-detail-master-page/lab-user-detail-master-page.component';
import { LabUserEditPageComponent } from './pages/lab-user-edit-page/lab-user-edit-page.component';
import { LabUserStatusPageComponent } from './pages/lab-user-status-page/lab-user-status-page.component';
import { LabUserSummaryPageComponent } from './pages/lab-user-summary-page/lab-user-summary-page.component';
import { LabUserListPageComponent } from './pages/labuser-list-page/labuser-list-page.component';
import { LabUserPrescriberListPageComponent } from './pages/labuser-prescriber-list-page/labuser-prescriber-list-page.component';

@NgModule({
  declarations: [
    LabUserListComponent,
    LabUserListPageComponent,
    ViewLabUserDialogComponent,
    ViewLabUserComponent,
    LabUserDetailMasterPageComponent,
    LabUserSummaryPageComponent,
    LabUserEditPageComponent,
    LabUserFormComponent,
    AddLabUserPageComponent,
    AddLabUserComponent,
    LabUserAttestationPageComponent,
    LabUserStatusPageComponent,
    LabUserPrescriberListPageComponent,
    PrescriberListComponent
  ],
  imports: [
    CommonModule,
    LabUserRoutingModule,
    MaterialModule,
    IdentityModule,
    SharedModule,
    RouterModule,
    MaterialModule,
    HttpClientModule,
    ReactiveFormsModule,
    StakeholderCommonModule
  ],
  providers: [{ provide: MAT_DIALOG_DEFAULT_OPTIONS, useValue: { hasBackdrop: false } }],
  entryComponents: [ViewLabUserDialogComponent]
})
export class LabUserModule {}
