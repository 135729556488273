﻿/* eslint-disable @typescript-eslint/naming-convention */
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AuthenticationService } from './../services/authentication.service';

@Injectable()
export class AuthenticationInterceptor implements HttpInterceptor {
  constructor(private readonly service: AuthenticationService) { }
  /**
   * Interceptor that adds an authorization header to every request.
   *
   * @param request The request.
   * @param next The next handler.
   * @returns An observable containing an http event.
   */
  public intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const token = this.service.token;

    if (token) {
      const headerValue = `Bearer ${token}`;
      request = request.clone({ setHeaders: { Authorization: headerValue } });
    }

    return next.handle(request);
  }
}
