import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MaterialModule, CoreModule } from '@roctavian-abstractions/core';
import { WorkgroupFormComponent } from './components/workgroup-form/workgroup-form.component';
import { NewWorkgroupComponent } from './components/new-workgroup/new-workgroup.component';
import { WorkgroupCardComponent } from './components/workgroup-card/workgroup-card.component';
import { WorkgroupCardListComponent } from './components/workgroup-card-list/workgroup-card-list.component';
import { WorkgroupDetailsComponent } from './components/workgroup-details/workgroup-details.component';
import { WorkgroupUserListComponent } from './components/workgroup-user-list/workgroup-user-list.component';
import { AvailableUserFormComponent } from './components/available-user-form/available-user-form.component';

@NgModule({
  imports: [CommonModule, RouterModule, MaterialModule, CoreModule, FormsModule, ReactiveFormsModule],
  declarations: [WorkgroupFormComponent,
    NewWorkgroupComponent,
    WorkgroupCardComponent,
    WorkgroupCardListComponent,
    WorkgroupDetailsComponent,
    WorkgroupUserListComponent,
    AvailableUserFormComponent],
  exports: [WorkgroupFormComponent,
    NewWorkgroupComponent,
    WorkgroupCardComponent,
    WorkgroupCardListComponent,
    WorkgroupDetailsComponent,
    WorkgroupUserListComponent,
    AvailableUserFormComponent]
})
export class WorkgroupModule {}
