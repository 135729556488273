/**
 * A class that encapsulates the parameters returned from the token API>
 */
export class TokenResponse {
  /**
   * The generated token.
   */
  public token: string;

  /**
   * The token expiration.
   */
  public expiration: string;
}
