import { Subscription } from 'rxjs';
import { Component, OnInit, OnDestroy, Output, EventEmitter, Inject } from '@angular/core';
import { PrescriberDelegatePrescriber } from '../../models';
import { FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { PrescriberClient } from '../../clients';
import { MessageService } from '@roctavian-abstractions/core';
import { AppTranslationService } from '@roctavian-abstractions/core';
import { Outcome } from '@roctavian-abstractions/web';

@Component({
  selector: 'roctavian-eumea-callcenter-portal-disassociate-lab-dialog',
  templateUrl: './disassociate-prescriber-delegate-dialog.component.html',
  styleUrls: ['./disassociate-prescriber-delegate-dialog.component.scss']
})
export class DisassociatePrescriberDelegateDialogComponent implements OnInit, OnDestroy {
  public prescriberDelegatePrescriber: PrescriberDelegatePrescriber;
  public form = new FormGroup({});
  @Output() public prescriberDelegateDisassociated = new EventEmitter();
  public errorMessages: string[] = [];
  private subscriptions = new Array<Subscription>();

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public reference: MatDialogRef<DisassociatePrescriberDelegateDialogComponent>,
    private client: PrescriberClient,
    private messageService: MessageService,
    private translate: AppTranslationService
  ) {
    this.prescriberDelegatePrescriber = data.prescriberDelegatePrescriber;
  }

  public ngOnInit() { }

  ngOnDestroy() {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }

  public removeAssociation() {
    this.errorMessages = [];

    this.subscriptions.push(this.client
      .disassociatePrescriberDelegate(
        this.prescriberDelegatePrescriber.prescriberId,
        this.prescriberDelegatePrescriber.prescriberDelegateId
      )
      .subscribe(
        outcome => {
          if (outcome.success) {
            this.messageService.open(
              this.translate.getTranslation(
                'Prescriber.DisassociatePrescriberDelegateDialog.SuccessfullyDisassociated'
              ),
              3000
            );
            this.prescriberDelegateDisassociated.emit();
            this.reference.close();
            return;
          }

          this.errorMessages = outcome.messages;
        },
        (error: Outcome) => {
          this.messageService.open(this.translate.getTranslation('Common.ErrorProcessingRequest'), 5000);
        }
      ));
  }
}
