import { TaskType } from './TaskType';
import { TaskAction } from './TaskAction';
import { EntityType } from '@roctavian-abstractions/core';

/**
 * Class used to represent a task in the system.
 */
export class Task {
         public taskIdentity?: string;
         public taskType: TaskType;
         public taskActionHistory: TaskAction[];
         public entityTypeIdentity?: string;
         public entityType?: EntityType;
         public taskEntityName?: string;
         public statusIdentity?: TaskStatus;
         public workgroupIdentity?: string;
         public workgroupDisplay?: string;
         public assignedUserIdentity?: string;
         public assignedUser?: string;
         public priority?: TaskPriority;
         public dueDate?: Date;
         public closedDate?: Date;
         public addUser?: string;
         public addDate?: Date;
         public editUser?: string;
         public editDate?: Date;
       }

export enum TaskStatus {
  Open = 1,
  InProgress = 2,
  Closed = 3
}

export enum TaskPriority {
  Low = 1,
  Medium = 2,
  High = 3
}
