import { PagedQuery } from '../../common';

export class ExternalLabTestPagedQuery extends PagedQuery {
  constructor(
    public pageIndex: number,
    public pageSize: number,
    public filterText: string,
    public sortColumn: string,
    public isSortDesc: boolean,
    public AgeRange: string,
    public TestResult: string,
    public TestType: string
  ) {
    super(pageIndex, pageSize, filterText, sortColumn, isSortDesc);
  }
}
