import { Component, OnInit } from '@angular/core';
import { Lab } from '../..';

@Component({
  selector: 'roctavian-eumea-callcenter-portal-add-lab-page',
  templateUrl: './add-lab-page.component.html',
  styleUrls: ['./add-lab-page.component.css']
})
export class AddLabPageComponent implements OnInit {
  public lab: Lab;

  constructor() {}

  ngOnInit() {}

  public onContinueWithLab(lab: Lab) {
    this.lab = lab;
  }
}
