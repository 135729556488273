import { Auditable } from '../../common';
import { Prescriber } from '../../prescriber';
import { Patient } from './patient.model';

export class PatientPrescriber extends Auditable {
  public patientId: number;
  public patient: Patient;
  public prescriberId: number;
  public prescriber: Prescriber;
  public isCurrent: boolean;
}
