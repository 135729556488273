import { Component, Output, OnInit, Input, EventEmitter, ChangeDetectionStrategy } from "@angular/core";
import { SubmittedErrorStateMatcher } from "@roctavian-abstractions/core";

/**
 * Encapsulates the login form submitted event data.
 */
export class LoginSubmitEvent {
  constructor(public username: string, public password: string) { }
}

@Component({
  selector: "roctavian-abstractions-login-form",
  templateUrl: "./login-form.component.html",
  styleUrls: ["./login-form.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LoginFormComponent {
  @Input() public username: string;
  @Input() public password: string;
  @Input() public buttonText = "Continue";
  @Input() public disabled = false;
  @Input() public usernameText = "Username";
  @Input() public passwordText = "Password";
  @Input() public provideUsernameText = "Please provide your username";
  @Input() public providePasswordText = "Please provide your password";

  @Output() public submitted: EventEmitter<LoginSubmitEvent>;

  public matcher = new SubmittedErrorStateMatcher();
  public hide: boolean = true;

  constructor() {
    this.submitted = new EventEmitter<LoginSubmitEvent>();
  }

  public handleFormSubmission() {
    const event = new LoginSubmitEvent(this.username, this.password);
    this.submitted.emit(event);
  }
}
