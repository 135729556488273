import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { Endpoints } from '@app/core/enums';
import { PagedQuery } from '@app/stakeholder/models';
import { UtilityService } from '@app/stakeholder/services';
import { Outcome, PaginatedList, ValueOutcome } from '@roctavian-abstractions/web';

import { PrescriberDelegate, PrescriberDelegatePagedQuery, PrescriberDelegatePrescriber } from '../models';

@Injectable({
  providedIn: 'root',
})
export class PrescriberDelegateClient {
  constructor(private readonly http: HttpClient, private readonly utilityService: UtilityService) {}

  getPrescriberDelegateListPaged(
    pagedQuery: PrescriberDelegatePagedQuery,
  ): Observable<ValueOutcome<PaginatedList<PrescriberDelegate>>> {
    return this.http.get<ValueOutcome<PaginatedList<PrescriberDelegate>>>(Endpoints.prescriberDelegate, {
      params: this.utilityService.toHttpParams(pagedQuery),
    });
  }

  getPrescriberDelegatePrescriberListPaged(
    prescriberDelegateId: string,
    pagedQuery: PagedQuery,
  ): Observable<ValueOutcome<PaginatedList<PrescriberDelegatePrescriber>>> {
    const url = `${Endpoints.prescriberDelegate}/${prescriberDelegateId}/prescriber`;
    return this.http.get<ValueOutcome<PaginatedList<PrescriberDelegatePrescriber>>>(url, {
      params: this.utilityService.toHttpParams(pagedQuery),
    });
  }

  createPrescriberDelegate(prescriberDelegate: PrescriberDelegate): Observable<Outcome> {
    return this.http.post<Outcome>(Endpoints.prescriberDelegate, prescriberDelegate);
  }

  updatePrescriberDelegate(prescriberDelegate: PrescriberDelegate): Observable<Outcome> {
    return this.http.put<Outcome>(Endpoints.prescriberDelegate, prescriberDelegate);
  }

  getPrescriberDelegate(prescriberDelegateId: string): Observable<ValueOutcome<PrescriberDelegate>> {
    const url = `${Endpoints.prescriberDelegate}/${prescriberDelegateId}`;
    return this.http.get<ValueOutcome<PrescriberDelegate>>(url);
  }

  getExportFile() {
    const url = `${Endpoints.prescriberDelegate}/export`;
    return this.http.get(url, { responseType: 'blob' });
  }
}
