import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ConfigService } from '@roctavian-abstractions/core';
import { Outcome } from '@roctavian-abstractions/web';
import { Observable } from 'rxjs';
import {
  ConfirmEmailAddressModel,
  ForgotPasswordRequestModel,
  ResetPasswordModel,
  SendEmailConfirmationMessageModel
} from '../models';

/**
 * A client used to communicate with the account API.
 */
@Injectable({
  providedIn: 'root'
})
export class AccountClient {
  /**
   * Initializes a new instance of the account client.
   *
   * @param client The http client.
   */
  constructor(private client: HttpClient, private configService: ConfigService) { }

  /**
   * Initiates the forgot password flow given a username.
   *
   * @param model The forgot password model.
   * @returns An observable of anything.
   */
  public forgotPassword(model: ForgotPasswordRequestModel): Observable<any> {
    return this.client.post(`${this.configService.get('apiUrl')}/identity/account/forgot`, model);
  }

  /**
   * Resets the password for the specified user. A valid reset
   * token is required when executing this request.
   *
   * @param model The reset password model.
   * @returns An observable containing an outcome.
   */
  public resetPassword(model: ResetPasswordModel): Observable<Outcome> {
    return this.client.post<Outcome>(`${this.configService.get('apiUrl')}/identity/account/reset`, model);
  }

  /**
   * Confirms the email address for the specified user.
   * This endpoint requires an email confirmation token.
   *
   * @param model The confirm email address model.
   * @returns An observable containing an outcome.
   */
  public confirmEmailAddress(model: ConfirmEmailAddressModel): Observable<Outcome> {
    return this.client.post<Outcome>(`${this.configService.get('apiUrl')}/identity/account/confirm`, model);
  }

  /**
   * Sends the email confirmation message.
   *
   * @param model The send email confirmation message model.
   * @returns An observable of anything.
   */
  public sendEmailConfirmationMessage(model: SendEmailConfirmationMessageModel): Observable<any> {
    return this.client.post(`${this.configService.get('apiUrl')}/identity/account/initiate/confirm`, model);
  }
}
