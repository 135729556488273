import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ConfigService } from '@roctavian-abstractions/core';
import { Observable } from 'rxjs';
import { IdentityConfigModel } from '../models';

/**
 * A client used to communicate with the identity config API.
 */
@Injectable({
  providedIn: 'root'
})
export class IdentityConfigClient {
  /**
   * Initializes a new instance of the identity config client.
   *
   * @param client The http client.
   */
  constructor(private client: HttpClient, private configService: ConfigService) {

  }

  /**
   * Gets the defined identity configuration.
   *
   * @returns The identity config model.
   */
  public getIdentityConfig(): Observable<IdentityConfigModel> {
    return this.client.get<IdentityConfigModel>(`${this.configService.get('apiUrl')}/identity/settings`);
  }
}
