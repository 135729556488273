import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { MessageService } from '@roctavian-abstractions/core';

@Component({
  selector: 'roctavian-abstractions-create-user-page',
  templateUrl: './create-user-page.component.html',
  styleUrls: ['./create-user-page.component.scss'],
})
export class CreateUserPageComponent {
  constructor(private router: Router, private service: MessageService) {}

  public handleSave() {
    this.service.open('User created successfully', 4000);
    this.router.navigate(['/administration/users']);
  }
}
