import { Component, OnInit, OnDestroy, Input, SimpleChanges, OnChanges } from '@angular/core';
import { PaginatedList } from '@roctavian-abstractions/web';
import { BehaviorSubject, Subscription, EMPTY } from 'rxjs';
import { debounceTime, distinctUntilChanged, switchMap } from 'rxjs/operators';
import { PageEvent, MatSort, MatDialog } from '@angular/material';
import { PagedQuery, UtilityService } from '../../../common';
import { PrescriberLab, Prescriber } from '../../models';
import { PrescriberService } from '../../services';
import { DisassociateLabDialogComponent } from '../disassociate-lab-dialog/disassociate-lab-dialog.component';

@Component({
  selector: 'prescriber-lab-list',
  templateUrl: './lab-list.component.html',
  styleUrls: ['./lab-list.component.css']
})
export class LabListComponent implements OnInit, OnChanges, OnDestroy {
  @Input() prescriber: Prescriber;

  public pagedQuery = new PagedQuery(1, 10, null, 'lab.labName', false);

  public prescriberLabPagedCollection: PaginatedList<PrescriberLab>;
  public isLoading = false;
  public filterText$ = new BehaviorSubject<string>(null);
  private subscriptions = new Array<Subscription>();

  public displayedColumns = [
    'lab.id',
    'lab.labName',
    'lab.stakeholder.address.country.name',
    'lab.stakeholder.address.address1',
    'lab.stakeholder.address.city',
    'lab.stakeholder.address.postalCode',
    'lab.stakeholder.phone',
    'lab.stakeholder.statuses.statusType',
    'lab.stakeholder.statuses.addDate',
    'addDate',
    'addUser.username',
    'isPrescriberOffice',
    'menu'
  ];

  constructor(
    public utilityService: UtilityService,
    private prescriberService: PrescriberService,
    private matDialog: MatDialog
  ) {
    this.subscriptions.push(this.filterText$
      .pipe(
        debounceTime(1000),
        distinctUntilChanged(),
        switchMap(searchText => {
          if (this.pagedQuery.filterText == searchText) {
            return EMPTY;
          }

          this.pagedQuery.filterText = searchText;
          this.pagedQuery.pageIndex = 1;
          this.refreshList();
          return EMPTY;
        })
      )
      .subscribe());
  }

  ngOnDestroy() {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
    this.prescriberService.prescriberLabPagedListSubject.next(new PaginatedList<PrescriberLab>());
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (
      !changes.prescriber ||
      !changes.prescriber.currentValue ||
      changes.prescriber.currentValue === changes.prescriber.previousValue
    ) {
      return;
    }

    this.refreshList();
  }

  public refreshList() {
    if (!this.prescriber) {
      return;
    }

    this.prescriberService.setPrescriberLabPage(this.pagedQuery, this.prescriber.id);
  }

  ngOnInit() {
    this.subscriptions.push(this.prescriberService.prescriberLabPagedListSubject.subscribe(
      collection => {
        this.prescriberLabPagedCollection = collection;
      }
    ));

    this.subscriptions.push(this.prescriberService.isLoadingSubject.subscribe(isLoading => {
      this.isLoading = isLoading;
    }));
  }

  public onPageEvent(pageEvent: PageEvent) {
    this.pagedQuery.pageIndex = pageEvent.pageIndex + 1;
    this.pagedQuery.pageSize = pageEvent.pageSize;
    this.refreshList();
  }

  public onSortEvent(sort: MatSort) {
    switch (sort.active) {
      case 'lab.id':
        this.pagedQuery.sortColumn = 'lab.stakeholder.externalId';
        break;
      case 'lab.stakeholder.statuses.statusType':
        this.pagedQuery.sortColumn = 'lab.stakeholder.statuses.FirstOrDefault(x => x.IsCurrent).statusType';
        break;
      case 'lab.stakeholder.statuses.addDate':
        this.pagedQuery.sortColumn = 'lab.stakeholder.statuses.FirstOrDefault(x => x.IsCurrent).addDate';
        break;

      default:
        this.pagedQuery.sortColumn = sort.active;
        break;
    }

    this.pagedQuery.isSortDesc = sort.direction == 'desc';
    this.pagedQuery.pageIndex = 1;
    this.refreshList();
  }

  public disassociateLab(prescriberLab: PrescriberLab) {

    prescriberLab.prescriber = this.prescriber;
    const dialogRef = this.matDialog.open(DisassociateLabDialogComponent, {
      hasBackdrop: true,
      disableClose: true,
      data: {
        prescriberLab
      },
      width: '800px'
    });
    this.subscriptions.push(dialogRef.componentInstance.labDisassociated.subscribe(() => {
      this.refreshList();
    }));
  }
}
