import { Router } from '@angular/router';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { Institution } from '../../models';
import { InstitutionService } from '../../services';
import { InstitutionClient } from '../../clients';
import { MessageService } from '@roctavian-abstractions/core';
import { AppTranslationService } from '@roctavian-abstractions/core';
import { UtilityService } from '../../../common';
import { Outcome } from '@roctavian-abstractions/web';
import { Subscription } from 'rxjs';

@Component({
  selector: 'roctavian-eumea-callcenter-portal-institution-edit-page',
  templateUrl: './institution-edit-page.component.html',
  styleUrls: ['./institution-edit-page.component.scss']
})
export class InstitutionEditPageComponent implements OnInit, OnDestroy {
  public form: FormGroup;
  public institution: Institution;
  public isProcessing = false;
  public errorMessages = new Array<string>();

  private subscriptions = new Array<Subscription>();

  constructor(
    private formBuilder: FormBuilder,
    private service: InstitutionService,
    private client: InstitutionClient,
    private messageService: MessageService,
    private translate: AppTranslationService,
    public utilityService: UtilityService,
    public router: Router
  ) {
    this.subscriptions.push(this.service.selectedInstitutionSubject.subscribe(institution => {
      this.institution = institution;
      if (!this.institution) {
        return;
      }

      setTimeout(() => {
        this.institution.changeReasonDescription = '';
        this.form.patchValue(this.institution);
      });
    }));
  }

  ngOnInit() {
    this.form = this.formBuilder.group({});
  }

  ngOnDestroy() {
    this.subscriptions.forEach(subscription => {
      subscription.unsubscribe();
    });
  }

  public submit() {
    this.form.markAllAsTouched();

    if (this.form.invalid) {
      return;
    }

    this.isProcessing = true;
    this.errorMessages = [];
    const institution: Institution = this.form.getRawValue();
    institution.id = this.institution.id;

    this.subscriptions.push(this.client.updateInstitution(institution).subscribe(
      outcome => {
        this.isProcessing = false;
        if (outcome.failure) {
          this.errorMessages = outcome.messages;
          return;
        }

        this.messageService.open(
          this.translate.getTranslation('Institution.InstitutionEditPage.SuccessfullyUpdated'),
          5000
        );
        this.router.navigate(['/institution', this.institution.id]);
        this.service.loadInstitution(this.institution.id, true);
      },
      (error: Outcome) => {
        this.isProcessing = false;
        this.errorMessages = error.messages;
      }
    ));
  }
}
