import { OnDestroy } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { Subscription } from 'rxjs';
import { LabKitOrderClient } from '../../clients/lab-kit-order.client';
import { MessageService } from '@roctavian-abstractions/core';
import { Router } from '@angular/router';
import { AppTranslationService } from '@roctavian-abstractions/core';
import { Outcome } from '@roctavian-abstractions/web';
import { LabKitOrder } from '../../models/lab-kit-order.model';
import { StakeholderTypes } from '../../../common';

@Component({
  selector: 'add-lab-kit-order',
  templateUrl: './add-lab-kit-order.component.html',
  styleUrls: ['./add-lab-kit-order.component.scss']
})
export class AddLabKitOrderComponent implements OnInit, OnDestroy {
  public form: FormGroup;
  public errorMessages: string[];
  public isProcessing = false;

  private subscriptions = new Array<Subscription>();

  constructor(
    private formBuilder: FormBuilder,
    private client: LabKitOrderClient,
    private messageService: MessageService,
    private router: Router,
    private translate: AppTranslationService
  ) { }

  public ngOnInit() {
    this.form = this.formBuilder.group({});
  }

  ngOnDestroy() {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }

  public submit() {
    this.errorMessages = [];

    this.form.markAllAsTouched();
    if (this.form.invalid) {
      return;
    }

    this.isProcessing = true;
    this.subscriptions.push(
      this.client.createLabKitOrder(this.getModel()).subscribe(
        outcome => {
          this.isProcessing = false;
          if (outcome.failure) {
            this.handleError(outcome);
            return;
          }

          this.messageService.open(this.translate.getTranslation('LabKitOrder.SuccessfullySaved'), 5000);
          this.router.navigate(['/lab-kit-order']);
        },
        (error: Outcome) => {
          this.isProcessing = false;
          this.handleError(error);
        }
      )
    );
  }

  public getModel(): LabKitOrder {
    const formValue = this.form.getRawValue();
    const labKitOrder: LabKitOrder = formValue;
    labKitOrder.stakeholder.stakeholderType = StakeholderTypes.LabKitOrder;

    return labKitOrder;
  }

  private handleError(error: Outcome) {
    if (!error || !error.messages || error.messages.length === 0) {
      this.errorMessages = [this.translate.getTranslation('Common.ErrorProcessingRequest')];
    } else {
      this.errorMessages = error.messages;
    }
  }
}
