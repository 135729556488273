import { Injectable } from '@angular/core';
import { PatientPrescriber } from '../models';

@Injectable({
  providedIn: 'root'
})
export class PatientUtilityService {
  constructor() {}

  public getCurrentPatientPrescriberAssociation(patientPrescribers: PatientPrescriber[]): PatientPrescriber {
    if (!patientPrescribers || patientPrescribers.length === 0) {
      return null;
    }

    const current = patientPrescribers.find(x => x.isCurrent);

    return current;
  }
}
