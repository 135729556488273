import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { ExternalLabTest } from '../../../external-lab-test/models/external-lab-test.model';
import { ExternalLabTestService } from '../../../external-lab-test/services/external-lab-test.service';

@Component({
  selector: 'external-lab-test-detail-master-page',
  templateUrl: './external-lab-test-detail-master-page.component.html',
  styleUrls: ['./external-lab-test-detail-master-page.component.css']
})
export class ExternalLabTestDetailMasterPageComponent implements OnInit, OnDestroy {
  public externalLabTest: ExternalLabTest;
  private subscriptions = new Array<Subscription>();

  constructor(private route: ActivatedRoute, private service: ExternalLabTestService) { }

  ngOnInit() {
    this.subscriptions.push(this.service.selectedExternalLabTestSubject.subscribe(externalLabTest => (this.externalLabTest = externalLabTest)));

    this.subscriptions.push(
      this.route.paramMap.subscribe(params => {
        const id = String(params.get('id'));
        this.service.loadExternalLabTest(id);
      })
    );
  }

  ngOnDestroy() {
    this.subscriptions.forEach(subscription => {
      subscription.unsubscribe();
    });
    this.service.selectedExternalLabTestSubject.next(null);
  }

  public getSidenavMode(): string {
    this.setStyleBasedOnMainNavbarHeight();
    if (window.innerWidth < 750) {
      return 'over';
    }
    return 'side';
  }

  public setStyleBasedOnMainNavbarHeight() {
    const mainNavbar = document.getElementById('main-navbar');
    if (!mainNavbar) {
      return;
    }

    const element: any = document.getElementsByClassName('sidenav-container').item(0);
    element.style.top = `${mainNavbar.scrollHeight}px`;
  }
}
