import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";
import { Outcome } from "@roctavian-abstractions/web";
import { AccountClient } from "./../../../../clients/account.client";
import { ConfirmEmailAddressModel } from "../../../../models";

@Component({
  selector: "roctavian-abstractions-confirm-email-address",
  templateUrl: "./confirm-email-address.component.html",
  styleUrls: ["./confirm-email-address.component.scss"]
})
export class ConfirmEmailAddressComponent implements OnInit {
  @Input() public username: string;
  @Input() public token: string;

  @Output() public success = new EventEmitter();
  @Output() public failure = new EventEmitter();

  public successful = false;
  public loading = true;

  constructor(private client: AccountClient) {}

  public ngOnInit() {
    const model = new ConfirmEmailAddressModel(this.username, this.token);
    this.client.confirmEmailAddress(model).subscribe(
      (outcome: Outcome) => {
        this.loading = false;
        if (outcome.success) {
          this.successful = true;
          this.success.emit();
          return;
        }
        this.successful = false;
        this.failure.emit();
      },
      () => {
        this.loading = false;
        this.successful = false;
        this.failure.emit();
      }
    );
  }
}
