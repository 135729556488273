import { OnDestroy } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import { LabKitOrder } from '../../models/lab-kit-order.model';
import { Subscription } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { LabKitOrderService } from '../../services/lab-kit-order.service';

@Component({
  selector: 'roctavian-eumea-callcenter-portal-lab-kit-order-detail-master-page',
  templateUrl: './lab-kit-order-detail-master-page.component.html',
  styleUrls: ['./lab-kit-order-detail-master-page.component.css']
})
export class LabKitOrderDetailMasterPageComponent implements OnInit, OnDestroy {
  public labKitOrder: LabKitOrder;
  private subscriptions = new Array<Subscription>();

  constructor(private route: ActivatedRoute, private labKitOrderService: LabKitOrderService) { }

  ngOnInit() {
    this.subscriptions.push(
      this.route.paramMap.subscribe(params => {
        const id = String(params.get('id'));
        this.labKitOrderService.selectLabKitOrder(id);
      })
    );

    this.subscriptions.push(
      this.labKitOrderService.selectedLabKitOrderSubject.subscribe(labKitOrder => (this.labKitOrder = labKitOrder))
    );
  }

  ngOnDestroy() {
    this.subscriptions.forEach(subscription => {
      subscription.unsubscribe();
    });

    this.labKitOrderService.selectedLabKitOrderSubject.next(null);
  }

  public getSidenavMode(): string {
    this.setStyleBasedOnMainNavbarHeight();
    if (window.innerWidth < 750) {
      return 'over';
    }
    return 'side';
  }

  public setStyleBasedOnMainNavbarHeight() {
    const mainNavbar = document.getElementById('main-navbar');
    if (!mainNavbar) {
      return;
    }

    const element: any = document.getElementsByClassName('sidenav-container').item(0);
    element.style.top = `${mainNavbar.scrollHeight}px`;
  }
}
