import { Injectable, OnDestroy } from '@angular/core';
import { MessageService } from '@roctavian-abstractions/core';
import { Outcome, PaginatedList } from '@roctavian-abstractions/web';
import { BehaviorSubject, Subscription } from 'rxjs';
import { ClearService } from '../../../../shared/interface/clear-service.interface';
import { Document, PagedQuery } from '../../common';
import { LabTest } from '../../lab-test/models/lab-test.model';
import { Prescriber } from '../../prescriber';
import { PatientClient } from '../clients';
import {
  DocumentPatientQuery,
  Patient,
  PatientDuplicateCheckPagedQuery,
  PatientPagedQuery,
  PatientPrescriber,
  PatientPrescriberAssociatePagedQuery,
} from '../models';
import { DocumentPatient } from '../models/document-patient.model';

@Injectable({
  providedIn: 'root',
})
export class PatientService implements OnDestroy, ClearService {
  public pagedListSubject = new BehaviorSubject<PaginatedList<Patient>>(
    new PaginatedList<Patient>(),
  );
  public pagedDuplicatePatientListSubject = new BehaviorSubject<
    PaginatedList<Patient>
  >(new PaginatedList<Patient>());
  public pagedPatientPrescriberAssociateListSubject = new BehaviorSubject<
    PaginatedList<Prescriber>
  >(new PaginatedList<Prescriber>());
  public pagedPatientPrescriberListSubject = new BehaviorSubject<
    PaginatedList<PatientPrescriber>
  >(new PaginatedList<PatientPrescriber>());
  public pagedPatientDocumentsListSubject = new BehaviorSubject<
    PaginatedList<DocumentPatient>
  >(new PaginatedList<DocumentPatient>());
  public pagedLabTestListSubject = new BehaviorSubject<PaginatedList<LabTest>>(
    new PaginatedList<LabTest>(),
  );
  public isLoadingSubject = new BehaviorSubject<boolean>(false);

  public selectedPatientSubject = new BehaviorSubject<Patient>(null);

  private getPagedListSubscription: Subscription;
  private getPatientSubscription: Subscription;
  private getPatientDuplicatesSubscription: Subscription;
  private getPagedPatientPrescriberAssociateListSubscription: Subscription;
  private getPatientsPrescribersByPatientIdSubscription: Subscription;
  private getPatientDocumentsListSubscription: Subscription;
  private getPatientsLabTestByPatientIdSubscription: Subscription;

  private addDocumentToPatientSubscription: Subscription;
  public addDocumentToPatientSubject = new BehaviorSubject<Outcome>(null);

  constructor(
    private patientClient: PatientClient,
    private messageService: MessageService,
  ) {}

  ngOnDestroy() {
    if (this.getPagedListSubscription) {
      this.getPagedListSubscription.unsubscribe();
    }

    if (this.getPatientSubscription) {
      this.getPatientSubscription.unsubscribe();
    }

    if (this.getPatientDuplicatesSubscription) {
      this.getPatientDuplicatesSubscription.unsubscribe();
    }

    if (this.getPagedPatientPrescriberAssociateListSubscription) {
      this.getPagedPatientPrescriberAssociateListSubscription.unsubscribe();
    }

    if (this.getPatientsPrescribersByPatientIdSubscription) {
      this.getPatientsPrescribersByPatientIdSubscription.unsubscribe();
    }

    if (this.getPatientDocumentsListSubscription) {
      this.getPatientDocumentsListSubscription.unsubscribe();
    }

    if (this.addDocumentToPatientSubscription) {
      this.addDocumentToPatientSubscription.unsubscribe();
    }

    if (this.getPatientsLabTestByPatientIdSubscription) {
      this.getPatientsLabTestByPatientIdSubscription.unsubscribe();
    }
  }

  public setPage(pagedQuery: PatientPagedQuery) {
    this.isLoadingSubject.next(true);

    if (this.getPagedListSubscription) {
      this.getPagedListSubscription.unsubscribe();
      this.getPagedListSubscription = null;
    }

    this.getPagedListSubscription = this.patientClient
      .getPagedList(pagedQuery)
      .subscribe(
        outcome => {
          if (!outcome.success || outcome.failure) {
            this.messageService.open(outcome.messages[0], 5000);
            return;
          }

          this.pagedListSubject.next(outcome.value);
          this.isLoadingSubject.next(false);
        },
        (error: Outcome) => {
          this.messageService.open(error.messages[0], 5000);
          this.isLoadingSubject.next(false);
        },
      );
  }

  public setDuplicatePatientsPage(pagedQuery: PatientDuplicateCheckPagedQuery) {
    if (!pagedQuery || !pagedQuery.lastName) {
      return;
    }

    this.isLoadingSubject.next(true);

    if (this.getPatientDuplicatesSubscription) {
      this.getPatientDuplicatesSubscription.unsubscribe();
      this.getPatientDuplicatesSubscription = null;
    }

    this.getPatientDuplicatesSubscription = this.patientClient
      .getPatientDuplicates(pagedQuery)
      .subscribe(
        outcome => {
          if (!outcome.success || outcome.failure) {
            return;
          }

          this.pagedDuplicatePatientListSubject.next(outcome.value);
          this.isLoadingSubject.next(false);
        },
        (error: Outcome) => {
          this.isLoadingSubject.next(false);
        },
      );
  }

  public setDocumentsPage(pagedQuery: DocumentPatientQuery) {
    this.isLoadingSubject.next(true);

    if (this.getPatientDocumentsListSubscription) {
      this.getPatientDocumentsListSubscription.unsubscribe();
      this.getPatientDocumentsListSubscription = null;
    }

    this.getPatientDocumentsListSubscription = this.patientClient
      .getDocumentsPagedList(pagedQuery)
      .subscribe(
        outcome => {
          if (!outcome.success || outcome.failure) {
            this.messageService.open(outcome.messages[0], 5000);
            return;
          }
          this.pagedPatientDocumentsListSubject.next(outcome.value);
          this.isLoadingSubject.next(false);
        },
        (error: Outcome) => {
          this.messageService.open(error.messages[0], 5000);
          this.isLoadingSubject.next(false);
        },
      );
  }

  public addDocumentToPatient(patientId: string, document: Document) {
    this.isLoadingSubject.next(true);

    if (this.addDocumentToPatientSubscription) {
      this.addDocumentToPatientSubscription.unsubscribe();
      this.addDocumentToPatientSubscription = null;
    }

    this.addDocumentToPatientSubscription = this.patientClient
      .addDocumentToPatient(patientId, document)
      .subscribe(
        outcome => {
          if (!outcome.success || outcome.failure) {
            this.messageService.open(outcome.messages[0], 5000);
            return;
          }

          this.addDocumentToPatientSubject.next(outcome);
          this.isLoadingSubject.next(false);
        },
        (error: Outcome) => {
          this.messageService.open(error.messages[0], 5000);
          this.isLoadingSubject.next(false);
        },
      );
  }

  public selectPatient(patientId: string, forceRefresh: boolean = false) {
    if (
      this.selectedPatientSubject.value &&
      this.selectedPatientSubject.value.id === patientId &&
      !forceRefresh
    ) {
      return;
    }

    if (this.getPatientSubscription) {
      this.getPatientSubscription.unsubscribe();
      this.getPatientSubscription = null;
    }

    this.isLoadingSubject.next(true);
    this.getPatientSubscription = this.patientClient
      .getPatient(patientId)
      .subscribe(
        outcome => {
          if (!outcome.success || outcome.failure) {
            return;
          }

          this.selectedPatientSubject.next(outcome.value);
          this.isLoadingSubject.next(false);
        },
        (error: Outcome) => {
          this.isLoadingSubject.next(false);
        },
      );
  }

  public setPatientPrescriberAssociatePage(
    pagedQuery: PatientPrescriberAssociatePagedQuery,
    patientId: string,
  ) {
    this.isLoadingSubject.next(true);

    if (this.getPagedPatientPrescriberAssociateListSubscription) {
      this.getPagedPatientPrescriberAssociateListSubscription.unsubscribe();
      this.getPagedPatientPrescriberAssociateListSubscription = null;
    }

    this.getPagedPatientPrescriberAssociateListSubscription = this.patientClient
      .getPatientPrescribersAssociateList(pagedQuery, patientId)
      .subscribe(
        outcome => {
          if (!outcome.success || outcome.failure) {
            return;
          }
          this.pagedPatientPrescriberAssociateListSubject.next(outcome.value);
          this.isLoadingSubject.next(false);
        },
        (error: Outcome) => {
          this.isLoadingSubject.next(false);
        },
      );
  }

  public setPatientPrescriberPage(patientId: string, pagedQuery: PagedQuery) {
    if (!pagedQuery || !patientId) {
      return;
    }

    this.isLoadingSubject.next(true);

    if (this.getPatientsPrescribersByPatientIdSubscription) {
      this.getPatientsPrescribersByPatientIdSubscription.unsubscribe();
      this.getPatientsPrescribersByPatientIdSubscription = null;
    }

    this.getPatientsPrescribersByPatientIdSubscription = this.patientClient
      .getPatientsPrescribersByPatientId(patientId, pagedQuery)
      .subscribe(
        outcome => {
          if (!outcome.success || outcome.failure) {
            return;
          }

          this.pagedPatientPrescriberListSubject.next(outcome.value);
          this.isLoadingSubject.next(false);
        },
        (error: Outcome) => {
          this.isLoadingSubject.next(false);
        },
      );
  }

  public setPatientLabTestPage(patientId: string, pagedQuery: PagedQuery) {
    if (!pagedQuery || !patientId) {
      return;
    }

    this.isLoadingSubject.next(true);

    if (this.getPatientsLabTestByPatientIdSubscription) {
      this.getPatientsLabTestByPatientIdSubscription.unsubscribe();
      this.getPatientsLabTestByPatientIdSubscription = null;
    }

    this.getPatientsLabTestByPatientIdSubscription = this.patientClient
      .getPatientLabTestsByPatientId(patientId, pagedQuery)
      .subscribe(
        outcome => {
          if (!outcome.success || outcome.failure) {
            return;
          }

          this.pagedLabTestListSubject.next(outcome.value);
          this.isLoadingSubject.next(false);
        },
        (error: Outcome) => {
          this.isLoadingSubject.next(false);
        },
      );
  }

  public clearService() {
    this.pagedListSubject.next(new PaginatedList<Patient>());
    this.pagedDuplicatePatientListSubject.next(new PaginatedList<Patient>());
    this.pagedPatientPrescriberAssociateListSubject.next(
      new PaginatedList<Prescriber>(),
    );
    this.pagedPatientPrescriberListSubject.next(
      new PaginatedList<PatientPrescriber>(),
    );
    this.pagedPatientDocumentsListSubject.next(
      new PaginatedList<DocumentPatient>(),
    );
    this.pagedLabTestListSubject.next(new PaginatedList<LabTest>());
    this.selectedPatientSubject.next(null);
    this.addDocumentToPatientSubject.next(null);
  }
}
