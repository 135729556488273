import { MessageService } from "@roctavian-abstractions/core";
import { Component, EventEmitter, Input, Output, OnInit } from "@angular/core";
import { AccountClient } from "../../../../clients/account.client";
import { IdentityConfigClient } from "../../../../clients/identity-config.client";
import { PasswordConfigModel, ResetPasswordModel } from "./../../../../models";
import { ResetPasswordFormSubmitEvent } from "../reset-password-form/reset-password-form.component";

@Component({
  selector: "roctavian-abstractions-reset-password",
  templateUrl: "./reset-password.component.html",
  styleUrls: ["./reset-password.component.scss"]
})
export class ResetPasswordComponent implements OnInit {
  @Input() public token: string;
  @Input() public username: string;

  public passwordConfig: PasswordConfigModel;
  public loading = true;
  public resetPasswordFailed = false;

  @Input() public buttonText = "Reset Password";

  /**
   * Event that is emitted when the user's password has been reset successfully.
   */
  @Output() public reset = new EventEmitter<any>();

  constructor(
    public accountClient: AccountClient,
    private service: MessageService,
    private configClient: IdentityConfigClient
  ) {}

  /**
   * When the component is initialize, hit the identity config endpoint
   *  to determine how passwords are configured in the application.
   */
  public ngOnInit() {
    this.configClient.getIdentityConfig().subscribe(config => {
      this.passwordConfig = config.passwordConfig;
      this.loading = false;
    });
  }

  /**
   * Handles the form submission event by making a request to the
   * reset endpoint and attempting to reset the user's password.
   * @param event The reset password form submission event.
   */
  public handleFormSubmission(event: ResetPasswordFormSubmitEvent) {
    this.resetPasswordFailed = false;

    if (!this.username || !this.token) {
      const message = "An error occurred when submitting your reset password request";
      this.service.open(message, 5000);
      return;
    }

    const model = new ResetPasswordModel(this.username, this.token, event.newPassword, event.confirmationPassword);

    this.accountClient.resetPassword(model).subscribe(
      () => {
        this.reset.emit();
      },
      () => {
        this.resetPasswordFailed = true;
      }
    );
  }
}
