import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog, MatSort, PageEvent } from '@angular/material';
import { Router } from '@angular/router';
import { AppTranslationService, MessageService } from '@roctavian-abstractions/core';
import { Outcome, PaginatedList } from '@roctavian-abstractions/web';
import { BehaviorSubject, EMPTY, Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged, switchMap } from 'rxjs/operators';
import { UtilityService } from '../../../common';
import { FileExportService } from '../../../common/services/file-export.service';
import { PrescriberDelegateClient } from '../../clients/prescriber-delegate.client';
import { PrescriberDelegatePagedQuery } from '../../models/prescriber-delegate-paged-query.model';
import { PrescriberDelegate } from '../../models/prescriber-delegate.model';
import { PrescriberDelegateService } from '../../services/prescriber-delegate.service';
import { ViewPrescriberDelegateDialogComponent } from '../view-prescriber-delegate-dialog/view-prescriber-delegate-dialog.component';

@Component({
  selector: 'prescriber-delegate-list',
  templateUrl: './prescriber-delegate-list.component.html',
  styleUrls: ['./prescriber-delegate-list.component.scss']
})
export class PrescriberDelegateListComponent implements OnInit, OnDestroy {
  public pagedQuery = new PrescriberDelegatePagedQuery(1, 10, null, 'id', true);

  public filterText$ = new BehaviorSubject<string>(null);
  public prescriberDelegatePagedCollection: PaginatedList<PrescriberDelegate>;
  public isLoading = false;

  private exportFileName = this.translate.getTranslation('PrescriberDelegate.PrescriberDelegateList.ExportFileName');
  public exportLabel = this.translate.getTranslation('Common.Export');

  private subscriptions = new Array<Subscription>();

  public displayedColumns = [
    'id',
    'stakeholder.firstName',
    'stakeholder.lastName',
    'stakeholder.email',
    'stakeholder.phone',
    'stakeholder.statuses.statusType',
    'stakeholder.statuses.addDate',
    'addDate',
    'addUser.username',
    'menu'
  ];

  public viewLabel = this.translate.getTranslation('PrescriberDelegate.PrescriberDelegateList.View');
  public editLabel = this.translate.getTranslation('PrescriberDelegate.PrescriberDelegateList.Edit');

  constructor(
    private prescriberDelegateService: PrescriberDelegateService,
    private router: Router,
    public utilityService: UtilityService,
    private matDialog: MatDialog,
    private prescriberDelegateClient: PrescriberDelegateClient,
    private messageService: MessageService,
    private translate: AppTranslationService,
    private fileExportService: FileExportService
  ) { }

  private refreshList() {
    this.prescriberDelegateService.setPage(this.pagedQuery);
  }

  ngOnInit() {
    this.subscriptions.push(
      this.filterText$
        .pipe(
          debounceTime(1000),
          distinctUntilChanged(),
          switchMap(searchText => {
            if (this.pagedQuery.filterText == searchText) {
              return EMPTY;
            }

            this.pagedQuery.filterText = searchText;
            this.pagedQuery.pageIndex = 1;
            this.refreshList();
            return EMPTY;
          })
        )
        .subscribe()
    );

    this.subscriptions.push(
      this.prescriberDelegateService.prescriberDelegatePagedListSubject.subscribe(collection => {
        this.prescriberDelegatePagedCollection = collection;
      })
    );

    this.subscriptions.push(
      this.prescriberDelegateService.isLoadingSubject.subscribe(isLoading => {
        this.isLoading = isLoading;
      })
    );

    this.refreshList();
  }

  ngOnDestroy() {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
    this.prescriberDelegateService.prescriberDelegatePagedListSubject.next(new PaginatedList<PrescriberDelegate>());
  }

  public onRowSelected(prescriberDelegate: PrescriberDelegate) {
    this.router.navigate(['/prescriber-delegate', prescriberDelegate.id]);
  }

  public viewPrescriberDelegate(prescriberDelegateId: string) {
    this.subscriptions.push(
      this.prescriberDelegateClient.getPrescriberDelegate(prescriberDelegateId).subscribe(
        outcome => {
          const dialogRef = this.matDialog.open(ViewPrescriberDelegateDialogComponent, {
            hasBackdrop: true,
            disableClose: true,
            data: {
              prescriberDelegate: outcome.value
            },
            width: '1000px'
          });
        },
        error => {
          this.messageService.open(this.translate.getTranslation('Common.ErrorProcessingRequest'), 3000);
        }
      )
    );
  }

  public onPageEvent(pageEvent: PageEvent) {
    this.pagedQuery.pageIndex = pageEvent.pageIndex + 1;
    this.pagedQuery.pageSize = pageEvent.pageSize;
    this.refreshList();
  }

  public onSortEvent(sort: MatSort) {
    switch (sort.active) {
      case 'id':
        this.pagedQuery.sortColumn = 'stakeholder.externalId';
        break;
      case 'stakeholder.statuses.statusType':
        this.pagedQuery.sortColumn = 'stakeholder.statuses.FirstOrDefault(x => x.IsCurrent).statusType';
        break;
      case 'stakeholder.statuses.addDate':
        this.pagedQuery.sortColumn = 'stakeholder.statuses.FirstOrDefault(x => x.IsCurrent).addDate';
        break;

      default:
        this.pagedQuery.sortColumn = sort.active;
        break;
    }

    this.pagedQuery.isSortDesc = sort.direction == 'desc';
    this.pagedQuery.pageIndex = 1;
    this.refreshList();
  }

  public downloadExportFile() {
    this.subscriptions.push(
      this.prescriberDelegateClient.getExportFile().subscribe(
        data => this.fileExportService.downloadFile(data, 'text/csv', this.exportFileName),
        (error: Outcome) => {
          this.messageService.open(error.messages[0], 3000);
        }
      )
    );
  }
}
