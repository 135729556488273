/**
 * A class that encapsulates the parameters required to update a user's details.
 */
export class UserDetailsModel {
  /**
   * Initializes a new instance of the user details model.
   * @param username The user's username.
   * @param firstName The user's first name.
   * @param lastName The user's last name.
   * @param email The user's email address.
   */
  constructor(public username?: string, public firstName?: string, public lastName?: string, public email?: string) {}
}
