/* eslint-disable @typescript-eslint/member-ordering */
import { DatePipe } from '@angular/common';
import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { FormControl, FormGroupDirective, Validators } from '@angular/forms';
import { TestTypes } from '@app/core/enums';
import { AppTranslationService } from '@roctavian-abstractions/core';
import { StakeholderTypes, StatusTypes, UtilityService } from '../../../common';
import { Lab } from '../../../lab';
import { Patient } from '../../../patient';
import { Prescriber } from '../../../prescriber';
import { LabTest } from '../../models/lab-test.model';

@Component({
  selector: 'lab-test-form',
  templateUrl: './lab-test-form.component.html',
  styleUrls: ['./lab-test-form.component.scss'],
})
export class LabTestFormComponent implements OnInit, OnChanges {
  @Input() public labTest: LabTest;

  @Input() public title = this.translate.getTranslation('LabTest.LabTestForm.Title');
  @Input() public showTitle = true;

  @Input() public showPatientSelection = true;

  public selectPatientHeader = this.translate.getTranslation('LabTest.LabTestForm.PatientHeader');
  public selectLabHeader = this.translate.getTranslation('LabTest.LabTestForm.LabHeader');
  public appointmentDateLabel = this.translate.getTranslation('LabTest.LabTestForm.AppointmentDate');
  public appointmentTimeLabel = this.translate.getTranslation('LabTest.LabTestForm.AppointmentTime');
  public requiresSampleShippingKitLabel = this.translate.getTranslation(
    'LabTest.LabTestForm.RequiresSampleShippingKit',
  );
  public cdcStatementLabel = this.translate.getTranslation(
    'LabTest.LabTestForm.CDCStatementLabel',
  );
  public labTestOrderCompleteLabel = this.translate.getTranslation('LabTest.LabTestForm.LabTestOrderComplete');
  public trackingNumberLabel = this.translate.getTranslation('LabTest.LabTestForm.TrackingNumber');
  public numberOfSpecimensSubmittedLabel = this.translate.getTranslation(
    'LabTest.LabTestForm.NumberOfSpecimensSubmitted',
  );
  public specimenCollectionDateLabel = this.translate.getTranslation('LabTest.LabTestForm.SpecimenCollectionDate');
  public specimenCollectionTimeLabel = this.translate.getTranslation('LabTest.LabTestForm.SpecimenCollectionTime');
  public specimenTransportPickupDateLabel = this.translate.getTranslation(
    'LabTest.LabTestForm.SpecimenTransportPickupDate',
  );
  public specimenTransportPickupTimeLabel = this.translate.getTranslation(
    'LabTest.LabTestForm.SpecimenTransportPickupTime',
  );
  public specimenTransportDispatchDateLabel = this.translate.getTranslation(
    'LabTest.LabTestForm.SpecimenTransportDispatchDate',
  );
  public specimenTransportDispatchTimeLabel = this.translate.getTranslation(
    'LabTest.LabTestForm.SpecimenTransportDispatchTime',
  );
  public estimatedTransportArrivalDateLabel = this.translate.getTranslation(
    'LabTest.LabTestForm.EstimatedTransportArrivalDate',
  );
  public estimatedTransportArrivalTimeLabel = this.translate.getTranslation(
    'LabTest.LabTestForm.EstimatedTransportArrivalTime',
  );
  public actualTransportArrivalDateLabel = this.translate.getTranslation(
    'LabTest.LabTestForm.ActualTransportArrivalDate',
  );
  public actualTransportArrivalTimeLabel = this.translate.getTranslation(
    'LabTest.LabTestForm.ActualTransportArrivalTime',
  );
  public currentPhysicianLabel = this.translate.getTranslation('LabTest.LabTestForm.CurrentPhysician');
  public yesLabel = this.translate.getTranslation('Common.Yes');
  public noLabel = this.translate.getTranslation('Common.No');

  public patientSelectionRequiredLabel = this.translate.getTranslation('LabTest.LabTestForm.PatientSelectionRequired');
  public labSelectionRequiredLabel = this.translate.getTranslation('LabTest.LabTestForm.LabSelectionRequired');

  public testTypeList = TestTypes;
  testTypeVal = [];
  public testTypeLabel = this.translate.getTranslation('LabTest.LabTestForm.TestType');

  constructor(
    public parentFormGroupDirective: FormGroupDirective,
    public utilityService: UtilityService,
    private translate: AppTranslationService,
    private datePipe: DatePipe,
  ) {
    this.testTypeVal = Object.keys(this.testTypeList).filter((f) => isNaN(Number(f)));
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.labTest && changes.labTest.currentValue) {
      this.setFromModel();
    }
  }

  ngOnInit() {
    this.parentFormGroupDirective.form.addControl('patientId', new FormControl(null, Validators.required));
    this.parentFormGroupDirective.form.addControl('prescriberId', new FormControl(null, Validators.required));
    this.parentFormGroupDirective.form.addControl('labId', new FormControl(null, Validators.required));
    this.parentFormGroupDirective.form.addControl('orderingPhysician', new FormControl());

    this.parentFormGroupDirective.form.addControl('appointmentDate', new FormControl(null));
    this.parentFormGroupDirective.form.addControl('appointmentTime', new FormControl(null));

    this.parentFormGroupDirective.form.addControl('requiresSampleShippingKit', new FormControl());
    this.parentFormGroupDirective.form.addControl('labTestComplete', new FormControl(false));
    this.parentFormGroupDirective.form.addControl('cdcStatement', new FormControl(null, Validators.required));

    this.parentFormGroupDirective.form.addControl('trackingNumber', new FormControl());
    this.parentFormGroupDirective.form.addControl('numberOfSpecimensSubmitted', new FormControl());
    this.parentFormGroupDirective.form.addControl('specimenCollectionDate', new FormControl());
    this.parentFormGroupDirective.form.addControl('specimenCollectionTime', new FormControl());

    this.parentFormGroupDirective.form.addControl('specimenTransportPickupDate', new FormControl());
    this.parentFormGroupDirective.form.addControl('specimenTransportPickupTime', new FormControl());
    this.parentFormGroupDirective.form.addControl('specimenTransportDispatchDate', new FormControl());
    this.parentFormGroupDirective.form.addControl('specimenTransportDispatchTime', new FormControl());

    this.parentFormGroupDirective.form.addControl('estimatedTransportArrivalDate', new FormControl());
    this.parentFormGroupDirective.form.addControl('estimatedTransportArrivalTime', new FormControl());
    this.parentFormGroupDirective.form.addControl('actualTransportArrivalDate', new FormControl());
    this.parentFormGroupDirective.form.addControl('actualTransportArrivalTime', new FormControl());
    this.parentFormGroupDirective.form.addControl('resultNotificationSentToPrescriber', new FormControl());

    this.parentFormGroupDirective.form.addControl('trackingNumberSecondary', new FormControl());
    this.parentFormGroupDirective.form.addControl('specimenTransportPickupDateSecondary', new FormControl());
    this.parentFormGroupDirective.form.addControl('specimenTransportPickupTimeSecondary', new FormControl());
    this.parentFormGroupDirective.form.addControl('specimenTransportDispatchDateSecondary', new FormControl());
    this.parentFormGroupDirective.form.addControl('specimenTransportDispatchTimeSecondary', new FormControl());

    this.parentFormGroupDirective.form.addControl('estimatedTransportArrivalDateSecondary', new FormControl());
    this.parentFormGroupDirective.form.addControl('estimatedTransportArrivalTimeSecondary', new FormControl());
    this.parentFormGroupDirective.form.addControl('actualTransportArrivalDateSecondary', new FormControl());
    this.parentFormGroupDirective.form.addControl('actualTransportArrivalTimeSecondary', new FormControl());

    this.parentFormGroupDirective.form.addControl('testType', new FormControl());

    this.refreshValidators();
  }

  public onPatientSelected(patient: Patient) {
    const patientId = patient ? patient.id : null;

    this.parentFormGroupDirective.form.get('patientId').setValue(patientId);
    const patientPrescriber = patient.patientsPrescribers.find(patientPrescriber => patientPrescriber.isCurrent);
    if (patientPrescriber) {
      this.parentFormGroupDirective.form.get('prescriberId').setValue(patientPrescriber.prescriberId);
      this.parentFormGroupDirective.form
        .get('orderingPhysician')
        .setValue(
          patientPrescriber.prescriber.stakeholder.firstName + ' ' + patientPrescriber.prescriber.stakeholder.lastName,
        );
    }
  }

  public onLabSelected(lab: Lab) {
    const labId = lab ? lab.id : null;
    this.parentFormGroupDirective.form.get('labId').setValue(labId);
  }

  public getModel(): LabTest {
    const formValue = this.parentFormGroupDirective.form.getRawValue();
    const labTest: LabTest = formValue;

    labTest.stakeholder.stakeholderType = StakeholderTypes.LabTest;

    labTest.appointmentDateTime = this.getCombinedDateTime(formValue.appointmentDate, formValue.appointmentTime);

    labTest.specimenCollectionDateTime = this.getCombinedDateTime(
      formValue.specimenCollectionDate,
      formValue.specimenCollectionTime,
    );
    labTest.specimenTransportPickupDateTime = this.getCombinedDateTime(
      formValue.specimenTransportPickupDate,
      formValue.specimenTransportPickupTime,
    );
    labTest.specimenTransportDispatchDateTime = this.getCombinedDateTime(
      formValue.specimenTransportDispatchDate,
      formValue.specimenTransportDispatchTime,
    );
    labTest.estimatedSpecimenArrivalDateTime = this.getCombinedDateTime(
      formValue.estimatedTransportArrivalDate,
      formValue.estimatedTransportArrivalTime,
    );
    labTest.actualSpecimenArrivalDateTime = this.getCombinedDateTime(
      formValue.actualTransportArrivalDate,
      formValue.actualTransportArrivalTime,
    );

    labTest.specimenTransportPickupDateTimeSecondary = this.getCombinedDateTime(
      formValue.specimenTransportPickupDateSecondary,
      formValue.specimenTransportPickupTimeSecondary,
    );
    labTest.specimenTransportDispatchDateTimeSecondary = this.getCombinedDateTime(
      formValue.specimenTransportDispatchDateSecondary,
      formValue.specimenTransportDispatchTimeSecondary,
    );
    labTest.estimatedSpecimenArrivalDateTimeSecondary = this.getCombinedDateTime(
      formValue.estimatedTransportArrivalDateSecondary,
      formValue.estimatedTransportArrivalTimeSecondary,
    );
    labTest.actualSpecimenArrivalDateTimeSecondary = this.getCombinedDateTime(
      formValue.actualTransportArrivalDateSecondary,
      formValue.actualTransportArrivalTimeSecondary,
    );

    if (this.labTest) {
      labTest.id = this.labTest.id;
    }

    return labTest;
  }

  private getCombinedDateTime(date: Date, time: Date): string {
    let result = this.datePipe.transform(date, 'MM/dd/yyyy');
    if (time) {
      result = this.datePipe.transform(new Date(`${date.toDateString()} ${time}`), 'MM/dd/yyyy, h:mm a');
    }

    return result;
  }

  public setFromModel() {
    if (!this.labTest) {
      return;
    }

    setTimeout(() => {
      this.parentFormGroupDirective.form.patchValue({
        ...this.labTest,
      });

      if (this.labTest.appointmentDateTime) {
        this.parentFormGroupDirective.form.get('appointmentDate').setValue(new Date(this.labTest.appointmentDateTime));
        if (this.datePipe.transform(this.labTest.appointmentDateTime, 'hh:mm a') != '12:00 AM') {
          this.parentFormGroupDirective.form
            .get('appointmentTime')
            .setValue(this.datePipe.transform(this.labTest.appointmentDateTime, 'hh:mm a'));
        }
      }

      if (this.labTest.specimenCollectionDateTime) {
        this.parentFormGroupDirective.form
          .get('specimenCollectionDate')
          .setValue(new Date(this.labTest.specimenCollectionDateTime));
        if (this.datePipe.transform(this.labTest.specimenCollectionDateTime, 'hh:mm a') != '12:00 AM') {
          this.parentFormGroupDirective.form
            .get('specimenCollectionTime')
            .setValue(this.datePipe.transform(this.labTest.specimenCollectionDateTime, 'hh:mm a'));
        }
      }

      // Initial
      if (this.labTest.specimenTransportPickupDateTime) {
        this.parentFormGroupDirective.form
          .get('specimenTransportPickupDate')
          .setValue(new Date(this.labTest.specimenTransportPickupDateTime));
        if (this.datePipe.transform(this.labTest.specimenTransportPickupDateTime, 'hh:mm a') != '12:00 AM') {
          this.parentFormGroupDirective.form
            .get('specimenTransportPickupTime')
            .setValue(this.datePipe.transform(this.labTest.specimenTransportPickupDateTime, 'hh:mm a'));
        }
      }

      if (this.labTest.specimenTransportDispatchDateTime) {
        this.parentFormGroupDirective.form
          .get('specimenTransportDispatchDate')
          .setValue(new Date(this.labTest.specimenTransportDispatchDateTime));
        if (this.datePipe.transform(this.labTest.specimenTransportDispatchDateTime, 'hh:mm a') != '12:00 AM') {
          this.parentFormGroupDirective.form
            .get('specimenTransportDispatchTime')
            .setValue(this.datePipe.transform(this.labTest.specimenTransportDispatchDateTime, 'hh:mm a'));
        }
      }

      if (this.labTest.estimatedSpecimenArrivalDateTime) {
        this.parentFormGroupDirective.form
          .get('estimatedTransportArrivalDate')
          .setValue(new Date(this.labTest.estimatedSpecimenArrivalDateTime));
        if (this.datePipe.transform(this.labTest.estimatedSpecimenArrivalDateTime, 'hh:mm a') != '12:00 AM') {
          this.parentFormGroupDirective.form
            .get('estimatedTransportArrivalTime')
            .setValue(this.datePipe.transform(this.labTest.estimatedSpecimenArrivalDateTime, 'hh:mm a'));
        }
      }

      if (this.labTest.actualSpecimenArrivalDateTime) {
        this.parentFormGroupDirective.form
          .get('actualTransportArrivalDate')
          .setValue(new Date(this.labTest.actualSpecimenArrivalDateTime));
        if (this.datePipe.transform(this.labTest.actualSpecimenArrivalDateTime, 'hh:mm a') != '12:00 AM') {
          this.parentFormGroupDirective.form
            .get('actualTransportArrivalTime')
            .setValue(this.datePipe.transform(this.labTest.actualSpecimenArrivalDateTime, 'hh:mm a'));
        }
      }

      // Secondary
      if (this.labTest.specimenTransportPickupDateTimeSecondary) {
        this.parentFormGroupDirective.form
          .get('specimenTransportPickupDateSecondary')
          .setValue(new Date(this.labTest.specimenTransportPickupDateTimeSecondary));
        if (this.datePipe.transform(this.labTest.specimenTransportPickupDateTimeSecondary, 'hh:mm a') != '12:00 AM') {
          this.parentFormGroupDirective.form
            .get('specimenTransportPickupTimeSecondary')
            .setValue(this.datePipe.transform(this.labTest.specimenTransportPickupDateTimeSecondary, 'hh:mm a'));
        }
      }

      if (this.labTest.specimenTransportDispatchDateTimeSecondary) {
        this.parentFormGroupDirective.form
          .get('specimenTransportDispatchDateSecondary')
          .setValue(new Date(this.labTest.specimenTransportDispatchDateTimeSecondary));
        if (this.datePipe.transform(this.labTest.specimenTransportDispatchDateTimeSecondary, 'hh:mm a') != '12:00 AM') {
          this.parentFormGroupDirective.form
            .get('specimenTransportDispatchTimeSecondary')
            .setValue(this.datePipe.transform(this.labTest.specimenTransportDispatchDateTimeSecondary, 'hh:mm a'));
        }
      }

      if (this.labTest.estimatedSpecimenArrivalDateTimeSecondary) {
        this.parentFormGroupDirective.form
          .get('estimatedTransportArrivalDateSecondary')
          .setValue(new Date(this.labTest.estimatedSpecimenArrivalDateTimeSecondary));
        if (this.datePipe.transform(this.labTest.estimatedSpecimenArrivalDateTimeSecondary, 'hh:mm a') != '12:00 AM') {
          this.parentFormGroupDirective.form
            .get('estimatedTransportArrivalTimeSecondary')
            .setValue(this.datePipe.transform(this.labTest.estimatedSpecimenArrivalDateTimeSecondary, 'hh:mm a'));
        }
      }

      if (this.labTest.actualSpecimenArrivalDateTimeSecondary) {
        this.parentFormGroupDirective.form
          .get('actualTransportArrivalDateSecondary')
          .setValue(new Date(this.labTest.actualSpecimenArrivalDateTimeSecondary));
        if (this.datePipe.transform(this.labTest.actualSpecimenArrivalDateTimeSecondary, 'hh:mm a') != '12:00 AM') {
          this.parentFormGroupDirective.form
            .get('actualTransportArrivalTimeSecondary')
            .setValue(this.datePipe.transform(this.labTest.actualSpecimenArrivalDateTimeSecondary, 'hh:mm a'));
        }
      }

      this.parentFormGroupDirective.form
        .get('orderingPhysician')
        .setValue(this.labTest.prescriber.stakeholder.firstName + ' ' + this.labTest.prescriber.stakeholder.lastName);

      this.refreshValidators();
    });
  }

  public get areFieldsRequired(): boolean {
    if (!this.labTest) {
      return false;
    }

    const currentStatus = this.labTest.stakeholder.statuses.find(status => status.isCurrent);
    if (!currentStatus || currentStatus.statusType != StatusTypes.LabTestComplete) {
      return false;
    }
    return true;
  }

  private refreshValidators() {
    this.parentFormGroupDirective.form.get('appointmentDate').clearValidators();
    this.parentFormGroupDirective.form.get('appointmentTime').clearValidators();
    this.parentFormGroupDirective.form.get('requiresSampleShippingKit').clearValidators();

    if (!this.showPatientSelection) {
      this.parentFormGroupDirective.form.get('patientId').clearValidators();
    }

    if (!this.areFieldsRequired) {
      return;
    }

    this.parentFormGroupDirective.form.get('appointmentDate').setValidators(Validators.required);
    this.parentFormGroupDirective.form.get('appointmentTime').setValidators(Validators.required);
    this.parentFormGroupDirective.form.get('requiresSampleShippingKit').setValidators(Validators.required);
  }

  public getCurrentPrescriber(patient: Patient): Prescriber {
    if (!patient || !patient.patientsPrescribers) {
      return null;
    }

    const currentPatientPrescriber = patient.patientsPrescribers.find(patientPrescriber => patientPrescriber.isCurrent);
    return currentPatientPrescriber ? currentPatientPrescriber.prescriber : null;
  }
}
