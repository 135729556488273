import { PagedQuery } from '../../common';

export class PatientDuplicateCheckPagedQuery extends PagedQuery {
  constructor(
    public pageIndex: number,
    public pageSize: number,
    public filterText: string,
    public sortColumn: string,
    public isSortDesc: boolean,
    public lastName: string,
    public birthDate: Date
  ) {
    super(pageIndex, pageSize, filterText, sortColumn, isSortDesc);
  }
}
