import { Component, OnInit, Input, SimpleChanges, OnChanges, OnDestroy } from '@angular/core';
import { Status } from '../../models';
import { UtilityService } from '../../services';
import { StatusPagedQuery } from '../../models/status-paged-query.model';
import { BehaviorSubject, Subscription, EMPTY } from 'rxjs';
import { PaginatedList } from '@roctavian-abstractions/web';
import { StatusService } from '../../services/status.service';
import { debounceTime, distinctUntilChanged, switchMap } from 'rxjs/operators';
import { PageEvent, MatSort } from '@angular/material';

@Component({
  selector: 'status-list',
  templateUrl: './status-list.component.html',
  styleUrls: ['./status-list.component.css']
})
export class StatusListComponent implements OnInit, OnChanges, OnDestroy {
  public pagedQuery = new StatusPagedQuery(1, 10, null, 'id', true, null);

  public filterText$ = new BehaviorSubject<string>(null);
  public statusPagedCollection: PaginatedList<Status>;
  public isLoading = false;
  private subscriptions = new Array<Subscription>();

  @Input() public stakeholderId: string;

  public displayedColumns = [
    'isCurrent',
    'statusType',
    'changeReasonType',
    'changeReasonDescription',
    'addUser.username',
    'addDate'
  ];

  constructor(public utilityService: UtilityService, private service: StatusService) { }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes.stakeholderId && changes.stakeholderId.currentValue) {
      this.pagedQuery.stakeholderId = changes.stakeholderId.currentValue;
      this.refreshList();
    }
  }

  public ngOnDestroy() {
    this.subscriptions.forEach(subscription => {
      subscription.unsubscribe();
    });

    this.service.statusPagedListSubject.next(new PaginatedList<Status>());
  }

  ngOnInit() {
    this.subscriptions.push(this.filterText$
      .pipe(
        debounceTime(1000),
        distinctUntilChanged(),
        switchMap(searchText => {
          if (this.pagedQuery.filterText == searchText) {
            return EMPTY;
          }

          this.pagedQuery.filterText = searchText;
          this.pagedQuery.pageIndex = 1;
          this.refreshList();
          return EMPTY;
        })
      )
      .subscribe());

    this.subscriptions.push(this.service.statusPagedListSubject.subscribe(collection => {
      this.statusPagedCollection = collection;
    }));

    this.subscriptions.push(this.service.isLoadingSubject.subscribe(isLoading => {
      this.isLoading = isLoading;
    }));

    this.pagedQuery.stakeholderId = this.stakeholderId;
  }

  public onPageEvent(pageEvent: PageEvent) {
    this.pagedQuery.pageIndex = pageEvent.pageIndex + 1;
    this.pagedQuery.pageSize = pageEvent.pageSize;
    this.refreshList();
  }

  public onSortEvent(sort: MatSort) {
    this.pagedQuery.sortColumn = sort.active;
    this.pagedQuery.isSortDesc = sort.direction == 'desc';
    this.pagedQuery.pageIndex = 1;
    this.refreshList();
  }

  public refreshList() {
    this.service.setPage(this.pagedQuery);
  }
}
