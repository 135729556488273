import { Injectable } from '@angular/core';

export class LoginPageSettings {
  buttonText: string;
  forgotPasswordText: string;
  headerText: string;
}

/**
 * A service that contains the login page settings.
 * This service can help customize the login component.
 */
@Injectable({
  providedIn: 'root'
})
export class LoginPageService {
  private settings: LoginPageSettings = { buttonText: 'Continue', forgotPasswordText: '', headerText: '' };

  /**
   * Initializes a new instance of the login page service.
   */
  constructor() { }

  /**
   * Gets the login page settings that have been set.
   *
   * @returns The login settings.
   */
  public getSettings(): LoginPageSettings {
    return this.settings;
  }

  /**
   * Updates the login page settings.
   */
  public updateSettings(settings: LoginPageSettings) {
    this.settings = settings;
  }
}
