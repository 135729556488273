import { Component, OnInit, Output, EventEmitter, Input, OnDestroy } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { MessageService } from '@roctavian-abstractions/core';
import { AppTranslationService } from '@roctavian-abstractions/core';
import { Stakeholder, Status } from '../../models';
import { StatusClient } from '../../clients';
import { Subscription } from 'rxjs';

@Component({
  selector: 'add-status',
  templateUrl: './add-status.component.html',
  styleUrls: ['./add-status.component.css']
})
export class AddStatusComponent implements OnInit, OnDestroy {
  public form: FormGroup;
  public errorMessages: string[] = [];
  @Output() public statusSaved = new EventEmitter();
  @Output() public cancelAddStatus = new EventEmitter();
  @Input() public stakeholder: Stakeholder;

  private subscriptions = new Array<Subscription>();

  constructor(
    private formBuilder: FormBuilder,
    private statusClient: StatusClient,
    private messageService: MessageService,
    private translate: AppTranslationService
  ) { }

  ngOnInit() {
    this.form = this.formBuilder.group({});
  }

  ngOnDestroy() {
    this.subscriptions.forEach(subscription => {
      subscription.unsubscribe();
    });
  }

  public saveStatus() {
    this.errorMessages = [];

    this.form.markAllAsTouched();
    if (this.form.invalid) {
      return;
    }

    const status: Status = this.form.get('status').value;
    status.stakeholderId = this.stakeholder.id;
    this.subscriptions.push(this.statusClient.saveStatus(status).subscribe(
      outcome => {
        if (outcome.failure) {
          this.errorMessages = outcome.messages;
          return;
        }

        this.messageService.open(this.translate.getTranslation('Common.AddStatus.SuccessfullyAdded'), 5000);
        this.statusSaved.emit();
      },
      error => {
        this.errorMessages = [this.translate.getTranslation('Common.ErrorProcessingRequest')];
      }
    ));
  }

  public cancelAddingStatus() {
    this.cancelAddStatus.emit();
  }
}
