import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'roctavian-abstractions-administration-dashboard-page',
  templateUrl: './administration-dashboard-page.component.html',
  styleUrls: ['./administration-dashboard-page.component.scss'],
})
export class AdministrationDashboardPageComponent implements OnInit {
  constructor() {}

  public ngOnInit() {}
}
