import { AppTranslationService } from '@roctavian-abstractions/core';
import { Component, OnInit, Input, SimpleChanges, OnChanges } from '@angular/core';
import { FormGroupDirective, FormControl, Validators } from '@angular/forms';
import { UtilityService } from '../../../common';

@Component({
  selector: 'lab-user-form',
  templateUrl: './lab-user-form.component.html',
  styleUrls: ['./lab-user-form.component.scss']
})
export class LabUserFormComponent implements OnInit {
  @Input() public areFieldsRequired = false;
  @Input() public title = this.translate.getTranslation('LabUser.LabUserForm.Title');
  @Input() public showTitle = true;
  @Input() public showAttestation = true;

  constructor(
    public parentFormGroupDirective: FormGroupDirective,
    public utilityService: UtilityService,
    private translate: AppTranslationService
  ) { }

  ngOnInit() {
    this.buildForm();
  }

  private buildForm() {
    this.parentFormGroupDirective.form.addControl('verified', new FormControl(false));
  }
}
