import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { ExternalLabTest } from '../../models/external-lab-test.model';

@Component({
  selector: 'view-external-lab-test-dialog',
  templateUrl: './view-external-lab-test-dialog.component.html',
  styleUrls: ['./view-external-lab-test-dialog.component.scss']
})
export class ViewExternalLabTestDialogComponent {
  public externalLabTest: ExternalLabTest;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any, public reference: MatDialogRef<ViewExternalLabTestDialogComponent>) {
    this.externalLabTest = data.externalLabTest;
  }
}
