import { Component, OnInit, Output, EventEmitter, Input, ChangeDetectionStrategy } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Workgroup, WorkgroupModel } from '../../../../models';

@Component({
  selector: 'roctavian-abstractions-workgroup-form',
  templateUrl: './workgroup-form.component.html',
  styleUrls: ['./workgroup-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class WorkgroupFormComponent implements OnInit {
  /**
   * Sets the values on the form with the information from the incoming workgroup.
   */
  @Input()
  set workgroup(workgroup: Workgroup) {
    if (workgroup) {
      this.workgroupIdentity = workgroup.workgroupIdentity;

      if (this.workgroupForm) {
        this.workgroupForm.patchValue({
          name: workgroup.name,
          description: workgroup.description
        });
      }
    }
  }

  /**
   * The form submitted event.
   */
  @Output() public submitted: EventEmitter<WorkgroupModel> = new EventEmitter<WorkgroupModel>();

  /**
   * The workgroup form.
   */
  public workgroupForm: FormGroup;

  /**
   * The identity of the workgroup passed into the component.
   */
  private workgroupIdentity?: string;

  /**
   * Constructs the component.
   *
   * @param builder the form builder
   */
  constructor(private builder: FormBuilder) {}

  /**
   * Builds the form when the component is initialized.
   */
  public ngOnInit() {
    this.buildForm();
  }

  /**
   * Maps the information from the form to a model and emit's it to the parent component.
   *
   * @param form the form information to submit
   */
  public submit(form: FormGroup) {
    const name = form.get('name').value;
    const description = form.get('description').value;

    const model = new WorkgroupModel();

    model.name = name;
    model.description = description;
    model.workgroupIdentity = this.workgroupIdentity;

    this.submitted.emit(model);
  }

  /**
   * Builds the form.
   */
  private buildForm(): void {
    this.workgroupForm = this.builder.group({
      name: [null, Validators.required],
      description: [null, Validators.required]
    });
  }
}
