import { Subscription } from 'rxjs';
import { AppTranslationService } from '@roctavian-abstractions/core';
import { FormBuilder, FormGroupDirective, FormGroup, FormControl, Validators } from '@angular/forms';
import { Component, OnInit, Input, OnChanges, SimpleChanges, OnDestroy } from '@angular/core';
import { StakeholderTypes } from '../../enums';
import { StakeholderStatusTypes, StatusChangeReasonTypes, StatusChangeReasonType } from '../../constants';

@Component({
  selector: 'status-form',
  templateUrl: './status-form.component.html',
  styleUrls: ['./status-form.component.css']
})
export class StatusFormComponent implements OnInit, OnChanges, OnDestroy {
  @Input() title = this.translate.getTranslation('Common.StatusForm.Title');
  @Input() showTitle = false;

  @Input() statusTypeLabel = this.translate.getTranslation('Common.StatusForm.StatusType');
  @Input() showStatusType = true;

  @Input() changeReasonDescriptionLabel = this.translate.getTranslation('Common.StatusForm.ChangeReasonDescription');
  @Input() showChangeReasonDescription = true;

  @Input() changeReasonTypeLabel = this.translate.getTranslation('Common.StatusForm.ChangeReasonType');
  @Input() showChangeReasonType = true;

  @Input() stakeholderType: StakeholderTypes;

  @Input() statusFormGroupName = 'status';
  @Input() areFieldsRequired = true;

  public isStatusTypeRequired = false;
  public isChangeReasonTypeRequired = false;

  public statusTypes = StakeholderStatusTypes;
  public form: FormGroup;

  private subscriptions = new Array<Subscription>();

  constructor(
    private formBuilder: FormBuilder,
    public parentFormGroupDirective: FormGroupDirective,
    private translate: AppTranslationService
  ) { }

  ngOnInit() {
    this.buildForm();

    this.subscriptions.push(this.form.get('statusType').valueChanges.subscribe(value => {
      this.form.get('changeReasonType').setValue(null);
      this.refreshValidations(this.areFieldsRequired);
    }));
  }

  ngOnDestroy() {
    this.subscriptions.forEach(subscription => {
      subscription.unsubscribe();
    });
  }

  private buildForm() {
    this.form = this.formBuilder.group({
      statusType: new FormControl(null, []),
      changeReasonType: new FormControl(null, [])
    });

    this.refreshValidations(this.areFieldsRequired);

    this.parentFormGroupDirective.form.addControl(this.statusFormGroupName, this.form);
    this.form.setParent(this.parentFormGroupDirective.form);
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (!this.form || !this.form.controls) {
      return;
    }

    this.refreshValidations(changes.areFieldsRequired.currentValue);
  }

  private refreshValidations(areFieldsRequired: boolean) {
    this.isStatusTypeRequired = false;
    this.isChangeReasonTypeRequired = false;
    this.form.get('statusType').clearValidators();
    this.form.get('changeReasonType').clearValidators();

    if (!areFieldsRequired) {
      return;
    }

    if (this.showStatusType) {
      this.form.get('statusType').setValidators([Validators.required]);
      this.isStatusTypeRequired = true;
    }

    if (this.showChangeReasonType && this.filteredChangeReasonTypes !== null) {
      this.form.get('changeReasonType').setValidators([Validators.required]);
      this.isChangeReasonTypeRequired = true;
    }
  }

  public get filteredChangeReasonTypes(): StatusChangeReasonType[] {
    const statusChangeReasonTypes = StatusChangeReasonTypes.filter(
      scrt => scrt.stakeholderType == this.stakeholderType && scrt.statusType == this.form.get('statusType').value
    );
    if (statusChangeReasonTypes.length === 0) {
      return null;
    }

    return statusChangeReasonTypes;
  }
}
