import { Component, Input } from "@angular/core";

@Component({
  selector: "roctavian-abstractions-facebook-button",
  templateUrl: "./facebook-button.component.html",
  styleUrls: ["./facebook-button.component.scss"]
})
export class FacebookButtonComponent {
  @Input() public buttonText = "Sign in with Facebook";

  constructor() {}
}
