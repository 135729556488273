import { AppTranslationService } from '@roctavian-abstractions/core';
import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { SystemKeyModel, SystemKeyService } from '@roctavian-abstractions/core';
import { Address } from '../../models';
import { UtilityService } from '../../services';
import { Subscription } from 'rxjs';

@Component({
  selector: 'view-address',
  templateUrl: './view-address.component.html',
  styleUrls: ['./view-address.component.css']
})
export class ViewAddressComponent {
  @Input() public title = this.translate.getTranslation('Common.ViewAddress.Title');
  @Input() public showTitle = false;

  @Input() public countryLabel = this.translate.getTranslation('Common.ViewAddress.Country');
  @Input() public showCountry = true;

  @Input() public nameLabel = this.translate.getTranslation('Common.ViewAddress.Name');
  @Input() public showName = true;

  @Input() public address1Label = this.translate.getTranslation('Common.ViewAddress.Address1');
  @Input() public showAddress1 = true;

  @Input() public address2Label = this.translate.getTranslation('Common.ViewAddress.Address2');
  @Input() public showAddress2 = true;

  @Input() public cityLabel = this.translate.getTranslation('Common.ViewAddress.City');
  @Input() public showCity = true;

  @Input() public postalCodeLabel = this.translate.getTranslation('Common.ViewAddress.PostalCode');
  @Input() public showPostalCode = true;

  @Input() public addressFormGroupName = 'address';
  @Input() public areFieldsRequired = false;

  @Input() public address: Address;

  constructor(public utilityService: UtilityService, private translate: AppTranslationService) { }
}
