import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MaterialModule, CoreModule } from "@roctavian-abstractions/core";
import { SecurityQuestionComponent } from "./components/security-question/security-question.component";
import { SecurityQuestionGroupComponent } from "./components/security-question-group/security-question-group.component";

@NgModule({
  imports: [CommonModule, CoreModule, FormsModule, MaterialModule, ReactiveFormsModule],
  declarations: [SecurityQuestionComponent, SecurityQuestionGroupComponent],
  exports: [SecurityQuestionComponent, SecurityQuestionGroupComponent]
})
export class SecurityModule { }
