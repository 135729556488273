import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ConfigService } from '@roctavian-abstractions/core';
import { Outcome } from '@roctavian-abstractions/web';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { RegisterModel } from '../models/register.model';

/**
 * A client used to communicate with the account management API.
 */
@Injectable({
  providedIn: 'root'
})
export class RegisterClient {
  /**
   * Initializes a new instance of the account management client.
   *
   * @param client The http client.
   */
  constructor(private client: HttpClient, private configService: ConfigService) { }

  public register(model: RegisterModel): Observable<Outcome> {
    return this.client.post<Outcome>(`${this.configService.get('apiUrl')}/identity/register/register-user`, model).pipe(
      catchError((response: HttpErrorResponse) => {
        if (response instanceof HttpErrorResponse) {
          const outcome: Outcome = Object.assign(new Outcome(), response.error);
          return throwError(outcome);
        }
      })
    );
  }
}
