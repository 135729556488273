import { Subscription } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { Prescriber } from '../../models';
import { PrescriberService } from '../../services';

@Component({
  selector: 'roctavian-eumea-callcenter-portal-prescriber-attestation-page',
  templateUrl: './prescriber-attestation-page.component.html',
  styleUrls: ['./prescriber-attestation-page.component.css']
})
export class PrescriberAttestationPageComponent implements OnDestroy {
  public prescriber: Prescriber;
  private subscriptions = new Array<Subscription>();

  constructor(private route: ActivatedRoute, private prescriberService: PrescriberService) {
    this.subscriptions.push(this.prescriberService.selectedPrescriberSubject.subscribe(
      prescriber => {
        this.prescriber = prescriber;
      }
    ));
  }

  ngOnDestroy() {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }

  public onAttestationSaved() {
    this.prescriberService.loadPrescriber(this.prescriber.id, true);
  }
}
