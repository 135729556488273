import { Component, Input } from '@angular/core';
import { FormGroupDirective } from '@angular/forms';
import { AppTranslationService } from '@roctavian-abstractions/core';
import { UtilityService } from '../../../common/services';
import { PrescriberDelegate } from '../../models/prescriber-delegate.model';

@Component({
  selector: 'prescriber-delegate-form',
  templateUrl: './prescriber-delegate-form.component.html',
  styleUrls: ['./prescriber-delegate-form.component.scss'],
})
export class PrescriberDelegateFormComponent {
  @Input() public prescriberDelegate: PrescriberDelegate;

  @Input() public title = this.translate.getTranslation(
    'PrescriberDelegate.PrescriberDelegateForm.Title'
  );
  @Input() public showTitle = true;
  @Input() public showAttestation = true;

  @Input() public attestationLabel = this.translate.getTranslation(
    'PrescriberDelegate.PrescriberDelegateForm.Attestation'
  );
  @Input() public attestationDateLabel = this.translate.getTranslation(
    'PrescriberDelegate.PrescriberDelegateForm.AttestationDate'
  );

  @Input() public areFieldsRequired = false;

  constructor(
    public parentFormGroupDirective: FormGroupDirective,
    public utilityService: UtilityService,
    private translate: AppTranslationService
  ) {}
}
