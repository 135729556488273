import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Role } from '../../../identity/models/role';
import { RoleManagementService } from '../../services/role-management.service';

@Component({
  selector: 'roctavian-abstractions-role-manager-page',
  templateUrl: './role-manager-page.component.html',
  styleUrls: ['./role-manager-page.component.scss'],
})
export class RoleManagerPageComponent implements OnDestroy, OnInit {
  public roles: Role[];
  public filterText: string;
  public unsubscribe: Subject<boolean> = new Subject<boolean>();

  constructor(private router: Router, private service: RoleManagementService) {
    this.filterText = this.service.getFilterText();
    this.service.roles$.pipe(takeUntil(this.unsubscribe)).subscribe(roles => {
      this.roles = roles.slice();
    });

    this.service.refreshRoles();
  }

  public handleSelectedCard(roleIdentity: number) {
    const route = `/administration/roles/edit/${roleIdentity}`;
    this.router.navigate([route]);
  }

  public handleKeyup(text: string) {
    this.service.filterRoles(text);
  }

  public ngOnInit() {}

  public ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }
}
