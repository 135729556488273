import { Auditable, Stakeholder } from '../../common';
import { PatientPrescriber } from '../../patient';
import { OfficeContact } from './office-contact.model';
import { Institution } from '../../institution';

export class Prescriber extends Auditable {
  public id: string;
  public nationalId: string;

  public officeContact: OfficeContact;
  public stakeholder: Stakeholder;
  public institution: Institution;

  public patientsPrescribers: PatientPrescriber[];
}
