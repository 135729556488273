import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { AuthenticationHistoryComponent } from "./components/authentication-history/authentication-history.component";
import { AuthenticationHistoryGridComponent } from "./components/authentication-history-grid/authentication-history-grid.component";

@NgModule({
  imports: [CommonModule],
  declarations: [AuthenticationHistoryComponent, AuthenticationHistoryGridComponent],
  exports: [AuthenticationHistoryComponent, AuthenticationHistoryGridComponent]
})
export class HistoryModule {}
