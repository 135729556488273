import { Component, Input, Output, EventEmitter } from "@angular/core";

export class TwoFactorCodeSubmittedEvent {
  constructor(public token: string) {}
}

@Component({
  selector: "roctavian-abstractions-two-factor-code-form",
  templateUrl: "./two-factor-code-form.component.html",
  styleUrls: ["./two-factor-code-form.component.scss"]
})
export class TwoFactorCodeFormComponent {
  @Input() public disabled = false;
  @Input() public buttonText = "Verify";

  public token: string;

  @Output() public submitted = new EventEmitter<TwoFactorCodeSubmittedEvent>();

  public handleFormSubmission() {
    const event = new TwoFactorCodeSubmittedEvent(this.token);
    this.submitted.emit(event);
  }
}
