import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ConfigService } from '@roctavian-abstractions/core';
import { Observable } from 'rxjs';
import { SecurityQuestionModel } from '../models/security-question.model';

/**
 * A client used to communicate with the security question API.
 */
@Injectable({
  providedIn: 'root'
})
export class SecurityQuestionClient {
  /**
   * Initializes a new instance of the security question client.
   *
   * @param client The http client.
   */
  constructor(private client: HttpClient, private configService: ConfigService) { }

  /**
   * Gets all the security questions defined in the application.
   *
   * @returns An array of security questions.
   */
  public getSecurityQuestions(localeIdentifier?: string): Observable<SecurityQuestionModel[]> {

    let url = `${this.configService.get('apiUrl')}/identity/securityquestion`;
    if (localeIdentifier) {
      url += `?localeIdentifier=${localeIdentifier}`;
    }
    return this.client.get<SecurityQuestionModel[]>(url);
  }
}
