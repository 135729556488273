import { Component, OnDestroy, ViewChild } from '@angular/core';
import { LabUserService } from '../../services';
import { LabUser } from '../../models';
import { Subscription } from 'rxjs';
import { PrescriberListComponent } from '../../components/prescriber-list/prescriber-list.component';

@Component({
  selector: 'roctavian-eumea-callcenter-portal-prescriber-list-page',
  templateUrl: './labuser-prescriber-list-page.component.html',
  styleUrls: ['./labuser-prescriber-list-page.component.scss']
})
export class LabUserPrescriberListPageComponent implements OnDestroy {
  public labUser: LabUser;
  private subscriptions = new Array<Subscription>();
  @ViewChild(PrescriberListComponent, { static: false }) public labUserPrescriberListComponent: PrescriberListComponent;

  constructor(private labUserService: LabUserService) {
    this.subscriptions.push(this.labUserService.selectedLabUserSubject.subscribe(
      labUser => (this.labUser = labUser)
    ));
  }

  ngOnDestroy() {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }
}
