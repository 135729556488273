import { OnDestroy } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import { Patient } from '../../models';
import { Subscription } from 'rxjs';
import { PatientService } from '../../services';
import { AppTranslationService } from '@roctavian-abstractions/core';
import { PatientClient } from '../../clients';
import { Outcome } from '@roctavian-abstractions/web';
import { MessageService } from '@roctavian-abstractions/core';

@Component({
  selector: 'roctavian-eumea-callcenter-portal-patient-attestation-page',
  templateUrl: './patient-attestation-page.component.html',
  styleUrls: ['./patient-attestation-page.component.scss']
})
export class PatientAttestationPageComponent implements OnInit, OnDestroy {
  public patient: Patient;
  public isProcessing = false;
  public subscriptions = new Array<Subscription>();
  public errorMessages = new Array<string>();

  public attestationFormHasSignatureLabel = this.translate.getTranslation(
    'Patient.PatientAttestationPage.AttestationFormHasSignatureLabel'
  );
  public attestationFormSignatureDateLabel = this.translate.getTranslation(
    'Patient.PatientAttestationPage.AttestationFormSignatureDateLabel'
  );
  public attestationFormGuardianNameLabel = this.translate.getTranslation(
    'Patient.PatientAttestationPage.AttestationFormGuardianNameLabel'
  );
  public attestationFormHasConsentTitle = this.translate.getTranslation(
    'Patient.PatientAttestationPage.AttestationFormHasConsentTitle'
  );
  public attestationFormHasConsentLabel = this.translate.getTranslation(
    'Patient.PatientAttestationPage.AttestationFormHasConsentLabel'
  );

  public attestationListHasSignatureLabel = this.translate.getTranslation(
    'Patient.PatientAttestationPage.AttestationListHasSignatureLabel'
  );
  public attestationListAddByUserLabel = this.translate.getTranslation(
    'Patient.PatientAttestationPage.AttestationListAddByUserLabel'
  );

  public attestationFormTitle = this.translate.getTranslation('Patient.PatientAttestationPage.AttestationFormTitle');

  constructor(
    private service: PatientService,
    private translate: AppTranslationService,
    private client: PatientClient,
    private messageService: MessageService
  ) { }

  ngOnInit() {
    this.subscriptions.push(
      this.service.selectedPatientSubject.subscribe(patient => {
        this.patient = patient;
      })
    );
  }

  ngOnDestroy() {
    this.subscriptions.forEach(subscription => {
      subscription.unsubscribe();
    });
  }

  public onAttestationSaved() {
    this.isProcessing = true;
    this.service.selectPatient(this.patient.id, true);
    // trigger status update

    this.subscriptions.push(
      this.client.updatePatient(this.patient).subscribe(
        outcome => {
          this.isProcessing = false;
          if (outcome.failure) {
            this.handleError(outcome);
            return;
          }
        },
        (error: Outcome) => {
          this.isProcessing = false;
          this.handleError(error);
        }
      )
    );
  }
  private handleError(error: Outcome) {
    if (!error || !error.messages || error.messages.length === 0) {
      this.errorMessages = [this.translate.getTranslation('Common.ErrorProcessingRequest')];
    } else {
      this.errorMessages = error.messages;
    }
  }
}
