import { OnDestroy } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { LabTest } from '../../models/lab-test.model';
import { Subscription } from 'rxjs';
import { LabTestService } from '../../services/lab-test.service';

@Component({
  selector: 'roctavian-eumea-callcenter-portal-lab-test-status-page',
  templateUrl: './lab-test-status-page.component.html',
  styleUrls: ['./lab-test-status-page.component.scss']
})
export class LabTestStatusPageComponent implements OnInit, OnDestroy {

  public labTest: LabTest;
  public subscriptions = new Array<Subscription>();


  constructor(private route: ActivatedRoute, private labTestService: LabTestService) {}

  ngOnInit() {
    this.subscriptions.push(
      this.labTestService.selectedLabTestSubject.subscribe(labTest => {
        this.labTest = labTest;
      })
    );
  }

  ngOnDestroy() {
    this.subscriptions.forEach(subscription => {
      subscription.unsubscribe();
    });
  }

  public onStatusSaved() {
    this.labTestService.loadLabTest(this.labTest.id, true);
  }
}
