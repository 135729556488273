import { Subscription } from 'rxjs';
import { Component, OnInit, EventEmitter, Output, Input, OnDestroy } from '@angular/core';
import { MessageService } from '@roctavian-abstractions/core';
import { AppTranslationService } from '@roctavian-abstractions/core';
import { Lab } from '../../../lab/models/lab.model';
import { LabUser } from '../../../labuser/models/labuser.model';
import { LabClient } from '../../clients/lab.client';
import { LabUserLab } from '../../../labuser/models/lab-user-lab.model';
import { LabService } from '../../services';

@Component({
  selector: 'lab-associate-lab-user-to-lab',
  templateUrl: './associate-lab-user-to-lab.component.html',
  styleUrls: ['./associate-lab-user-to-lab.component.scss']
})
export class AssociateLabUserToLabComponent implements OnDestroy {
  public errorMessages: string[] = [];
  @Output() public associated = new EventEmitter();
  @Output() public cancelAssociation = new EventEmitter();
  @Input() public lab: Lab;
  public selectedLabUser: LabUser;
  public subscriptions = new Array<Subscription>();
  public isAuthorizedRep = false;
  public isAuthorizedRepCheckboxEnabled = false;

  onChange(event) {
    this.isAuthorizedRep = event.checked;
  }
  constructor(
    private labClient: LabClient,
    private labService: LabService,
    private messageService: MessageService,
    private translate: AppTranslationService
  ) { }

  ngOnDestroy() {
    this.subscriptions.forEach(subscription => {
      subscription.unsubscribe();
    });
  }

  public cancelLabUserAssociation() {
    this.cancelAssociation.emit();
  }

  public onLabUserSelected(labUser: LabUser) {
    this.isAuthorizedRepCheckboxEnabled = true;
    this.selectedLabUser = labUser;
  }

  public saveAssociation() {
    this.errorMessages = [];

    if (!this.selectedLabUser) {
      this.errorMessages = ['Please select a lab user for association'];
      return;
    }

    const labUserLab = new LabUserLab();
    labUserLab.labId = this.lab.id;
    labUserLab.labUserId = this.selectedLabUser.id;
    labUserLab.isAuthorizedRepresentative = this.isAuthorizedRep;

    this.subscriptions.push(
      this.labClient.createLabUserLabAssociation(labUserLab).subscribe(
        outcome => {
          if (outcome.failure) {
            this.errorMessages = outcome.messages;
            return;
          }

          this.messageService.open(this.translate.getTranslation('Lab.AssociateLabUser.SuccessfullyAssociated'), 5000);
          this.associated.emit();
          this.labService.selectLab(this.lab.id, true);
        },
        error => {
          this.errorMessages = [this.translate.getTranslation('Common.ErrorProcessingRequest')];
        }
      )
    );
  }
}
