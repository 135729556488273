import { AppTranslationService } from '@roctavian-abstractions/core';
import { Component, OnInit, Input, SimpleChanges } from '@angular/core';
import { FormGroupDirective, FormControl, Validators, FormArray, FormBuilder } from '@angular/forms';
import { UtilityService } from '../../../common';
import { Institution } from '../../../institution';
import { Prescriber } from '../../models';

@Component({
  selector: 'prescriber-form',
  templateUrl: './prescriber-form.component.html',
  styleUrls: ['./prescriber-form.component.css']
})
export class PrescriberFormComponent implements OnInit {
  @Input() public prescriber: Prescriber;

  @Input() public title = this.translate.getTranslation('Prescriber.PrescriberForm.Title');
  @Input() public showTitle = true;

  @Input() public institutionLabel = this.translate.getTranslation('Prescriber.PrescriberForm.Institution');

  @Input() public nationalIdLabel = this.translate.getTranslation('Prescriber.PrescriberForm.NationalId');
  @Input() public disableNationalId = true;

  @Input() public disableLastName = true;

  @Input() public showAttestation = true;

  @Input() public areFieldsRequired = false;

  constructor(
    public parentFormGroupDirective: FormGroupDirective,
    public utilityService: UtilityService,
    private translate: AppTranslationService,
    private formBuilder: FormBuilder
  ) { }

  ngOnInit() {
    this.buildForm();
    this.refreshValidations(this.areFieldsRequired);
  }

  private buildForm() {
    this.parentFormGroupDirective.form.addControl('nationalId', new FormControl(null, [Validators.required]));
    this.parentFormGroupDirective.form.addControl(
      'institution',
      this.formBuilder.group({
        id: new FormControl(null, [Validators.required])
      })
    );
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes.areFieldsRequired) {
      this.refreshValidations(changes.areFieldsRequired.currentValue);
    }
  }

  private refreshValidations(areFieldsRequired: boolean) {
    if (
      !this.parentFormGroupDirective.form ||
      !this.parentFormGroupDirective.form.controls ||
      !this.parentFormGroupDirective.form.get('institution.id')
    ) {
      return;
    }

    //this.parentFormGroupDirective.form.get("institution.id").clearValidators();

    if (!areFieldsRequired) {
      return;
    }

    this.parentFormGroupDirective.form.get('institution.id').setValidators([Validators.required]);
  }

  public onInstitutionSelected(institution: Institution) {
    if (institution) {
      this.parentFormGroupDirective.form.get('institution.id').setValue(institution.id);
      return;
    }

    this.parentFormGroupDirective.form.get('institution.id').setValue(null);
  }
}
