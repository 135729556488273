import { Component, OnInit, Input } from "@angular/core";
import { AuthenticationHistoryRecord } from "./../../../../models";

@Component({
  selector: "roctavian-abstractions-authentication-history-grid",
  templateUrl: "./authentication-history-grid.component.html",
  styleUrls: ["./authentication-history-grid.component.scss"]
})
export class AuthenticationHistoryGridComponent implements OnInit {
  @Input() public records: AuthenticationHistoryRecord[] = [];
  constructor() {}

  public ngOnInit() {}
}
