/* eslint-disable @typescript-eslint/member-ordering */
import { Injectable, OnDestroy } from '@angular/core';
import {
  AppTranslationService,
  MessageService
} from '@roctavian-abstractions/core';
import { Outcome, PaginatedList } from '@roctavian-abstractions/web';
import { BehaviorSubject, Subscription } from 'rxjs';
import { ClearService } from '../../../../shared/interface/clear-service.interface';
import { ExternalLabTestClient } from '../clients/external-lab-test.client';
import { ExternalLabTestPagedQuery } from '../models/external-lab-test-paged-query.model';
import { ExternalLabTest } from '../models/external-lab-test.model';

@Injectable({
  providedIn: 'root',
})
export class ExternalLabTestService implements OnDestroy, ClearService {
  public externalLabTestPagedListSubject = new BehaviorSubject<PaginatedList<ExternalLabTest>>(
    new PaginatedList<ExternalLabTest>(),
  );
  public isLoadingSubject = new BehaviorSubject<boolean>(false);

  public selectedExternalLabTestSubject = new BehaviorSubject<ExternalLabTest>(null);

  private subscriptions = new Array<Subscription>();

  constructor(
    private externalLabTestClient: ExternalLabTestClient,
    private messageService: MessageService,
    private translate: AppTranslationService,
  ) {}

  ngOnDestroy() {
    this.clearSubscriptions();
  }

  private clearSubscriptions() {
    this.subscriptions.forEach(subscription => {
      subscription.unsubscribe();
    });

    this.subscriptions = new Array<Subscription>();
  }

  public setPage(pagedQuery: ExternalLabTestPagedQuery) {
    this.isLoadingSubject.next(true);

    this.subscriptions.push(
      this.externalLabTestClient.getExternalLabTestListPaged(pagedQuery).subscribe(
        outcome => {
          if (!outcome.success || outcome.failure) {
            this.handleError(outcome);
            return;
          }
          this.externalLabTestPagedListSubject.next(outcome.value);
          this.isLoadingSubject.next(false);
        },
        (error: Outcome) => {
          this.handleError(error);
        },
      ),
    );
  }

  public loadExternalLabTest(externalLabTestId: string, forceRefresh: boolean = false) {
    if (
      this.selectedExternalLabTestSubject.value &&
      this.selectedExternalLabTestSubject.value.id === externalLabTestId &&
      !forceRefresh
    ) {
      return;
    }

    this.clearSubscriptions();

    this.isLoadingSubject.next(true);

    this.subscriptions.push(
      this.externalLabTestClient.getExternalLabTest(externalLabTestId).subscribe(
        outcome => {
          if (!outcome.success || outcome.failure) {
            this.messageService.open(outcome.messages[0], 5000);
            return;
          }

          this.selectedExternalLabTestSubject.next(outcome.value);
          this.isLoadingSubject.next(false);
        },
        (error: Outcome) => {
          this.handleError(error);
        },
      ),
    );
  }

  private handleError(outcome: Outcome) {
    this.messageService.open(
      this.translate.getTranslation('Common.ErrorProcessingRequest'),
      5000,
    );
    this.isLoadingSubject.next(false);
  }

  public clearService() {
    this.externalLabTestPagedListSubject.next(new PaginatedList<ExternalLabTest>());
    this.selectedExternalLabTestSubject.next(null);
  }
}
