import { AttestationTypes } from '../enums';
import { Auditable } from './auditable.model';

export class Attestation extends Auditable {
  public id: string;
  public isCurrent: boolean;
  public hasSignature: boolean;
  public signatureDate: Date | string;
  public guardianName: string;
  public hasConsent: boolean;
  public changeReasonDescription: string;
  public attestationType: AttestationTypes;
  public stakeholderId: string;
}
