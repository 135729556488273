import { Component, OnInit } from "@angular/core";

@Component({
  selector: "roctavian-abstractions-account-settings-page",
  templateUrl: "./account-settings-page.component.html",
  styleUrls: ["./account-settings-page.component.scss"]
})
export class AccountSettingsPageComponent implements OnInit {
  constructor() {}

  public ngOnInit() {}
}
