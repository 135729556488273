import { OnDestroy, Input, SimpleChanges } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import { PrescriberDelegate, PrescriberDelegatePrescriber } from '../../models/prescriber-delegate.model';
import { PagedQuery, UtilityService } from '../../../common';
import { PaginatedList } from '@roctavian-abstractions/web';
import { BehaviorSubject, Subscription, EMPTY } from 'rxjs';
import { PrescriberDelegateService } from '../../services/prescriber-delegate.service';
import { debounceTime, distinctUntilChanged, switchMap } from 'rxjs/operators';
import { PageEvent, MatSort } from '@angular/material';
import { AppTranslationService } from '@roctavian-abstractions/core';

@Component({
  selector: 'prescriber-delegate-prescriber-list',
  templateUrl: './prescriber-delegate-prescriber-list.component.html',
  styleUrls: ['./prescriber-delegate-prescriber-list.component.scss']
})
export class PrescriberDelegatePrescriberListComponent implements OnInit, OnDestroy {
  @Input() prescriberDelegate: PrescriberDelegate;

  public pagedQuery = new PagedQuery(1, 10, null, 'id', true);

  public prescriberDelegatePrescriberPagedCollection: PaginatedList<PrescriberDelegatePrescriber>;
  public isLoading = false;
  public filterText$ = new BehaviorSubject<string>(null);
  private subscriptions = new Array<Subscription>();

  public displayedColumns = [
    'prescriberId',
    'prescriber.stakeholder.firstName',
    'prescriber.stakeholder.lastName',
    'prescriber.nationalId',
    'prescriber.stakeholder.statuses.statusType',
    'prescriber.stakeholder.statuses.addDate',
    'addDate',
    'addUser.username'
  ];

  constructor(
    public utilityService: UtilityService,
    private prescriberDelegateService: PrescriberDelegateService,
    private translate: AppTranslationService
  ) { }

  ngOnDestroy() {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
    this.prescriberDelegateService.prescriberDelegatePrescriberPagedListSubject.next(new PaginatedList<PrescriberDelegatePrescriber>());
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (
      !changes.prescriberDelegate ||
      !changes.prescriberDelegate.currentValue ||
      changes.prescriberDelegate.currentValue === changes.prescriberDelegate.previousValue
    ) {
      return;
    }

    this.refreshList();
  }

  public refreshList() {
    if (!this.prescriberDelegate) {
      return;
    }

    this.prescriberDelegateService.setPrescriberDelegatePrescriberPage(this.prescriberDelegate.id, this.pagedQuery);
  }

  ngOnInit() {
    this.subscriptions.push(
      this.filterText$
        .pipe(
          debounceTime(1000),
          distinctUntilChanged(),
          switchMap(searchText => {
            if (this.pagedQuery.filterText == searchText) {
              return EMPTY;
            }

            this.pagedQuery.filterText = searchText;
            this.pagedQuery.pageIndex = 1;
            this.refreshList();
            return EMPTY;
          })
        )
        .subscribe()
    );

    this.subscriptions.push(
      this.prescriberDelegateService.prescriberDelegatePrescriberPagedListSubject.subscribe(collection => {
        this.prescriberDelegatePrescriberPagedCollection = collection;
      })
    );

    this.subscriptions.push(
      this.prescriberDelegateService.isLoadingSubject.subscribe(isLoading => {
        this.isLoading = isLoading;
      })
    );
  }

  public onPageEvent(pageEvent: PageEvent) {
    this.pagedQuery.pageIndex = pageEvent.pageIndex + 1;
    this.pagedQuery.pageSize = pageEvent.pageSize;
    this.refreshList();
  }

  public onSortEvent(sort: MatSort) {
    switch (sort.active) {
      case 'prescriber.stakeholder.statuses.statusType':
        this.pagedQuery.sortColumn = 'prescriber.stakeholder.statuses.FirstOrDefault(x => x.IsCurrent).statusType';
        break;
      case 'prescriber.stakeholder.statuses.addDate':
        this.pagedQuery.sortColumn = 'prescriber.stakeholder.statuses.FirstOrDefault(x => x.IsCurrent).addDate';
        break;

      default:
        this.pagedQuery.sortColumn = sort.active;
        break;
    }

    this.pagedQuery.isSortDesc = sort.direction == 'desc';
    this.pagedQuery.pageIndex = 1;
    this.refreshList();
  }
}
