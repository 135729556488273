import {
  ChangeDetectionStrategy, Component, EventEmitter, Input, Output
} from '@angular/core';
import { Permission } from 'apps/roctavian-eumea-callcenter-portal/src/app/modules/identity/models';

@Component({
  selector: 'roctavian-abstractions-permission-list',
  templateUrl: './permission-list.component.html',
  styleUrls: ['./permission-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PermissionListComponent {
  @Input() public permissionGroups: Permission[][];

  @Output() public deleted = new EventEmitter<number>();
  @Output() public edited = new EventEmitter<number>();

  constructor() {}


  public handleDelete(identity: number) {
    this.deleted.emit(identity);
  }

  public handleEdit(identity: number) {
    this.edited.emit(identity);
  }

  public getGroupName(permissionGroup: Permission[]): string {
    return permissionGroup[0].group.toUpperCase();
  }
}
