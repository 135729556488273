import { DatePipe } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog, MatSort, PageEvent } from '@angular/material';
import { Router } from '@angular/router';
import { AppTranslationService, MessageService } from '@roctavian-abstractions/core';
import { Outcome, PaginatedList } from '@roctavian-abstractions/web';
import { BehaviorSubject, EMPTY, Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged, switchMap } from 'rxjs/operators';
import { PagedQuery, UtilityService } from '../../../common';
import { FileExportService } from '../../../common/services/file-export.service';
import { Patient } from '../../../patient';
import { Prescriber } from '../../../prescriber';
import { LabTestClient } from '../../clients/lab-test.client';
import { LabTest } from '../../models/lab-test.model';
import { LabTestService } from '../../services/lab-test.service';
import { ViewLabTestDialogComponent } from '../view-lab-test-dialog/view-lab-test-dialog.component';

@Component({
  selector: 'lab-test-list',
  templateUrl: './lab-test-list.component.html',
  styleUrls: ['./lab-test-list.component.scss']
})
export class LabTestListComponent implements OnInit, OnDestroy {
  public pagedQuery = new PagedQuery(1, 10, null, 'id', true);

  public filterText$ = new BehaviorSubject<string>(null);
  public labTestPagedCollection: PaginatedList<LabTest>;
  public isLoading = false;

  private exportFileName = 'LabTestExport.csv';
  public exportLabel = this.translate.getTranslation('Common.Export');

  private subscriptions = new Array<Subscription>();

  public displayedColumns = [
    'id',
    'stakeholder.statuses.statusType',
    'patientId',
    'patient.stakeholder.firstName',
    'patient.stakeholder.lastName',
    'patient.stakeholder.address.country.name',
    'prescriber.stakeholder.lastName',
    'lab.labName',
    'lab.stakeholder.address.city',
    'lab.stakeholder.address.country.name',
    'appointmentDate',
    'appointmentTime',
    'testType',
    'addUser.username',
    'addDate',
    'menu'
  ];

  constructor(
    private labTestService: LabTestService,
    private router: Router,
    public utilityService: UtilityService,
    private matDialog: MatDialog,
    private labTestClient: LabTestClient,
    private messageService: MessageService,
    private translate: AppTranslationService,
    private fileExportService: FileExportService,
    private datePipe: DatePipe
  ) {}

  private refreshList() {
    this.labTestService.setPage(this.pagedQuery);
  }

  ngOnInit() {
    this.subscriptions.push(
      this.filterText$
        .pipe(
          debounceTime(1000),
          distinctUntilChanged(),
          switchMap(searchText => {
            if (this.pagedQuery.filterText == searchText) {
              return EMPTY;
            }

            this.pagedQuery.filterText = searchText;
            this.pagedQuery.pageIndex = 1;
            this.refreshList();
            return EMPTY;
          })
        )
        .subscribe()
    );

    this.subscriptions.push(
      this.labTestService.labTestPagedListSubject.subscribe(collection => {
        this.labTestPagedCollection = collection;
      })
    );

    this.subscriptions.push(
      this.labTestService.isLoadingSubject.subscribe(isLoading => {
        this.isLoading = isLoading;
      })
    );

    this.refreshList();
  }

  ngOnDestroy() {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
    this.labTestService.labTestPagedListSubject.next(new PaginatedList<LabTest>());
  }

  public onRowSelected(labTest: LabTest) {
    this.router.navigate(['/lab-test', labTest.id]);
  }

  public viewLabTest(labTestId: string) {
    this.subscriptions.push(
      this.labTestClient.getLabTest(labTestId).subscribe(
        outcome => {
          const dialogRef = this.matDialog.open(ViewLabTestDialogComponent, {
            hasBackdrop: true,
            disableClose: true,
            data: {
              labTest: outcome.value
            },
            width: '1000px'
          });
        },
        error => {
          this.messageService.open(this.translate.getTranslation('Common.ErrorProcessingRequest'), 3000);
        }
      )
    );
  }

  public onPageEvent(pageEvent: PageEvent) {
    this.pagedQuery.pageIndex = pageEvent.pageIndex + 1;
    this.pagedQuery.pageSize = pageEvent.pageSize;
    this.refreshList();
  }

  public onSortEvent(sort: MatSort) {
    switch (sort.active) {
      case 'id':
        this.pagedQuery.sortColumn = 'stakeholder.externalId';
        break;
      case 'patientId':
        this.pagedQuery.sortColumn = 'patient.stakeholder.externalId';
        break;
      case 'stakeholder.statuses.statusType':
        this.pagedQuery.sortColumn = 'stakeholder.statuses.FirstOrDefault(x => x.IsCurrent).statusType';
        break;
      case 'appointmentDate':
        this.pagedQuery.sortColumn = 'appointmentDateTime';
        break;
      default:
        this.pagedQuery.sortColumn = sort.active;
        break;
    }

    this.pagedQuery.isSortDesc = sort.direction == 'desc';
    this.pagedQuery.pageIndex = 1;
    this.refreshList();
  }

  public downloadExportFile() {
    this.subscriptions.push(
      this.labTestClient.getExportFile().subscribe(
        data => this.fileExportService.downloadFile(data, 'text/csv', this.exportFileName),
        (error: Outcome) => {
          this.messageService.open(error.messages[0], 3000);
        }
      )
    );
  }

  public getCurrentPrescriber(patient: Patient): Prescriber {
    if (!patient || !patient.patientsPrescribers) {
      return null;
    }

    const currentPatientPrescriber = patient.patientsPrescribers.find(patientPrescriber => patientPrescriber.isCurrent);
    return currentPatientPrescriber ? currentPatientPrescriber.prescriber : null;
  }

  public getAppointmentTime(appointmentDate: Date): String {
    let result = '';
    if (this.datePipe.transform(appointmentDate, 'hh:mm a') != '12:00 AM') {
      result = this.datePipe.transform(appointmentDate, 'hh:mm a');
    }
    return result;
  }
}
