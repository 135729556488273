import { Component, OnInit, OnDestroy } from '@angular/core';
import { Institution } from '../../models';
import { FormGroup, FormBuilder } from '@angular/forms';
import { InstitutionClient } from '../../clients';
import { MessageService } from '@roctavian-abstractions/core';
import { Router } from '@angular/router';
import { AppTranslationService } from '@roctavian-abstractions/core';
import { Subscription } from 'rxjs';

@Component({
  selector: 'add-institution',
  templateUrl: './add-institution.component.html',
  styleUrls: ['./add-institution.component.scss']
})
export class AddInstitutionComponent implements OnInit, OnDestroy {
  public form: FormGroup;
  public errorMessages: string[];
  public isProcessing = false;

  private subscriptions = new Array<Subscription>();

  constructor(
    private formBuilder: FormBuilder,
    private client: InstitutionClient,
    private messageService: MessageService,
    private router: Router,
    private translate: AppTranslationService
  ) { }

  public ngOnInit() {
    this.form = this.formBuilder.group({});
  }

  ngOnDestroy() {
    this.subscriptions.forEach(subscription => {
      subscription.unsubscribe();
    });
  }

  public submit() {
    this.errorMessages = [];

    this.form.markAllAsTouched();
    if (this.form.invalid) {
      return;
    }

    this.isProcessing = true;
    this.subscriptions.push(this.client.createInstitution(this.getModel()).subscribe(
      outcome => {
        this.isProcessing = false;
        if (outcome.failure) {
          this.errorMessages = outcome.messages;
          return;
        }

        this.messageService.open(this.translate.getTranslation('Institution.AddInstitution.SuccessfullySaved'), 5000);
        this.router.navigate(['/institution']);
      },
      error => {
        this.isProcessing = false;
        this.errorMessages = [this.translate.getTranslation('Common.ErrorProcessingRequest')];
      }
    ));
  }

  public getModel(): Institution {
    const formValue = this.form.getRawValue();
    return formValue;
  }
}
