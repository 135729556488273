import { HttpClient, HttpEvent } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { scan, switchMap } from 'rxjs/operators';

import { ContentTypes, Endpoints } from '@app/core/enums';
import { isHttpProgressEvent, isHttpResponse } from '@app/core/types';

import { contentTypeDescriptions } from '../constants';
import { DownloadDocument, FileUpload, UploadDocument } from '../models';

const ENDPOINT = Endpoints.documentStorage;

/**
 * Manage downloading and uploading documents to document storage.
 *
 * @export
 * @class DocumentStorageService
 */
@Injectable({
  providedIn: 'root',
})
export class DocumentStorageService {
  constructor(private readonly http: HttpClient) {}

  /**
   *
   *
   * Upload document to document storage.
   *
   *
   *
   * @param document Upload document properties.
   * @returns
   */
  upload(document: UploadDocument): Observable<FileUpload> {
    const data = new FormData();
    const initialState: FileUpload = { state: 'PENDING', progress: 0 };

    data.append('file', document.file, document.fileName);
    data.append('relativePath', document.relativePath);
    data.append('documentType', '' + document.documentType);

    return this.http
      .put(ENDPOINT, data, {
        reportProgress: true,
        observe: 'events',
      })
      .pipe(
        scan(this.calculateState, initialState)
      );
  }

  /**
   *
   *
   * Download a document from document storage.
   *
   *
   *
   * @param document Download document properties.
   * @return The object URL for the document.
   */
  download(document: DownloadDocument): Observable<string> {
    return this.http.post(ENDPOINT, document, { responseType: 'arraybuffer' }).pipe(
      switchMap(data => {
        const url = this.getObjectUrl(data, document.contentType);
        return of(url);
      })
    );
  }

  private calculateState(upload: FileUpload, event: HttpEvent<unknown>): FileUpload {
    if (isHttpProgressEvent(event)) {
      return {
        progress: event.total
          ? Math.round((100 * event.loaded) / event.total)
          : upload.progress,
        state: 'IN_PROGRESS',
      };
    }

    if (isHttpResponse(event)) {
      return {
        progress: 100,
        state: 'DONE',
      };
    }

    return upload;
  }

  private getObjectUrl(data: ArrayBuffer, contentType: ContentTypes): string {
    const blob = new Blob([data], {
      type: contentTypeDescriptions.get(contentType),
    });
    const url = URL.createObjectURL(blob);
    return url;
  }
}
