import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";

@Component({
  selector: "roctavian-abstractions-confirm-email-address-page",
  templateUrl: "./confirm-email-address-page.component.html",
  styleUrls: ["./confirm-email-address-page.component.scss"]
})
export class ConfirmEmailAddressPageComponent implements OnInit {
  public username: string;
  public token: string;

  constructor(private route: ActivatedRoute, private router: Router) {}

  public ngOnInit() {
    // Grab the confirmation token and username which should be provided in the url:
    this.route.queryParams.subscribe(params => (this.username = params["username"]));
    this.route.queryParams.subscribe(params => (this.token = params["token"]));
  }
}
