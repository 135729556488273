import { Component, OnInit, Input } from '@angular/core';
import { AppTranslationService } from '@roctavian-abstractions/core';
import { LabUser } from '../../models/labuser.model';

@Component({
  selector: 'view-labuser',
  templateUrl: './view-lab-user.component.html',
  styleUrls: ['./view-lab-user.component.scss']
})
export class ViewLabUserComponent implements OnInit {
  @Input() public labUser: LabUser;
  @Input() public title = this.translate.getTranslation('LabUser.ViewLabUser.Title');
  @Input() public showTitle = true;
  @Input() public labUserIdLabel = this.translate.getTranslation('LabUser.ViewLabUser.Id');

  constructor(private translate: AppTranslationService) { }

  ngOnInit() { }
}
