import { Component, OnInit, Inject } from '@angular/core';
import { PrescriberDelegate } from '../../models/prescriber-delegate.model';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';

@Component({
  selector: 'roctavian-eumea-callcenter-portal-view-prescriber-delegate-dialog',
  templateUrl: './view-prescriber-delegate-dialog.component.html',
  styleUrls: ['./view-prescriber-delegate-dialog.component.scss']
})
export class ViewPrescriberDelegateDialogComponent {
  public prescriberDelegate: PrescriberDelegate;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public reference: MatDialogRef<ViewPrescriberDelegateDialogComponent>
  ) {
    this.prescriberDelegate = data.prescriberDelegate;
  }
}
