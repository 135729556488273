import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { Endpoints } from '@app/core/enums';
import { UtilityService } from '@app/stakeholder/services';
import { Outcome, PaginatedList, ValueOutcome } from '@roctavian-abstractions/web';

import { ExternalLabTestPagedQuery } from '../models/external-lab-test-paged-query.model';
import { ExternalLabTest } from '../models/external-lab-test.model';

@Injectable({
  providedIn: 'root',
})
export class ExternalLabTestClient {
  constructor(
    private readonly http: HttpClient,
    private readonly utilityService: UtilityService
  ) {}

  getExternalLabTestListPaged(pagedQuery: ExternalLabTestPagedQuery): Observable<ValueOutcome<PaginatedList<ExternalLabTest>>> {
    const url = `${Endpoints.externalLabTest}/list`;
    return this.http.get<ValueOutcome<PaginatedList<ExternalLabTest>>>(url, {
      params: this.utilityService.toHttpParams(pagedQuery),
    });
  }

  createExternalLabTest(externalLabTest: ExternalLabTest): Observable<Outcome> {
    return this.http.post<Outcome>(Endpoints.externalLabTest, externalLabTest);
  }

  updateExternalLabTest(externalLabTest: ExternalLabTest): Observable<Outcome> {
    return this.http.put<Outcome>(Endpoints.externalLabTest, externalLabTest);
  }

  getExternalLabTest(externalLabTestId: string): Observable<Outcome> {
    const url = `${Endpoints.externalLabTest}/${externalLabTestId}`;
    return this.http.get<Outcome>(url);
  }

  deleteExternalLabTest(externalLabTestId: string): Observable<Outcome> {
    const url = `${Endpoints.externalLabTest}/${externalLabTestId}`;
    return this.http.delete<Outcome>(url);
  }

  getExportFile() {
    const url = `${Endpoints.externalLabTest}/export`;
    return this.http.get(url, { responseType: 'blob' });
  }

  downloadShipmentLabel(id: string): Observable<Outcome>{
    const url = `${Endpoints.externalLabTest}/${id}`;
    return this.http.get<Outcome>(url);
  }
}
