export class PasswordConfigModel {
  /**
   * Indicates the minimum length a password must have.
   */
  public requiredLength: number;

  /**
   * Indicates whether a password must include a digit.
   */
  public requireDigit: boolean;

  /**
   * Indicates whether a password must include a lowercase letter.
   */
  public requireLowercase: boolean;

  /**
   * Indicates whether a password must include an uppercase letter.
   */
  public requireUppercase: boolean;

  /**
   * Indicates whether a password must include a non-alphanumeric character.
   */
  public requireNonAlphanumeric: boolean;

  /**
   * The active password rules.
   */
  public rules: PasswordRule[];
}

export class PasswordRule {
  /**
   * The password rule key.
   */
  public ruleKey: string;

  /**
   * The password rule description.
   */
  public ruleDescription: string;
}
