import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { environment } from '@app/environments';
import { TranslateModule } from '@ngx-translate/core';
import {
  CoreDirectivesModule,
  CoreModule,
  PipeModule,
} from '@roctavian-abstractions/core';
import { NgpSortModule } from 'ngp-sort-pipe';
import { NgxMaskModule } from 'ngx-mask';
import { IdentityModule } from '../identity/identity.module';
import { FileUploadComponent } from './components';
import { httpInterceptorProviders } from './interceptors';
import { LoginPageComponent } from './pages/login-page/login-page.component';
import { DocumentTypeStringPipe } from './pipes';

@NgModule({
  declarations: [DocumentTypeStringPipe, FileUploadComponent, LoginPageComponent],
  imports: [
    CommonModule,
    NgxMaskModule.forRoot(),
    PipeModule,
    CoreDirectivesModule,
    CoreModule.forRoot(environment),
    NgpSortModule,
    IdentityModule,
    MatProgressBarModule,
  ],
  exports: [
    CoreDirectivesModule,
    CoreModule,
    DocumentTypeStringPipe,
    FileUploadComponent,
    NgpSortModule,
    NgxMaskModule,
    PipeModule,
    TranslateModule,
  ],
  providers: [httpInterceptorProviders],
})
export class SharedModule {}
