import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import {
  PrescriberListPageComponent,
  AddPrescriberPageComponent,
  PrescriberDetailMasterPageComponent,
  PrescriberSummaryPageComponent,
  PrescriberAttestationPageComponent,
  PrescriberStatusPageComponent,
  PrescriberEditPageComponent,
  PrescriberDelegateListPageComponent,
  PrescriberPatientListPageComponent,
  PrescriberLabListPageComponent
} from './pages';
import { PrescriberLabUserDelegateListPageComponent } from './pages/prescriber-lab-user-delegate-list-page/prescriber-lab-user-delegate-list-page.component';
import { RoctavianAuthenticationGuard } from '../../../shared/guards/roctavian-authentication.guard';
import { PrescriberDocumentsPageComponent } from './pages/prescriber-documents-page/prescriber-documents-page.component';

const ROUTES = [
  {
    path: 'prescriber',
    canActivate: [RoctavianAuthenticationGuard],
    children: [
      { path: '', redirectTo: 'list', pathMatch: 'full' },
      { path: 'list', component: PrescriberListPageComponent },
      { path: 'add', component: AddPrescriberPageComponent },
      {
        path: ':id',
        component: PrescriberDetailMasterPageComponent,
        children: [
          { path: '', redirectTo: 'summary', pathMatch: 'full' },
          { path: 'summary', component: PrescriberSummaryPageComponent },
          { path: 'attestation', component: PrescriberAttestationPageComponent },
          { path: 'status', component: PrescriberStatusPageComponent },
          { path: 'edit', component: PrescriberEditPageComponent },
          { path: 'patient', component: PrescriberPatientListPageComponent },
          { path: 'lab', component: PrescriberLabListPageComponent },
          { path: 'document', component: PrescriberDocumentsPageComponent},
          { path: 'prescriber-delegate', component: PrescriberDelegateListPageComponent },
          { path: 'lab-user-delegate', component: PrescriberLabUserDelegateListPageComponent }
        ]
      }
    ]
  }
];

@NgModule({
  imports: [CommonModule, RouterModule.forChild(ROUTES)],
  exports: [RouterModule]
})
export class PrescriberRoutingModule { }
