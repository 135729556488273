import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MaterialModule, CoreModule } from '@roctavian-abstractions/core';
import { NewTaskComponent } from './components/new-task/new-task.component';
import { NewTaskFormComponent } from './components/new-task-form/new-task-form.component';
import { TaskListComponent } from './components/task-list/task-list.component';
import { TaskDetailsComponent } from './components/task-details/task-details.component';
import { AddTaskActionFormComponent } from './components/add-task-action-form/add-task-action-form.component';
import { TaskActionListComponent } from './components/task-action-list/task-action-list.component';
import { TaskDetailsFormComponent } from './components/task-details-form/task-details-form.component';
import { ParseTaskActionPipe } from './pipes/parse-task-action.pipe';
import { PipeModule } from '../../pipes/pipe.module';

@NgModule({
  imports: [CommonModule, CoreModule, RouterModule, MaterialModule, FormsModule, ReactiveFormsModule, PipeModule],
  declarations: [NewTaskComponent,
    NewTaskFormComponent,
    TaskListComponent,
    TaskDetailsComponent,
    AddTaskActionFormComponent,
    TaskActionListComponent,
    ParseTaskActionPipe,
    TaskDetailsFormComponent],
  exports: [NewTaskComponent,
    NewTaskFormComponent,
    TaskListComponent,
    TaskDetailsComponent,
    AddTaskActionFormComponent,
    TaskActionListComponent,
    ParseTaskActionPipe,
    TaskDetailsFormComponent]
})
export class TaskModule {}
