import { Injectable } from '@angular/core';
import { CanActivate, Router, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthenticationService } from '../../identity/services/authentication.service';

@Injectable({
  providedIn: 'root'
})
export class RoctavianAuthenticationGuard implements CanActivate {

  constructor(private router: Router, private service: AuthenticationService) { }

  public canActivate(): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
      if (this.service.authenticated) {
        if(this.service.claimsPrincipal.hasRole('Administrator') || this.service.claimsPrincipal.hasRole('SystemAdministrator')) {
          return true;
        }
      }
        this.router.navigate(['/log-in']);
        return false;
    }
}
