import { Auditable } from '../../common/models/auditable.model';
import { Lab } from '../../lab/models/lab.model';
import { Prescriber } from './prescriber.model';

export class PrescriberLab extends Auditable {
  public id: string;
  public prescriberId: string;
  public prescriber: Prescriber;
  public lab: Lab;
  public labId: string;
  public isPrescriberOffice: boolean;
}
