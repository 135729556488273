import { Component, OnInit } from "@angular/core";

@Component({
  selector: "roctavian-abstractions-security-question-group",
  templateUrl: "./security-question-group.component.html",
  styleUrls: ["./security-question-group.component.scss"]
})
export class SecurityQuestionGroupComponent implements OnInit {
  constructor() {}

  public ngOnInit() {}
}
