import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, FormGroupDirective, Validators } from '@angular/forms';
import { AppTranslationService } from '@roctavian-abstractions/core';
import { StakeholderAttestationTypes } from '../../constants';
import { StakeholderTypes } from '../../enums';
import { AttestationService } from '../../services/attestation.service';

@Component({
  selector: 'attestation-form',
  templateUrl: './attestation-form.component.html',
  styleUrls: ['./attestation-form.component.css']
})
export class AttestationFormComponent implements OnInit, OnChanges {
  @Input() title = this.translate.getTranslation('Common.AttestationForm.Title');
  @Input() showTitle = false;

  @Input() hasSignatureLabel = this.translate.getTranslation('Common.AttestationForm.Signature');
  @Input() showHasSignature = true;

  @Input() signatureDateLabel = this.translate.getTranslation('Common.AttestationForm.SignatureDate');
  @Input() signatureDateLabelSignature = this.translate.getTranslation('Common.Attestation.SignatureDate');

  @Input() showSignatureDate = true;

  @Input() guardianNameLabel = this.translate.getTranslation('Common.AttestationForm.GuardianName');
  @Input() showGuardianName = false;

  @Input() hasConsentTitle = this.translate.getTranslation('Common.Stakeholder.Attestation.HasConsentTitle');
  @Input() showHasConsentTitle = false;
  @Input() hasConsentLabel = this.translate.getTranslation('Common.AttestationForm.HasConsent');
  @Input() showHasConsent = false;

  @Input() changeReasonDescriptionLabel = this.translate.getTranslation(
    'Common.AttestationForm.ChangeReasonDescription'
  );
  @Input() showChangeReasonDescription = false;

  @Input() attestationTypeLabel = this.translate.getTranslation('Common.AttestationForm.AttestationType');
  @Input() showAttestationType = false;

  @Input() public attestationFormGroupName = 'attestation';
  @Input() public areFieldsRequired = false;
  @Input() public areFieldsSoftRequired = false;

  @Input() public stakeholderType: StakeholderTypes;

  public attestationTypes = StakeholderAttestationTypes;

  public form: FormGroup;
  public currentDate = new Date();

  constructor(
    private formBuilder: FormBuilder,
    public parentFormGroupDirective: FormGroupDirective,
    private translate: AppTranslationService,
    private attService: AttestationService
  ) { }

  ngOnInit() {
    this.buildForm();
    this.preselectAttestationType(this.stakeholderType);
    this.attService.signatureDateSubject.next(null);
  }

  private buildForm() {
    this.form = this.formBuilder.group({
      hasSignature: new FormControl(false, []),
      guardianName: new FormControl(null, []),
      signatureDate: new FormControl(null, []),
      attestationType: new FormControl(null, []),
      hasConsent: new FormControl(false, [])
    });

    this.parentFormGroupDirective.form.addControl(this.attestationFormGroupName, this.form);
    this.form.setParent(this.parentFormGroupDirective.form);

    this.refreshValidations(this.areFieldsRequired);
  }

  onDateSelected(event) {
      if (event.value && event.value instanceof Date) {
      let newDate = new Date(event.value);
      let currentTime = new Date();
      newDate.setHours(currentTime.getHours());
      newDate.setMinutes(currentTime.getMinutes());
      newDate.setSeconds(currentTime.getSeconds());
      newDate.setMilliseconds(currentTime.getMilliseconds());   
      newDate.setMinutes(newDate.getMinutes() + newDate.getTimezoneOffset());
      this.attService.signatureDateSubject.next(newDate);
    }
  }


  public ngOnChanges(changes: SimpleChanges): void {

    if (!this.form || !this.form.controls) {

      return;
    }

    if (changes.areFieldsRequired && changes.areFieldsRequired.currentValue !== null) {
      this.refreshValidations(changes.areFieldsRequired.currentValue);
    }

    if (changes.stakeholderType && changes.stakeholderType.currentValue !== null) {
      this.preselectAttestationType(changes.stakeholderType.currentValue);
    }
  }

  private preselectAttestationType(stakeholderType: StakeholderTypes) {
    const attestationTypes = this.attestationTypes.filter(a => a.stakeholderType == stakeholderType);
    if (attestationTypes.length === 1) {
      this.form.get('attestationType').setValue(attestationTypes[0].attestationType);
    }
  }

  private refreshValidations(areFieldsRequired: boolean) {
    this.form.get('hasSignature').clearValidators();
    this.form.get('signatureDate').clearValidators();
    this.form.get('attestationType').clearValidators();
    this.form.get('guardianName').clearValidators();
    this.form.get('hasConsent').clearValidators();

    if (this.showAttestationType) {
      this.form.get('attestationType').setValidators(Validators.required);
    }

    if (!areFieldsRequired) {
      return;
    }

    if (this.showHasSignature) {
      this.form.get('hasSignature').setValidators(Validators.required);
    }

    if (this.showSignatureDate) {
      this.form.get('signatureDate').setValidators(Validators.required);
    }

    if (this.showGuardianName) {
      this.form.get('guardianName').setValidators(Validators.required);
    }

    if (this.showHasConsent) {
      this.form.get('hasConsent').setValidators(Validators.required);
    }
  }
}
