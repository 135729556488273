import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { NgModule } from '@angular/core';
import {
  InstitutionListPageComponent,
  InstitutionAddPageComponent,
  InstitutionDetailMasterPageComponent,
  InstitutionSummaryPageComponent,
  InstitutionEditPageComponent
} from './pages';
import { RoctavianAuthenticationGuard } from '../../../shared/guards/roctavian-authentication.guard';

const ROUTES = [
  {
    path: 'institution',
    canActivate: [RoctavianAuthenticationGuard],
    children: [
      { path: '', redirectTo: 'list', pathMatch: 'full' },
      { path: 'list', component: InstitutionListPageComponent },
      { path: 'add', component: InstitutionAddPageComponent },
      {
        path: ':id',
        component: InstitutionDetailMasterPageComponent,
        children: [
          { path: '', redirectTo: 'summary', pathMatch: 'full' },
          { path: 'summary', component: InstitutionSummaryPageComponent },
          { path: 'edit', component: InstitutionEditPageComponent }
        ]
      }
    ]
  }
];

@NgModule({
  imports: [CommonModule, RouterModule.forChild(ROUTES)],
  exports: [RouterModule]
})
export class InstitutionRoutingModule { }
