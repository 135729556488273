import { Component, Input } from "@angular/core";

@Component({
  selector: "roctavian-abstractions-google-button",
  templateUrl: "./google-button.component.html",
  styleUrls: ["./google-button.component.scss"]
})
export class GoogleButtonComponent {
  @Input() public buttonText = "Sign in with Google";

  constructor() {}
}
