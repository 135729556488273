import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { WorkgroupModel } from '../../../../models';
import { WorkgroupClient } from './../../../../clients/workgroup.client';

@Component({
  selector: 'roctavian-abstractions-new-workgroup',
  templateUrl: './new-workgroup.component.html',
  styleUrls: ['./new-workgroup.component.scss']
})
export class NewWorkgroupComponent {
  /**
   * Initializes a new instance of the component.
   *
   * @param workgroupClient The workgroup client.
   * @param router The router.
   */
  constructor(private workgroupClient: WorkgroupClient, private router: Router) {}

  /**
   * Handles the submission of the workgroup form.
   *
   * @param model The workgroup model.
   */
  public handleSubmission(model: WorkgroupModel) {
    this.workgroupClient.createWorkgroup(model).subscribe(result => {
      const workgroupIdentity = result.value;
      this.router.navigate([`/tasks/workgroup/${workgroupIdentity}`]);
    });
  }
}
