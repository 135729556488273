import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'roctavian-eumea-callcenter-portal-institution-list-page',
  templateUrl: './institution-list-page.component.html',
  styleUrls: ['./institution-list-page.component.scss']
})
export class InstitutionListPageComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
