import { Component, OnInit } from "@angular/core";
import { IdentityConfigClient } from "../../clients/identity-config.client";

@Component({
  selector: "roctavian-abstractions-forgot-password-page",
  templateUrl: "./forgot-password-page.component.html",
  styleUrls: ["./forgot-password-page.component.scss"]
})
export class ForgotPasswordPageComponent implements OnInit {
  public loading = true;
  public showSuccessMessage = false;
  public requireSecurityQuestions = false;
  public enableRecaptcha = false;

  constructor(private client: IdentityConfigClient) {}

  public ngOnInit() {
    this.client.getIdentityConfig().subscribe(config => {
      this.loading = false;
      this.requireSecurityQuestions = config.requireSecurityQuestionsAnswered;
      this.enableRecaptcha = config.enableRecaptcha;
    });
  }

  public handleSuccessfulSubmission() {
    this.showSuccessMessage = true;
  }
}
