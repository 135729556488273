import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ConfigService } from '@roctavian-abstractions/core';
import { Outcome, PaginatedList } from '@roctavian-abstractions/web';
import { BehaviorSubject, Observable } from 'rxjs';
import { CreateUserModel, SimplifiedUser } from '../models';
import { UpdateUserModel } from '../models/update-user.model';

/**
 * A client used to communicate with the users API.
 */
@Injectable({
  providedIn: 'root'
})
export class UserClient {

  public isLoadingSubject = new BehaviorSubject<boolean>(false);
  /**
   * Initializes a new instance of the user client.
   *
   * @param client The http client.
   */
  constructor(private client: HttpClient, private configService: ConfigService) { }

  /**
   * Creates a new user in the application.
   *
   * @param model The create user model.
   * @returns An outcome indicating whether the user was created successfully.
   */
  public createUser(model: CreateUserModel): Observable<Outcome> {
    return this.client.post<Outcome>(`${this.configService.get('apiUrl')}/identity/user`, model);
  }

  /**
   * Updates a user in the application.
   *
   * @param model The edit user model.
   * @returns An outcome indicating whether the user was updated successfully.
   */
   public updateUser(model: UpdateUserModel): Observable<Outcome> {
    return this.client.put<Outcome>(`${this.configService.get('apiUrl')}/identity/user`, model);
  }

  /**
   * Returns the user with the specified identity.
   *
   * @param identity The user identity.
   * @returns The user if they exist.
   */
  public getUser(identity: string): Observable<SimplifiedUser> {
    return this.client.get<SimplifiedUser>(`${this.configService.get('apiUrl')}/identity/user/${identity}`);
  }

  /**
   * Returns a paged collection of simplified user objects.
   *
   * @param page The requested page.
   * @param size The requested page size.
   * @returns A paged collection of simplified user records.
   */
  public getUsersPaged(page: number, size: number, filterText: string): Observable<PaginatedList<SimplifiedUser>> {
    return this.client.get<PaginatedList<SimplifiedUser>>(
      `${this.configService.get('apiUrl')}/identity/user?pageIndex=${page}&pageSize=${size}&filterText=${filterText}`
    );
  }

  /**
   * Locks the user with the specified identity.
   *
   * @param identity The user identity.
   * @returns An observable containing an outcome indicating whether the user was locked.
   */
  public lockUser(identity: string): Observable<Outcome> {
    return this.client.post<Outcome>(`${this.configService.get('apiUrl')}/identity/user/${identity}/lock`, {});
  }

  /**
   * Unlocks the user with the specified identity.
   *
   * @param identity The user identity.
   * @returns An observable.
   */
  public unlockUser(identity: string): Observable<any> {
    return this.client.post(`${this.configService.get('apiUrl')}/identity/user/${identity}/unlock`, {});
  }

  /**
   * Initiates the password reset process for the
   * user with the specified identity.
   *
   * @param identity The user identity.
   * @returns An observable.
   */
  public initiateReset(identity: string): Observable<any> {
    return this.client.post(`${this.configService.get('apiUrl')}/identity/user/${identity}/reset`, {});
  }


  reSendInvite(email: string): Observable<any> {
    const url = `${this.configService.get('apiUrl')}/api/user-messages/invite`;
    return this.client.get<Outcome>(url,{params: {email}});
  }
}
