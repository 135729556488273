import { DatePipe } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog, MatSort, PageEvent } from '@angular/material';
import { Router } from '@angular/router';
import { AppTranslationService, MessageService } from '@roctavian-abstractions/core';
import { Outcome, PaginatedList } from '@roctavian-abstractions/web';
import { BehaviorSubject, EMPTY, Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged, switchMap } from 'rxjs/operators';
import { UtilityService } from '../../../common';
import { FileExportService } from '../../../common/services/file-export.service';
import { ExternalLabTestClient } from '../../clients/external-lab-test.client';
import { ExternalLabTestPagedQuery } from '../../models/external-lab-test-paged-query.model';
import { ExternalLabTest } from '../../models/external-lab-test.model';
import { ExternalLabTestService } from '../../services/external-lab-test.service';
import { DeleteExternalLabTestDialogComponent } from '../delete-external-lab-test-dialog/delete-external-lab-test-dialog.component';
import { ViewExternalLabTestDialogComponent } from '../view-external-lab-test-dialog/view-external-lab-test-dialog.component';

@Component({
  selector: 'external-lab-test-list',
  templateUrl: './external-lab-test-list.component.html',
  styleUrls: ['./external-lab-test-list.component.scss']
})
export class ExternalLabTestListComponent implements OnInit, OnDestroy {
  public pagedQuery = new ExternalLabTestPagedQuery(1, 10, null, 'id', true, null, null, null);

  public filterText$ = new BehaviorSubject<string>(null);
  public externalLabTestPagedCollection: PaginatedList<ExternalLabTest>;
  public isLoading = false;

  private exportFileName = 'ExternalLabTestExport.csv';
  public exportLabel = this.translate.getTranslation('Common.Export');

  private subscriptions = new Array<Subscription>();

  public displayedColumns = [
    'id',
    'stakeholder.address.country.name',
    'ageRange',
    'testResult',
    'testType',
    'addUser.username',
    'addDate',
    'menu'
  ];

  constructor(
    private externalLabTestService: ExternalLabTestService,
    private router: Router,
    public utilityService: UtilityService,
    private matDialog: MatDialog,
    private externalLabTestClient: ExternalLabTestClient,
    private messageService: MessageService,
    private translate: AppTranslationService,
    private fileExportService: FileExportService,
    private datePipe: DatePipe
  ) {}

  private refreshList() {
    this.externalLabTestService.setPage(this.pagedQuery);
  }

  ngOnInit() {
    this.subscriptions.push(
      this.filterText$
        .pipe(
          debounceTime(1000),
          distinctUntilChanged(),
          switchMap(searchText => {
            if (this.pagedQuery.filterText === searchText) {
              return EMPTY;
            }

            this.pagedQuery.filterText = searchText;
            this.pagedQuery.pageIndex = 1;
            this.refreshList();
            return EMPTY;
          })
        )
        .subscribe()
    );

    this.subscriptions.push(
      this.externalLabTestService.externalLabTestPagedListSubject.subscribe(collection => {
        this.externalLabTestPagedCollection = collection;
      })
    );

    this.subscriptions.push(
      this.externalLabTestService.isLoadingSubject.subscribe(isLoading => {
        this.isLoading = isLoading;
      })
    );

    this.refreshList();
  }

  ngOnDestroy() {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
    this.externalLabTestService.externalLabTestPagedListSubject.next(new PaginatedList<ExternalLabTest>());
  }

  public onRowSelected(externalLabTest: ExternalLabTest) {
    this.router.navigate(['/external-lab-test', externalLabTest.id]);
  }

  public viewExternalLabTest(externalLabTestId: string) {
    this.subscriptions.push(
      this.externalLabTestClient.getExternalLabTest(externalLabTestId).subscribe(
        outcome => {
          const dialogRef = this.matDialog.open(ViewExternalLabTestDialogComponent, {
            hasBackdrop: true,
            disableClose: true,
            data: {
              externalLabTest: outcome.value
            },
            width: '1000px'
          });
        },
        error => {
          this.messageService.open(this.translate.getTranslation('Common.ErrorProcessingRequest'), 3000);
        }
      )
    );
  }

  public onPageEvent(pageEvent: PageEvent) {
    this.pagedQuery.pageIndex = pageEvent.pageIndex + 1;
    this.pagedQuery.pageSize = pageEvent.pageSize;
    this.refreshList();
  }

  public onSortEvent(sort: MatSort) {
    switch (sort.active) {
      case 'id':
        this.pagedQuery.sortColumn = 'stakeholder.externalId';
        break;
      default:
        this.pagedQuery.sortColumn = sort.active;
        break;
    }

    this.pagedQuery.isSortDesc = sort.direction === 'desc';
    this.pagedQuery.pageIndex = 1;
    this.refreshList();
  }

  public downloadExportFile() {
    this.subscriptions.push(
      this.externalLabTestClient.getExportFile().subscribe(
        data => this.fileExportService.downloadFile(data, 'text/csv', this.exportFileName),
        (error: Outcome) => {
          this.messageService.open(error.messages[0], 3000);
        }
      )
    );
  }

  public deleteExternalLabTest(externalLabTest: ExternalLabTest) {
    const dialogRef = this.matDialog.open(DeleteExternalLabTestDialogComponent, {
      hasBackdrop: true,
      disableClose: true,
      data: {
        externalLabTest
      },
      width: '800px'
    });

    this.subscriptions.push(
      dialogRef.componentInstance.externalLabTestDeleted.subscribe(() => {
        this.refreshList();
      })
    );
  }
}
