import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject } from 'rxjs';
import { mergeMap, takeUntil } from 'rxjs/operators';
import { RolesClient } from '../../../identity/clients/roles.client';
import { Role } from '../../../identity/models';

@Component({
  selector: 'roctavian-abstractions-edit-role-page',
  templateUrl: './edit-role-page.component.html',
  styleUrls: ['./edit-role-page.component.scss'],
})
export class EditRolePageComponent implements OnInit {
  public role: Role;
  public unsubscribe: Subject<boolean> = new Subject<boolean>();

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private client: RolesClient
  ) {}

  public ngOnInit() {
    this.route.params
      .pipe(
        takeUntil(this.unsubscribe),
        mergeMap(params => {
          const identity = params['identity'];
          return this.client.getRole(identity);
        })
      )
      .subscribe(role => {
        this.role = role;
      });
  }

  public handleSuccessfulUpdate() {
    this.router.navigate(['/administration/roles']);
  }
}
