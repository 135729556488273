import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'roctavian-eumea-callcenter-portal-labuser-list-page',
  templateUrl: './labuser-list-page.component.html',
  styleUrls: ['./labuser-list-page.component.scss']
})
export class LabUserListPageComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
