import { Subscription } from 'rxjs';
import { Component, OnDestroy } from '@angular/core';
import { Patient } from '../../models';
import { PatientService } from '../../services';

@Component({
  selector: 'roctavian-eumea-callcenter-portal-patient-summary-page',
  templateUrl: './patient-summary-page.component.html',
  styleUrls: ['./patient-summary-page.component.css']
})
export class PatientSummaryPageComponent implements OnDestroy {
  public patient: Patient;
  private subscriptions = new Array<Subscription>();

  constructor(private patientService: PatientService) {
    this.subscriptions.push(this.patientService.selectedPatientSubject.subscribe(patient => {
      this.patient = patient;
    }));
  }

  ngOnDestroy() {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }
}
