import { Component } from '@angular/core';

@Component({
  selector: 'roctavian-abstractions-task-dashboard-page',
  templateUrl: './task-dashboard-page.component.html',
  styleUrls: ['./task-dashboard-page.component.scss']
})
export class TaskDashboardPageComponent {
  constructor() {}
}
