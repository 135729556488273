export class Claim {
  /**
   * The claim type.
   */
  public type: string;
  /**
   * The claim value.
   */
  public value: string;
}
