import { Attestation, Address } from '../../common';
import { Lab } from './lab.model';

export class AuthorizedRepresentative {
  public id: string;
  public labUser: LabUser;
  public lab: Lab;
}

export class LabUser {
  public id: string;
  public stakeholder: LabUserStakeholder;
  public isAuthorizedRepresentative?: boolean;
}

export class LabUserStakeholder {
  public id: string;
  public firstName: string;
  public middleName: string;
  public lastName: string;
  public email: string;
  public suffix: string;
  public statuses: any;
  public address: Address;
  public attestations: Attestation[];
  public notes: string;
  public phone: string;
  public fax: string;
  public alternatePhone: string;
  public user: any;
  public stakeholderType: any;
}
