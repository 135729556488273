import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import {
  ChangeDetectionStrategy, ChangeDetectorRef,
  Component, EventEmitter, Input,
  OnDestroy,
  OnInit,
  Output
} from '@angular/core';
import { AppTranslationService, MessageService } from '@roctavian-abstractions/core';
import { Outcome } from '@roctavian-abstractions/web';
import { UserClient } from 'apps/roctavian-eumea-callcenter-portal/src/app/modules/identity/clients/user.client';
import { User } from 'apps/roctavian-eumea-callcenter-portal/src/app/modules/identity/models';
import { Subject, Subscription } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'roctavian-abstractions-user-context-menu',
  templateUrl: './user-context-menu.component.html',
  styleUrls: ['./user-context-menu.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UserContextMenuComponent implements OnDestroy, OnInit {
  /**
   * The user.
   */
  @Input()
  public user: User;

  /**
   * Event that is emitted when the lock user option is selected.
   */
  @Output()
  public locked = new EventEmitter();

  /**
   * Event that is emitted when the unlock user option is selected.
   */
  @Output()
  public unlocked = new EventEmitter();

  /**
   * Event that is emitted when the reset password option is selected.
   */
  // eslint-disable-next-line @angular-eslint/no-output-native
  @Output()
  public reset = new EventEmitter();

  /**
   * Whether or not the menu is collapsed.
   */
  public collapsed: boolean;

  /**
   * Subject used to unsubscribe from all subscriptions.
   */
  private unsubscribe: Subject<boolean> = new Subject<boolean>();
  private subscriptions = new Array<Subscription>();

  constructor(
    private breakpointObserver: BreakpointObserver,
    private changeDetector: ChangeDetectorRef,
    private client: UserClient,
    private messageService: MessageService,
    private translate: AppTranslationService
  ) {}

  public ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
    this.clearSubscriptions();
  }

  public ngOnInit() {
    this.user.locked = this.user.lockoutEnd != null;
    this.handleResize();
  }

  public handleLock() {
    this.locked.emit();
  }

  public handleUnlock() {
    this.unlocked.emit();
  }

  public handleReset() {
    this.reset.emit();
  }

  public resendInvite() {
    this.client.isLoadingSubject.next(true);
    this.subscriptions.push(
      this.client.reSendInvite(this.user.email).subscribe(
        outcome => {
          if (outcome.success || outcome.failure) {
            if (outcome.messages.length > 0){
              const message = `${outcome.messages[0]}`;
              this.messageService.open(message, 5000);
            }
            else {
              this.messageService.open('Invite sent', 5000);
            }
            return;
          }
          this.client.isLoadingSubject.next(false);
        },
        (error: Outcome) => {
          this.handleError(error);
        },
      ),
    );
  }
  private handleError(outcome: Outcome) {
    this.messageService.open(
      this.translate.getTranslation('Common.ErrorProcessingRequest'),
      5000,
    );
    this.client.isLoadingSubject.next(false);
  }

  private clearSubscriptions() {
    this.subscriptions.forEach(subscription => {
      subscription.unsubscribe();
    });

    this.subscriptions = new Array<Subscription>();
  }

  /**
   * Responsible for observing window size changes
   */
  private handleResize() {
    // begin observing window size
    this.breakpointObserver
      .observe([Breakpoints.Handset, Breakpoints.TabletPortrait])
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(result => {
        // set this collapsed to result of match
        this.collapsed = result.matches;

        // detect changes
        this.changeDetector.detectChanges();
      });
  }
}
