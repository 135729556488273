/* eslint-disable @typescript-eslint/member-ordering */
import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroupDirective, Validators } from '@angular/forms';
import { AgeRangeTypes, TestResultTypes, TestTypes } from '@app/core/enums';
import { AppTranslationService } from '@roctavian-abstractions/core';
import { UtilityService } from '../../../common';
import { ExternalLabTest } from '../../../external-lab-test/models/external-lab-test.model';

@Component({
  selector: 'external-lab-test-form',
  templateUrl: './external-lab-test-form.component.html',
  styleUrls: ['./external-lab-test-form.component.scss'],
})
export class ExternalLabTestFormComponent implements OnInit {
  @Input() public externalLabTest: ExternalLabTest;

  public ageRangeList = AgeRangeTypes;
  public testResultList = TestResultTypes;
  public testTypeList = TestTypes;
  ageVal = [];
  testResultVal = [];
  testTypeVal = [];
  @Input() public ExternallabTest: ExternalLabTest;
  @Input() public title = this.translate.getTranslation('ExternalLabTest.ExternalLabTestForm.Title');
  @Input() public showTitle = true;
  @Input() public showProcessedCheckbox = false;
  public ageRangeLabel = this.translate.getTranslation('ExternalLabTest.ExternalLabTestForm.AgeRange');
  public testResultLabel = this.translate.getTranslation('ExternalLabTest.ExternalLabTestForm.TestResult');
  public testTypeLabel = this.translate.getTranslation('ExternalLabTest.ExternalLabTestForm.TestType');



  constructor(
    public parentFormGroupDirective: FormGroupDirective,
    public utilityService: UtilityService,
    private translate: AppTranslationService,
  ) {
    this.ageVal = Object.keys(this.ageRangeList).filter((f) => isNaN(Number(f)));
    this.testResultVal = Object.keys(this.testResultList).filter((f) => isNaN(Number(f)));
    this.testTypeVal = Object.keys(this.testTypeList).filter((f) => isNaN(Number(f)));
  }

  ngOnInit() {
    this.parentFormGroupDirective.form.addControl('ageRange', new FormControl(null, Validators.required));
    this.parentFormGroupDirective.form.addControl('testResult', new FormControl(null, Validators.required));
    this.parentFormGroupDirective.form.addControl('testType', new FormControl(null, Validators.required));
  }

  public getModel(): ExternalLabTest {
    const formValue = this.parentFormGroupDirective.form.getRawValue();
    const externalLabTest: ExternalLabTest = formValue;
    if (this.ExternallabTest){
      externalLabTest.id = this.externalLabTest.id;
    }
    return externalLabTest;
  }
}
