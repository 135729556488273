import { TwoFactorComponent } from "./components/two-factor/two-factor.component";
import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { RouterModule } from "@angular/router";
import { MaterialModule, CoreModule, CoreDirectivesModule } from "@roctavian-abstractions/core";
import { QRCodeModule } from "angularx-qrcode";
import { TwoFactorDisableComponent } from "./components/two-factor-disable/two-factor-disable.component";
import { TwoFactorQrCodeComponent } from "./components/two-factor-qr-code/two-factor-qr-code.component";
import { TwoFactorRecoveryCodesComponent } from "./components/two-factor-recovery-codes/two-factor-recovery-codes.component";
import { TwoFactorStatusComponent } from "./components/two-factor-status/two-factor-status.component";
import { TwoFactorCodeFormComponent } from "./components/two-factor-code-form/two-factor-code-form.component";


@NgModule({
  imports: [
    CommonModule,
    CoreModule,
    CoreDirectivesModule,
    FormsModule,
    MaterialModule,
    ReactiveFormsModule,
    RouterModule,
    QRCodeModule
  ],
  declarations: [TwoFactorComponent,
    TwoFactorDisableComponent,
    TwoFactorQrCodeComponent,
    TwoFactorRecoveryCodesComponent,
    TwoFactorStatusComponent,
    TwoFactorCodeFormComponent],
  exports: [TwoFactorComponent,
    TwoFactorDisableComponent,
    TwoFactorQrCodeComponent,
    TwoFactorRecoveryCodesComponent,
    TwoFactorStatusComponent,
    TwoFactorCodeFormComponent]
})
export class TwoFactorModule {}
