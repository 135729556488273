import { Subscription } from 'rxjs';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { PatientService } from '../../services';
import { Patient } from '../../models';

@Component({
  selector: 'roctavian-eumea-callcenter-portal-patient-detail-master-page',
  templateUrl: './patient-detail-master-page.component.html',
  styleUrls: ['./patient-detail-master-page.component.css']
})
export class PatientDetailMasterPageComponent implements OnDestroy {
  public patient: Patient;
  private subscriptions = new Array<Subscription>();

  constructor(private route: ActivatedRoute, private patientService: PatientService) {
    this.subscriptions.push(this.route.paramMap.subscribe(params => {
      const id = String(params.get('id'));
      this.patientService.selectPatient(id);
    }));

    this.subscriptions.push(this.patientService.selectedPatientSubject.subscribe(
      patient => (this.patient = patient)
    ));
  }

  ngOnDestroy() {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
    this.patientService.selectedPatientSubject.next(null);
  }

  public getSidenavMode(): string {
    this.setStyleBasedOnMainNavbarHeight();
    if (window.innerWidth < 750) {
      return 'over';
    }
    return 'side';
  }

  public setStyleBasedOnMainNavbarHeight() {
    const mainNavbar = document.getElementById('main-navbar');
    if (!mainNavbar) {
      return;
    }

    const element: any = document.getElementsByClassName('sidenav-container').item(0);
    element.style.top = `${mainNavbar.scrollHeight}px`;
  }
}
