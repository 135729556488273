import { AppTranslationService } from '@roctavian-abstractions/core';
import { Component, OnInit, Input, SimpleChanges } from '@angular/core';
import { FormGroup, FormBuilder, FormGroupDirective, FormControl, Validators } from '@angular/forms';

@Component({
  selector: 'authorized-representative-form',
  templateUrl: './authorized-representative-form.component.html',
  styleUrls: ['./authorized-representative-form.component.css']
})
export class AuthorizedRepresentativeFormComponent implements OnInit {
  @Input() public title = this.translate.getTranslation('Lab.AuthorizedRepresentativeForm.Title');
  @Input() public showTitle = true;

  @Input() public firstNameLabel = this.translate.getTranslation('Lab.AuthorizedRepresentativeForm.FirstName');
  @Input() public middleInitialLabel = this.translate.getTranslation('Lab.AuthorizedRepresentativeForm.MiddleInitial');
  @Input() public lastNameLabel = this.translate.getTranslation('Lab.AuthorizedRepresentativeForm.LastName');
  @Input() public phoneLabel = this.translate.getTranslation('Lab.AuthorizedRepresentativeForm.Phone');
  @Input() public altPhoneLabel = this.translate.getTranslation('Lab.AuthorizedRepresentativeForm.AltPhone');
  @Input() public emailLabel = this.translate.getTranslation('Lab.AuthorizedRepresentativeForm.Email');

  public form: FormGroup;
  @Input() public areFieldsRequired = false;
  @Input() public areFieldsSoftRequired = true;
  @Input() public authorizedRepresentativeFormGroupName = 'authorizedRepresentative';

  constructor(
    private formBuilder: FormBuilder,
    private parentFormGroupDirective: FormGroupDirective,
    private translate: AppTranslationService
  ) { }

  ngOnInit() {
    this.buildForm();
  }

  private buildForm() {
    this.form = this.formBuilder.group({
      firstName: new FormControl(null, []),
      middleInitial: new FormControl(null, []),
      lastName: new FormControl(null, []),
      phone: new FormControl(null, []),
      altPhone: new FormControl(null, []),
      email: new FormControl(null, [])
    });

    this.refreshValidations(this.areFieldsRequired);

    this.parentFormGroupDirective.form.addControl(this.authorizedRepresentativeFormGroupName, this.form);
    this.form.setParent(this.parentFormGroupDirective.form);
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (!this.form || !this.form.controls || !changes.areFieldsRequired) {
      return;
    }

    this.refreshValidations(changes.areFieldsRequired.currentValue);
  }

  private refreshValidations(areFieldsRequired: boolean) {
    this.form.get('firstName').clearValidators();
    this.form.get('lastName').clearValidators();
    this.form.get('phone').clearValidators();
    this.form.get('email').clearValidators();

    this.form.get('email').setValidators([Validators.email]);

    if (!areFieldsRequired) {
      return;
    }

    this.form.get('firstName').setValidators(Validators.required);
    this.form.get('lastName').setValidators(Validators.required);
    this.form.get('phone').setValidators(Validators.required);
    this.form.get('email').setValidators([Validators.required, Validators.email]);
  }
}
