import { Component } from "@angular/core";
import { LocalStorageService, MessageService } from "@roctavian-abstractions/core";
import { UserDetailsFormSubmitEvent } from "../user-details-form/user-details-form.component";
import { AccountManagementClient } from "../../../../clients/account-management.client";
import { UpdateUserDetailsModel, UserDetailsModel } from "../../../../models";

@Component({
  selector: "roctavian-abstractions-user-details",
  templateUrl: "./user-details.component.html",
  styleUrls: ["./user-details.component.scss"]
})
export class UserDetailsComponent {
  public storedDetails: UserDetailsModel;

  constructor(
    private storage: LocalStorageService,
    private client: AccountManagementClient,
    private messageService: MessageService
  ) {
    this.storedDetails = JSON.parse(this.storage.get("user_info"));
  }

  public handleSubmit(submitEvent: UserDetailsFormSubmitEvent) {
    const model = new UpdateUserDetailsModel(submitEvent.firstName, submitEvent.lastName, submitEvent.email);

    this.client.updateUserDetails(model).subscribe(
      outcome => {
        this.storedDetails = model;
        this.synchronizeStoredUserDetails(model);
        this.messageService.open("", 5000);
      },
      error => {
        this.messageService.open("An error occurred.", 5000);
      }
    );
  }

  /**
   * Synchronizes the changes made to the user's details with the user details object stored in local storage.
   * @param model The update user details model.
   */
  public synchronizeStoredUserDetails(model: UpdateUserDetailsModel) {
    this.storedDetails.firstName = model.firstName;
    this.storedDetails.lastName = model.lastName;
    this.storedDetails.email = model.email;
    this.storage.set("user_info", JSON.stringify(this.storedDetails));
  }
}
