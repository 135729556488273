import { Component, OnInit, SimpleChanges, OnChanges, Input } from '@angular/core';
import { UtilityService } from '../../../common';
import { AppTranslationService } from '@roctavian-abstractions/core';
import { FormGroupDirective, FormControl } from '@angular/forms';

@Component({
  selector: 'institution-form',
  templateUrl: './institution-form.component.html',
  styleUrls: ['./institution-form.component.scss']
})
export class InstitutionFormComponent implements OnInit, OnChanges {
  @Input() public title = this.translate.getTranslation('Institution.InstitutionForm.Title');
  @Input() public showTitle = true;

  @Input() public address1Label = this.translate.getTranslation('Institution.InstitutionForm.Address1');
  @Input() public address2Label = this.translate.getTranslation('Institution.InstitutionForm.Address2');

  @Input() public aggregationIdLabel = this.translate.getTranslation('Institution.InstitutionForm.AggregationId');

  @Input() public areFieldsRequired = false;

  constructor(
    public parentFormGroupDirective: FormGroupDirective,
    public utilityService: UtilityService,
    private translate: AppTranslationService
  ) { }

  ngOnInit() {
    this.buildForm();
  }

  private buildForm() {
    this.parentFormGroupDirective.form.addControl('aggregationId', new FormControl(null));
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes.areFieldsRequired) {
      this.refreshValidations(changes.areFieldsRequired.currentValue);
    }
  }

  private refreshValidations(areFieldsRequired: boolean) {
    if (!this.parentFormGroupDirective.form || !this.parentFormGroupDirective.form.controls) {
      return;
    }
  }
}
