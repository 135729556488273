import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { SystemKeyModel, SystemKeyService } from '@roctavian-abstractions/core';
import { Subscription } from 'rxjs';

@Component({
  selector: 'view-system-key',
  templateUrl: './view-system-key.component.html',
  styleUrls: ['./view-system-key.component.css']
})
export class ViewSystemKeyComponent implements OnInit, OnDestroy {
  @Input() public label: string;
  @Input() public systemKeyGroup: string;
  @Input() public value: any;

  public systemKeys: SystemKeyModel[] = [];

  private subscriptions = new Array<Subscription>();

  constructor(private systemKeyService: SystemKeyService) { }

  ngOnInit() {
    if (!this.systemKeyGroup) {
      return;
    }

    this.subscriptions.push(this.systemKeyService.loadedSystemKeysSubject.subscribe(systemKeys => {
      this.systemKeys = systemKeys.filter(x => x.group == this.systemKeyGroup);
    }));

    this.systemKeyService.loadAllSystemKeys();
  }

  ngOnDestroy() {
    this.subscriptions.forEach(subscription => {
      subscription.unsubscribe();
    });
  }

  public getValues() {
    if (this.systemKeys.length === 0 || !this.value) {
      return '';
    }

    let result = '';
    if (this.value instanceof Array) {
      result += '<ul>';

      this.value.forEach(id => {
        const values = this.systemKeys.filter(x => x.key == id);
        if (values.length === 0) {
          return;
        }

        result += `<li>${values[0].value}</li>`;
      });

      result += '</ul>';
      return result;
    }

    const systemKeyValues = this.systemKeys.filter(x => x.key == this.value);
    if (systemKeyValues.length === 0) {
      return '';
    }

    return systemKeyValues[0].value;
  }
}
