import { Injectable, OnDestroy } from '@angular/core';
import {
  AppTranslationService,
  MessageService,
} from '@roctavian-abstractions/core';
import { Outcome, PaginatedList } from '@roctavian-abstractions/web';
import { BehaviorSubject, Subscription } from 'rxjs';
import { ClearService } from '../../../../shared/interface/clear-service.interface';
import { Document, PagedQuery } from '../../common';
import { LabUserLab } from '../../labuser/models/lab-user-lab.model';
import { LabClient } from '../clients';
import {
  DocumentLab,
  DocumentLabQuery,
  Lab,
  LabDuplicateCheckQuery,
  LabPrescriber,
  LabUser,
} from '../models';

@Injectable({
  providedIn: 'root',
})
export class LabService implements OnDestroy, ClearService {
  public pagedListSubject = new BehaviorSubject<PaginatedList<Lab>>(
    new PaginatedList<Lab>(),
  );
  public duplicateLabPagedListSubject = new BehaviorSubject<PaginatedList<Lab>>(
    new PaginatedList<Lab>(),
  );
  public prescriberPagedListSubject = new BehaviorSubject<
    PaginatedList<LabPrescriber>
  >(new PaginatedList<LabPrescriber>());
  public labUserPagedListSubject = new BehaviorSubject<
    PaginatedList<LabUserLab>
  >(new PaginatedList<LabUserLab>());
  public unassociatedLabUserPagedListSubject = new BehaviorSubject<
    PaginatedList<LabUser>
  >(new PaginatedList<LabUser>());
  public pagedLabDocumentsListSubject = new BehaviorSubject<
    PaginatedList<DocumentLab>
  >(new PaginatedList<DocumentLab>());

  public refreshSummurySubject = new BehaviorSubject<boolean>(false);
  public resfreshSummury = this.refreshSummurySubject.asObservable();

  public isLoadingSubject = new BehaviorSubject<boolean>(false);

  public selectedLabSubject = new BehaviorSubject<Lab>(null);

  private subscriptions = new Array<Subscription>();

  private addDocumentToLabSubscription: Subscription;
  public addDocumentToLabSubject = new BehaviorSubject<Outcome>(null);
  private getLabDocumentsListSubscription: Subscription;

  constructor(
    private labClient: LabClient,
    private messageService: MessageService,
    private translate: AppTranslationService,
  ) {}

  ngOnDestroy() {
    this.clearSubscriptions();
  }

  private clearSubscriptions() {
    this.subscriptions.forEach(subscription => {
      subscription.unsubscribe();
    });

    this.subscriptions = new Array<Subscription>();
  }

  public setPage(pagedQuery: PagedQuery) {
    this.isLoadingSubject.next(true);

    this.clearSubscriptions();

    this.subscriptions.push(
      this.labClient.getPagedList(pagedQuery).subscribe(
        outcome => {
          if (!outcome.success || outcome.failure) {
            this.messageService.open(outcome.messages[0], 5000);
            return;
          }

          this.pagedListSubject.next(outcome.value);
          this.isLoadingSubject.next(false);
        },
        (error: Outcome) => {
          this.messageService.open(error.messages[0], 5000);
          this.isLoadingSubject.next(false);
        },
      ),
    );
  }

  public setDuplicateLabPage(pagedQuery: LabDuplicateCheckQuery) {
    this.isLoadingSubject.next(true);

    this.clearSubscriptions();

    this.subscriptions.push(
      this.labClient.getLabDuplicates(pagedQuery).subscribe(
        outcome => {
          if (!outcome.success || outcome.failure) {
            return;
          }

          this.duplicateLabPagedListSubject.next(outcome.value);
          this.isLoadingSubject.next(false);
        },
        (error: Outcome) => {
          this.handleError(error);
        },
      ),
    );
  }

  public selectLab(labId: string, forceRefresh: boolean = false) {
    if (
      this.selectedLabSubject.value &&
      this.selectedLabSubject.value.id === labId &&
      !forceRefresh
    ) {
      return;
    }

    this.clearSubscriptions();

    this.isLoadingSubject.next(true);

    this.subscriptions.push(
      this.labClient.getLab(labId).subscribe(
        outcome => {
          if (!outcome.success || outcome.failure) {
            return;
          }
          this.selectedLabSubject.next(outcome.value);
          this.isLoadingSubject.next(false);
        },
        (error: Outcome) => {
          this.handleError(error);
        },
      ),
    );
  }

  public setLabUserPage(pagedQuery: PagedQuery, labId: string) {
    this.isLoadingSubject.next(true);

    this.clearSubscriptions();

    this.subscriptions.push(
      this.labClient.getLabUserListPaged(pagedQuery, labId).subscribe(
        outcome => {
          if (!outcome.success || outcome.failure) {
            this.messageService.open(outcome.messages[0], 5000);
            return;
          }

          this.labUserPagedListSubject.next(outcome.value);
          this.isLoadingSubject.next(false);
        },
        (error: Outcome) => {
          this.handleError(error);
        },
      ),
    );
  }

  public setUnassociatedLabUserPage(pagedQuery: PagedQuery) {
    this.isLoadingSubject.next(true);

    this.clearSubscriptions();

    this.subscriptions.push(
      this.labClient.getUnassociatedLabUsersPaged(pagedQuery).subscribe(
        outcome => {
          if (!outcome.success || outcome.failure) {
            this.messageService.open(outcome.messages[0], 5000);
            return;
          }

          this.unassociatedLabUserPagedListSubject.next(outcome.value);
          this.isLoadingSubject.next(false);
        },
        (error: Outcome) => {
          this.handleError(error);
        },
      ),
    );
  }

  public setPrescriberLabPage(pagedQuery: PagedQuery, labId: string) {
    this.isLoadingSubject.next(true);

    this.clearSubscriptions();

    this.subscriptions.push(
      this.labClient.getPrescriberLabListPaged(pagedQuery, labId).subscribe(
        outcome => {
          if (!outcome.success || outcome.failure) {
            this.messageService.open(outcome.messages[0], 5000);
            return;
          }

          this.prescriberPagedListSubject.next(outcome.value);
          this.isLoadingSubject.next(false);
        },
        (error: Outcome) => {
          this.handleError(error);
        },
      ),
    );
  }

  public setDocumentsPage(pagedQuery: DocumentLabQuery) {
    this.isLoadingSubject.next(true);

    if (this.getLabDocumentsListSubscription) {
      this.getLabDocumentsListSubscription.unsubscribe();
      this.getLabDocumentsListSubscription = null;
    }

    this.getLabDocumentsListSubscription = this.labClient
      .getDocumentsPagedList(pagedQuery)
      .subscribe(
        outcome => {
          if (!outcome.success || outcome.failure) {
            this.messageService.open(outcome.messages[0], 5000);
            return;
          }
          this.pagedLabDocumentsListSubject.next(outcome.value);
          this.isLoadingSubject.next(false);
        },
        (error: Outcome) => {
          this.messageService.open(error.messages[0], 5000);
          this.isLoadingSubject.next(false);
        },
      );
  }

  public addDocumentToLab(labId: string, document: Document) {
    this.isLoadingSubject.next(true);

    if (this.addDocumentToLabSubscription) {
      this.addDocumentToLabSubscription.unsubscribe();
      this.addDocumentToLabSubscription = null;
    }

    this.addDocumentToLabSubscription = this.labClient
      .addDocumentToLab(labId, document)
      .subscribe(
        outcome => {
          if (!outcome.success || outcome.failure) {
            this.messageService.open(outcome.messages[0], 5000);
            return;
          }

          this.addDocumentToLabSubject.next(outcome);
          this.isLoadingSubject.next(false);
        },
        (error: Outcome) => {
          this.messageService.open(error.messages[0], 5000);
          this.isLoadingSubject.next(false);
        },
      );
  }

  private handleError(outcome: Outcome) {
    this.messageService.open(
      this.translate.getTranslation('Common.ErrorProcessingRequest'),
      5000,
    );
    this.isLoadingSubject.next(false);
  }

  public clearService() {
    this.pagedListSubject.next(new PaginatedList<Lab>());
    this.duplicateLabPagedListSubject.next(new PaginatedList<Lab>());
    this.prescriberPagedListSubject.next(new PaginatedList<LabPrescriber>());
    this.labUserPagedListSubject.next(new PaginatedList<LabUserLab>());
    this.unassociatedLabUserPagedListSubject.next(new PaginatedList<LabUser>());
    this.selectedLabSubject.next(null);
    this.pagedLabDocumentsListSubject.next(new PaginatedList<DocumentLab>());
    this.addDocumentToLabSubject.next(null);
  }
}
