/* eslint-disable @typescript-eslint/naming-convention */
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ConfigService } from '@roctavian-abstractions/core';
import { PaginatedList } from '@roctavian-abstractions/web';
import { Observable } from 'rxjs';
import { AuthenticationHistoryRecord } from '../models';

/**
 * A client used to communicate with the authentication history API.
 */
@Injectable({
  providedIn: 'root'
})
export class AuthenticationHistoryClient {
  /**
   * Initializes a new instance of the authentication history client.
   *
   * @param client The http client.
   */
  constructor(private client: HttpClient, private configService: ConfigService) { }

  public GetUserAuthenticationHistory(
    page: number,
    size: number
  ): Observable<PaginatedList<AuthenticationHistoryRecord>> {
    const url = `${this.configService.get('apiUrl')}/identity/authentication/history/user?page=${page}&size=${size}`;
    return this.client.get<PaginatedList<AuthenticationHistoryRecord>>(url);
  }

  /**
   * Gets the authentication history records as a paged collection.
   * The history records can optionally be filtered to a specific user identity.
   *
   * @param page The requested page.
   * @param size The requested page size.
   * @param identity The user identity.
   * @returns An observable containing a paged collection of history records.
   */
  public GetAuthenticationHistory(
    page: number,
    size: number,
    identity?: number
  ): Observable<PaginatedList<AuthenticationHistoryRecord>> {
    let url = `${this.configService.get('apiUrl')}/identity/authentication/history?page=${page}&size=${size}`;

    if (identity) {
      url = url + `&identity=${identity}`;
    }

    return this.client.get<PaginatedList<AuthenticationHistoryRecord>>(url);
  }
}
