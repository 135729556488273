import { OnDestroy, ViewChild } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import { Prescriber } from '../../models';
import { Subscription } from 'rxjs';
import { PrescriberService } from '../../services';
import { PrescriberDelegateListComponent } from '../../components/prescriber-delegate-list/prescriber-delegate-list.component';

@Component({
  selector: 'roctavian-eumea-callcenter-portal-prescriber-delegate-list-page',
  templateUrl: './prescriber-delegate-list-page.component.html',
  styleUrls: ['./prescriber-delegate-list-page.component.scss']
})
export class PrescriberDelegateListPageComponent implements OnInit, OnDestroy {
  public prescriber: Prescriber;
  private subscriptions = new Array<Subscription>();
  @ViewChild(PrescriberDelegateListComponent, { static: false })
  public prescriberDelegateListComponent: PrescriberDelegateListComponent;

  constructor(private prescriberService: PrescriberService) { }

  ngOnInit() {
    this.subscriptions.push(
      this.prescriberService.selectedPrescriberSubject.subscribe(prescriber => (this.prescriber = prescriber))
    );
  }

  ngOnDestroy() {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }

  public showAssociatePrescriberDelegate = false;

  public beginAssociation() {
    this.showAssociatePrescriberDelegate = true;
  }

  public onPrescriberDelegateAssociated() {
    this.showAssociatePrescriberDelegate = false;

    if (this.prescriberDelegateListComponent) {
      this.prescriberDelegateListComponent.refreshList();
    }
  }

  public onCancelAssociation() {
    this.showAssociatePrescriberDelegate = false;
  }
}
