import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { Prescriber } from '../../models';

@Component({
  selector: 'roctavian-eumea-callcenter-portal-view-prescriber-dialog',
  templateUrl: './view-prescriber-dialog.component.html',
  styleUrls: ['./view-prescriber-dialog.component.css']
})
export class ViewPrescriberDialogComponent {
  public prescriber: Prescriber;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public reference: MatDialogRef<ViewPrescriberDialogComponent>
  ) {
    this.prescriber = data.prescriber;
  }
}
