import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Outcome, PaginatedList } from '@roctavian-abstractions/web';
import { Observable } from 'rxjs';
import { CreateTaskModel, CreateTaskActionModel, UpdateTaskModel, TaskListItem, Task } from '../models';
import { ConfigService } from '@roctavian-abstractions/core';

@Injectable({
  providedIn: 'root'
})
export class TaskClient {
  constructor(private client: HttpClient, private configService: ConfigService) { }

  /**
   * Returns all the saved tasks.
   *
   * @returns An outcome containing all the tasks.
   */
  public getTasks(): Observable<Task[]> {
    return this.client.get<Task[]>(`${this.configService.get('apiUrl')}/task`);
  }

  /**
   * Returns all tasks found in the system.
   *
   * @returns An array of task list items.
   */
  public getTaskList(): Observable<TaskListItem[]> {
    return this.client.get<TaskListItem[]>(`${this.configService.get('apiUrl')}/task/list`);
  }

  /**
   * Returns a paged collection of workgroups.
   *
   * @param page The requested page.
   * @param size The requested page size.
   * @param filterText Optional text used to filter the workgroups.
   * @returns A paged collection of tasks.
   */
  public getTaskListPaged(page: number, size: number, filterText?: string) {
    const url = `${this.configService.get('apiUrl')}/task/paged?page=${page}&size=${size}&filterText=${filterText}`;
    return this.client.get<PaginatedList<TaskListItem>>(url);
  }

  /**
   * Gets the task with the specified identity.
   *
   * @param taskIdentity The task identity.
   * @returns An outcome containing the specified task if it exists.
   */
  public getTask(taskIdentity: string): Observable<Task> {
    return this.client.get<Task>(`${this.configService.get('apiUrl')}/task/${taskIdentity}`);
  }

  /**
   * Saves the task.
   *
   * @param model The task to add.
   * @returns An outcome indicating whether the task was saved successfully.
   */
  public addTask(model: CreateTaskModel): Observable<Outcome> {
    return this.client.post<Outcome>(`${this.configService.get('apiUrl')}/task`, model);
  }

  /**
   * Saves the task action.
   *
   * @param model The task action to add.
   * @returns An outcome indicating whether the task action was saved successfully.
   */
  public addTaskAction(taskIdentity: string, model: CreateTaskActionModel): Observable<Outcome> {
    return this.client.post<Outcome>(`${this.configService.get('apiUrl')}/task/${taskIdentity}/action`, model);
  }

  /**
   * Updates the task.
   *
   * @param taskIdentity The task identity.
   * @param model The update task model.
   * @returns An outcome indicating whether the task was updated successfully.
   */
  public updateTask(taskIdentity: string, model: UpdateTaskModel): Observable<Outcome> {
    return this.client.put<Outcome>(`${this.configService.get('apiUrl')}/task/${taskIdentity}`, model);
  }

  /**
   * Deletes the specified task.
   *
   * @param taskIdentity The task identity to delete.
   * @returns An outcome indicating whether the task was deleted successfully.
   */
  public deleteTask(taskIdentity: string): Observable<Outcome> {
    return this.client.delete<Outcome>(`${this.configService.get('apiUrl')}/task/${taskIdentity}`);
  }

  /**
   * Closes the specified task.
   *
   * @param taskIdentity The identity of the task to close.
   * @returns An outcome indicating whether the task was closed successfully.
   */
  public closeTask(taskIdentity: string): Observable<Outcome> {
    return this.client.post<Outcome>(`${this.configService.get('apiUrl')}/task/close`, { TaskIdentity: taskIdentity });
  }
}
