import { Component, EventEmitter, Output } from '@angular/core';

import { PermissionClient } from 'apps/roctavian-eumea-callcenter-portal/src/app/modules/identity/clients/permission.client';
import { CreatePermissionModel } from 'apps/roctavian-eumea-callcenter-portal/src/app/modules/identity/models/create-permission-model';
import { PermissionFormSubmitEvent } from '../permission-form/permission-form.component';

@Component({
  selector: 'roctavian-abstractions-create-permission',
  templateUrl: './create-permission.component.html',
  styleUrls: ['./create-permission.component.scss'],
})
export class CreatePermissionComponent  {
  @Output() public saved = new EventEmitter();

  public disabled = false;
  public messages: string[] = [];

  constructor(private client: PermissionClient) {}


  public handleSubmission(event: PermissionFormSubmitEvent) {
    const model = new CreatePermissionModel(event.name, event.description, event.group);
    this.disabled = true;
    this.client.createPermission(model).subscribe(
      outcome => {
        this.disabled = false;
        if (!outcome.failure) {
          this.saved.emit();
        }
      },
      response => {
        this.disabled = false;
        this.messages = response.error.messages;
      }
    );
  }
}
