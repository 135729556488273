import { Component, Input } from "@angular/core";

@Component({
  selector: "roctavian-abstractions-two-factor-qr-code",
  templateUrl: "./two-factor-qr-code.component.html",
  styleUrls: ["./two-factor-qr-code.component.scss"]
})
export class TwoFactorQrCodeComponent {
  /**
   * The key to generate the QR code for.
   */
  @Input() public key: string;

  constructor() {}
}
