import { Subscription } from 'rxjs';
import { Component, OnInit, EventEmitter, Output, Input, OnDestroy } from '@angular/core';
import { MessageService } from '@roctavian-abstractions/core';
import { AppTranslationService } from '@roctavian-abstractions/core';
import { Lab } from '../../../lab';
import { Prescriber, PrescriberLab } from '../../models';
import { PrescriberClient } from '../../clients';

@Component({
  selector: 'prescriber-associate-lab',
  templateUrl: './associate-lab.component.html',
  styleUrls: ['./associate-lab.component.css']
})
export class AssociateLabComponent implements OnDestroy {
  public errorMessages: string[] = [];
  @Output() public associated = new EventEmitter();
  @Output() public cancelAssociation = new EventEmitter();
  @Input() public prescriber: Prescriber;
  public selectedLab: Lab;

  private subscriptions = new Array<Subscription>();

  constructor(
    private prescriberClient: PrescriberClient,
    private messageService: MessageService,
    private translate: AppTranslationService
  ) { }

  ngOnDestroy() {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }

  public saveAssociation() {
    this.errorMessages = [];

    if (!this.selectedLab) {
      this.errorMessages = ['Please select a lab for association'];
      return;
    }

    const prescriberLab = new PrescriberLab();
    prescriberLab.prescriberId = this.prescriber.id;
    prescriberLab.labId = this.selectedLab.id;
    prescriberLab.isPrescriberOffice = this.selectedLab.isPrescriberOffice;

    this.subscriptions.push(this.prescriberClient
      .createPrescriberLabAssociation(prescriberLab)
      .subscribe(
        outcome => {
          if (outcome.failure) {
            this.errorMessages = outcome.messages;
            return;
          }

          this.messageService.open(
            this.translate.getTranslation('Prescriber.AssociateLab.SuccessfullyAssociated'),
            5000
          );
          this.associated.emit();
        },
        error => {
          this.errorMessages = [this.translate.getTranslation('Common.ErrorProcessingRequest')];
        }
      ));
  }

  public cancelLabAssociation() {
    this.cancelAssociation.emit();
  }

  public onLabSelected(lab: Lab) {
    this.selectedLab = lab;
  }
}
