import { OnDestroy } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import { LabTest } from '../../models/lab-test.model';
import { Subscription } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { LabTestService } from '../../services/lab-test.service';

@Component({
  selector: 'roctavian-eumea-callcenter-portal-lab-test-detail-master-page',
  templateUrl: './lab-test-detail-master-page.component.html',
  styleUrls: ['./lab-test-detail-master-page.component.css']
})
export class LabTestDetailMasterPageComponent implements OnInit, OnDestroy {
  public labTest: LabTest;
  private subscriptions = new Array<Subscription>();

  constructor(private route: ActivatedRoute, private service: LabTestService) { }

  ngOnInit() {
    this.subscriptions.push(this.service.selectedLabTestSubject.subscribe(labTest => (this.labTest = labTest)));

    this.subscriptions.push(
      this.route.paramMap.subscribe(params => {
        const id = String(params.get('id'));
        this.service.loadLabTest(id);
      })
    );
  }

  ngOnDestroy() {
    this.subscriptions.forEach(subscription => {
      subscription.unsubscribe();
    });
    this.service.selectedLabTestSubject.next(null);
  }

  public getSidenavMode(): string {
    this.setStyleBasedOnMainNavbarHeight();
    if (window.innerWidth < 750) {
      return 'over';
    }
    return 'side';
  }

  public setStyleBasedOnMainNavbarHeight() {
    const mainNavbar = document.getElementById('main-navbar');
    if (!mainNavbar) {
      return;
    }

    const element: any = document.getElementsByClassName('sidenav-container').item(0);
    element.style.top = `${mainNavbar.scrollHeight}px`;
  }
}
