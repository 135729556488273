import { Component, OnInit, OnDestroy } from '@angular/core';
import { Lab } from '../../models';
import { LabService } from '../../services';
import { Subscription } from 'rxjs';
import { Router } from '@angular/router';

@Component({
  selector: 'roctavian-eumea-callcenter-portal-lab-summary-page',
  templateUrl: './lab-summary-page.component.html',
  styleUrls: ['./lab-summary-page.component.css']
})
export class LabSummaryPageComponent implements OnDestroy, OnInit {
  public lab: Lab;

  private subscriptions = new Array<Subscription>();

  constructor(private labService: LabService, private router: Router) {
    this.subscriptions.push(this.labService.selectedLabSubject.subscribe(lab => (this.lab = lab)));
  }


  ngOnInit() {
    this.labService.selectLab(this.lab.id, true);
  }

  ngOnDestroy() {
    this.subscriptions.forEach(subscription => {
      subscription.unsubscribe();
    });
  }
}
