export enum DocumentTypes {
  labTestOrderForm = 10,
  labTestRequisitionForm = 11,
  labRegistrationForm = 12,
  labTestDocument = 13,
  labTestResultPdf = 14,
  labDocument = 15,
  patientConsentForm = 20,
  patientDocument = 21,
  prescriberDocument = 29,
  prescriberRegistrationForm = 30,
  patientRegistrationForm = 31,
}
