import { Pipe, PipeTransform } from '@angular/core';
import { TaskType } from '../models';

/**
 * Pipe used to filter a TaskType array to show only general tasks included in it.
 */
@Pipe({ name: 'generalTasks' })
export class GeneralTasksPipe implements PipeTransform {
  public transform(taskTypes: TaskType[]): TaskType[] {
    return taskTypes.filter(tt => tt.isGeneral);
  }
}
