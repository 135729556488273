import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { MessageService } from '@roctavian-abstractions/core';
import { Router } from '@angular/router';
import { AppTranslationService } from '@roctavian-abstractions/core';
import { Lab } from '../../models';
import { LabClient } from '../../clients';
import { Subscription } from 'rxjs';
import { Outcome } from '@roctavian-abstractions/web';

@Component({
  selector: 'add-lab',
  templateUrl: './add-lab.component.html',
  styleUrls: ['./add-lab.component.css']
})
export class AddLabComponent implements OnInit, OnDestroy {
  public form: FormGroup;
  @Input() prefillLab: Lab;
  public errorMessages: string[];
  public isProcessing = false;

  private subscriptions = new Array<Subscription>();

  constructor(
    private formBuilder: FormBuilder,
    private client: LabClient,
    private messageService: MessageService,
    private router: Router,
    private translate: AppTranslationService
  ) { }

  public ngOnInit() {
    this.form = this.formBuilder.group({});
  }

  ngOnDestroy() {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }

  ngAfterViewInit(): void {
    this.prefillLabData();
  }

  public submit() {
    this.errorMessages = [];

    this.form.markAllAsTouched();
    if (this.form.invalid) {
      return;
    }

    this.isProcessing = true;
    this.subscriptions.push(this.client.createLab(this.getModel()).subscribe(
      outcome => {
        this.isProcessing = false;
        if (outcome.failure) {
          this.errorMessages = outcome.messages;
          return;
        }

        this.messageService.open(this.translate.getTranslation('Lab.AddLab.SuccessfullySaved'), 5000);
        this.router.navigate(['/lab']);
      },
      (error: Outcome) => {
        this.isProcessing = false;
        this.errorMessages = error.messages;
      }
    ));
  }

  private prefillLabData() {
    if (!this.prefillLab) {
      return;
    }

    this.form.patchValue(this.prefillLab);
  }

  public getModel(): Lab {
    const formValue = this.form.getRawValue();
    const lab = formValue;

    return lab;
  }
}
