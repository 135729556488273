import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog, MatSort, PageEvent } from '@angular/material';
import { Router } from '@angular/router';
import { AppTranslationService, MessageService } from '@roctavian-abstractions/core';
import { Outcome, PaginatedList } from '@roctavian-abstractions/web';
import { BehaviorSubject, EMPTY, Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged, switchMap } from 'rxjs/operators';
import {
  UtilityService
} from '../../../common';
import { FileExportService } from '../../../common/services/file-export.service';
import { InstitutionClient } from '../../clients';
import { Institution, InstitutionPagedQuery } from '../../models';
import { InstitutionService } from '../../services';
import { DeleteInstitutionDialogComponent } from '../delete-institution-dialog/delete-institution-dialog.component';
import { ViewInstitutionDialogComponent } from '../view-institution-dialog/view-institution-dialog.component';


@Component({
  selector: 'institution-list',
  templateUrl: './institution-list.component.html',
  styleUrls: ['./institution-list.component.scss']
})
export class InstitutionListComponent implements OnInit, OnDestroy {
  public pagedQuery = new InstitutionPagedQuery(1, 10, null, 'id', true);

  public filterText$ = new BehaviorSubject<string>(null);
  public pagedCollection: PaginatedList<Institution>;
  public isLoading = false;

  private exportFileName = this.translate.getTranslation('Institution.InstitutionList.ExportFileName');
  public exportLabel = this.translate.getTranslation('Common.Export');

  private subscriptions = new Array<Subscription>();


  public displayedColumns = [
    'stakeholder.address.name',
    'stakeholder.address.country.name',
    'stakeholder.address.address1',
    'stakeholder.address.address2',
    'stakeholder.address.city',
    'stakeholder.address.postalCode',
    'menu'
  ];

  constructor(
    private service: InstitutionService,
    private router: Router,
    public utilityService: UtilityService,
    private matDialog: MatDialog,
    private client: InstitutionClient,
    private messageService: MessageService,
    private translate: AppTranslationService,
    private fileExportService: FileExportService
  ) { }

  private refreshList() {
    this.service.setPage(this.pagedQuery);
  }

  ngOnInit() {
    this.subscriptions.push(this.filterText$
      .pipe(
        debounceTime(1000),
        distinctUntilChanged(),
        switchMap(searchText => {
          if (this.pagedQuery.filterText == searchText) {
            return EMPTY;
          }

          this.pagedQuery.filterText = searchText;
          this.pagedQuery.pageIndex = 1;
          this.refreshList();
          return EMPTY;
        })
      )
      .subscribe());

    this.subscriptions.push(this.service.institutionPagedListSubject.subscribe(collection => {
      this.pagedCollection = collection;
    }));

    this.subscriptions.push(this.service.isLoadingSubject.subscribe(isLoading => {
      this.isLoading = isLoading;
    }));

    this.refreshList();
  }

  ngOnDestroy() {

    this.subscriptions.forEach(subscription => {
      subscription.unsubscribe();
    });

    this.service.institutionPagedListSubject.next(new PaginatedList<Institution>());
  }

  public onRowSelected(institution: Institution) {
    this.router.navigate(['/institution', institution.id]);
  }

  public viewInstitution(institutionId: string) {

    this.subscriptions.push(this.client.getInstitution(institutionId).subscribe(
      outcome => {
        const dialogRef = this.matDialog.open(ViewInstitutionDialogComponent, {
          hasBackdrop: true,
          disableClose: true,
          data: {
            institution: outcome.value
          },
          width: '1000px'
        });
      },
      error => {
        this.messageService.open(this.translate.getTranslation('Common.ErrorProcessingRequest'), 3000);
      }
    ));
  }

  public deleteInstitution(institutionId: string) {

    this.subscriptions.push(this.client.getInstitution(institutionId).subscribe(outcome => {
      const dialogRef = this.matDialog.open(DeleteInstitutionDialogComponent, {
        hasBackdrop: true,
        disableClose: true,
        data: {
          institution: outcome.value
        },
        width: '800px'
      });
      dialogRef.componentInstance.institutionDeleted.subscribe(() => {
        this.refreshList();
        dialogRef.componentInstance.institutionDeleted.unsubscribe();
      });
    }));
  }

  public onPageEvent(pageEvent: PageEvent) {
    this.pagedQuery.pageIndex = pageEvent.pageIndex + 1;
    this.pagedQuery.pageSize = pageEvent.pageSize;
    this.refreshList();
  }

  public onSortEvent(sort: MatSort) {
    this.pagedQuery.sortColumn = sort.active;
    this.pagedQuery.isSortDesc = sort.direction == 'desc';
    this.pagedQuery.pageIndex = 1;
    this.refreshList();
  }

  public downloadExportFile() {
    this.subscriptions.push(
      this.client.getExportFile().subscribe(
        data => this.fileExportService.downloadFile(data, 'text/csv', this.exportFileName),
        (error: Outcome) => {
          if (!error.messages[0] == null || !error.messages[0] == undefined || error.messages.length !== 0) {
            this.messageService.open(error.messages[0], 3000);
          }
        }
      )
    );
  }
}
