import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Lab } from '../../models';
import { LabService } from '../../services';
import { Subscription } from 'rxjs';

@Component({
  selector: 'roctavian-eumea-callcenter-portal-lab-status-page',
  templateUrl: './lab-status-page.component.html',
  styleUrls: ['./lab-status-page.component.css']
})
export class LabStatusPageComponent implements OnDestroy {
  public lab: Lab;
  private subscriptions = new Array<Subscription>();

  constructor(private route: ActivatedRoute, private labService: LabService) {
    this.subscriptions.push(this.labService.selectedLabSubject.subscribe(lab => {
      this.lab = lab;
    }));
  }

  ngOnDestroy() {
    this.subscriptions.forEach(subscription => {
      subscription.unsubscribe();
    });
  }

  public onStatusSaved() {
    this.labService.selectLab(this.lab.id, true);
  }
}
