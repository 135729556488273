import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { Lab } from '../..';

@Component({
  selector: 'roctavian-eumea-callcenter-portal-view-lab-dialog',
  templateUrl: './view-lab-dialog.component.html',
  styleUrls: ['./view-lab-dialog.component.css']
})
export class ViewLabDialogComponent {
  public lab: Lab;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any, public reference: MatDialogRef<ViewLabDialogComponent>) {
    this.lab = data.lab;
  }
}
