import { Component, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { takeWhile } from 'rxjs/operators';
import { Task } from '../../models';
import { TaskClient } from '../../clients/task.client';

@Component({
  selector: 'roctavian-abstractions-edit-task-page',
  templateUrl: './edit-task-page.component.html',
  styleUrls: ['./edit-task-page.component.scss']
})
export class EditTaskPageComponent implements OnDestroy {
  public taskIdentity: string;

  public task: Task;

  private unsubscribe: boolean = false;

  constructor(private route: ActivatedRoute, private taskClient: TaskClient) {
    this.route.params.pipe(takeWhile(() => !this.unsubscribe)).subscribe(params => {
      this.taskIdentity = params['id'];
      this.taskClient.getTask(this.taskIdentity).subscribe(task => {
        this.task = task;
      });
    });
  }

  public ngOnDestroy(): void {
    this.unsubscribe = true;
  }
}
