import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'roctavian-abstractions-register-page',
  templateUrl: './register-page.component.html',
  styleUrls: ['./register-page.component.css']
})
export class RegisterPageComponent implements OnInit {

  constructor() {
  }

  ngOnInit() {
  }

}
