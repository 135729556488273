import { Component, OnInit, OnDestroy, OnChanges, Input, SimpleChanges, Output, EventEmitter } from '@angular/core';
import { PageEvent, MatSort } from '@angular/material';
import { Prescriber } from '../../models';
import { PaginatedList } from '@roctavian-abstractions/web';
import { Institution } from '../../../institution';
import { BehaviorSubject, Subscription, EMPTY } from 'rxjs';
import { UtilityService, PagedQuery } from '../../../common';
import { PrescriberService } from '../../services';
import { debounceTime, distinctUntilChanged, switchMap } from 'rxjs/operators';

@Component({
  selector: 'prescriber-institution-list',
  templateUrl: './prescriber-institution-list.component.html',
  styleUrls: ['./prescriber-institution-list.component.scss']
})
export class PrescriberInstitutionListComponent implements OnInit, OnChanges, OnDestroy {
  @Input() prescriber: Prescriber;
  @Output() public institutionSelected = new EventEmitter<Institution>();
  public selectedInstitution: Institution;

  public pagedQuery = new PagedQuery(1, 10, null, 'id', true);

  public pagedCollection: PaginatedList<Institution>;
  public isLoading = false;
  public filterText$ = new BehaviorSubject<string>(null);
  private subscriptions = new Array<Subscription>();

  public displayedColumns = [
    'stakeholder.address.country.name',
    'stakeholder.address.name',
    'stakeholder.address.address1',
    'stakeholder.address.address2',
    'stakeholder.address.city',
    'stakeholder.address.postalCode'
  ];

  constructor(public utilityService: UtilityService, private service: PrescriberService) { }

  ngOnDestroy() {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
    this.service.prescriberInstitutionPagedListSubject.next(new PaginatedList<Institution>());
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (
      !changes.prescriberId ||
      !changes.prescriberId.currentValue ||
      changes.prescriberId.currentValue === changes.prescriberId.previousValue
    ) {
      return;
    }

    if (changes.prescriber) {
      this.selectInstitutionFromPrescriber(changes.prescriber.currentValue);
    }

    this.refreshList();
  }

  private refreshList() {
    let prescriberId: string = null;
    if (this.prescriber) {
      prescriberId = this.prescriber.id;
    }
    this.service.setPrescriberInstitutionPage(this.pagedQuery);
  }

  ngOnInit() {
    this.subscriptions.push(this.filterText$
      .pipe(
        debounceTime(1000),
        distinctUntilChanged(),
        switchMap(searchText => {
          if (this.pagedQuery.filterText == searchText) {
            return EMPTY;
          }

          this.pagedQuery.filterText = searchText;
          this.pagedQuery.pageIndex = 1;
          this.refreshList();
          return EMPTY;
        })
      )
      .subscribe());

    this.subscriptions.push(this.service.prescriberInstitutionPagedListSubject.subscribe(
      collection => {
        this.pagedCollection = collection;
      }
    ));

    this.subscriptions.push(this.service.isLoadingSubject.subscribe(isLoading => {
      this.isLoading = isLoading;
    }));

    this.refreshList();
    this.selectInstitutionFromPrescriber(this.prescriber);
  }

  public onPageEvent(pageEvent: PageEvent) {
    this.pagedQuery.pageIndex = pageEvent.pageIndex + 1;
    this.pagedQuery.pageSize = pageEvent.pageSize;
    this.refreshList();
  }

  public onSortEvent(sort: MatSort) {
    this.pagedQuery.sortColumn = sort.active;
    this.pagedQuery.isSortDesc = sort.direction == 'desc';
    this.pagedQuery.pageIndex = 1;
    this.refreshList();
  }

  public onRowSelected(institution: Institution) {
    if (this.selectedInstitution === institution) {
      this.selectedInstitution = null;
      this.institutionSelected.emit(null);
      return;
    }

    this.selectedInstitution = institution;
    this.institutionSelected.emit(this.selectedInstitution);
  }

  private selectInstitutionFromPrescriber(prescriber: Prescriber) {
    if (!prescriber || !prescriber.institution || !prescriber.institution.id) {
      this.onRowSelected(null);
      return;
    }

    this.onRowSelected(prescriber.institution);
  }
}
