import { Component, OnInit, OnDestroy } from '@angular/core';
import { PagedQuery, UtilityService } from '../../../common';
import { BehaviorSubject, Subscription, EMPTY } from 'rxjs';
import { PaginatedList, Outcome } from '@roctavian-abstractions/web';
import { LabKitOrder } from '../../models/lab-kit-order.model';
import { LabKitOrderService } from '../../services/lab-kit-order.service';
import { Router } from '@angular/router';
import { MatDialog, PageEvent, MatSort } from '@angular/material';
import { LabKitOrderClient } from '../../clients/lab-kit-order.client';
import { MessageService } from '@roctavian-abstractions/core';
import { AppTranslationService } from '@roctavian-abstractions/core';
import { debounceTime, distinctUntilChanged, switchMap } from 'rxjs/operators';
import { ViewLabKitOrderDialogComponent } from '../view-lab-kit-order-dialog/view-lab-kit-order-dialog.component';
import { LabKitOrderPagedQuery } from '../../models/lab-kit-order-paged-query.model';
import { FileExportService } from '../../../common/services/file-export.service';
import { DeleteLabKitOrderDialogComponent } from '../delete-lab-kit-order-dialog/delete-lab-kit-order-dialog.component';

@Component({
  selector: 'lab-kit-order-list',
  templateUrl: './lab-kit-order-list.component.html',
  styleUrls: ['./lab-kit-order-list.component.scss']
})
export class LabKitOrderListComponent implements OnInit, OnDestroy {
  public pagedQuery = new LabKitOrderPagedQuery(1, 10, null, 'id', true, null, null);

  public filterText$ = new BehaviorSubject<string>(null);
  public labKitOrderPagedCollection: PaginatedList<LabKitOrder>;
  public isLoading = false;

  private exportFileName = this.translate.getTranslation('LabKitOrder.ExportFileName');
  public exportLabel = this.translate.getTranslation('Common.Export');

  private subscriptions = new Array<Subscription>();

  public displayedColumns = [
    'processed',
    'id',
    'stakeholder.address.name',
    'stakeholder.address.country.name',
    'nationalId',
    'stakeholder.address.address1',
    'stakeholder.address.address2',
    'stakeholder.address.city',
    'stakeholder.address.postalCode',
    'stakeholder.phone',
    'stakeholder.email',
    'quantity',
    'menu'
  ];

  constructor(
    private labKitOrderService: LabKitOrderService,
    private router: Router,
    public utilityService: UtilityService,
    private matDialog: MatDialog,
    private labKitOrderClient: LabKitOrderClient,
    private messageService: MessageService,
    private translate: AppTranslationService,
    private fileExportService: FileExportService
  ) { }

  private refreshList() {
    this.labKitOrderService.setPage(this.pagedQuery);
  }

  ngOnInit() {
    this.subscriptions.push(
      this.filterText$
        .pipe(
          debounceTime(1000),
          distinctUntilChanged(),
          switchMap(searchText => {
            if (this.pagedQuery.filterText == searchText) {
              return EMPTY;
            }

            this.pagedQuery.filterText = searchText;
            this.pagedQuery.pageIndex = 1;
            this.refreshList();
            return EMPTY;
          })
        )
        .subscribe()
    );

    this.subscriptions.push(
      this.labKitOrderService.labKitOrderPagedListSubject.subscribe(
        collection => (this.labKitOrderPagedCollection = collection)
      )
    );

    this.subscriptions.push(
      this.labKitOrderService.isLoadingSubject.subscribe(isLoading => (this.isLoading = isLoading))
    );

    this.refreshList();
  }

  ngOnDestroy() {
    this.subscriptions.forEach(subscription => {
      subscription.unsubscribe();
    });

    this.labKitOrderService.labKitOrderPagedListSubject.next(new PaginatedList<LabKitOrder>());
  }

  public onRowSelected(labKitOrder: LabKitOrder) {
    this.router.navigate(['/lab-kit-order', labKitOrder.id]);
  }

  public viewLabKitOrder(labKitOrderId: string) {
    this.subscriptions.push(
      this.labKitOrderClient.getLabKitOrder(labKitOrderId).subscribe(
        outcome => {
          const dialogRef = this.matDialog.open(ViewLabKitOrderDialogComponent, {
            hasBackdrop: true,
            disableClose: true,
            data: {
              labKitOrder: outcome.value
            },
            width: '1000px'
          });
        },
        error => {
          this.messageService.open(this.translate.getTranslation('Common.ErrorProcessingRequest'), 3000);
        }
      )
    );
  }

  public onPageEvent(pageEvent: PageEvent) {
    this.pagedQuery.pageIndex = pageEvent.pageIndex + 1;
    this.pagedQuery.pageSize = pageEvent.pageSize;
    this.refreshList();
  }

  public onSortEvent(sort: MatSort) {
    switch (sort.active) {
      case 'id':
        this.pagedQuery.sortColumn = 'stakeholder.externalId';
        break;

      default:
        this.pagedQuery.sortColumn = sort.active;
        break;
    }

    this.pagedQuery.isSortDesc = sort.direction == 'desc';
    this.pagedQuery.pageIndex = 1;
    this.refreshList();
  }

  public downloadExportFile() {
    this.subscriptions.push(
      this.labKitOrderClient.getExportFile().subscribe(
        data => this.fileExportService.downloadFile(data, 'text/csv', this.exportFileName),
        (error: Outcome) => {
          this.messageService.open(this.translate.getTranslation('Common.ErrorProcessingRequest'), 3000);
        }
      )
    );
  }

  public deleteLabKitOrder(labKitOrder: LabKitOrder) {
    const dialogRef = this.matDialog.open(DeleteLabKitOrderDialogComponent, {
      hasBackdrop: true,
      disableClose: true,
      data: {
        labKitOrder
      },
      width: '800px'
    });

    this.subscriptions.push(
      dialogRef.componentInstance.labKitOrderDeleted.subscribe(() => {
        this.refreshList();
      })
    );
  }
}
