import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { MatSort, PageEvent } from '@angular/material';
import { PaginatedList } from '@roctavian-abstractions/web';
import { BehaviorSubject, EMPTY, Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged, switchMap } from 'rxjs/operators';
import { StatusTypes, UtilityService } from '../../../common';
import { Prescriber } from '../../../prescriber';
import { Patient, PatientPrescriberAssociatePagedQuery } from '../../models';
import { PatientService } from '../../services';

@Component({
  selector: 'patient-prescriber-associate-list',
  templateUrl: './patient-prescriber-associate-list.component.html',
  styleUrls: ['./patient-prescriber-associate-list.component.scss']
})
export class PatientPrescriberAssociateListComponent implements OnInit, OnChanges, OnDestroy {
  @Input() patient: Patient;
  @Input() countryId: string;
  @Output() public prescriberSelected = new EventEmitter<Prescriber>();

  @Input() public showPrescriberIdColumn = false;
  @Input() public showFirstNameColumn = true;
  @Input() public showLastNameColumn = true;
  @Input() public showNationalIdColumn = false;
  @Input() public showAddressColumn = true;
  @Input() public showCityColumn = true;
  @Input() public showPostalCodeColumn = true;
  @Input() public showCountryColumn = true;
  @Input() public showStatusColumn = true;
  @Input() public showStatusDateColumn = false;

  public selectedPrescriber: Prescriber;

  public pagedQuery = new PatientPrescriberAssociatePagedQuery(1, 10, null, 'id', true, null, StatusTypes.Registered, this.countryId);

  public pagedCollection: PaginatedList<Prescriber>;
  public isLoading = false;
  public filterText$ = new BehaviorSubject<string>(null);

  private subscriptions = new Array<Subscription>();

  public displayedColumns = [];

  constructor(public utilityService: UtilityService, private service: PatientService) { }

  ngOnDestroy() {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
    this.service.pagedPatientPrescriberAssociateListSubject.next(new PaginatedList<Prescriber>());
  }

  public refreshList() {
    let patientId: string = null;
    if (this.patient) {
      patientId = this.patient.id;
    }

    this.service.setPatientPrescriberAssociatePage(this.pagedQuery, patientId);
  }

  ngOnInit() {
    this.initializeColumns();

    this.subscriptions.push(
      this.filterText$
        .pipe(
          debounceTime(1000),
          distinctUntilChanged(),
          switchMap(searchText => {
            if (this.pagedQuery.filterText === searchText) {
              return EMPTY;
            }

            this.pagedQuery.filterText = searchText;
            this.pagedQuery.pageIndex = 1;
            this.refreshList();
            return EMPTY;
          })
        )
        .subscribe()
    );

    this.subscriptions.push(
      this.service.pagedPatientPrescriberAssociateListSubject.subscribe(collection => {
        this.pagedCollection = collection;
      })
    );

    this.subscriptions.push(
      this.service.isLoadingSubject.subscribe(isLoading => {
        this.isLoading = isLoading;
      })
    );

    this.refreshList();
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes.countryId && changes.countryId.currentValue) {
      this.pagedQuery.country = changes.countryId.currentValue;
      this.refreshList();
    }
  }

  public onPageEvent(pageEvent: PageEvent) {
    this.pagedQuery.pageIndex = pageEvent.pageIndex + 1;
    this.pagedQuery.pageSize = pageEvent.pageSize;
    this.refreshList();
  }

  public onSortEvent(sort: MatSort) {
    switch (sort.active) {
      case 'stakeholder.statuses.statusType':
        this.pagedQuery.sortColumn = 'stakeholder.statuses.FirstOrDefault(x => x.IsCurrent).statusType';
        break;
      case 'stakeholder.statuses.addDate':
        this.pagedQuery.sortColumn = 'stakeholder.statuses.FirstOrDefault(x => x.IsCurrent).addDate';
        break;
      default:
        this.pagedQuery.sortColumn = sort.active;
        break;
    }

    this.pagedQuery.isSortDesc = sort.direction === 'desc';
    this.pagedQuery.pageIndex = 1;
    this.refreshList();
  }

  public onRowSelected(prescriber: Prescriber) {
    if (this.selectedPrescriber === prescriber) {
      this.selectedPrescriber = null;
      this.prescriberSelected.emit(null);
      return;
    }

    this.selectedPrescriber = prescriber;
    this.prescriberSelected.emit(this.selectedPrescriber);
  }

  private initializeColumns() {
    this.displayedColumns = [];

    if (this.showPrescriberIdColumn) {
      this.displayedColumns.push('id');
    }
    if (this.showFirstNameColumn) {
      this.displayedColumns.push('stakeholder.firstName');
    }
    if (this.showLastNameColumn) {
      this.displayedColumns.push('stakeholder.lastName');
    }
    if (this.showNationalIdColumn) {
      this.displayedColumns.push('nationalId');
    }
    if (this.showCountryColumn) {
      this.displayedColumns.push('institution.stakeholder.address.country.name');
    }
    if (this.showAddressColumn) {
      this.displayedColumns.push('institution.stakeholder.address.address1');
    }
    if (this.showCityColumn) {
      this.displayedColumns.push('institution.stakeholder.address.city');
    }
    if (this.showPostalCodeColumn) {
      this.displayedColumns.push('institution.stakeholder.address.postalCode');
    }
    if (this.showStatusColumn) {
      this.displayedColumns.push('stakeholder.statuses.statusType');
    }
    if (this.showStatusDateColumn) {
      this.displayedColumns.push('stakeholder.statuses.addDate');
    }
  }
}
