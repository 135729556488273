import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

import { PermissionClient } from 'apps/roctavian-eumea-callcenter-portal/src/app/modules/identity/clients/permission.client';
import { Permission, UpdatePermissionModel } from 'apps/roctavian-eumea-callcenter-portal/src/app/modules/identity/models';
import { PermissionFormSubmitEvent } from '../permission-form/permission-form.component';

@Component({
  selector: 'roctavian-abstractions-edit-permission',
  templateUrl: './edit-permission.component.html',
  styleUrls: ['./edit-permission.component.scss'],
})
export class EditPermissionComponent implements OnInit {
  @Input() public identity;

  @Output() public updated = new EventEmitter();

  public permission: Permission;
  public disabled = false;
  public messages: string[] = [];

  constructor(private client: PermissionClient) {}

  public ngOnInit() {
    this.client.getPermission(this.identity).subscribe(permission => {
      this.permission = permission;
    });
  }

  public handleSubmission(event: PermissionFormSubmitEvent) {
    const identity = this.permission.id;
    const model = new UpdatePermissionModel(event.name, event.description, event.group);
    this.client.updatePermission(identity, model).subscribe(
      outcome => {
        if (outcome.success) {
          this.updated.emit();
        }
      },
      response => {
        this.disabled = false;
        this.messages = response.error.messages;
      }
    );
  }
}
