import { Component, OnInit, Input, SimpleChanges, OnDestroy } from '@angular/core';
import { Patient, PatientPrescriber } from '../../models';
import { PagedQuery, UtilityService } from '../../../common';
import { PaginatedList } from '@roctavian-abstractions/web';
import { BehaviorSubject, Subscription, EMPTY } from 'rxjs';
import { PatientService } from '../../services';
import { debounceTime, distinctUntilChanged, switchMap } from 'rxjs/operators';
import { PageEvent, MatSort } from '@angular/material';

@Component({
  selector: 'patient-prescriber-associated-list',
  templateUrl: './patient-prescriber-associated-list.component.html',
  styleUrls: ['./patient-prescriber-associated-list.component.scss']
})
export class PatientPrescriberAssociatedListComponent implements OnInit, OnDestroy {
  @Input() public patient: Patient;

  public pagedQuery = new PagedQuery(1, 10, null, 'isCurrent', true);

  public patientPrescriberPagedCollection: PaginatedList<PatientPrescriber>;
  public isLoading = false;
  public filterText$ = new BehaviorSubject<string>(null);
  private subscriptions = new Array<Subscription>();

  public displayedColumns = [
    'isCurrent',
    'prescriberId',
    'prescriber.stakeholder.firstName',
    'prescriber.stakeholder.lastName',
    'prescriber.nationalId',
    'prescriber.institution.stakeholder.address.country.name',
    'prescriber.stakeholder.statuses.statusType',
    'prescriber.stakeholder.statuses.addDate',
    'addDate',
    'addUser.username'
  ];

  constructor(public utilityService: UtilityService, private service: PatientService) {
    this.subscriptions.push(this.filterText$
      .pipe(
        debounceTime(1000),
        distinctUntilChanged(),
        switchMap(searchText => {
          if (this.pagedQuery.filterText == searchText) {
            return EMPTY;
          }

          this.pagedQuery.filterText = searchText;
          this.pagedQuery.pageIndex = 1;
          this.refreshList();
          return EMPTY;
        })
      )
      .subscribe());
  }

  ngOnDestroy() {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
    this.service.pagedPatientPrescriberListSubject.next(new PaginatedList<PatientPrescriber>());
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (!changes.patient) {
      return;
    }

    if (changes.patient.currentValue) {
      this.refreshList();
    }
  }

  public refreshList() {
    if (!this.patient) {
      return;
    }

    this.service.setPatientPrescriberPage(this.patient.id, this.pagedQuery);
  }

  ngOnInit() {
    this.subscriptions.push(this.service.pagedPatientPrescriberListSubject.subscribe(
      collection => {
        this.patientPrescriberPagedCollection = collection;
      }
    ));

    this.subscriptions.push(this.service.isLoadingSubject.subscribe(isLoading => {
      this.isLoading = isLoading;
    }));
  }

  public onPageEvent(pageEvent: PageEvent) {
    this.pagedQuery.pageIndex = pageEvent.pageIndex + 1;
    this.pagedQuery.pageSize = pageEvent.pageSize;
    this.refreshList();
  }

  public onSortEvent(sort: MatSort) {
    switch (sort.active) {
      case 'prescriber.stakeholder.statuses.statusType':
        this.pagedQuery.sortColumn = 'prescriber.stakeholder.statuses.FirstOrDefault(x => x.IsCurrent).statusType';
        break;
      case 'prescriber.stakeholder.statuses.addDate':
        this.pagedQuery.sortColumn = 'prescriber.stakeholder.statuses.FirstOrDefault(x => x.IsCurrent).addDate';
        break;

      default:
        this.pagedQuery.sortColumn = sort.active;
        break;
    }

    this.pagedQuery.isSortDesc = sort.direction == 'desc';
    this.pagedQuery.pageIndex = 1;
    this.refreshList();
  }
}
