import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MAT_DIALOG_DEFAULT_OPTIONS } from '@angular/material';
import { RouterModule } from '@angular/router';
import { CoreDirectivesModule, MaterialModule, PipeModule } from '@roctavian-abstractions/core';
import { IdentityModule } from '../../../identity/identity.module';
import { SharedModule } from '../../../shared/shared.module';
import { StakeholderCommonModule } from '../common';
import { AddInstitutionComponent } from './components/add-institution/add-institution.component';
import { DeleteInstitutionDialogComponent } from './components/delete-institution-dialog/delete-institution-dialog.component';
import { InstitutionFormComponent } from './components/institution-form/institution-form.component';
import { InstitutionListComponent } from './components/institution-list/institution-list.component';
import { ViewInstitutionDialogComponent } from './components/view-institution-dialog/view-institution-dialog.component';
import { ViewInstitutionComponent } from './components/view-institution/view-institution.component';
import { InstitutionRoutingModule } from './institution-routing.module';
import { InstitutionAddPageComponent } from './pages/institution-add-page/institution-add-page.component';
import { InstitutionDetailMasterPageComponent } from './pages/institution-detail-master-page/institution-detail-master-page.component';
import { InstitutionEditPageComponent } from './pages/institution-edit-page/institution-edit-page.component';
import { InstitutionListPageComponent } from './pages/institution-list-page/institution-list-page.component';
import { InstitutionSummaryPageComponent } from './pages/institution-summary-page/institution-summary-page.component';

@NgModule({
  declarations: [
    InstitutionListPageComponent,
    InstitutionAddPageComponent,
    InstitutionDetailMasterPageComponent,
    InstitutionSummaryPageComponent,
    InstitutionEditPageComponent,
    AddInstitutionComponent,
    InstitutionListComponent,
    InstitutionFormComponent,
    ViewInstitutionComponent,
    ViewInstitutionDialogComponent,
    DeleteInstitutionDialogComponent
  ],
  imports: [
    CommonModule,
    IdentityModule,
    RouterModule,
    MaterialModule,
    FormsModule,
    HttpClientModule,
    ReactiveFormsModule,
    PipeModule,
    InstitutionRoutingModule,
    CoreDirectivesModule,
    StakeholderCommonModule,
    SharedModule
  ],
  providers: [{ provide: MAT_DIALOG_DEFAULT_OPTIONS, useValue: { hasBackdrop: false } }],
  entryComponents: [ViewInstitutionDialogComponent, DeleteInstitutionDialogComponent],
  exports: [
    InstitutionListPageComponent,
    InstitutionAddPageComponent,
    InstitutionDetailMasterPageComponent,
    InstitutionSummaryPageComponent,
    InstitutionEditPageComponent,
    AddInstitutionComponent,
    InstitutionListComponent,
    InstitutionFormComponent,
    ViewInstitutionComponent,
    ViewInstitutionDialogComponent,
    DeleteInstitutionDialogComponent
  ]
})
export class InstitutionModule {}
