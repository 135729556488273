import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'list-loading-indicator',
  templateUrl: './list-loading-indicator.component.html',
  styleUrls: ['./list-loading-indicator.component.css']
})
export class ListLoadingIndicatorComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
