import { Component, OnInit, Output, EventEmitter, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormGroupDirective } from '@angular/forms';
import { directions, actionTypes, results } from '../../constants';
import { CreateTaskActionModel } from '../../../../models';

@Component({
  selector: 'roctavian-abstractions-add-task-action-form',
  templateUrl: './add-task-action-form.component.html',
  styleUrls: ['./add-task-action-form.component.scss']
})
export class AddTaskActionFormComponent implements OnInit {
  @Output() public submitted: EventEmitter<CreateTaskActionModel> = new EventEmitter<CreateTaskActionModel>();

  @ViewChild(FormGroupDirective, { static: false }) private formGroupDirective: FormGroupDirective;

  public form: FormGroup;

  public directions = directions;
  public actionTypes = actionTypes;
  public results = results;

  constructor(private builder: FormBuilder) {}

  public ngOnInit() {
    this.buildForm();
  }

  public submit(form: FormGroup) {
    const model = new CreateTaskActionModel();
    Object.keys(form.controls).forEach(key => {
      model[key] = form.get(key).value;
    });

    this.submitted.emit(model);

    this.resetForm();
  }

  private resetForm(): void {
    this.formGroupDirective.resetForm();
    this.form.reset();
  }

  private buildForm() {
    this.form = this.builder.group({
      directionId: [null, Validators.required],
      actionTypeId: [null, Validators.required],
      resultId: [null, Validators.required],
      notes: [null]
    });
  }
}
