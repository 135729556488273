/* eslint-disable prefer-arrow/prefer-arrow-functions */
import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { MessageService } from '@roctavian-abstractions/core';
import { Outcome } from '@roctavian-abstractions/web';
import { UserClient } from 'apps/roctavian-eumea-callcenter-portal/src/app/modules/identity/clients/user.client';
import { SimplifiedUser } from 'apps/roctavian-eumea-callcenter-portal/src/app/modules/identity/models';
import { userRole } from 'apps/roctavian-eumea-callcenter-portal/src/app/modules/identity/models/user-role.model';

@Component({
  selector: 'roctavian-abstractions-user-details',
  templateUrl: './user-details.component.html',
  styleUrls: ['./user-details.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UserDetailsComponent implements OnInit {
  @Input()
  public user: SimplifiedUser;
  constructor(private client: UserClient, private service: MessageService) {}

  public ngOnInit() {}

  public getCommaSeparatedUserRoles(userRoles: userRole[]): string {
    userRoles.sort(function(a, b) {
      const roleA = a.role.name.toUpperCase();
      const roleB = b.role.name.toUpperCase();
      if (roleA < roleB) {
        return -1;
      }
      if (roleA > roleB) {
        return 1;
      }
      return 0;
    });
    //get comma separated list of roles for user
    let userRolesCommaSeparated: string = '';
    for (let i = 0; i < userRoles.length; i++) {
      userRolesCommaSeparated += userRoles[i].role.name;
      if (userRoles.length > 1 && i != userRoles.length - 1) {
        userRolesCommaSeparated += ', ';
      }
    }
    return userRolesCommaSeparated;
  }

  public handleLock() {
    this.user.locked = true;
    this.user = Object.assign(this.user, {});
    this.client.lockUser(this.user.id).subscribe(
      (outcome: Outcome) => {
        this.service.open('The user account has been locked.', 5000);
      },
      () => {
        this.user.locked = false;
        this.user = Object.assign(this.user, {});
        this.service.open('An error occurred when locking the user account.', 5000);
      }
    );
  }

  public handleUnlock() {
    this.user.locked = false;
    this.user = Object.assign(this.user, {});
    this.client.unlockUser(this.user.id).subscribe(
      (outcome: Outcome) => {
        this.service.open('The user account has been unlocked.', 5000);
      },
      () => {
        this.user.locked = true;
        this.user = Object.assign(this.user, {});
        this.service.open('An error occurred when unlocking the user account.', 5000);
      }
    );
  }

  public handleReset() {
    this.client.initiateReset(this.user.id).subscribe(
      () => {
        this.service.open('The forgot password email has been sent.', 5000);
      },
      () => {
        this.service.open(
          'An error occurred when initiating the password reset process.',
          5000
        );
      }
    );
  }
}
