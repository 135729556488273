import { Component, Input, Output, EventEmitter } from '@angular/core';
import { Workgroup } from '../../../../models';

@Component({
  selector: 'roctavian-abstractions-workgroup-card',
  templateUrl: './workgroup-card.component.html',
  styleUrls: ['./workgroup-card.component.scss']
})
export class WorkgroupCardComponent {
  @Input() public workgroup: Workgroup;
  @Output() public selected: EventEmitter<Workgroup> = new EventEmitter<Workgroup>();

  constructor() {}

  public onSelect() {
    this.selected.emit(this.workgroup);
  }
}
