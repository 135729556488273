/**
 * Class used to represent a task action.
 */
export class TaskAction {
  public taskActionIdentity: number;
  public taskIdentity: number;
  public actionTypeId: number;
  public directionId: number;
  public resultId: number;
  public notes: string;
  public isCurrent: boolean;
  public addDate: Date;
}
