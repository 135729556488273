import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { Endpoints } from '@app/core/enums';
import { Status, StatusPagedQuery } from '@app/stakeholder/models';
import { UtilityService } from '@app/stakeholder/services';
import { Outcome, PaginatedList, ValueOutcome } from '@roctavian-abstractions/web';

@Injectable({
  providedIn: 'root',
})
export class StatusClient {
  constructor(private readonly http: HttpClient, private readonly utilityService: UtilityService) {}

  saveStatus(status: Status): Observable<Outcome> {
    return this.http.post<Outcome>(Endpoints.status, status);
  }

  getStatusListPaged(pagedQuery: StatusPagedQuery): Observable<ValueOutcome<PaginatedList<Status>>> {
    return this.http.get<ValueOutcome<PaginatedList<Status>>>(Endpoints.status, {
      params: this.utilityService.toHttpParams(pagedQuery),
    });
  }
}
