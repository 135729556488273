import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MAT_DIALOG_DEFAULT_OPTIONS, MatProgressSpinnerModule } from '@angular/material';
import { RouterModule } from '@angular/router';
import { MaterialModule } from '@roctavian-abstractions/core';
import { IdentityModule } from '../../../identity/identity.module';
import { SharedModule } from '../../../shared/shared.module';
import { StakeholderCommonModule } from '../common';
import {
  AddLabComponent,
  AuthorizedRepresentativeFormComponent,
  LabFormComponent,
  LabListComponent,
  LabLookupComponent,
  LabPrescriberListComponent,
  LabUserListComponent,
  ViewAuthorizedRepresentativeComponent,
  ViewLabComponent,
  ViewLabDialogComponent
} from './components';
import { AssociateLabUserToLabComponent } from './components/associate-lab-user-to-lab/associate-lab-user-to-lab.component';
import { AssociateNewLabUserListComponent } from './components/associate-new-lab-user-list/associate-new-lab-user-list.component';
import { DisassociateLabUserDialogComponent } from './components/disassociate-lab-user-dialog/disassociate-lab-user-dialog.component';
import { LabRoutingModule } from './lab-routing.module';
import {
  AddLabPageComponent,
  LabDetailMasterPageComponent,
  LabEditPageComponent,
  LabListPageComponent,
  LabPrescriberListPageComponent,
  LabStatusPageComponent,
  LabSummaryPageComponent,
  LabUserListPageComponent
} from './pages';
import { LabDocumentsPageComponent } from './pages/lab-documents-page/lab-documents-page.component';

@NgModule({
  declarations: [
    LabListPageComponent,
    AddLabPageComponent,
    LabDetailMasterPageComponent,
    LabSummaryPageComponent,
    LabStatusPageComponent,
    LabEditPageComponent,
    LabListComponent,
    ViewLabDialogComponent,
    ViewLabComponent,
    AddLabComponent,
    LabFormComponent,
    LabLookupComponent,
    AuthorizedRepresentativeFormComponent,
    ViewAuthorizedRepresentativeComponent,
    LabPrescriberListComponent,
    LabPrescriberListPageComponent,
    LabUserListComponent,
    LabUserListPageComponent,
    DisassociateLabUserDialogComponent,
    AssociateLabUserToLabComponent,
    AssociateNewLabUserListComponent,
    LabDocumentsPageComponent
  ],
  imports: [
    CommonModule,
    IdentityModule,
    RouterModule,
    MaterialModule,
    FormsModule,
    HttpClientModule,
    ReactiveFormsModule,
    LabRoutingModule,
    StakeholderCommonModule,
    SharedModule,
    MatProgressSpinnerModule
  ],
  providers: [{ provide: MAT_DIALOG_DEFAULT_OPTIONS, useValue: { hasBackdrop: false } }],
  entryComponents: [ViewLabDialogComponent, DisassociateLabUserDialogComponent]
})
export class LabModule {}
