import { ChangeReasonTypes, StatusTypes } from '../enums';
import { Auditable } from './auditable.model';

export class Status extends Auditable {
  public id: string;
  public isCurrent: boolean;
  public statusType: StatusTypes;
  public changeReasonDescription: string;
  public changeReasonType: ChangeReasonTypes;
  public stakeholderId: string;
}
