import { OnDestroy } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import { PrescriberDelegate } from '../../models/prescriber-delegate.model';
import { Subscription } from 'rxjs';
import { PrescriberDelegateService } from '../../services/prescriber-delegate.service';

@Component({
  selector: 'roctavian-eumea-callcenter-portal-prescriber-list-page',
  templateUrl: './prescriber-list-page.component.html',
  styleUrls: ['./prescriber-list-page.component.scss']
})
export class PrescriberListPageComponent implements OnInit, OnDestroy {
  public prescriberDelegate: PrescriberDelegate;
  private subscriptions = new Array<Subscription>();

  constructor(private service: PrescriberDelegateService) {}

  ngOnInit() {
    this.subscriptions.push(
      this.service.selectedPrescriberDelegateSubject.subscribe(
        prescriberDelegate => (this.prescriberDelegate = prescriberDelegate)
      )
    );
  }

  ngOnDestroy() {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }
}
