export enum ContentTypes {
  bmp,
  docx,
  gif,
  jpg,
  jpeg,
  json,
  pdf,
  png,
  pptx,
  text,
  xlsx,
  txt,
  tiff,
  tif,
  doc,
  xls,
}
