import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

import { Document } from '@app/stakeholder/models';

@Injectable({
  providedIn: 'root',
})
export class FileUploadMessageService {
  private readonly uploaded = new Subject<Document>();

  readonly uploaded$ = this.uploaded.asObservable();

  uploadComplete(document: Document) {
    this.uploaded.next(document);
  }
}
