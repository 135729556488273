import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroupDirective, Validators } from '@angular/forms';
import { AppTranslationService } from '@roctavian-abstractions/core';
import { GenderTypes, UtilityService } from '../../../common';
import { Prescriber } from '../../../prescriber';
import { Patient } from '../../models';

@Component({
  selector: 'patient-form',
  templateUrl: './patient-form.component.html',
  styleUrls: ['./patient-form.component.scss']
})
export class PatientFormComponent implements OnInit {
  @Input() public patient: Patient;

  @Input() public title = this.translate.getTranslation('Patient.PatientForm.Title');
  @Input() public showTitle = true;

  @Input() public birthDateLabel = this.translate.getTranslation('Patient.PatientForm.Birthdate');
  @Input() public genderLabel = this.translate.getTranslation('Patient.PatientForm.Gender');
  @Input() public drugInclusionLabel = this.translate.getTranslation('Patient.PatientForm.PatientDrugInclusionLabel');
  @Input() public medicalInformationTitle = this.translate.getTranslation(
    'Patient.PatientForm.MedicalInformationTitle'
  );
  @Input() public medicalRecordNumberLabel = this.translate.getTranslation('Patient.PatientForm.MedicalRecordNumber');
  @Input() public prescriberSelectionLabel = this.translate.getTranslation('Patient.PatientForm.PrescriberSelection');
  @Input() public attestationTitle = this.translate.getTranslation('Patient.PatientForm.AttestationTitle');
  @Input() public middleInitialLabel = this.translate.getTranslation('Patient.PatientForm.MiddleInitial');
  @Input() public signatureDateLabel = this.translate.getTranslation('Patient.PatientAttestationPage.AttestationFormSignatureDateLabel');
  @Input() public showPrescriberAssociation = true;

  @Input() public showAttestation = true;
  @Input() public showPrescriberSelection = false;

  @Input() public areFieldsRequired = false;

  public genderTypes = GenderTypes;
  public currentDate = new Date();
  public countryId = null;

  constructor(
    public parentFormGroupDirective: FormGroupDirective,
    public utilityService: UtilityService,
    private translate: AppTranslationService,
    private formBuilder: FormBuilder
  ) { }

  ngOnInit() {
    this.buildForm();
    this.refreshValidations(this.areFieldsRequired);
  }

  private buildForm() {
    this.parentFormGroupDirective.form.addControl('drugInclusion', new FormControl(null));
    this.parentFormGroupDirective.form.addControl('birthDate', new FormControl(null));
    this.parentFormGroupDirective.form.addControl('gender', new FormControl(null));
    this.parentFormGroupDirective.form.addControl('medicalRecordNumber', new FormControl(null));
    this.parentFormGroupDirective.form.addControl(
      'patientsPrescribers',
      this.formBuilder.array([
        this.formBuilder.group({
          prescriberId: new FormControl(null, [Validators.required])
        })
      ])
    );
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes.areFieldsRequired) {
      this.refreshValidations(changes.areFieldsRequired.currentValue);
    }
  }

  private refreshValidations(areFieldsRequired: boolean) {
    if (
      !this.parentFormGroupDirective.form ||
      !this.parentFormGroupDirective.form.controls ||
      !this.parentFormGroupDirective.form.get('birthDate')||
      !this.parentFormGroupDirective.form.get('drugInclusion')
    ) {
      return;
    }

    this.parentFormGroupDirective.form.get('birthDate').clearValidators();
    this.parentFormGroupDirective.form.get('gender').clearValidators();
    this.parentFormGroupDirective.form.get('drugInclusion').clearValidators();
    this.parentFormGroupDirective.form.get('patientsPrescribers').clearValidators();

    if (!areFieldsRequired) {
      return;
    }

    this.parentFormGroupDirective.form.get('birthDate').setValidators([Validators.required]);
    this.parentFormGroupDirective.form.get('gender').setValidators([Validators.required]);
    this.parentFormGroupDirective.form.get('drugInclusion').setValidators([Validators.required]);
    this.parentFormGroupDirective.form.get('patientsPrescribers').setValidators([Validators.required]);
  }

  public onPrescriberSelected(prescriber: Prescriber) {
    const patientPrescriberAssociations = this.parentFormGroupDirective.form.get('patientsPrescribers') as FormArray;

    if (prescriber) {
      patientPrescriberAssociations.controls[0].get('prescriberId').setValue(prescriber.id);
      return;
    }

    patientPrescriberAssociations.controls[0].get('prescriberId').setValue(null);
  }

  public onCountryChange(data) {
    this.countryId = data;
  }
}
