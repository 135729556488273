/* eslint-disable @typescript-eslint/member-ordering */
import { Component, Input, OnInit } from '@angular/core';
import { PermissionClient } from 'apps/roctavian-eumea-callcenter-portal/src/app/modules/identity/clients/permission.client';
import { PermissionListToggleEvent } from '../permission-toggle-group/permission-toggle-group.component';
import {
  PermissionToggleEvent, PermissionViewModel
} from '../permission-toggle/permission-toggle.component';

@Component({
  selector: 'roctavian-abstractions-permission-toggle-group-list',
  templateUrl: './permission-toggle-group-list.component.html',
  styleUrls: ['./permission-toggle-group-list.component.scss'],
})
export class PermissionToggleGroupListComponent implements OnInit {
  @Input() identities: string[] = [];

  public permissionGroups: PermissionViewModel[][];

  constructor(private client: PermissionClient) {}

  public ngOnInit() {
    this.client.getPermissions().subscribe(permissions => {
      const groups = this.getDistinctGroups(permissions);
      this.permissionGroups = this.groupPermissions(permissions, groups);
    });
  }

  private getDistinctGroups(permissions): string[] {
    const groups = [];
    // eslint-disable-next-line @typescript-eslint/prefer-for-of
    for (let i = 0; i < permissions.length; i++) {
      if (groups.indexOf(permissions[i].group) === -1) {
        groups.push(permissions[i].group);
      }
    }
    return groups;
  }

  private groupPermissions(permissions, groups: string[]) {
    const permissionsGroups = [];
    // eslint-disable-next-line @typescript-eslint/prefer-for-of
    for (let i = 0; i < groups.length; i++) {
      const group = groups[i];
      // Get all permissions belonging to the group:
      const groupedPermissions = permissions.filter(p => p.group === group);
      // Map them into the permission view model:
      const mappedPermissions = groupedPermissions.map(
        p => new PermissionViewModel(p, this.isSelected(p.permissionIdentity))
      );
      permissionsGroups.push(mappedPermissions);
    }
    return permissionsGroups;
  }

  private isSelected(identity: string) {
    return this.identities.indexOf(identity) !== -1;
  }

  public handleToggle(event: PermissionToggleEvent) {
    const groups = this.permissionGroups.slice();
    for (let i = 0; i < groups.length; i++) {
      // Create a new array from the list of permissions:
      const permissions = groups[i].slice();
      // Determine if the permission that was toggled is in the group we are looking at:
      if (
        permissions.findIndex(p => p.permissionIdentity === event.permissionIdentity) ===
        -1
      ) {
        continue;
      }

      // Retrieve the permission from the
      const index = permissions.findIndex(
        p => p.permissionIdentity === event.permissionIdentity
      );
      const permission = permissions[index];
      // Create a copy of the permission which will have a new reference:
      const newPermission = Object.assign({}, permission);
      // Toggle the enabled field of the permission:
      newPermission.enabled = !newPermission.enabled;
      permissions[index] = newPermission;
      // Set the permissions array to the new modified version:
      groups[i] = permissions;
    }
    this.permissionGroups = groups;
  }

  public handleListToggled(event: PermissionListToggleEvent) {
    const groups = this.permissionGroups.slice();
    for (const identity of event.identities) {
      for (let i = 0; i < groups.length; i++) {
        // Create a new array from the list of permissions:
        const permissions = groups[i].slice();
        // Determine if the permission that was toggled is in the group we are looking at:
        if (permissions.findIndex(p => p.permissionIdentity === identity) === -1) {
          continue;
        }

        // Retrieve the permission from the
        const index = permissions.findIndex(p => p.permissionIdentity === identity);
        const permission = permissions[index];
        // Create a copy of the permission which will have a new reference:
        const newPermission = Object.assign({}, permission);
        // Toggle the enabled field of the permission:
        newPermission.enabled = event.checked;
        permissions[index] = newPermission;
        // Set the permissions array to the new modified version:
        groups[i] = permissions;
      }
    }
    this.permissionGroups = groups;
  }

  public getSelectedPermissionIdentities() {
    const identities: string[] = [];
    for (const permissions of this.permissionGroups) {
      for (const permission of permissions) {
        if (permission.enabled) {
          identities.push(permission.permissionIdentity);
        }
      }
    }
    return identities;
  }
}
