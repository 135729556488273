import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MAT_RIPPLE_GLOBAL_OPTIONS, RippleGlobalOptions } from '@angular/material';
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { CoreDirectivesModule } from '@roctavian-abstractions/core';
import { AppComponent } from './app.component';
import { NavbarComponent } from './components/navbar/navbar.component';
import { HomeComponent } from './home/home.component';
import { AdminModule } from './modules/admin/admin.module';
import { AuthenticationEventHandler } from './modules/identity/authentication-event.handler';
import { IdentityModule } from './modules/identity/identity.module';
import { SessionManagementEventHandler } from './modules/identity/session-management-event.handler';
import { NotAuthenticatedGuard } from './modules/shared/guards/not-authenticated.guard';
import { LoginPageComponent } from './modules/shared/pages/login-page/login-page.component';
import { SharedModule } from './modules/shared/shared.module';
import { StakeholderModule } from './modules/stakeholder';
import { TasksModule } from './modules/tasks/tasks.module';
import { RoctavianAuthenticationEventHandler } from './roctavian-authentication-event.handler';
import { RoctavianSessionManagementEventHandler } from './roctavian-session-management-event.handler';

const globalRippleConfig: RippleGlobalOptions = {
  disabled: false,
};

@NgModule({
  declarations: [AppComponent, HomeComponent, NavbarComponent],
  imports: [
    BrowserModule,
    SharedModule,
    HttpClientModule,
    FormsModule,
    MatDialogModule,
    BrowserAnimationsModule,
    RouterModule.forRoot([
      { path: '', redirectTo: 'log-in', pathMatch: 'full' },
      {
        path: 'log-in',
        component: LoginPageComponent,
        canActivate: [NotAuthenticatedGuard],
      },
    ]),
    CoreDirectivesModule,
    AdminModule,
    IdentityModule,
    TasksModule,
    StakeholderModule,
  ],
  providers: [
    {
      provide: AuthenticationEventHandler,
      useClass: RoctavianAuthenticationEventHandler,
    },
    { provide: MAT_RIPPLE_GLOBAL_OPTIONS, useValue: globalRippleConfig },
    {
      provide: SessionManagementEventHandler,
      useClass: RoctavianSessionManagementEventHandler,
    },
    { provide: MAT_DIALOG_DATA, useValue: {} },
    { provide: MatDialogRef, useValue: {} },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
