import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {
  MatButtonToggleModule, MatDividerModule, MatPaginatorModule
} from '@angular/material';
import { RouterModule } from '@angular/router';
import {
  AlertModule, ErrorsModule, MaterialModule, PanelModule, SearchModule, ToggleModule, WrapperModule
} from '@roctavian-abstractions/core';
import { HistoryModule } from '../../../identity/modules/history/history.module';
import { PermissionsModule } from '../permissions/permissions.module';
import { AdminAuthenticationHistoryComponent } from './components/admin-authentication-history/admin-authentication-history.component';
import { ConfirmResetDialogComponent } from './components/confirm-reset-dialog/confirm-reset-dialog.component';
import { CreateUserFormComponent } from './components/create-user-form/create-user-form.component';
import { CreateUserComponent } from './components/create-user/create-user.component';
import { EditUserFormComponent } from './components/edit-user-form/edit-user-form.component';
import { EditUserComponent } from './components/edit-user/edit-user.component';
import { UserContextMenuComponent } from './components/user-context-menu/user-context-menu.component';
import { UserDetailsComponent } from './components/user-details/user-details.component';
import { UserGridComponent } from './components/user-grid/user-grid.component';

@NgModule({
  imports: [
    CommonModule,
    PanelModule,
    AlertModule,
    WrapperModule,
    RouterModule,
    MaterialModule,
    MatPaginatorModule,
    FormsModule,
    ReactiveFormsModule,
    SearchModule,
    ErrorsModule,
    MatDividerModule,
    MatButtonToggleModule,
    HistoryModule,
    ToggleModule,
    PermissionsModule,
  ],
  declarations: [
    UserGridComponent,
    UserDetailsComponent,
    CreateUserComponent,
    CreateUserFormComponent,
    UserContextMenuComponent,
    AdminAuthenticationHistoryComponent,
    EditUserComponent,
    EditUserFormComponent,
    ConfirmResetDialogComponent,
  ],
  exports: [
    UserGridComponent,
    UserDetailsComponent,
    CreateUserComponent,
    CreateUserFormComponent,
    EditUserComponent,
    EditUserFormComponent,
    UserContextMenuComponent,
    ConfirmResetDialogComponent,
    AdminAuthenticationHistoryComponent,
  ],
  entryComponents: [ConfirmResetDialogComponent],
})
export class UserModule {}
