import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { AppTranslationService, MessageService } from '@roctavian-abstractions/core';
import { Outcome } from '@roctavian-abstractions/web';
import { Subscription } from 'rxjs';
import { UtilityService } from '../../../common';
import { ExternalLabTestClient } from '../../clients/external-lab-test.client';
import { ExternalLabTest } from '../../models/external-lab-test.model';
import { ExternalLabTestService } from '../../services/external-lab-test.service';

@Component({
  selector: 'edit-external-lab-test-page',
  templateUrl: './edit-external-lab-test-page.component.html',
  styleUrls: ['./edit-external-lab-test-page.component.scss']
})
export class EditExternalLabTestPageComponent implements OnInit, OnDestroy {
  public form: FormGroup;
  public ExternalLabTest: ExternalLabTest;
  public isProcessing = false;
  public errorMessages = new Array<string>();

  private subscriptions = new Array<Subscription>();

  constructor(
    private formBuilder: FormBuilder,
    private externalLabTestService: ExternalLabTestService,
    private externalLabTestClient: ExternalLabTestClient,
    private messageService: MessageService,
    private translate: AppTranslationService,
    public utilityService: UtilityService,
    private router: Router
  ) { }

  ngOnDestroy() {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }

  ngOnInit() {
    this.form = this.formBuilder.group({});

    this.subscriptions.push(
      this.externalLabTestService.selectedExternalLabTestSubject.subscribe(externalLabTest => {
        this.ExternalLabTest = externalLabTest;
        if (!this.ExternalLabTest) {
          return;
        }

        setTimeout(() => {
          this.form.patchValue({
            ...this.ExternalLabTest
          });
        });
      })
    );
  }

  public submit() {
    this.form.markAllAsTouched();

    if (this.form.invalid) {
      return;
    }

    this.isProcessing = true;
    this.errorMessages = [];
    const updatedExternalLabTest: ExternalLabTest = this.form.getRawValue();
    updatedExternalLabTest.id = this.ExternalLabTest.id;

    this.subscriptions.push(
      this.externalLabTestClient.updateExternalLabTest(updatedExternalLabTest).subscribe(
        outcome => {
          this.isProcessing = false;
          if (outcome.failure) {
            this.handleError(outcome);
            return;
          }

          this.messageService.open(this.translate.getTranslation('ExternalLabTest.ExternalLabTestEditPage.EditSuccessMessage'), 5000);
          this.externalLabTestService.loadExternalLabTest(this.ExternalLabTest.id, true);
          this.router.navigate(['/external-lab-test', this.ExternalLabTest.id]);
        },
        (error: Outcome) => {
          this.isProcessing = false;
          this.handleError(error);
        }
      )
    );
  }

  private handleError(error: Outcome) {
    if (!error || !error.messages || error.messages.length === 0) {
      this.errorMessages = [this.translate.getTranslation('Common.ErrorProcessingRequest')];
    } else {
      this.errorMessages = error.messages;
    }
  }
}
