import { OnDestroy } from '@angular/core';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Subscription } from 'rxjs';
import { PaginatedList, Outcome } from '@roctavian-abstractions/web';
import { LabKitOrder } from '../models/lab-kit-order.model';
import { LabKitOrderClient } from '../clients/lab-kit-order.client';
import { MessageService } from '@roctavian-abstractions/core';
import { AppTranslationService } from '@roctavian-abstractions/core';
import { PagedQuery } from '../../common';
import { LabKitOrderPagedQuery } from '../models/lab-kit-order-paged-query.model';
import { ClearService } from '../../../../shared/interface/clear-service.interface';

@Injectable({
  providedIn: 'root'
})
export class LabKitOrderService implements OnDestroy, ClearService {
  public labKitOrderPagedListSubject = new BehaviorSubject<PaginatedList<LabKitOrder>>(
    new PaginatedList<LabKitOrder>()
  );
  public isLoadingSubject = new BehaviorSubject<boolean>(false);

  public selectedLabKitOrderSubject = new BehaviorSubject<LabKitOrder>(null);

  private subscriptions = new Array<Subscription>();

  constructor(
    private labKitOrderClient: LabKitOrderClient,
    private messageService: MessageService,
    private translate: AppTranslationService
  ) { }

  ngOnDestroy() {
    this.clearSubscriptions();
  }

  private clearSubscriptions() {
    this.subscriptions.forEach(subscription => {
      subscription.unsubscribe();
    });

    this.subscriptions = new Array<Subscription>();
  }

  public setPage(pagedQuery: LabKitOrderPagedQuery) {
    this.isLoadingSubject.next(true);

    this.clearSubscriptions();

    this.subscriptions.push(
      this.labKitOrderClient.getLabKitOrderListPaged(pagedQuery).subscribe(
        outcome => {
          if (!outcome.success || outcome.failure) {
            this.messageService.open(outcome.messages[0], 5000);
            return;
          }

          this.labKitOrderPagedListSubject.next(outcome.value);
          this.isLoadingSubject.next(false);
        },
        (error: Outcome) => {
          this.handleError(error);
        }
      )
    );
  }

  public selectLabKitOrder(labKitOrderId: string, forceRefresh: boolean = false) {
    if (
      this.selectedLabKitOrderSubject.value &&
      this.selectedLabKitOrderSubject.value.id === labKitOrderId &&
      !forceRefresh
    ) {
      return;
    }

    this.clearSubscriptions();

    this.isLoadingSubject.next(true);

    this.subscriptions.push(
      this.labKitOrderClient.getLabKitOrder(labKitOrderId).subscribe(
        outcome => {
          if (!outcome.success || outcome.failure) {
            this.messageService.open(outcome.messages[0], 5000);
            return;
          }

          this.selectedLabKitOrderSubject.next(outcome.value);
          this.isLoadingSubject.next(false);
        },
        (error: Outcome) => {
          this.handleError(error);
        }
      )
    );
  }

  private handleError(outcome: Outcome) {
    this.messageService.open(this.translate.getTranslation('Common.ErrorProcessingRequest'), 5000);
    this.isLoadingSubject.next(false);
  }

  public clearService() {
    this.labKitOrderPagedListSubject.next(new PaginatedList<LabKitOrder>());
    this.selectedLabKitOrderSubject.next(null);
  }
}
