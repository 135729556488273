import { Component, OnInit, ViewChild, EventEmitter, Output, Input } from '@angular/core';
import { Stakeholder } from '../../models';
import { StatusListComponent } from '../../components';

@Component({
  selector: 'status-list-page',
  templateUrl: './status-list-page.component.html',
  styleUrls: ['./status-list-page.component.css']
})
export class StatusListPageComponent implements OnInit {
  @Input() public showAddStatusForm = false;
  @Input() public showAddStatusButton = true;
  @Input() public stakeholder: Stakeholder;
  @Output() public statusSaved = new EventEmitter();

  @ViewChild('statusList', { static: false }) statusListComponent: StatusListComponent;

  constructor() {}

  ngOnInit() {}

  public beginAddStatus() {
    this.showAddStatusForm = true;
  }

  public onStatusSaved() {
    this.statusSaved.emit();
    this.statusListComponent.refreshList();
    this.showAddStatusForm = false;
  }

  public onCancelAddStatus() {
    this.showAddStatusForm = false;
  }
}
