import { Component, OnInit, Inject } from '@angular/core';
import { Institution } from '../../models';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';

@Component({
  selector: 'institution-dialog',
  templateUrl: './view-institution-dialog.component.html',
  styleUrls: ['./view-institution-dialog.component.scss']
})
export class ViewInstitutionDialogComponent {
  public institution: Institution;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public reference: MatDialogRef<ViewInstitutionDialogComponent>
  ) {
    this.institution = data.institution;
  }
}
