import { OnDestroy, Output, EventEmitter, Inject } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import { LabKitOrder } from '../../models/lab-kit-order.model';
import { Subscription } from 'rxjs';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { LabKitOrderClient } from '../../clients/lab-kit-order.client';
import { MessageService } from '@roctavian-abstractions/core';
import { AppTranslationService } from '@roctavian-abstractions/core';

@Component({
  selector: 'roctavian-eumea-callcenter-portal-delete-lab-kit-order-dialog',
  templateUrl: './delete-lab-kit-order-dialog.component.html',
  styleUrls: ['./delete-lab-kit-order-dialog.component.scss']
})
export class DeleteLabKitOrderDialogComponent implements OnInit, OnDestroy {
  public labKitOrderIdLabel = this.translate.getTranslation('LabKitOrder.LabKitOrderId');
  public labNameLabel = this.translate.getTranslation('LabKitOrder.LabName');
  public nationalIdLabel = this.translate.getTranslation('LabKitOrder.NationalId');

  public isProcessing = false;

  public labKitOrder: LabKitOrder;
  @Output() public labKitOrderDeleted = new EventEmitter();

  private subscriptions = new Array<Subscription>();

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public reference: MatDialogRef<DeleteLabKitOrderDialogComponent>,
    private client: LabKitOrderClient,
    private messageService: MessageService,
    private translate: AppTranslationService
  ) {
    this.labKitOrder = data.labKitOrder;
  }

  public ngOnInit() { }

  ngOnDestroy() {
    this.subscriptions.forEach(subscription => {
      subscription.unsubscribe();
    });
  }

  public submit() {
    this.isProcessing = true;

    this.client.deleteLabKitOrder(this.labKitOrder.id).subscribe(
      outcome => {
        this.isProcessing = false;
        if (!outcome.success) {
          this.messageService.open(this.translate.getTranslation('Common.ErrorProcessingRequest'), 3000);
          return;
        }

        this.messageService.open(this.translate.getTranslation('LabKitOrder.SuccessfullyDeleted'), 3000);
        this.labKitOrderDeleted.emit();
        this.reference.close();
      },
      error => {
        this.isProcessing = false;
        this.messageService.open(this.translate.getTranslation('Common.ErrorProcessingRequest'), 3000);
      }
    );
  }
}
