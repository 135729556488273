import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Role } from 'apps/roctavian-eumea-callcenter-portal/src/app/modules/identity/models';

export class CreateUserSubmitEvent {
  public username: string;
  public email: string;
  public firstName: string;
  public lastName: string;
  public roles: string[];
}

@Component({
  selector: 'roctavian-abstractions-create-user-form',
  templateUrl: './create-user-form.component.html',
  styleUrls: ['./create-user-form.component.scss'],
})
export class CreateUserFormComponent {
  public form: FormGroup;

  @Input() public disabled = false;

  @Input() public allRoles: Role[];

  @Output() public submitted = new EventEmitter<any>();

  constructor(private formBuilder: FormBuilder) {
    this.form = this.formBuilder.group({
      username: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
      firstName: ['', Validators.required],
      lastName: ['', Validators.required],
      roles: ['', Validators.required],
    });
  }

  public submit(form: FormGroup) {
    const event = new CreateUserSubmitEvent();
    event.username = form.value.username;
    event.firstName = form.value.firstName;
    event.lastName = form.value.lastName;
    event.email = form.value.email;
    event.roles = form.value.roles;
    this.submitted.emit(event);
  }
}
