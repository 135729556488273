import { Component, OnInit, Input, OnChanges, SimpleChanges, OnDestroy } from '@angular/core';
import { PatientPrescriber } from '../../../patient/models/patient-prescriber.model';
import { UtilityService } from '../../../common/services/utility.service';
import { PagedQuery } from '../../../common/models/paged-query.model';
import { PaginatedList } from '@roctavian-abstractions/web';
import { PrescriberService } from '../../services/prescriber.service';
import { PageEvent, MatSort } from '@angular/material';
import { BehaviorSubject, Subscription, EMPTY } from 'rxjs';
import { debounceTime, distinctUntilChanged, switchMap } from 'rxjs/operators';
import { Prescriber } from '../../models';

@Component({
  selector: 'prescriber-patient-list',
  templateUrl: './prescriber-patient-list.component.html',
  styleUrls: ['./prescriber-patient-list.component.css']
})
export class PrescriberPatientListComponent implements OnInit, OnChanges, OnDestroy {
  @Input() prescriber: Prescriber;

  public pagedQuery = new PagedQuery(1, 10, null, 'patient.id', true);

  public patientPrescriberPagedCollection: PaginatedList<PatientPrescriber>;
  public isLoading = false;
  public filterText$ = new BehaviorSubject<string>(null);
  private subscriptions = new Array<Subscription>();

  public displayedColumns = [
    'patient.id',
    'patient.stakeholder.firstName',
    'patient.stakeholder.lastName',
    'patient.labTest.statuses.statusType',
    'patient.birthDate',
    'patient.stakeholder.address.country.name',
    'patient.stakeholder.statuses.statusType',
    'patient.stakeholder.statuses.addDate'
  ];

  constructor(public utilityService: UtilityService, private prescriberService: PrescriberService) {
    this.subscriptions.push(this.filterText$
      .pipe(
        debounceTime(1000),
        distinctUntilChanged(),
        switchMap(searchText => {
          if (this.pagedQuery.filterText == searchText) {
            return EMPTY;
          }

          this.pagedQuery.filterText = searchText;
          this.pagedQuery.pageIndex = 1;
          this.refreshList();
          return EMPTY;
        })
      )
      .subscribe());
  }

  public ngOnDestroy() {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
    this.prescriberService.patientPrescriberPagedListSubject.next(new PaginatedList<PatientPrescriber>());
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (!changes.prescriber) {
      return;
    }

    if (changes.prescriber.currentValue) {
      this.refreshList();
    }
  }

  private refreshList() {
    if (!this.prescriber) {
      return;
    }

    this.prescriberService.setPatientPrescriberPage(this.pagedQuery, this.prescriber.id);
  }

  public ngOnInit() {
    this.subscriptions.push(this.prescriberService.patientPrescriberPagedListSubject.subscribe(
      collection => {
        this.patientPrescriberPagedCollection = collection;
      }
    ));

    this.subscriptions.push(this.prescriberService.isLoadingSubject.subscribe(isLoading => {
      this.isLoading = isLoading;
    }));
  }

  public onPageEvent(pageEvent: PageEvent) {
    this.pagedQuery.pageIndex = pageEvent.pageIndex + 1;
    this.pagedQuery.pageSize = pageEvent.pageSize;
    this.refreshList();
  }

  public onSortEvent(sort: MatSort) {
    switch (sort.active) {
      case 'patient.id':
        this.pagedQuery.sortColumn = 'patient.stakeholder.externalId';
        break;

      default:
        this.pagedQuery.sortColumn = sort.active;
        break;
    }
    this.pagedQuery.isSortDesc = sort.direction == 'desc';
    this.pagedQuery.pageIndex = 1;
    this.refreshList();
  }
}
