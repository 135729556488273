import { OnDestroy } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import { PrescriberDelegate } from '../../models/prescriber-delegate.model';
import { Subscription } from 'rxjs';
import { PrescriberDelegateService } from '../../services/prescriber-delegate.service';

@Component({
  selector: 'roctavian-eumea-callcenter-portal-prescriber-delegate-status-page',
  templateUrl: './prescriber-delegate-status-page.component.html',
  styleUrls: ['./prescriber-delegate-status-page.component.scss']
})
export class PrescriberDelegateStatusPageComponent implements OnInit, OnDestroy {
  public prescriberDelegate: PrescriberDelegate;
  private subscriptions = new Array<Subscription>();

  constructor(private service: PrescriberDelegateService) {}

  ngOnInit() {
    this.subscriptions.push(
      this.service.selectedPrescriberDelegateSubject.subscribe(prescriberDelegate => {
        this.prescriberDelegate = prescriberDelegate;
      })
    );
  }

  ngOnDestroy() {
    this.subscriptions.forEach(subscription => {
      subscription.unsubscribe();
    });
  }

  public onStatusSaved() {
    this.service.loadPrescriberDelegate(this.prescriberDelegate.id, true);
  }
}
