import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MAT_DIALOG_DEFAULT_OPTIONS } from '@angular/material';
import { RouterModule } from '@angular/router';
import { CoreDirectivesModule, MaterialModule, PipeModule } from '@roctavian-abstractions/core';
import { IdentityModule } from '../../../identity/identity.module';
import { SharedModule } from '../../../shared/shared.module';
import { StakeholderCommonModule } from '../common';
import { AddLabKitOrderComponent } from './components/add-lab-kit-order/add-lab-kit-order.component';
import { DeleteLabKitOrderDialogComponent } from './components/delete-lab-kit-order-dialog/delete-lab-kit-order-dialog.component';
import { LabKitOrderFormComponent } from './components/lab-kit-order-form/lab-kit-order-form.component';
import { LabKitOrderListComponent } from './components/lab-kit-order-list/lab-kit-order-list.component';
import { ViewLabKitOrderDialogComponent } from './components/view-lab-kit-order-dialog/view-lab-kit-order-dialog.component';
import { ViewLabKitOrderComponent } from './components/view-lab-kit-order/view-lab-kit-order.component';
import { LabKitOrderRoutingModule } from './lab-kit-order-routing.module';
import { AddLabKitOrderPageComponent } from './pages/add-lab-kit-order-page/add-lab-kit-order-page.component';
import { EditLabKitOrderPageComponent } from './pages/edit-lab-kit-order-page/edit-lab-kit-order-page.component';
import { LabKitOrderDetailMasterPageComponent } from './pages/lab-kit-order-detail-master-page/lab-kit-order-detail-master-page.component';
import { LabKitOrderListPageComponent } from './pages/lab-kit-order-list-page/lab-kit-order-list-page.component';
import { LabKitOrderSummaryPageComponent } from './pages/lab-kit-order-summary-page/lab-kit-order-summary-page.component';

@NgModule({
  declarations: [
    LabKitOrderListPageComponent,
    AddLabKitOrderPageComponent,
    EditLabKitOrderPageComponent,
    LabKitOrderSummaryPageComponent,
    LabKitOrderDetailMasterPageComponent,
    ViewLabKitOrderDialogComponent,
    LabKitOrderListComponent,
    AddLabKitOrderComponent,
    LabKitOrderFormComponent,
    ViewLabKitOrderComponent,
    DeleteLabKitOrderDialogComponent
  ],
  imports: [
    CommonModule,
    IdentityModule,
    RouterModule,
    MaterialModule,
    FormsModule,
    HttpClientModule,
    ReactiveFormsModule,
    PipeModule,
    LabKitOrderRoutingModule,
    CoreDirectivesModule,
    StakeholderCommonModule,
    SharedModule
  ],
  providers: [{ provide: MAT_DIALOG_DEFAULT_OPTIONS, useValue: { hasBackdrop: false } }],
  entryComponents: [ViewLabKitOrderDialogComponent, DeleteLabKitOrderDialogComponent]
})
export class LabKitOrderModule {}
