import { Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject, Subscription } from 'rxjs';
import { PaginatedList, Outcome } from '@roctavian-abstractions/web';
import { Institution, InstitutionPagedQuery } from '../models';
import { InstitutionClient } from '../clients';
import { ClearService } from '../../../../shared/interface/clear-service.interface';

@Injectable({
  providedIn: 'root'
})
export class InstitutionService implements OnDestroy, ClearService {
  public institutionPagedListSubject = new BehaviorSubject<PaginatedList<Institution>>(
    new PaginatedList<Institution>()
  );

  public isLoadingSubject = new BehaviorSubject<boolean>(false);

  public selectedInstitutionSubject = new BehaviorSubject<Institution>(null);

  private getInstitutionListPagedSubscription: Subscription;
  private getInstitutionSubscription: Subscription;

  constructor(private client: InstitutionClient) { }

  ngOnDestroy() {
    if (this.getInstitutionListPagedSubscription) {
      this.getInstitutionListPagedSubscription.unsubscribe();
    }

    if (this.getInstitutionSubscription) {
      this.getInstitutionSubscription.unsubscribe();
    }
  }

  public setPage(pagedQuery: InstitutionPagedQuery) {
    this.isLoadingSubject.next(true);

    this.getInstitutionListPagedSubscription = this.client.getInstitutionListPaged(pagedQuery).subscribe(
      outcome => {
        if (!outcome.success || outcome.failure) {
          return;
        }

        this.institutionPagedListSubject.next(outcome.value);
        this.isLoadingSubject.next(false);
      },
      (error: Outcome) => {
        this.isLoadingSubject.next(false);
      }
    );
  }

  public loadInstitution(institutionId: string, forceRefresh: boolean = false) {
    if (
      this.selectedInstitutionSubject.value &&
      this.selectedInstitutionSubject.value.id === institutionId &&
      !forceRefresh
    ) {
      return;
    }

    this.isLoadingSubject.next(true);
    this.getInstitutionSubscription = this.client.getInstitution(institutionId).subscribe(
      outcome => {
        if (!outcome.success || outcome.failure) {
          return;
        }

        this.selectedInstitutionSubject.next(outcome.value);
        this.isLoadingSubject.next(false);
      },
      (error: Outcome) => {
        this.isLoadingSubject.next(false);
      }
    );
  }

  public clearService() {
    this.institutionPagedListSubject.next(new PaginatedList<Institution>());
    this.selectedInstitutionSubject.next(null);

  }
}
