import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'roctavian-abstractions-new-workgroup-page',
  templateUrl: './new-workgroup-page.component.html',
  styleUrls: ['./new-workgroup-page.component.scss']
})
export class NewWorkgroupPageComponent implements OnInit {
  constructor() {}

  public ngOnInit() {}
}
