import { Component } from '@angular/core';

@Component({
  selector: 'roctavian-abstractions-workgroup-dashboard-page',
  templateUrl: './workgroup-dashboard-page.component.html',
  styleUrls: ['./workgroup-dashboard-page.component.scss']
})
export class WorkgroupDashboardPageComponent {
  constructor() {}
}
