import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { Endpoints } from '@app/core/enums';
import { Document, PagedQuery } from '@app/stakeholder/models';
import { UtilityService } from '@app/stakeholder/services';
import {
  Outcome,
  PaginatedList,
  ValueOutcome,
} from '@roctavian-abstractions/web';

import { Institution } from '../../institution';
import { Lab } from '../../lab';
import { PatientPrescriber } from '../../patient';
import { PrescriberDelegate } from '../../prescriber-delegate/models/prescriber-delegate.model';
import {
  DocumentPrescriber,
  DocumentPrescriberQuery,
  LabUserDelegate,
  Prescriber,
  PrescriberDelegatePrescriber,
  PrescriberLab,
  PrescriberLabPagedQuery,
  PrescriberPagedQuery,
} from '../models';

@Injectable({
  providedIn: 'root',
})
export class PrescriberClient {
  constructor(
    private readonly http: HttpClient,
    private readonly utilityService: UtilityService,
  ) {}

  getPrescriberListPaged(
    pagedQuery: PrescriberPagedQuery,
  ): Observable<ValueOutcome<PaginatedList<Prescriber>>> {
    return this.http.get<ValueOutcome<PaginatedList<Prescriber>>>(
      Endpoints.prescriber,
      {
        params: this.utilityService.toHttpParams(pagedQuery),
      },
    );
  }

  getPatientPrescriberListPaged(
    pagedQuery: PagedQuery,
    prescriberId: string,
  ): Observable<ValueOutcome<PaginatedList<PatientPrescriber>>> {
    const url = `${Endpoints.prescriber}/${prescriberId}/prescriber-patient`;
    return this.http.get<ValueOutcome<PaginatedList<PatientPrescriber>>>(url, {
      params: this.utilityService.toHttpParams(pagedQuery),
    });
  }

  gePrescriberLabListPaged(
    pagedQuery: PagedQuery,
    prescriberId: string,
  ): Observable<ValueOutcome<PaginatedList<PrescriberLab>>> {
    const url = `${Endpoints.prescriber}/${prescriberId}/lab`;
    return this.http.get<ValueOutcome<PaginatedList<PrescriberLab>>>(url, {
      params: this.utilityService.toHttpParams(pagedQuery),
    });
  }

  getPrescriberDelegateListPaged(
    pagedQuery: PagedQuery,
    prescriberId: string,
  ): Observable<ValueOutcome<PaginatedList<PrescriberDelegatePrescriber>>> {
    const url = `${Endpoints.prescriber}/${prescriberId}/prescriberdelegate/get`;
    return this.http.get<
      ValueOutcome<PaginatedList<PrescriberDelegatePrescriber>>
    >(url, {
      params: this.utilityService.toHttpParams(pagedQuery),
    });
  }

  gePrescriberInstitutionListPaged(
    pagedQuery: PagedQuery,
  ): Observable<ValueOutcome<PaginatedList<Institution>>> {
    const url = `${Endpoints.institution}`;
    return this.http.get<ValueOutcome<PaginatedList<Institution>>>(url, {
      params: this.utilityService.toHttpParams(pagedQuery),
    });
  }

  gePrescriberAssociateLabListPaged(
    pagedQuery: PrescriberLabPagedQuery,
    prescriberId: string,
  ): Observable<ValueOutcome<PaginatedList<Lab>>> {
    const url = `${Endpoints.prescriber}/${prescriberId}/lab/getbycountry/${pagedQuery.country}`;
    return this.http.get<ValueOutcome<PaginatedList<Lab>>>(url, {
      params: this.utilityService.toHttpParams(pagedQuery),
    });
  }

  getPrescriberAssociatePrescriberDelegateListPaged(
    prescriberDelegatePagedQuery: PagedQuery,
    prescriberId: string,
  ) {
    const url = `${Endpoints.prescriber}/${prescriberId}/prescriberdelegate/getavailable`;
    return this.http.get<ValueOutcome<PaginatedList<PrescriberDelegate>>>(url, {
      params: this.utilityService.toHttpParams(prescriberDelegatePagedQuery),
    });
  }

  getPrescriberDuplicates(
    nationalId: string,
    lastName: string,
  ): Observable<Outcome> {
    let params = new HttpParams();

    if (nationalId) {
      params = params.set('nationalId', nationalId);
    }
    if (lastName) {
      params = params.set('lastName', lastName);
    }

    const url = `${Endpoints.prescriber}/duplicate-check`;
    return this.http.get<Outcome>(url, { params });
  }

  getDocumentsPagedList(
    pagedQuery: DocumentPrescriberQuery,
  ): Observable<ValueOutcome<PaginatedList<DocumentPrescriber>>> {
    const value = new PaginatedList<Document>();
    value.items = [];
    value.pageIndex = 1;
    value.totalCount = 0;
    value.totalPages = 1;

    return this.http.get<ValueOutcome<PaginatedList<DocumentPrescriber>>>(
      `${Endpoints.documentPrescriber}`,
      {
        params: this.utilityService.toHttpParams(pagedQuery),
      },
    );
  }

  addDocumentToPrescriber(
    prescriberId: string,
    document: Document,
  ): Observable<Outcome> {
    const value = new DocumentPrescriber();
    value.prescriberId = prescriberId;
    value.document = document;

    return this.http.post<Outcome>(`${Endpoints.documentPrescriber}`, value);
  }

  createPrescriber(prescriber: Prescriber): Observable<Outcome> {
    return this.http.post<Outcome>(Endpoints.prescriber, prescriber);
  }

  updatePrescriber(prescriber: Prescriber): Observable<Outcome> {
    return this.http.put<Outcome>(Endpoints.prescriber, prescriber);
  }

  getPrescriber(prescriberId: string): Observable<Outcome> {
    const url = `${Endpoints.prescriber}/${prescriberId}`;
    return this.http.get<Outcome>(url);
  }

  createPrescriberLabAssociation(
    prescriberLab: PrescriberLab,
  ): Observable<Outcome> {
    const url = `${Endpoints.prescriber}/associatelab`;
    return this.http.post<Outcome>(url, prescriberLab);
  }

  createPrescriberDelegateAssociation(
    prescriberDelegatePrescriber: PrescriberDelegatePrescriber,
  ): Observable<Outcome> {
    const url = `${Endpoints.prescriber}/${prescriberDelegatePrescriber.prescriberId}/prescriberdelegate/associate/${prescriberDelegatePrescriber.prescriberDelegateId}`;
    return this.http.post<Outcome>(url, prescriberDelegatePrescriber);
  }

  disassociateLab(prescriberId: string, labId: string): Observable<Outcome> {
    const url = `${Endpoints.prescriber}/${prescriberId}/disassociatelab/${labId}`;
    return this.http.delete<Outcome>(url, {});
  }

  disassociatePrescriberDelegate(
    prescriberId: string,
    prescriberDelegateId: string,
  ): Observable<Outcome> {
    const url = `${Endpoints.prescriber}/${prescriberId}/prescriberdelegate/disassociate/${prescriberDelegateId}`;
    return this.http.delete<Outcome>(url, {});
  }

  geLabUserDelegateListPaged(
    pagedQuery: PagedQuery,
    prescriberId: string,
  ): Observable<ValueOutcome<PaginatedList<LabUserDelegate>>> {
    const url = `${Endpoints.prescriber}/${prescriberId}/labuserdelegate/get`;
    return this.http.get<ValueOutcome<PaginatedList<LabUserDelegate>>>(url, {
      params: this.utilityService.toHttpParams(pagedQuery),
    });
  }

  getNationalIdDuplicateCheck(
    nationalId: string,
  ): Observable<ValueOutcome<boolean>> {
    const url = `${Endpoints.prescriber}/duplicatecheck?nationalId=${nationalId}`;
    return this.http.get<ValueOutcome<boolean>>(url);
  }

  getExportFile() {
    const url = `${Endpoints.prescriber}/export`;
    return this.http.get(url, { responseType: 'blob' });
  }
}
