import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { NgModule } from '@angular/core';
import { LabUserListPageComponent } from './pages/labuser-list-page/labuser-list-page.component';
import { LabUserDetailMasterPageComponent } from './pages/lab-user-detail-master-page/lab-user-detail-master-page.component';
import { LabUserSummaryPageComponent } from './pages/lab-user-summary-page/lab-user-summary-page.component';
import { LabUserEditPageComponent } from './pages/lab-user-edit-page/lab-user-edit-page.component';
import { AddLabUserPageComponent } from './pages/add-lab-user-page/add-lab-user-page.component';
import { LabUserAttestationPageComponent } from './pages/lab-user-attestation-page/lab-user-attestation-page.component';
import { LabUserStatusPageComponent } from './pages/lab-user-status-page/lab-user-status-page.component';
import { LabUserPrescriberListPageComponent } from './pages/labuser-prescriber-list-page/labuser-prescriber-list-page.component';
import { RoctavianAuthenticationGuard } from '../../../shared/guards/roctavian-authentication.guard';

const ROUTES = [
  {
    path: 'labuser',
    canActivate: [RoctavianAuthenticationGuard],
    children: [
      { path: '', redirectTo: 'list', pathMatch: 'full' },
      { path: 'list', component: LabUserListPageComponent },
      { path: 'add', component: AddLabUserPageComponent },
      {
        path: ':id',
        component: LabUserDetailMasterPageComponent,
        children: [
          { path: '', redirectTo: 'summary', pathMatch: 'full' },
          { path: 'summary', component: LabUserSummaryPageComponent },
          { path: 'attestation', component: LabUserAttestationPageComponent },
          { path: 'status', component: LabUserStatusPageComponent },
          { path: 'edit', component: LabUserEditPageComponent },
          { path: 'prescriber', component: LabUserPrescriberListPageComponent }
        ]
      }
    ]
  }
];

@NgModule({
  imports: [CommonModule, RouterModule.forChild(ROUTES)],
  exports: [RouterModule]
})
export class LabUserRoutingModule { }
