import { Injectable } from '@angular/core';
import { JwtHelperService } from '@auth0/angular-jwt';

@Injectable({
  providedIn: 'root'
})
export class TokenDecoder {
  private helper = new JwtHelperService();

  constructor() {}

  /**
   * Decodes the token and returns an object that
   * matches the structure of the decoded token.
   *
   * @param token The JWT token.
   */
  public decode(token: string): any {
    return this.helper.decodeToken(token);
  }

  /**
   * Determines whether the token is currently expired.
   *
   * @param token The JWT token.
   */
  public isTokenExpired(token: string): boolean {
    return this.helper.isTokenExpired(token);
  }

  /**
   * Gets the expiration date from the provided token.
   *
   * @param token The JWT token.
   */
  public getTokenExpiration(token: string): Date {
    return this.helper.getTokenExpirationDate(token);
  }
}
