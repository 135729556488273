import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthenticationService } from './../services/authentication.service';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationGuard implements CanActivate {
  constructor(private router: Router, private service: AuthenticationService) {}
  /**
   * A route protected by this guard requires the user
   * to be logged into the application. If they are not
   * logged in, they will be redirected to the login page.
   */
  public canActivate(): Observable<boolean> | Promise<boolean> | boolean {
    if (this.service.authenticated) {
      return true;
    }
    this.router.navigate(['/login']);
    return false;
  }
}
