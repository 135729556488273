import { Component, OnInit, OnDestroy, OnChanges, SimpleChanges, Input, Output, EventEmitter } from '@angular/core';
import { Lab, LabUser } from '../../models';
import { LabuserPagedQuery } from '../../../labuser/models';
import { PaginatedList } from '@roctavian-abstractions/web';
import { BehaviorSubject, Subscription, EMPTY } from 'rxjs';
import { LabService } from '../../services';
import { PagedQuery, UtilityService } from '../../../common';
import { debounceTime, distinctUntilChanged, switchMap } from 'rxjs/operators';
import { PageEvent, MatSort, MatDialog } from '@angular/material';

@Component({
  selector: 'associate-new-lab-user-list',
  templateUrl: './associate-new-lab-user-list.component.html',
  styleUrls: ['./associate-new-lab-user-list.component.scss']
})
export class AssociateNewLabUserListComponent implements OnInit, OnDestroy, OnChanges {
  @Input() lab: Lab;
  @Output() public labUserSelected = new EventEmitter<LabUser>();
  public selectedLabUser: LabUser;
  public pagedQuery = new LabuserPagedQuery(1, 10, null, 'id', true, null, null, null);
  public labUserPagedCollection: PaginatedList<LabUser>;
  public isLoading = false;
  public filterText$ = new BehaviorSubject<string>(null);

  private subscriptions = new Array<Subscription>();

  public displayedColumns = [
    'id',
    'stakeholder.firstName',
    'stakeholder.lastName',
    'stakeholder.address.country.name',
    'stakeholder.email',
    'stakeholder.phone'
  ];

  constructor(public utilityService: UtilityService, private labService: LabService) { }

  ngOnDestroy(): void {
    this.subscriptions.forEach(subscription => {
      subscription.unsubscribe();
    });

    this.labService.unassociatedLabUserPagedListSubject.next(new PaginatedList<LabUser>());
  }
  private refreshList() {
    let labId: string = null;
    if (this.lab) {
      labId = this.lab.id;
    }
    this.labService.setUnassociatedLabUserPage(this.pagedQuery);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (!changes.labId || !changes.labId.currentValue || changes.labId.currentValue === changes.labId.previousValue) {
      return;
    }

    this.refreshList();
  }

  ngOnInit() {
    this.subscriptions.push(
      this.filterText$
        .pipe(
          debounceTime(1000),
          distinctUntilChanged(),
          switchMap(searchText => {
            if (this.pagedQuery.filterText == searchText) {
              return EMPTY;
            }

            this.pagedQuery.filterText = searchText;
            this.pagedQuery.pageIndex = 1;
            this.refreshList();
            return EMPTY;
          })
        )
        .subscribe()
    );

    this.subscriptions.push(
      this.labService.unassociatedLabUserPagedListSubject.subscribe(collection => {
        this.labUserPagedCollection = collection;
      })
    );

    this.subscriptions.push(
      this.labService.isLoadingSubject.subscribe(isLoading => {
        this.isLoading = isLoading;
      })
    );
    this.refreshList();
  }

  private get labUserCountry(): string {
    if (this.lab && this.lab.stakeholder && this.lab.stakeholder.address && this.lab.stakeholder.address.country) {
      return this.lab.stakeholder.address.countryId;
    }
    return null;
  }

  public onSortEvent(sort: MatSort) {
    this.pagedQuery.sortColumn = sort.active;
    this.pagedQuery.isSortDesc = sort.direction == 'desc';
    this.pagedQuery.pageIndex = 1;
    this.refreshList();
  }

  public onPageEvent(pageEvent: PageEvent) {
    this.pagedQuery.pageIndex = pageEvent.pageIndex + 1;
    this.pagedQuery.pageSize = pageEvent.pageSize;
    this.refreshList();
  }

  public onRowSelected(labUser: LabUser) {
    if (this.selectedLabUser === labUser) {
      this.selectedLabUser = null;
      this.labUserSelected.emit(null);
      return;
    }
    this.selectedLabUser = labUser;
    this.labUserSelected.emit(this.selectedLabUser);
  }
}
