import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { FormBuilder, FormControl, FormGroup, Validators, ValidatorFn } from "@angular/forms";
import { regexValidator } from "@roctavian-abstractions/core";
import { Subject, of } from "rxjs";
import { map, debounceTime, distinctUntilChanged, flatMap } from "rxjs/operators";
import { PasswordConfigModel } from "./../../../../models";

export class ResetPasswordFormSubmitEvent {
  constructor(public newPassword: string, public confirmationPassword: string) { }
}

export class PasswordErrorKeys {
  static readonly Digit = "digit";
  static readonly Alphanumeric = "alpha";
  static readonly Lowercase = "lower";
  static readonly Uppercase = "upper";
  static readonly MinimumLength = "minlength";
}

@Component({
  selector: "roctavian-abstractions-reset-password-form",
  templateUrl: "./reset-password-form.component.html",
  styleUrls: ["./reset-password-form.component.scss"]
})
export class ResetPasswordFormComponent implements OnInit {
  public subject = new Subject<any>();
  public confirmationPasswordDifferent = false;

  @Input() public disabled = false;
  @Input() public buttonText = "Reset Password";
  @Input() public passwordConfig: PasswordConfigModel;

  @Output() public submitted = new EventEmitter<ResetPasswordFormSubmitEvent>();

  public validKeys: string[] = [];
  public invalidKeys: string[] = [];

  public form: FormGroup;

  constructor(private builder: FormBuilder) {
    this.subject
      .pipe(
        map(event => event.target.value),
        debounceTime(250),
        distinctUntilChanged(),
        flatMap(text => of(text))
      )
      .subscribe(text => {
        this.confirmationPasswordDifferent = this.form.hasError("noMatch") ? true : false;
      });
  }

  public ngOnInit() {
    this.buildForm();
  }

  public buildForm() {
    const validators = [Validators.required];
    validators.push(...this.getActiveValidators());
    this.form = this.builder.group({
      newPassword: ["", validators],
      confirmationPassword: ["", Validators.required]
    });
    this.form.setValidators(this.passwordsAreEqual);
  }

  public handleFormSubmission(form: FormGroup) {
    if (!form.valid) {
      return;
    }

    const newPassword = form.get("newPassword").value;
    const confirmationPassword = form.get("confirmationPassword").value;
    const event = new ResetPasswordFormSubmitEvent(newPassword, confirmationPassword);

    this.submitted.emit(event);
  }

  private passwordsAreEqual(control: FormControl) {
    const newPassword = control.get("newPassword");
    const confirmationPassword = control.get("confirmationPassword");

    if (!newPassword || !confirmationPassword) {
      return null;
    }
    if (newPassword.value === "" || confirmationPassword.value === "") {
      return null;
    }

    return newPassword.value === confirmationPassword.value ? null : { noMatch: true };
  }

  public getUnsatisfiedRuleKeys(): string[] {
    const invalidRules: string[] = [];
    if (this.form.get("newPassword").hasError("digit")) {
      invalidRules.push("digit");
    }
    if (this.form.get("newPassword").hasError("alpha")) {
      invalidRules.push("alpha");
    }
    if (this.form.get("newPassword").hasError("lower")) {
      invalidRules.push("lower");
    }
    if (this.form.get("newPassword").hasError("upper")) {
      invalidRules.push("upper");
    }
    if (this.form.get("newPassword").hasError("minlength")) {
      invalidRules.push("length");
    }
    return invalidRules;
  }

  public getSatisfiedRuleKeys(): string[] {
    const validRules: string[] = [];

    const value = this.form.get("newPassword").value;

    if (value && !this.form.get("newPassword").hasError("digit")) {
      validRules.push("digit");
    }
    if (value && !this.form.get("newPassword").hasError("alpha")) {
      validRules.push("alpha");
    }
    if (value && !this.form.get("newPassword").hasError("lower")) {
      validRules.push("lower");
    }
    if (value && !this.form.get("newPassword").hasError("upper")) {
      validRules.push("upper");
    }
    if (value && !this.form.get("newPassword").hasError("minlength")) {
      validRules.push("length");
    }

    return validRules.slice();
  }

  public getActiveValidators(): ValidatorFn[] {
    const activeValidators = [];

    activeValidators.push(Validators.minLength(this.passwordConfig.requiredLength));

    if (this.passwordConfig.requireDigit) {
      activeValidators.push(regexValidator(new RegExp("[0-9]"), { digit: true }));
    }
    if (this.passwordConfig.requireNonAlphanumeric) {
      activeValidators.push(regexValidator(new RegExp("[^a-zA-Z0-9]"), { alpha: true }));
    }
    if (this.passwordConfig.requireLowercase) {
      activeValidators.push(regexValidator(new RegExp("[a-z]"), { lower: true }));
    }
    if (this.passwordConfig.requireUppercase) {
      activeValidators.push(regexValidator(new RegExp("[A-Z]"), { upper: true }));
    }

    return activeValidators;
  }

  public displayMinimumErrorMessage(): boolean {
    const keys = [
      PasswordErrorKeys.Digit,
      PasswordErrorKeys.Alphanumeric,
      PasswordErrorKeys.Lowercase,
      PasswordErrorKeys.Uppercase,
      PasswordErrorKeys.MinimumLength
    ];

    const control = this.form.get("newPassword");
    const value = control.value;

    if (!value) {
      return false;
    }

    let minimumRequirementsError = false;
    for (const key of keys) {
      if (control.hasError(key)) {
        minimumRequirementsError = true;
      }
    }

    return minimumRequirementsError;
  }
}
