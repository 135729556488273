import {
  ChangeDetectionStrategy, Component, EventEmitter, Input, Output
} from '@angular/core';

export class RoleFormSubmitEvent {
  constructor(public name: string) {}
}

@Component({
  selector: 'roctavian-abstractions-role-form',
  templateUrl: './role-form.component.html',
  styleUrls: ['./role-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RoleFormComponent {
  @Input() public name: string;
  @Input() public disabled: boolean;
  @Input() public buttonText = 'Create Role';
  @Output() submitted = new EventEmitter<RoleFormSubmitEvent>();

  public handleFormSubmission() {
    const event = new RoleFormSubmitEvent(this.name);
    this.submitted.emit(event);
  }
}
