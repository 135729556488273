import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CoreModule, MaterialModule, SearchModule } from '@roctavian-abstractions/core';
import { WebModule } from '@roctavian-abstractions/web';
import { AdminRoutingModule } from './admin-routing.module';
import { PermissionsModule } from './modules/permissions/permissions.module';
import { RolesModule } from './modules/roles/roles.module';
import { UserModule } from './modules/user/user.module';
import { AdministrationDashboardPageComponent } from './pages/administration-dashboard-page/administration-dashboard-page.component';
import { AdministrationPageComponent } from './pages/administration-page/administration-page.component';
import { CreatePermissionPageComponent } from './pages/create-permission-page/create-permission-page.component';
import { CreateRolePageComponent } from './pages/create-role-page/create-role-page.component';
import { CreateUserPageComponent } from './pages/create-user-page/create-user-page.component';
import { EditPermissionPageComponent } from './pages/edit-permission-page/edit-permission-page.component';
import { EditRolePageComponent } from './pages/edit-role-page/edit-role-page.component';
import { PermissionManagerPageComponent } from './pages/permission-manager-page/permission-manager-page.component';
import { RoleManagerPageComponent } from './pages/role-manager-page/role-manager-page.component';
import { UserDetailsPageComponent } from './pages/user-details-page/user-details-page.component';
import { UserEditPageComponent } from './pages/user-edit-page/user-edit-page.component';
import { UserManagerPageComponent } from './pages/user-manager-page/user-manager-page.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MaterialModule,
    CoreModule,
    AdminRoutingModule,
    PermissionsModule,
    UserModule,
    RolesModule,
    WebModule,
    HttpClientModule,
    SearchModule,
  ],
  declarations: [
    UserManagerPageComponent,
    CreateUserPageComponent,
    AdministrationPageComponent,
    RoleManagerPageComponent,
    CreateRolePageComponent,
    UserDetailsPageComponent,
    AdministrationDashboardPageComponent,
    PermissionManagerPageComponent,
    CreatePermissionPageComponent,
    EditPermissionPageComponent,
    EditRolePageComponent,
    UserEditPageComponent,
  ],
  exports: [PermissionsModule, RolesModule, UserModule],
})
export class AdminModule {}
