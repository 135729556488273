import { HttpParams } from '@angular/common/http';
import { Injectable, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';

import { getStatusChangeReasonName, getStatusName } from '@app/stakeholder/constants';
import { ChangeReasonTypes, StatusTypes } from '@app/stakeholder/enums';
import { Attestation, PagedQuery, Status } from '@app/stakeholder/models';
import { SystemKeyModel, SystemKeyService } from '@roctavian-abstractions/core';

@Injectable({
  providedIn: 'root',
})
export class UtilityService implements OnDestroy {
  private allSystemKeys: SystemKeyModel[] = [];
  private loadedSystemKeysSubjectSubscription: Subscription;

  constructor(private systemKeyService: SystemKeyService) {
    this.loadedSystemKeysSubjectSubscription = this.systemKeyService.loadedSystemKeysSubject.subscribe(systemKeys => {
      this.allSystemKeys = systemKeys;
    });
    this.systemKeyService.loadAllSystemKeys();
  }

  ngOnDestroy() {
    this.loadedSystemKeysSubjectSubscription.unsubscribe();
  }

  getSystemKeyValue(group: string, key: string): string {
    const valueSystemKeyList = this.allSystemKeys.filter(x => x.group == group && x.key == key);
    if (valueSystemKeyList.length === 0) {
      return null;
    }

    return valueSystemKeyList[0].value;
  }

  getCurrentStatus(statuses: Status[]): Status {
    if (!statuses) {
      return null;
    }

    return statuses.find(x => x.isCurrent);
  }

  getStakeholderStatus(status: Status): string {
    return getStatusName(status ? status.statusType : StatusTypes.Unknown);
  }

  getStatusChangeReason(status: Status): string {
    return getStatusChangeReasonName(status ? status.changeReasonType : ChangeReasonTypes.Unknown);
  }

  getCurrentAttestation(attestations: Attestation[]): Attestation {
    if (!attestations) {
      return null;
    }

    return attestations.find(x => x.isCurrent);
  }

  isSystemKeySelected(selection: any, systemKeyGroup: string, matchingValue: string): boolean {
    if (!selection || !systemKeyGroup || !matchingValue) {
      return false;
    }

    const systemKeys = this.allSystemKeys.filter(x => x.group == systemKeyGroup);

    const matchingSystemKey = systemKeys.find(x => x.value == matchingValue);
    if (!matchingSystemKey) {
      return false;
    }

    let isSelected = false;
    if (selection instanceof Array) {
      selection.forEach(id => {
        if (id != matchingSystemKey.key) {
          return;
        }

        isSelected = true;
      });
    } else {
      isSelected = selection == matchingSystemKey.key;
    }

    return isSelected;
  }

  toHttpParams(pagedQuery: PagedQuery): HttpParams {
    let params = new HttpParams();

    Object.keys(pagedQuery).forEach(key => {
      const value = pagedQuery[key];

      if (value === null || value === undefined) {
        return;
      }

      if (value instanceof Date) {
        params = params.append(key, pagedQuery[key].toDateString());
      } else {
        params = params.append(key, pagedQuery[key]);
      }
    });

    return params;
  }
}
