import { StakeholderTypes, StatusTypes } from '../enums';

export class StakeholderStatusType {
  constructor(public stakeholderType: StakeholderTypes, public statusType: StatusTypes, public name: string) { }
}

export const StakeholderStatusTypes: StakeholderStatusType[] = [
  new StakeholderStatusType(StakeholderTypes.Prescriber, StatusTypes.Inactive, getStatusName(StatusTypes.Inactive)),
  new StakeholderStatusType(StakeholderTypes.Prescriber, StatusTypes.Incomplete, getStatusName(StatusTypes.Incomplete)),

  new StakeholderStatusType(StakeholderTypes.Patient, StatusTypes.Inactive, getStatusName(StatusTypes.Inactive)),
  new StakeholderStatusType(StakeholderTypes.Patient, StatusTypes.Incomplete, getStatusName(StatusTypes.Incomplete)),

  new StakeholderStatusType(StakeholderTypes.Laboratory, StatusTypes.Inactive, getStatusName(StatusTypes.Inactive)),
  new StakeholderStatusType(StakeholderTypes.Laboratory, StatusTypes.Incomplete, getStatusName(StatusTypes.Incomplete)),

  new StakeholderStatusType(StakeholderTypes.LabTest, StatusTypes.LabTestCancelled, getStatusName(StatusTypes.LabTestCancelled)),
  new StakeholderStatusType(StakeholderTypes.LabTest, StatusTypes.LabTestSampleShipped, getStatusName(StatusTypes.LabTestSampleShipped))
];

export function getStatusName(statusType: StatusTypes): string {
  switch (statusType) {
    case StatusTypes.Pending:
      return 'Pending';
    case StatusTypes.Registered:
      return 'Registered';
    case StatusTypes.PreRegistered:
      return 'Pre-Registered';
    case StatusTypes.Inactive:
      return 'Inactive';
    case StatusTypes.Incomplete:
      return 'Not Complete';
    case StatusTypes.LabTestIncomplete:
      return 'Incomplete';
    case StatusTypes.LabTestComplete:
      return 'Complete';
    case StatusTypes.LabTestOrdered:
      return 'Test Ordered';
    case StatusTypes.LabTestSampleShipped:
      return 'Specimen Shipped';
    case StatusTypes.LabTestSampleReceived:
      return 'Specimen Received';
    case StatusTypes.LabTestResultReceived:
      return 'Result Received';
    case StatusTypes.LabTestCancelled:
      return 'Cancelled';
    case StatusTypes.Unknown:
    default:
      return '';
  }
}
