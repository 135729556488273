import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MessageService } from '@roctavian-abstractions/core';

@Component({
  selector: 'roctavian-abstractions-create-role-page',
  templateUrl: './create-role-page.component.html',
  styleUrls: ['./create-role-page.component.scss'],
})
export class CreateRolePageComponent implements OnInit {
  constructor(private router: Router, private service: MessageService) {}

  public ngOnInit() {}

  public handleSave() {
    this.service.open('Role created successfully', 4000);
    this.router.navigate(['/administration/roles']);
  }
}
