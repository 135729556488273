import { StatusTypes, ChangeReasonTypes, StakeholderTypes } from '../enums';

export class StatusChangeReasonType {
  constructor(
    public stakeholderType: StakeholderTypes,
    public statusType: StatusTypes,
    public changeReasonType: ChangeReasonTypes,
    public name: string
  ) {}
}

export const StatusChangeReasonTypes: StatusChangeReasonType[] = [
  new StatusChangeReasonType(
    StakeholderTypes.Prescriber,
    StatusTypes.Inactive,
    ChangeReasonTypes.StakeholderRequest,
    getStatusChangeReasonName(ChangeReasonTypes.StakeholderRequest)
  ),
  new StatusChangeReasonType(
    StakeholderTypes.Prescriber,
    StatusTypes.Inactive,
    ChangeReasonTypes.DuplicateRecord,
    getStatusChangeReasonName(ChangeReasonTypes.DuplicateRecord)
  ),
  new StatusChangeReasonType(
    StakeholderTypes.Prescriber,
    StatusTypes.Inactive,
    ChangeReasonTypes.Other,
    getStatusChangeReasonName(ChangeReasonTypes.Other)
  ),
  new StatusChangeReasonType(
    StakeholderTypes.Prescriber,
    StatusTypes.Incomplete,
    ChangeReasonTypes.IncompleteRegistration,
    getStatusChangeReasonName(ChangeReasonTypes.IncompleteRegistration)
  ),
  new StatusChangeReasonType(
    StakeholderTypes.Prescriber,
    StatusTypes.Incomplete,
    ChangeReasonTypes.DuplicateRecord,
    getStatusChangeReasonName(ChangeReasonTypes.DuplicateRecord)
  ),
  new StatusChangeReasonType(
    StakeholderTypes.Prescriber,
    StatusTypes.Incomplete,
    ChangeReasonTypes.Other,
    getStatusChangeReasonName(ChangeReasonTypes.Other)
  ),

  new StatusChangeReasonType(
    StakeholderTypes.Patient,
    StatusTypes.Inactive,
    ChangeReasonTypes.StakeholderRequest,
    getStatusChangeReasonName(ChangeReasonTypes.StakeholderRequest)
  ),
  new StatusChangeReasonType(
    StakeholderTypes.Patient,
    StatusTypes.Inactive,
    ChangeReasonTypes.DuplicateRecord,
    getStatusChangeReasonName(ChangeReasonTypes.DuplicateRecord)
  ),
  new StatusChangeReasonType(
    StakeholderTypes.Patient,
    StatusTypes.Inactive,
    ChangeReasonTypes.Other,
    getStatusChangeReasonName(ChangeReasonTypes.Other)
  ),
  new StatusChangeReasonType(
    StakeholderTypes.Patient,
    StatusTypes.Incomplete,
    ChangeReasonTypes.IncompleteRegistration,
    getStatusChangeReasonName(ChangeReasonTypes.IncompleteRegistration)
  ),
  new StatusChangeReasonType(
    StakeholderTypes.Patient,
    StatusTypes.Incomplete,
    ChangeReasonTypes.DuplicateRecord,
    getStatusChangeReasonName(ChangeReasonTypes.DuplicateRecord)
  ),
  new StatusChangeReasonType(
    StakeholderTypes.Patient,
    StatusTypes.Incomplete,
    ChangeReasonTypes.Other,
    getStatusChangeReasonName(ChangeReasonTypes.Other)
  ),

  new StatusChangeReasonType(
    StakeholderTypes.Laboratory,
    StatusTypes.Inactive,
    ChangeReasonTypes.StakeholderRequest,
    getStatusChangeReasonName(ChangeReasonTypes.StakeholderRequest)
  ),
  new StatusChangeReasonType(
    StakeholderTypes.Laboratory,
    StatusTypes.Inactive,
    ChangeReasonTypes.DuplicateRecord,
    getStatusChangeReasonName(ChangeReasonTypes.DuplicateRecord)
  ),
  new StatusChangeReasonType(
    StakeholderTypes.Laboratory,
    StatusTypes.Inactive,
    ChangeReasonTypes.Other,
    getStatusChangeReasonName(ChangeReasonTypes.Other)
  ),
  new StatusChangeReasonType(
    StakeholderTypes.Laboratory,
    StatusTypes.Incomplete,
    ChangeReasonTypes.IncompleteRegistration,
    getStatusChangeReasonName(ChangeReasonTypes.IncompleteRegistration)
  ),
  new StatusChangeReasonType(
    StakeholderTypes.Laboratory,
    StatusTypes.Incomplete,
    ChangeReasonTypes.DuplicateRecord,
    getStatusChangeReasonName(ChangeReasonTypes.DuplicateRecord)
  ),
  new StatusChangeReasonType(
    StakeholderTypes.Laboratory,
    StatusTypes.Incomplete,
    ChangeReasonTypes.Other,
    getStatusChangeReasonName(ChangeReasonTypes.Other)
  ),

  new StatusChangeReasonType(
    StakeholderTypes.LabTest,
    StatusTypes.LabTestSampleShipped,
    ChangeReasonTypes.CourierConfirmed,
    getStatusChangeReasonName(ChangeReasonTypes.CourierConfirmed)
  ),
  new StatusChangeReasonType(
    StakeholderTypes.LabTest,
    StatusTypes.LabTestCancelled,
    ChangeReasonTypes.ARUPCancelledOrder,
    getStatusChangeReasonName(ChangeReasonTypes.ARUPCancelledOrder)
  )
];

export function getStatusChangeReasonName(changeReasonType: ChangeReasonTypes): string {
  switch (changeReasonType) {
    case ChangeReasonTypes.IncompleteRegistration:
      return 'Incomplete Registration';
    case ChangeReasonTypes.DuplicateRecord:
      return 'Duplicate Record';
    case ChangeReasonTypes.StakeholderRequest:
      return 'Stakeholder Request';
    case ChangeReasonTypes.Other:
      return 'Other';
    case ChangeReasonTypes.CourierConfirmed:
      return 'Courier Confirmed';
    case ChangeReasonTypes.ARUPCancelledOrder:
      return 'ARUP Cancelled Order';
    case ChangeReasonTypes.Unknown:
    default:
      return '';
  }
}
