import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from "@angular/core";
import { FormBuilder, FormControl, FormGroup, Validators, FormGroupDirective, ValidatorFn } from "@angular/forms";
import { BehaviorSubject, Subject, of } from "rxjs";
import { map, debounceTime, distinctUntilChanged, flatMap } from "rxjs/operators";
import { regexValidator } from "@roctavian-abstractions/core";
import { PasswordConfigModel } from "../../../../models";
import { PasswordErrorKeys } from "../reset-password-form/reset-password-form.component";

export class ChangePasswordFormSubmitEvent {
  constructor(public password: string, public newPassword: string, public confirmationPassword: string) {}
}

@Component({
  selector: "roctavian-abstractions-change-password-form",
  templateUrl: "./change-password-form.component.html",
  styleUrls: ["./change-password-form.component.scss"]
})
export class ChangePasswordFormComponent implements OnInit {
  public subject = new Subject<any>();
  public confirmationPasswordDifferent = false;

  @Input() public buttonText = "Change Password";

  @Input() public showPasswordField = false;

  @Input() public clearForm = new BehaviorSubject<boolean>(false);

  @Input() public disabled = false;

  @Output() public submitted = new EventEmitter<ChangePasswordFormSubmitEvent>();

  @Input() public passwordConfig: PasswordConfigModel;
  public validKeys: string[] = [];
  public invalidKeys: string[] = [];

  @ViewChild(FormGroupDirective, { static: false }) private formGroupDirective: FormGroupDirective;

  public form: FormGroup;

  constructor(private builder: FormBuilder) {
    this.subject
      .pipe(
        map(event => event.target.value),
        debounceTime(250),
        distinctUntilChanged(),
        flatMap(text => of(text))
      )
      .subscribe(text => {
        this.confirmationPasswordDifferent = this.form.hasError("noMatch") ? true : false;
      });
  }

  public ngOnInit() {
    this.buildForm();

    this.clearForm.subscribe(value => {
      if (value) {
        this.formGroupDirective.resetForm();
        this.form.reset();
      }
    });
  }

  public buildForm() {
    const validators = [Validators.required];
    validators.push(...this.getActiveValidators());
    this.form = this.builder.group({
      password: ["", this.showPasswordField ? Validators.required : null],
      newPassword: ["", validators],
      confirmationPassword: ["", Validators.required]
    });
    this.form.setValidators(this.passwordsAreEqual);
  }

  public handleFormSubmission(form: FormGroup) {
    if (!form.valid) {
      return;
    }
    const password = form.get("password").value;
    const newPassword = form.get("newPassword").value;
    const confirmationPassword = form.get("confirmationPassword").value;
    const event = new ChangePasswordFormSubmitEvent(password, newPassword, confirmationPassword);

    this.submitted.emit(event);
  }

  private passwordsAreEqual(control: FormControl) {
    const newPassword = control.get("newPassword");
    const confirmationPassword = control.get("confirmationPassword");

    if (!newPassword || !confirmationPassword) {
      return null;
    }
    if (newPassword.value === "" || confirmationPassword.value === "") {
      return null;
    }

    return newPassword.value === confirmationPassword.value ? null : { noMatch: true };
  }

  public getUnsatisfiedRuleKeys(): string[] {
    const invalidRules: string[] = [];
    if (this.form.get("newPassword").hasError("digit")) {
      invalidRules.push("digit");
    }
    if (this.form.get("newPassword").hasError("alpha")) {
      invalidRules.push("alpha");
    }
    if (this.form.get("newPassword").hasError("lower")) {
      invalidRules.push("lower");
    }
    if (this.form.get("newPassword").hasError("upper")) {
      invalidRules.push("upper");
    }
    if (this.form.get("newPassword").hasError("minlength")) {
      invalidRules.push("length");
    }

    return invalidRules.slice();
  }

  public getSatisfiedRuleKeys(): string[] {
    const validRules: string[] = [];

    const value = this.form.get("newPassword").value;

    if (value && !this.form.get("newPassword").hasError("digit")) {
      validRules.push("digit");
    }
    if (value && !this.form.get("newPassword").hasError("alpha")) {
      validRules.push("alpha");
    }
    if (value && !this.form.get("newPassword").hasError("lower")) {
      validRules.push("lower");
    }
    if (value && !this.form.get("newPassword").hasError("upper")) {
      validRules.push("upper");
    }
    if (value && !this.form.get("newPassword").hasError("minlength")) {
      validRules.push("length");
    }

    return validRules.slice();
  }

  public getActiveValidators(): ValidatorFn[] {
    const activeValidators = [];

    activeValidators.push(Validators.minLength(this.passwordConfig.requiredLength));

    if (this.passwordConfig.requireDigit) {
      activeValidators.push(regexValidator(new RegExp("[0-9]"), { digit: true }));
    }
    if (this.passwordConfig.requireNonAlphanumeric) {
      activeValidators.push(regexValidator(new RegExp("[^a-zA-Z0-9]"), { alpha: true }));
    }
    if (this.passwordConfig.requireLowercase) {
      activeValidators.push(regexValidator(new RegExp("[a-z]"), { lower: true }));
    }
    if (this.passwordConfig.requireUppercase) {
      activeValidators.push(regexValidator(new RegExp("[A-Z]"), { upper: true }));
    }

    return activeValidators;
  }

  public displayMinimumErrorMessage(): boolean {
    const keys = [
      PasswordErrorKeys.Digit,
      PasswordErrorKeys.Alphanumeric,
      PasswordErrorKeys.Lowercase,
      PasswordErrorKeys.Uppercase,
      PasswordErrorKeys.MinimumLength
    ];

    const control = this.form.get("newPassword");
    const value = control.value;

    if (!value) {
      return false;
    }

    let minimumRequirementsError = false;
    for (const key of keys) {
      if (control.hasError(key)) {
        minimumRequirementsError = true;
      }
    }

    return minimumRequirementsError;
  }
}
