import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { MaterialModule, CoreModule } from "@roctavian-abstractions/core";
import { ConfirmEmailAddressComponent } from "./components/confirm-email-address/confirm-email-address.component";

@NgModule({
  imports: [CommonModule, CoreModule, MaterialModule],
  declarations: [ConfirmEmailAddressComponent],
  exports: [ConfirmEmailAddressComponent]
})
export class EmailModule {}
