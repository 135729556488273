import { Component, OnInit, Inject, Output, EventEmitter } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { ConfirmationDialogConfig } from '../../models';
import { ConfirmationDialogButtons } from '../../enums';

@Component({
  selector: 'roctavian-eumea-callcenter-portal-confirmation-dialog',
  templateUrl: './confirmation-dialog.component.html',
  styleUrls: ['./confirmation-dialog.component.scss']
})
export class ConfirmationDialogComponent {
  public config: ConfirmationDialogConfig;
  public confirmationDialogButtons = ConfirmationDialogButtons;
  @Output() public continueClicked = new EventEmitter();
  @Output() public yesClicked = new EventEmitter();

  constructor(@Inject(MAT_DIALOG_DATA) public data: any, public reference: MatDialogRef<ConfirmationDialogComponent>) {
    this.config = data.config;
  }

  public continue() {
    this.continueClicked.emit();
  }

  public yes() {
    this.yesClicked.emit();
    this.reference.close();
  }
}
