import { Lab } from '../../lab';
import { Auditable } from '../../common';
import { LabUser } from './labuser.model';

export class LabUserLab extends Auditable {
  public id: string;
  public labUserId: string;
  public labUser: LabUser;
  public labId: string;
  public lab: Lab;
  public isAuthorizedRepresentative: boolean;
}
