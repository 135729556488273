import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MAT_DIALOG_DEFAULT_OPTIONS } from '@angular/material';
import { RouterModule } from '@angular/router';
import { CoreDirectivesModule, MaterialModule, PipeModule } from '@roctavian-abstractions/core';
import { IdentityModule } from '../../../identity/identity.module';
import { SharedModule } from '../../../shared/shared.module';
import { StakeholderCommonModule } from '../common';
import { AddPrescriberDelegateComponent } from './components/add-prescriber-delegate/add-prescriber-delegate.component';
import { PrescriberDelegateListComponent } from './components/prescriber-delegate-list/prescriber-delegate-list.component';
import { PrescriberDelegatePrescriberListComponent } from './components/prescriber-delegate-prescriber-list/prescriber-delegate-prescriber-list.component';
import { ViewPrescriberDelegateDialogComponent } from './components/view-prescriber-delegate-dialog/view-prescriber-delegate-dialog.component';
import { AddPrescriberDelegatePageComponent } from './pages/add-prescriber-delegate-page/add-prescriber-delegate-page.component';
import { EditPrescriberDelegatePageComponent } from './pages/edit-prescriber-delegate-page/edit-prescriber-delegate-page.component';
import { PrescriberDelegateAttestationPageComponent } from './pages/prescriber-delegate-attestation-page/prescriber-delegate-attestation-page.component';
import { PrescriberDelegateDetailMasterPageComponent } from './pages/prescriber-delegate-detail-master-page/prescriber-delegate-detail-master-page.component';
import { PrescriberDelegateListPageComponent } from './pages/prescriber-delegate-list-page/prescriber-delegate-list-page.component';
import { PrescriberDelegateStatusPageComponent } from './pages/prescriber-delegate-status-page/prescriber-delegate-status-page.component';
import { PrescriberDelegateSummaryPageComponent } from './pages/prescriber-delegate-summary-page/prescriber-delegate-summary-page.component';
import { PrescriberListPageComponent } from './pages/prescriber-list-page/prescriber-list-page.component';
import { PrescriberDelegateRoutingModule } from './prescriber-delegate-routing.module';

@NgModule({
  declarations: [
    PrescriberDelegateListPageComponent,
    AddPrescriberDelegatePageComponent,
    PrescriberDelegateDetailMasterPageComponent,
    PrescriberDelegateSummaryPageComponent,
    EditPrescriberDelegatePageComponent,
    ViewPrescriberDelegateDialogComponent,
    PrescriberDelegateListComponent,
    AddPrescriberDelegateComponent,
    PrescriberDelegateAttestationPageComponent,
    PrescriberDelegateStatusPageComponent,
    PrescriberListPageComponent,
    PrescriberDelegatePrescriberListComponent
  ],
  imports: [
    CommonModule,
    IdentityModule,
    RouterModule,
    MaterialModule,
    FormsModule,
    HttpClientModule,
    ReactiveFormsModule,
    PipeModule,
    PrescriberDelegateRoutingModule,
    CoreDirectivesModule,
    StakeholderCommonModule,
    SharedModule
  ],
  providers: [
    {
      provide: MAT_DIALOG_DEFAULT_OPTIONS,
      useValue: { hasBackdrop: false }
    }
  ],
  exports: [],
  entryComponents: [ViewPrescriberDelegateDialogComponent]
})
export class PrescriberDelegateModule {}
