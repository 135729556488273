import { Component, OnInit, OnDestroy } from '@angular/core';
import { LabUser } from '../../models/labuser.model';
import { Subscription } from 'rxjs';
import { LabUserService } from '../../services';

@Component({
  selector: 'roctavian-eumea-callcenter-portal-lab-user-attestation-page',
  templateUrl: './lab-user-attestation-page.component.html',
  styleUrls: ['./lab-user-attestation-page.component.scss']
})
export class LabUserAttestationPageComponent implements OnInit, OnDestroy {
  public labUser: LabUser;
  private subscriptions = new Array<Subscription>();

  constructor(private service: LabUserService) { }

  ngOnInit() {
    this.subscriptions.push(
      this.service.selectedLabUserSubject.subscribe(labUser => {
        this.labUser = labUser;
      })
    );
  }

  ngOnDestroy() {
    this.subscriptions.forEach(subscription => {
      subscription.unsubscribe();
    });
  }

  public onAttestationSaved() {
    this.service.loadLabUser(this.labUser.id, true);
  }
}
