import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog, MatSort, PageEvent } from '@angular/material';
import { Router } from '@angular/router';
import { AppTranslationService, MessageService } from '@roctavian-abstractions/core';
import { Outcome, PaginatedList } from '@roctavian-abstractions/web';
import { BehaviorSubject, EMPTY, Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged, switchMap } from 'rxjs/operators';
import { UtilityService } from '../../../common';
import { FileExportService } from '../../../common/services/file-export.service';
import { Prescriber } from '../../../prescriber';
import { PatientClient } from '../../clients';
import { Patient, PatientPagedQuery } from '../../models';
import { PatientService, PatientUtilityService } from '../../services';
import { ViewPatientDialogComponent } from '../view-patient-dialog/view-patient-dialog.component';

@Component({
  selector: 'patient-list',
  templateUrl: './patient-list.component.html',
  styleUrls: ['./patient-list.component.css']
})
export class PatientListComponent implements OnInit, OnDestroy {
  public pagedQuery = new PatientPagedQuery(1, 10, null, 'id', true, null, null, null);

  public filterText$ = new BehaviorSubject<string>(null);
  public pagedCollection: PaginatedList<Patient>;
  public isLoading = false;

  private subscriptions = new Array<Subscription>();
  private exportFileName = this.translate.getTranslation('Patient.PatientList.ExportFileName');

  public displayedColumns = [
    'id',
    'stakeholder.firstName',
    'stakeholder.lastName',
    'birthDate',
    'stakeholder.address.country.name',
    'patientsPrescribers.prescriber.stakeholder.fullName',
    'stakeholder.statuses.statusType',
    'stakeholder.statuses.addDate',
    'menu'
  ];

  constructor(
    private patientService: PatientService,
    private router: Router,
    public utilityService: UtilityService,
    private matDialog: MatDialog,
    private patientClient: PatientClient,
    private messageService: MessageService,
    private translate: AppTranslationService,
    private patientUtilityService: PatientUtilityService,
    private fileExportService: FileExportService
  ) {
    this.subscriptions.push(this.filterText$
      .pipe(
        debounceTime(1000),
        distinctUntilChanged(),
        switchMap(searchText => {
          if (this.pagedQuery.filterText === searchText) {
            return EMPTY;
          }

          this.pagedQuery.filterText = searchText;
          this.pagedQuery.pageIndex = 1;
          this.refreshList();
          return EMPTY;
        })
      )
      .subscribe());
  }

  private refreshList() {
    this.patientService.setPage(this.pagedQuery);
  }

  ngOnInit() {
    this.subscriptions.push(this.patientService.pagedListSubject.subscribe(collection => {
      this.pagedCollection = collection;
    }));

    this.subscriptions.push(this.patientService.isLoadingSubject.subscribe(isLoading => {
      this.isLoading = isLoading;
    }));

    this.refreshList();
  }

  ngOnDestroy() {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
    this.patientService.pagedListSubject.next(new PaginatedList<Patient>());
  }

  public onRowSelected(patient: Patient) {
    this.router.navigate(['/patient', patient.id]);
  }

  public viewPatient(patientId: string) {

    this.subscriptions.push(this.patientClient.getPatient(patientId).subscribe(
      outcome => {
        const dialogRef = this.matDialog.open(ViewPatientDialogComponent, {
          hasBackdrop: true,
          disableClose: true,
          data: {
            patient: outcome.value
          },
          width: '1000px'
        });
      },
      error => {
        this.messageService.open(this.translate.getTranslation('Common.ErrorProcessingRequest'), 3000);
      }
    ));
  }

  public onPageEvent(pageEvent: PageEvent) {
    this.pagedQuery.pageIndex = pageEvent.pageIndex + 1;
    this.pagedQuery.pageSize = pageEvent.pageSize;
    this.refreshList();
  }

  public onSortEvent(sort: MatSort) {
    switch (sort.active) {
      case 'id':
        this.pagedQuery.sortColumn = 'stakeholder.externalId';
        break;
      case 'patientsPrescribers.prescriber.stakeholder.fullName':
        this.pagedQuery.sortColumn =
          'patientsPrescribers.FirstOrDefault(x => x.IsCurrent).prescriber.stakeholder.firstName';
        break;
      case 'stakeholder.statuses.statusType':
        this.pagedQuery.sortColumn = 'stakeholder.statuses.FirstOrDefault(x => x.IsCurrent).statusType';
        break;
      case 'stakeholder.statuses.addDate':
        this.pagedQuery.sortColumn = 'stakeholder.statuses.FirstOrDefault(x => x.IsCurrent).addDate';
        break;

      default:
        this.pagedQuery.sortColumn = sort.active;
        break;
    }

    this.pagedQuery.isSortDesc = sort.direction === 'desc';
    this.pagedQuery.pageIndex = 1;
    this.refreshList();
  }

  public getCurrentPrescriber(patient: Patient): Prescriber {
    const patientPrescriber = this.patientUtilityService.getCurrentPatientPrescriberAssociation(
      patient.patientsPrescribers
    );
    if (!patientPrescriber) {
      return null;
    }

    return patientPrescriber.prescriber;
  }

  public downloadExportFile() {
    this.subscriptions.push(
      this.patientClient.getExportFile().subscribe(
        data => this.fileExportService.downloadFile(data, 'text/csv', this.exportFileName),
        (error: Outcome) => {
          if (!error.messages[0] == null || !error.messages[0] == undefined || error.messages.length !== 0) {
            this.messageService.open(error.messages[0], 3000);
          }
        }
      )
    );
  }
}
