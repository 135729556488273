import { Component, Input, Output, EventEmitter } from "@angular/core";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";

export class ChangeUsernameFormSubmitEvent {
  constructor(public newUsername: string) {}
}

@Component({
  selector: "roctavian-abstractions-change-username-form",
  templateUrl: "./change-username-form.component.html",
  styleUrls: ["./change-username-form.component.scss"]
})
export class ChangeUsernameFormComponent {
  public form: FormGroup;

  @Input() public disabled = false;
  @Input() public buttonText = "Change Username";

  @Output() public submitted = new EventEmitter<any>();

  constructor(private formBuilder: FormBuilder) {
    this.form = this.formBuilder.group({
      username: ["", Validators.required]
    });
  }

  public submitRequest(form: FormGroup) {
    const newUsername = form.get("username").value;
    this.submitted.emit(new ChangeUsernameFormSubmitEvent(newUsername));
  }
}
