import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MatSelectChange } from '@angular/material';
import { EntityService, EntityType } from '@roctavian-abstractions/core';
import { directions, priorities, actionTypes, results } from '../../constants';
import { WorkgroupUserClient } from './../../../../clients/workgroup-user.client';
import { WorkgroupClient } from './../../../../clients/workgroup.client';
import { TaskTypeClient } from './../../../../clients/task-type.client';
import {
  TaskType,
  CreateTaskModel,
  Workgroup,
  WorkgroupUser,
  CreateTaskActionModel,
  TaskPriority
} from '../../../../models';

@Component({
  selector: 'roctavian-abstractions-new-task-form',
  templateUrl: './new-task-form.component.html',
  styleUrls: ['./new-task-form.component.scss']
})
export class NewTaskFormComponent implements OnInit {
  @Output() public submitted: EventEmitter<CreateTaskModel> = new EventEmitter<CreateTaskModel>();

  public entityTypes: EntityType[] = [];
  public workgroups: Workgroup[] = [];
  public taskTypes: TaskType[] = [];
  public workgroupUsers: WorkgroupUser[] = [];

  public form: FormGroup;

  public priorities = priorities;
  public directions = directions;
  public actionTypes = actionTypes;
  public results = results;

  constructor(
    private builder: FormBuilder,
    private entityService: EntityService,
    private taskTypeService: TaskTypeClient,
    private workgroupClient: WorkgroupClient,
    private workgroupUserService: WorkgroupUserClient
  ) {}

  /**
   * Retrieves the entities and the workgroups from the API
   * and builds the form when the component is initialized.
   */
  public ngOnInit() {
    this.entityService.getEntityTypes().subscribe(result => {
      this.entityTypes = result;
    });

    this.workgroupClient.getWorkgroups().subscribe(result => {
      this.workgroups = result;
    });

    this.buildForm();
  }

  /**
   * Handles the select change event for the entity select.
   * The list of task types are filtered based on the entity type selected.
   *
   * @param event The select change event.
   */
  public onEntityTypeSelected(event: MatSelectChange): void {
    const entityTypeIdentity: number = event.value;

    if (entityTypeIdentity === null || entityTypeIdentity === undefined) {
      return;
    }

    this.form.patchValue({
      taskTypeIdentity: null
    });

    this.taskTypes = this.taskTypes.slice(0, 0);

    this.taskTypeService.getTaskTypesForEntityType(entityTypeIdentity).subscribe(result => {
      this.taskTypes = result.value;
    });
  }

  /**
   * Handles the selection of a workgroup by retrieving the users in the workgroup.
   *
   * @param event The workgroup select change event.
   */
  public onWorkgroupSelected(event: MatSelectChange): void {
    const workgroupIdentity = event.value;

    if (workgroupIdentity === null || workgroupIdentity === undefined) {
      this.workgroupUsers = this.workgroupUsers.slice(0, 0);
      return;
    }

    this.workgroupUserService.getWorkgroupUsers(workgroupIdentity).subscribe(outcome => {
      this.workgroupUsers = outcome.value;
    });
  }

  /**
   * Handles the submission of the form by mapping the form
   * fields to a model and emitting the submitted event.
   *
   * @param form The submitted form group.
   */
  public submit(form: FormGroup) {
    const model = new CreateTaskModel();
    Object.keys(form.controls).forEach(key => {
      model[key] = form.get(key).value;
    });

    const taskAction: CreateTaskActionModel = {
      directionId: form.get('directionId').value,
      actionTypeId: form.get('actionTypeId').value,
      resultId: form.get('resultId').value,
      notes: form.get('notes').value
    };

    model.taskAction = taskAction;
    this.submitted.emit(model);
  }

  /**
   * Determines whether the task is being assigned to a specific user.
   */
  public isBeingAssigned() {
    const assignedUser = this.form.get('assignedUserIdentity').value;
    if (assignedUser) {
      return true;
    }

    return false;
  }

  /**
   * Determines whether the selected task type is a compliance task.
   */
  public complianceTaskSelected() {
    const selectedTaskTypeIdentity = this.form.get('taskTypeIdentity').value;
    const taskType = this.taskTypes.find(tt => tt.taskTypeIdentity === selectedTaskTypeIdentity);
    if (taskType && taskType.isCompliance) {
      return true;
    }

    return false;
  }

  private addDays(date, days) {
    const result = new Date(date);
    result.setDate(result.getDate() + days);
    return result;
  }

  /**
   * Builds the new task form.
   */
  private buildForm() {
    const date = new Date();
    this.form = this.builder.group({
      entityTypeIdentity: [null, Validators.required],
      taskEntityName: [null, Validators.required],
      taskTypeIdentity: [null, Validators.required],
      workgroupIdentity: [null],
      assignedUserIdentity: [null],
      priority: [TaskPriority.Low],
      dueDate: [new Date().setDate(new Date().getDate() + 7)],
      directionId: [null, Validators.required],
      actionTypeId: [null, Validators.required],
      resultId: [null, Validators.required],
      notes: [null]
    });
  }
}
