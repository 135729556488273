import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatSort, PageEvent } from '@angular/material';
import { ActivatedRoute } from '@angular/router';
import { ContentTypes, DocumentTypes } from '@app/core/enums';
import { DownloadDocument, documentTypeNames } from '@app/core/models';
import { DocumentStorageService } from '@app/core/services';
import { Document } from '@app/stakeholder/models';
import { PaginatedList } from '@roctavian-abstractions/web';
import { FileUploadMessageService } from 'apps/roctavian-eumea-callcenter-portal/src/app/modules/shared/components/file-upload';
import { BehaviorSubject, EMPTY, Subject } from 'rxjs';
import {
  debounceTime,
  distinctUntilChanged,
  switchMap,
  takeUntil,
  tap,
} from 'rxjs/operators';
import { UtilityService } from '../../../common';
import { DocumentLab, DocumentLabQuery } from '../../models';
import { LabService } from '../../services';

@Component({
  selector: 'roctavian-eumea-callcenter-portal-lab-documents-page',
  templateUrl: './lab-documents-page.component.html',
  styleUrls: ['./lab-documents-page.component.scss'],
})
export class LabDocumentsPageComponent implements OnInit, OnDestroy {
  private readonly destroyed$ = new Subject();
  private labId: string;

  displayedColumns = ['id', 'document.documentType', 'addDate', 'menu'];
  documentTypes = documentTypeNames;
  filterText$ = new BehaviorSubject<string>(null);
  isLoading = false;
  pagedCollection: PaginatedList<DocumentLab>;
  pagedQuery = new DocumentLabQuery(1, 10, null, null, true);
  selectedDocumentType: DocumentTypes;

  constructor(
    private readonly documentStorageService: DocumentStorageService,
    private readonly fileUploadMessageService: FileUploadMessageService,
    private readonly labService: LabService,
    private readonly route: ActivatedRoute,
    public readonly utilityService: UtilityService
  ) {
    this.route.parent.paramMap
      .pipe(
        takeUntil(this.destroyed$),
        tap(params => {
          const id = params.get('id');
          this.labId = id;
          this.pagedQuery.labId = id;
        })
      )
      .subscribe();

    this.filterText$
      .pipe(
        takeUntil(this.destroyed$),
        debounceTime(1000),
        distinctUntilChanged(),
        switchMap(searchText => {
          this.pagedQuery.filterText = searchText;
          this.pagedQuery.pageIndex = 1;
          this.refreshList();
          return EMPTY;
        })
      )
      .subscribe();
  }

  ngOnInit() {
    this.fileUploadMessageService.uploaded$
      .pipe(
        takeUntil(this.destroyed$),
        tap(document => this.associateDocument(document))
      )
      .subscribe();

    this.labService.pagedLabDocumentsListSubject
      .pipe(
        takeUntil(this.destroyed$),
        tap(collection => (this.pagedCollection = collection))
      )
      .subscribe();

    this.labService.isLoadingSubject
      .pipe(
        takeUntil(this.destroyed$),
        tap(isLoading => (this.isLoading = isLoading))
      )
      .subscribe();

    this.labService.addDocumentToLabSubject
      .pipe(
        takeUntil(this.destroyed$),
        tap(outcome => {
          if (outcome != null) {
            this.refreshList();
          }
        })
      )
      .subscribe();
  }

  ngOnDestroy() {
    this.destroyed$.unsubscribe();
  }

  onRowSelected(document: Document) {
    this.viewDocument(document);
  }

  onDocumentTypeSelected(documentType: number) {
    this.selectedDocumentType = documentType;
  }

  viewDocument(document: Document) {
    let fileType = ContentTypes[document.documentPath.split('.').slice(-1)[0]];
    if (fileType === null || fileType === undefined){
      fileType = ContentTypes.pdf;
    }
    const storageDocument: DownloadDocument = {
      contentType: fileType,
      documentType: DocumentTypes.labDocument,
      fileName: document.documentPath,
    };

    this.documentStorageService
      .download(storageDocument)
      .pipe(
        takeUntil(this.destroyed$),
        tap(url => window.open(url, '_blank'))
      )
      .subscribe();
  }

  onPageEvent(pageEvent: PageEvent) {
    this.pagedQuery.pageIndex = pageEvent.pageIndex + 1;
    this.pagedQuery.pageSize = pageEvent.pageSize;
    this.refreshList();
  }

  onSortEvent(sort: MatSort) {
    this.pagedQuery.sortColumn = sort.active;
    this.pagedQuery.isSortDesc = sort.direction === 'desc';
    this.pagedQuery.pageIndex = 1;
    this.refreshList();
  }

  private associateDocument(document: Document) {
    this.labService.addDocumentToLab(this.labId, document);
  }

  private refreshList() {
    this.labService.setDocumentsPage(this.pagedQuery);
  }
}
