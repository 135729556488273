import { TaskStatus } from './Task';

/**
 * Class used to represent a task list item.
 */
export class TaskListItem {
         public taskIdentity: string;
         public taskName: string;
         public entityTypeIdentity: string;
         public entityType: string;
         public workgroupName: string;
         public statusIdentity: TaskStatus;
         public assignedUserIdentity: string;
         public assignedUser: string;
         public priority: number;
         public priorityDisplay: string;
         public dueDate: Date;
         public closedDate: Date;
         public addUserIdentity: string;
         public addUser: string;
         public addDate: Date;
         public editUser: string;
         public editDate: Date;
       }
