import { PagedQuery } from '../../common';

export class PrescriberLabPagedQuery extends PagedQuery {
  constructor(
    public pageIndex: number,
    public pageSize: number,
    public filterText: string,
    public sortColumn: string,
    public isSortDesc: boolean,
    public country: string,
    public labName: string,
    public nationalId: string
  ) {
    super(pageIndex, pageSize, filterText, sortColumn, isSortDesc);
  }
}
