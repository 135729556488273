import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { TaskListItem } from '../models';
import { TaskClient } from './../clients/task.client';

@Injectable({
  providedIn: 'root'
})
export class TaskManagementService {
  public tasks = new BehaviorSubject<TaskListItem[]>([]);
  public total = new BehaviorSubject<number>(0);
  public loading = new BehaviorSubject<boolean>(true);

  private page = 1;
  private size = 10;
  private filterText = '';

  /**
   * Returns the current value of the page.
   */
  get currentPage(): number {
    return this.page;
  }

  /**
   * Returns the current value of the page size.
   */
  get pageSize(): number {
    return this.size;
  }

  /**
   * Returns the current value of the filter text.
   */
  get currentFilterText(): string {
    return this.filterText;
  }

  constructor(private client: TaskClient) {}

  /**
   * Executes a request to the paged workgroup endpoint. The workgroups,
   * total, and loading behavior subjects are updated when the call
   * is completed.
   *
   * @param page The requested page.
   * @param size The requested page size.
   * @param filterText Filtering text that can be applied.
   */
  public setPage(page: number, size: number, filterText: string) {
    this.filterText = filterText;
    this.loading.next(true);

    this.client.getTaskListPaged(page, size, filterText).subscribe(collection => {
      this.page = page;
      this.size = size;
      this.total.next(collection.totalCount);
      this.tasks.next(collection.items);
      this.loading.next(false);
    });
  }
}
