import { OnDestroy } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import { PrescriberDelegate } from '../../models/prescriber-delegate.model';
import { Subscription } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { PrescriberDelegateService } from '../../services/prescriber-delegate.service';

@Component({
  selector: 'roctavian-eumea-callcenter-portal-prescriber-delegate-detail-master-page',
  templateUrl: './prescriber-delegate-detail-master-page.component.html',
  styleUrls: ['./prescriber-delegate-detail-master-page.component.css']
})
export class PrescriberDelegateDetailMasterPageComponent implements OnInit, OnDestroy {
  public prescriberDelegate: PrescriberDelegate;
  private subscriptions = new Array<Subscription>();

  constructor(private route: ActivatedRoute, private service: PrescriberDelegateService) { }

  ngOnInit() {
    this.subscriptions.push(
      this.service.selectedPrescriberDelegateSubject.subscribe(
        prescriberDelegate => (this.prescriberDelegate = prescriberDelegate)
      )
    );

    this.subscriptions.push(
      this.route.paramMap.subscribe(params => {
        const id = String(params.get('id'));
        this.service.loadPrescriberDelegate(id);
      })
    );
  }

  ngOnDestroy() {
    this.subscriptions.forEach(subscription => {
      subscription.unsubscribe();
    });
    this.service.selectedPrescriberDelegateSubject.next(null);
  }

  public getSidenavMode(): string {
    this.setStyleBasedOnMainNavbarHeight();
    if (window.innerWidth < 750) {
      return 'over';
    }
    return 'side';
  }

  public setStyleBasedOnMainNavbarHeight() {
    const mainNavbar = document.getElementById('main-navbar');
    if (!mainNavbar) {
      return;
    }

    const element: any = document.getElementsByClassName('sidenav-container').item(0);
    element.style.top = `${mainNavbar.scrollHeight}px`;
  }
}
