import { Component, OnInit, OnDestroy } from '@angular/core';
import { LabUser } from '../../models/labuser.model';
import { Subscription } from 'rxjs';
import { LabUserService } from '../../services';

@Component({
  selector: 'roctavian-eumea-callcenter-portal-lab-user-status-page',
  templateUrl: './lab-user-status-page.component.html',
  styleUrls: ['./lab-user-status-page.component.scss']
})
export class LabUserStatusPageComponent implements OnInit, OnDestroy {
  public labUser: LabUser;
  private subscriptions = new Array<Subscription>();

  constructor(private service: LabUserService) {}

  ngOnInit() {
    this.subscriptions.push(
      this.service.selectedLabUserSubject.subscribe(labuser => {
        this.labUser = labuser;
      })
    );
  }

  ngOnDestroy() {
    this.subscriptions.forEach(subscription => {
      subscription.unsubscribe();
    });
  }

  public onStatusSaved() {
    this.service.loadLabUser(this.labUser.id, true);
  }
}
