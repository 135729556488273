import { Component } from "@angular/core";

@Component({
  selector: "roctavian-abstractions-forbidden-page",
  templateUrl: "./forbidden-page.component.html",
  styleUrls: ["./forbidden-page.component.scss"]
})
export class ForbiddenPageComponent {
  constructor() {}
}
