import { User } from './user';
import { userRole } from './user-role.model';

export class SimplifiedUser {
  public id: string;
  public userName: string;
  public firstName: string;
  public lastName: string;
  public locked: boolean;
  public lockoutEnd: Date;
  public email: string;
  // public accessFailedCount: number;
  public addDate: Date;
  public addUser: User;
  public userRoles: userRole[];
}
