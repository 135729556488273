import { User } from '../../../../identity/models/user';
import { StakeholderTypes } from '../enums';
import { Address } from './address.model';
import { Attestation } from './attestation.model';
import { Auditable } from './auditable.model';
import { Status } from './status.model';

export class Stakeholder extends Auditable {
  public id: string;
  public firstName: string;
  public middleName: string;
  public lastName: string;
  public suffix: string;
  public statuses: Status[];
  public address: Address;
  public attestations: Attestation[];
  public notes: string;
  public email: string;
  public phone: string;
  public fax: string;
  public user: User;
  public stakeholderType: StakeholderTypes;
  public externalId: number;
}
