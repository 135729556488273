import { AppTranslationService, MessageService } from "@roctavian-abstractions/core";
import { Component, EventEmitter, Input, Output } from "@angular/core";
import { LoginSubmitEvent } from "./../login-form/login-form.component";
import { Outcome } from "@roctavian-abstractions/web";
import { AuthenticationService } from "../../../../services/authentication.service";
import { TwoFactorClient } from "./../../../../clients/two-factor.client";
import { HttpErrorResponse } from '@angular/common/http';

export abstract class LoginFailureHandler {
  public abstract handleFailure(outcome: Outcome): void;
}

export class TokenStatus {
  public static readonly InitialLockout = 400;
  public static readonly AccountLocked = 401;
  public static readonly PasswordExpired = 402;
  public static readonly EmailNotConfirmed = 403;
}

export class TwoFactorVerificationRequiredEvent {
  constructor(public username: string, public password: string) { }
}

@Component({
  selector: "roctavian-abstractions-login",
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.scss"]
})
export class LoginComponent {
  @Input() public showSocialButtons = false;
  @Input() public submitButtonText = "Continue";
  @Input() public usernameText = "Username";
  @Input() public passwordText = "Password";
  @Input() public provideUsernameText = "Please provide your username";
  @Input() public providePasswordText = "Please provide your password";
  @Input() public forgotPasswordText = "Forgot your password?";
  @Input() public forgotPasswordRouterLink = "/forgot";

  @Output() public authenticated = new EventEmitter();
  @Output() public expired = new EventEmitter();
  @Output() public initialLockout = new EventEmitter();
  @Output() public lockout = new EventEmitter();
  @Output() public emailNotConfirmed = new EventEmitter();
  @Output() public twoFactorRequired = new EventEmitter<TwoFactorVerificationRequiredEvent>();

  public disabled = false;
  public errorMessages: string[] = [];
  public displayUnconfirmedEmailMessage = false;

  constructor(
    private messageService: MessageService,
    private service: AuthenticationService,
    private translate: AppTranslationService,
    private twoFactorClient: TwoFactorClient
  ) { }

  public clearMessages(): void {
    this.errorMessages = [];
  }

  public handleFormSubmitted(loginSubmitEvent: LoginSubmitEvent) {
    this.disabled = true;

    const username = loginSubmitEvent.username;
    const password = loginSubmitEvent.password;

    this.twoFactorClient.getTwoFactorStatusAnonymous(username, password).subscribe(result => {
      if (result.twoFactorAuthenticationEnabled) {
        const twoFactorEvent = new TwoFactorVerificationRequiredEvent(username, password);
        this.twoFactorRequired.emit(twoFactorEvent);
      } else {
        this.requestToken(username, password);
      }
    });
  }

  /**
   * Requests a token from the endpoint to authenticate the user.
   * @param username The username.
   * @param password The password.
   */
  private requestToken(username: string, password: string) {
    const verificationToken = null;
    this.service.login(username, password, verificationToken).subscribe(
      () => {
        this.disabled = false;
        this.authenticated.emit();
      },
      (error: HttpErrorResponse) => {
        this.disabled = false;
        this.clearErrorFlags();
        this.clearMessages();

        const outcome: Outcome = error.error;
        if (outcome && outcome.messages && outcome.messages.length > 0) {
          if(outcome.messages.find(message => message.includes("Your username or password is incorrect.")) != undefined)
          {
            this.errorMessages.push(this.translate.getTranslation("Common.IncorrectCredentialsMessage"));
          }
          else{
            this.errorMessages = this.errorMessages.concat(outcome.messages);
            this.handleAuthenticationErrors(outcome);
          }
          return;
        }

        const message = "An error occurred when submitting your request. Please try again.";

        this.messageService.open(message, 5000);
      }
    );
  }

  private handleAuthenticationErrors(outcome: Outcome) {
    switch (outcome.statusCode) {
      case TokenStatus.PasswordExpired:
        this.expired.emit();
        break;
      case TokenStatus.InitialLockout:
        this.initialLockout.emit();
        break;
      case TokenStatus.AccountLocked:
        this.lockout.emit();
        break;
      case TokenStatus.EmailNotConfirmed:
        this.displayUnconfirmedEmailMessage = true;
        this.emailNotConfirmed.emit();
        break;
    }
  }

  public clearErrorFlags() {
    this.displayUnconfirmedEmailMessage = false;
  }
}
