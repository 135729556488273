import { Injectable } from '@angular/core';
import { Outcome } from '@roctavian-abstractions/web';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { PermissionClient } from '../../identity/clients/permission.client';
import { Permission } from '../../identity/models';

@Injectable({
  providedIn: 'root',
})
export class PermissionManagementService {
  public permissions: Permission[];
  public permissions$ = new BehaviorSubject<Permission[]>([]);
  public unfilteredPermissionCount$ = new BehaviorSubject<number>(0);
  public loading = new BehaviorSubject<boolean>(true);
  public initialized = false;
  private filterText = '';

  constructor(private client: PermissionClient) {
    if (!this.initialized) {
      this.getPermissions();
      this.initialized = true;
    }
  }

  public getFilterText() {
    return this.filterText;
  }

  public getPermissions() {
    this.loading.next(true);
    this.client
      .getPermissions()
      .pipe(
        map(permissions => {
          this.loading.next(false);
          return permissions;
        })
      )
      .subscribe(permissions => {
        this.permissions = permissions;
        this.permissions$.next(permissions);
        this.unfilteredPermissionCount$.next(permissions.length);
      });
  }

  public refreshPermissions() {
    this.client.getPermissions().subscribe(permissions => {
      this.permissions = permissions;
      this.unfilteredPermissionCount$.next(permissions.length);
      this.filterPermissions(this.filterText);
    });
  }

  private filter(permissions: Permission[], text: string) {
    if (!text) {
      return permissions;
    }

    text = text.toLowerCase();
    const filteredPermissions = this.permissions.filter(permission => {
      const nameMatches = permission.name.toLowerCase().indexOf(text) > -1;
      const descriptionMatches = permission.description.toLowerCase().indexOf(text) > -1;
      const groupMatches = permission.group.toLowerCase().indexOf(text) > -1;

      return nameMatches || descriptionMatches || groupMatches;
    });

    return filteredPermissions;
  }

  public filterPermissions(text: string) {
    if (!text) {
      this.filterText = text;
      this.permissions$.next(this.permissions);
      return;
    }

    this.filterText = text.toLowerCase();

    const filteredPermissions = this.filter(this.permissions, this.filterText);

    this.permissions$.next(filteredPermissions);
  }

  public deletePermission(identity: string): Observable<Outcome> {
    return this.client.deletePermission(identity).pipe(
      map(outcome => {
        // Remove the deleted permission from the collection:
        this.permissions = this.permissions.filter(p => p.id !== identity);
        // Apply a filter on the permissions as filtering text may be active:
        const filteredPermissions = this.filter(this.permissions, this.filterText);
        // Set the behavior subject so the deleted permission is removed:
        this.permissions$.next(filteredPermissions);

        return outcome;
      })
    );
  }
}
