import { Component, OnInit } from "@angular/core";
import { AuthenticationHistoryRecord } from "./../../../../models";
import { AuthenticationHistoryClient } from "../../../../clients/authentication-history.client";

@Component({
  selector: "roctavian-abstractions-authentication-history",
  templateUrl: "./authentication-history.component.html",
  styleUrls: ["./authentication-history.component.scss"]
})
export class AuthenticationHistoryComponent implements OnInit {
  public records: AuthenticationHistoryRecord[] = [];

  constructor(private client: AuthenticationHistoryClient) { }

  public ngOnInit() {
    this.client.GetUserAuthenticationHistory(1, 10).subscribe(PaginatedList => {
      this.records = PaginatedList.items;
    });
  }
}
