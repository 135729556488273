import { OnDestroy, ViewChild } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import { Patient, PatientPrescriberAssociatePagedQuery } from '../../models';
import { Subscription } from 'rxjs';
import { PatientService } from '../../services';
import { PatientPrescriberAssociatedListComponent } from '../../components/patient-prescriber-associated-list/patient-prescriber-associated-list.component';
import { StatusTypes } from '../../../common';
import { PatientClient } from '../../clients';
import { MessageService } from '@roctavian-abstractions/core';
import { Outcome, PaginatedList } from '@roctavian-abstractions/web';
import { AppTranslationService } from '@roctavian-abstractions/core';
import { Prescriber } from '../../../prescriber';

@Component({
  selector: 'roctavian-eumea-callcenter-portal-patient-prescriber-page',
  templateUrl: './patient-prescriber-page.component.html',
  styleUrls: ['./patient-prescriber-page.component.scss']
})
export class PatientPrescriberPageComponent implements OnInit, OnDestroy {
  public patient: Patient;
  public isProcessing = false;
  public subscriptions = new Array<Subscription>();
  public errorMessages = new Array<string>();

  @ViewChild(PatientPrescriberAssociatedListComponent, { static: false })
  public patientPrescriberAssociatedList: PatientPrescriberAssociatedListComponent;
  public showAssociationScreen = false;
  public pagedQuery = new PatientPrescriberAssociatePagedQuery(1, 10, null, 'id', true, null, StatusTypes.Registered, null);

  constructor(
    private service: PatientService,
    private client: PatientClient,
    private messageService: MessageService,
    private translate: AppTranslationService
  ) { }

  ngOnInit() {
    this.subscriptions.push(this.service.selectedPatientSubject.subscribe(patient => (this.patient = patient)));
  }

  ngOnDestroy() {
    this.subscriptions.forEach(subscription => {
      subscription.unsubscribe();
    });
    this.service.pagedPatientPrescriberAssociateListSubject.next(new PaginatedList<Prescriber>());
  }

  public refreshList() {
    let patientId: string = null;
    if (this.patient) {
      patientId = this.patient.id;
    }
    this.service.setPatientPrescriberAssociatePage(this.pagedQuery, patientId);
    this.service.selectPatient(patientId);
  }

  public beginAssociation() {
    this.showAssociationScreen = true;
  }

  public onAssociated() {
    this.showAssociationScreen = false;
    this.service.selectPatient(this.patient.id, true);
    this.refreshList();

    // trigger status update

    this.subscriptions.push(
      this.client.updatePatient(this.patient).subscribe(
        outcome => {
          this.isProcessing = false;
          if (outcome.failure) {
            this.handleError(outcome);
            return;
          }
        },
        (error: Outcome) => {
          this.isProcessing = false;
          this.handleError(error);
        }
      )
    );
  }
  private handleError(error: Outcome) {
    if (!error || !error.messages || error.messages.length === 0) {
      this.errorMessages = [this.translate.getTranslation('Common.ErrorProcessingRequest')];
    } else {
      this.errorMessages = error.messages;
    }
  }

  public onCancelAssociation() {
    this.showAssociationScreen = false;
  }
}
