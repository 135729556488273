import { Component, OnInit } from '@angular/core';
import { LabUser } from '../../models/labuser.model';

@Component({
  selector: 'roctavian-eumea-callcenter-portal-add-lab-user-page',
  templateUrl: './add-lab-user-page.component.html',
  styleUrls: ['./add-lab-user-page.component.scss']
})
export class AddLabUserPageComponent implements OnInit {
  public labUser: LabUser;

  constructor() {}

  ngOnInit() {}

  public onContunueWithLabUser(labUser: LabUser) {
    this.labUser = labUser;
  }
}
