import { Component, OnInit, OnDestroy } from '@angular/core';
import { BehaviorSubject, Subscription, EMPTY } from 'rxjs';
import { Router } from '@angular/router';
import { MatDialog, PageEvent, MatSort } from '@angular/material';
import { MessageService } from '@roctavian-abstractions/core';
import { AppTranslationService } from '@roctavian-abstractions/core';
import { debounceTime, distinctUntilChanged, switchMap } from 'rxjs/operators';
import { PagedQuery, UtilityService } from '../../../common';
import { LabService } from '../../services';
import { LabClient } from '../../clients';
import { Lab } from '../../models';
import { ViewLabDialogComponent } from '../view-lab-dialog/view-lab-dialog.component';
import { FileExportService } from '../../../common/services/file-export.service';
import { PaginatedList, Outcome } from '@roctavian-abstractions/web';

@Component({
  selector: 'lab-list',
  templateUrl: './lab-list.component.html',
  styleUrls: ['./lab-list.component.css']
})
export class LabListComponent implements OnInit, OnDestroy {
  public pagedQuery = new PagedQuery(1, 10, null, 'id', true);

  public filterText$ = new BehaviorSubject<string>(null);
  public pagedCollection: PaginatedList<Lab>;
  public isLoading = false;
  private exportFileName = this.translate.getTranslation('Lab.LabList.ExportFileName');
  public exportLabel = this.translate.getTranslation('Common.Export');
  private subscriptions = new Array<Subscription>();

  public displayedColumns = [
    'nationalId',
    'stakeholder.address.country.name',
    'labName',
    'stakeholder.address.name',
    'stakeholder.address.address1',
    'stakeholder.address.city',
    'stakeholder.address.postalCode',
    'stakeholder.email',
    'stakeholder.phone',
    'stakeholder.statuses.statusType',
    'stakeholder.statuses.addDate',
    'menu'
  ];

  constructor(
    private labService: LabService,
    private router: Router,
    public utilityService: UtilityService,
    private matDialog: MatDialog,
    private labClient: LabClient,
    private messageService: MessageService,
    private translate: AppTranslationService,
    private fileExportService: FileExportService
  ) { }

  private refreshList() {
    this.labService.setPage(this.pagedQuery);
  }

  ngOnInit() {
    this.subscriptions.push(
      this.filterText$
        .pipe(
          debounceTime(1000),
          distinctUntilChanged(),
          switchMap(searchText => {
            if (this.pagedQuery.filterText == searchText) {
              return EMPTY;
            }

            this.pagedQuery.filterText = searchText;
            this.pagedQuery.pageIndex = 1;
            this.refreshList();
            return EMPTY;
          })
        )
        .subscribe()
    );
    this.subscriptions.push(
      this.labService.pagedListSubject.subscribe(collection => {
        this.pagedCollection = collection;
      })
    );

    this.subscriptions.push(
      this.labService.isLoadingSubject.subscribe(isLoading => {
        this.isLoading = isLoading;
      })
    );
    this.refreshList();
  }

  ngOnDestroy() {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
    this.labService.pagedListSubject.next(new PaginatedList<Lab>());
  }

  public onRowSelected(lab: Lab) {
    this.router.navigate(['/lab', lab.id]);
  }

  public viewLab(institutionId: string) {

    this.subscriptions.push(
      this.labClient.getLab(institutionId).subscribe(
        outcome => {
          const dialogRef = this.matDialog.open(ViewLabDialogComponent, {
            hasBackdrop: true,
            disableClose: true,
            data: {
              lab: outcome.value
            },
            width: '1000px'
          });
        },
        error => {
          this.messageService.open(this.translate.getTranslation('Common.ErrorProcessingRequest'), 3000);
        }
      )
    );
  }

  public onPageEvent(pageEvent: PageEvent) {
    this.pagedQuery.pageIndex = pageEvent.pageIndex + 1;
    this.pagedQuery.pageSize = pageEvent.pageSize;
    this.refreshList();
  }

  public onSortEvent(sort: MatSort) {
    switch (sort.active) {
      case 'stakeholder.statuses.statusType':
        this.pagedQuery.sortColumn = 'stakeholder.statuses.FirstOrDefault(x => x.IsCurrent).statusType';
        break;
      case 'stakeholder.statuses.addDate':
        this.pagedQuery.sortColumn = 'stakeholder.statuses.FirstOrDefault(x => x.IsCurrent).addDate';
        break;
      default:
        this.pagedQuery.sortColumn = sort.active;
        break;
    }
    this.pagedQuery.isSortDesc = sort.direction == 'desc';
    this.pagedQuery.pageIndex = 1;
    this.refreshList();
  }

  public downloadExportFile() {
    this.subscriptions.push(
      this.labClient.getExportFile().subscribe(
        data => this.fileExportService.downloadFile(data, 'text/csv', this.exportFileName),
        (error: Outcome) => {
          if (!error.messages[0] == null || !error.messages[0] == undefined || error.messages.length !== 0) {
            this.messageService.open(error.messages[0], 3000);
          }
        }
      )
    );
  }
}
