/* eslint-disable eqeqeq */
/* eslint-disable prefer-arrow/prefer-arrow-functions */
import {
  Component, EventEmitter, Input,
  OnDestroy,
  Output, ViewChild
} from '@angular/core';
import { MatPaginator, MatSlideToggleChange } from '@angular/material';
import { AppTranslationService, MessageService } from '@roctavian-abstractions/core';
import { Outcome } from '@roctavian-abstractions/web';
import { UserClient } from 'apps/roctavian-eumea-callcenter-portal/src/app/modules/identity/clients/user.client';
import { SimplifiedUser } from 'apps/roctavian-eumea-callcenter-portal/src/app/modules/identity/models';
import { userRole } from 'apps/roctavian-eumea-callcenter-portal/src/app/modules/identity/models/user-role.model';
import { Subject, Subscription } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { UserManagementService } from '../../../../services/user-management.service';

@Component({
  selector: 'roctavian-abstractions-user-grid',
  templateUrl: './user-grid.component.html',
  styleUrls: ['./user-grid.component.scss'],
})
export class UserGridComponent implements OnDestroy {
  @Input() public page = 1;
  @Input() public users = new Array<SimplifiedUser>();
  @Input() public total = 0;
  @Input() public isTableLoading = false;

  public filterText: string;
  public size = 15;
  public options: number[] = [5, 10, 25];
  private subscriptions = new Array<Subscription>();

  public unsubscribe: Subject<boolean> = new Subject<boolean>();
  @Output() viewUserSelected = new EventEmitter<string>();
  @Output() editUserSelected = new EventEmitter<string>();

  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;

  constructor(
    private service: UserManagementService,
    private client: UserClient,
    private messageService: MessageService,
    private translate: AppTranslationService) {
    this.filterText = this.service.getFilterText();
    this.page = this.service.getPage();
    this.size = this.service.getPageSize();
    this.service.loading.pipe(takeUntil(this.unsubscribe)).subscribe(loading => {
      this.isTableLoading = loading;
    });
    this.service.total.pipe(takeUntil(this.unsubscribe)).subscribe(total => {
      this.total = total;
    });
    this.service.users.pipe(takeUntil(this.unsubscribe)).subscribe(users => {
      this.users = users;
    });
    this.service.setPage(this.page, this.size, this.filterText);
  }

  private clearSubscriptions() {
    this.subscriptions.forEach(subscription => {
      subscription.unsubscribe();
    });

    this.subscriptions = new Array<Subscription>();
  }

  public handleViewUserClick(user: SimplifiedUser) {
    this.viewUserSelected.emit(user.id);
  }

  public handleEditUserClick(user: SimplifiedUser) {
    this.editUserSelected.emit(user.id);
  }

  public resendInvite(user: SimplifiedUser) {
    this.client.isLoadingSubject.next(true);
    this.subscriptions.push(
      this.client.reSendInvite(user.email).subscribe(
        outcome => {
          if (outcome.success || outcome.failure) {
            if (outcome.messages.length > 0){
              const message = `${outcome.messages[0]}`;
              this.messageService.open(message, 5000);
            }
            else {
              this.messageService.open('Invite sent', 5000);
            }
            return;
          }
          this.client.isLoadingSubject.next(false);
        },
        (error: Outcome) => {
          this.handleError(error);
        },
      ),
    );
  }

  private handleError(outcome: Outcome) {
    this.messageService.open(
      this.translate.getTranslation('Common.ErrorProcessingRequest'),
      5000,
    );
    this.client.isLoadingSubject.next(false);
  }

  public getUserRolesCommaSeparated(userRoles: userRole[]): string {
    userRoles.sort(function(a, b) {
      const roleA = a.role.name.toUpperCase();
      const roleB = b.role.name.toUpperCase();
      if (roleA < roleB) {
        return -1;
      }
      if (roleA > roleB) {
        return 1;
      }
      return 0;
    });
    //get comma separated list of roles for user
    let userRolesCommaSeparated: string = '';
    for (let i = 0; i < userRoles.length; i++) {
      userRolesCommaSeparated += userRoles[i].role.name;
      if (userRoles.length > 1 && i != userRoles.length - 1) {
        userRolesCommaSeparated += ', ';
      }
    }
    return userRolesCommaSeparated;
  }

  public handleUserLock(event: MatSlideToggleChange, user: SimplifiedUser) {
    // find user and set lock status
    const selectedUser = this.users.find(usr => usr.id === user.id);

    if (!selectedUser) {
      return;
    }

    if (event.checked) {
      this.client
        .lockUser(selectedUser.id)
        .subscribe(() => this.handleLockEvent(selectedUser, event.checked));

      return;
    }

    this.client
      .unlockUser(selectedUser.id)
      .subscribe(() => this.handleLockEvent(selectedUser, event.checked));
  }

  public handleKeyup(text: string) {
    this.page = 1;
    this.filterText = text;
    this.service.setPage(this.page, this.size, text);
  }

  public handlePage(event) {
    this.page = event.pageIndex + 1;
    this.service.setPage(event.pageIndex + 1, this.size, this.filterText);
  }

  /**
   * Clean-up observable subscriptions
   * when the component is destroyed.
   */
  public ngOnDestroy() {
    this.clearSubscriptions();
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  private handleLockEvent(user: SimplifiedUser, isLocked: boolean) {
    // find user and set lock status
    const selectedUser = this.users.find(usr => usr.id === user.id);

    // ensure user
    if (!selectedUser) {
      return;
    }

    // set lock status
    selectedUser.locked = isLocked;
  }
}
