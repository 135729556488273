import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MAT_DIALOG_DEFAULT_OPTIONS, MatProgressSpinnerModule } from '@angular/material';
import { RouterModule } from '@angular/router';
import { CoreDirectivesModule, MaterialModule, PipeModule } from '@roctavian-abstractions/core';
import { IdentityModule } from '../../../identity/identity.module';
import { SharedModule } from '../../../shared/shared.module';
import { StakeholderCommonModule } from '../common';
import { PrescriberModule } from '../prescriber';
import {
  AddPatientComponent,
  PatientFormComponent,
  PatientListComponent,
  PatientLookupComponent,
  ViewPatientComponent,
  ViewPatientDialogComponent
} from './components';
import { PatientAssociatePrescriberComponent } from './components/patient-associate-prescriber/patient-associate-prescriber.component';
import { PatientLabTestListComponent } from './components/patient-lab-test-list/patient-lab-test-list.component';
import { PatientPrescriberAssociateListComponent } from './components/patient-prescriber-associate-list/patient-prescriber-associate-list.component';
import { PatientPrescriberAssociatedListComponent } from './components/patient-prescriber-associated-list/patient-prescriber-associated-list.component';
import {
  AddPatientPageComponent,
  PatientDetailMasterPageComponent,
  PatientEditPageComponent,
  PatientListPageComponent,
  PatientStatusPageComponent,
  PatientSummaryPageComponent
} from './pages';
import { PatientAttestationPageComponent } from './pages/patient-attestation-page/patient-attestation-page.component';
import { PatientDocumentsPageComponent } from './pages/patient-documents-page/patient-documents-page.component';
import { PatientLabTestPageComponent } from './pages/patient-lab-test-page/patient-lab-test-page.component';
import { PatientPrescriberPageComponent } from './pages/patient-prescriber-page/patient-prescriber-page.component';
import { PatientRoutingModule } from './patient-routing.module';

@NgModule({
  declarations: [
    PatientListPageComponent,
    AddPatientPageComponent,
    PatientDetailMasterPageComponent,
    PatientSummaryPageComponent,
    PatientEditPageComponent,
    PatientStatusPageComponent,
    PatientListComponent,
    ViewPatientDialogComponent,
    ViewPatientComponent,
    PatientLookupComponent,
    AddPatientComponent,
    PatientFormComponent,
    PatientPrescriberAssociateListComponent,
    PatientPrescriberPageComponent,
    PatientPrescriberAssociatedListComponent,
    PatientAssociatePrescriberComponent,
    PatientAttestationPageComponent,
    PatientDocumentsPageComponent,
    PatientLabTestPageComponent,
    PatientLabTestListComponent
  ],
  imports: [
    CommonModule,
    IdentityModule,
    RouterModule,
    MaterialModule,
    FormsModule,
    HttpClientModule,
    ReactiveFormsModule,
    PatientRoutingModule,
    StakeholderCommonModule,
    SharedModule,
    PipeModule,
    CoreDirectivesModule,
    PrescriberModule,
    MatProgressSpinnerModule
  ],
  providers: [{ provide: MAT_DIALOG_DEFAULT_OPTIONS, useValue: { hasBackdrop: false } }],
  entryComponents: [ViewPatientDialogComponent]
})
export class PatientModule {}
