import { Component, Input, Output, EventEmitter, ChangeDetectionStrategy } from '@angular/core';
import { WorkgroupUser } from '../../../../models';

@Component({
  selector: 'roctavian-abstractions-workgroup-user-list',
  templateUrl: './workgroup-user-list.component.html',
  styleUrls: ['./workgroup-user-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class WorkgroupUserListComponent {
  @Input() public workgroupUsers: WorkgroupUser[];
  @Output() public removed: EventEmitter<WorkgroupUser> = new EventEmitter<WorkgroupUser>();

  constructor() {}

  public removeWorkgroupUser(user: WorkgroupUser) {
    this.removed.emit(user);
  }
}
