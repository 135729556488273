import { Component, Input, Output, EventEmitter } from '@angular/core';
import { MatTabChangeEvent } from '@angular/material';
import { MessageService } from '@roctavian-abstractions/core';
import { Workgroup, WorkgroupUser, WorkgroupModel } from '../../../../models';
import { WorkgroupUserClient } from './../../../../clients/workgroup-user.client';
import { WorkgroupClient } from './../../../../clients/workgroup.client';

@Component({
  selector: 'roctavian-abstractions-workgroup-details',
  templateUrl: './workgroup-details.component.html',
  styleUrls: ['./workgroup-details.component.scss']
})
export class WorkgroupDetailsComponent {
  @Input() public workgroup: Workgroup;
  @Output() public tabChange: EventEmitter<string> = new EventEmitter<string>();

  constructor(
    private workgroupClient: WorkgroupClient,
    private workgroupUserClient: WorkgroupUserClient,
    private messageService: MessageService
  ) {}

  /**
   * Emits the currently selected tab when it's changed.
   *
   * @param event the tab change event
   */
  public onTabChange(event: MatTabChangeEvent) {
    const currentTabName = event.tab.textLabel;
    this.tabChange.emit(currentTabName);
  }

  /**
   * Updates the workgroup with the provided model information.
   *
   * @param model the information to update
   */
  public onWorkgroupEdited(model: WorkgroupModel) {
    this.workgroupClient.updateWorkgroup(model).subscribe(result => {
      this.messageService.open('Workgroup Updated!', 1000);
    });
  }

  /**
   * Adds the user to the workgroup, then updates both the assigned and available user arrays
   * so that they are properly filtered on the child components.
   *
   * @param user the user to assign to the workgroup
   */
  public onNewUser(user: WorkgroupUser) {
    this.workgroupUserClient.addWorkgroupUser(this.workgroup.workgroupIdentity, user.userIdentity).subscribe(result => {
      this.messageService.open('User Added!', 1000);

      // update the workgroup user
      const workgroupUser = result.value;
      user.workgroupUserIdentity = workgroupUser.workgroupUserIdentity;
      user.workgroupIdentity = workgroupUser.workgroupIdentity;
      user.addDate = workgroupUser.addDate;

      // push the user to the array and reassign the input array to trigger change detection
      this.workgroup.workgroupUsers.push(user);
      this.workgroup.workgroupUsers = this.workgroup.workgroupUsers.slice();

      // remove from the available users
      const newAvailableUsers = this.workgroup.availableWorkgroupUsers.filter(u => u.username !== user.username);
      this.workgroup.availableWorkgroupUsers = newAvailableUsers.slice();
    });
  }

  /**
   * Removes a user from the workgroup, then updates both the assigned and available user arrays
   * so that they are properly filtered on the child components.
   *
   * @param user the user to remove from the workgroup
   */
  public onUserRemoved(user: WorkgroupUser) {
    this.workgroupUserClient.removeWorkgroupUser(user.workgroupUserIdentity).subscribe(result => {
      this.messageService.open('User Removed!', 1000);

      // update the workgroup user
      user.workgroupUserIdentity = null;
      user.workgroupIdentity = null;

      // remove from the assigned users
      const newWorkgroupUsers = this.workgroup.workgroupUsers.filter(
        u => u.workgroupUserIdentity !== user.workgroupUserIdentity
      );

      this.workgroup.workgroupUsers = newWorkgroupUsers.slice();

      // add to the available users
      this.workgroup.availableWorkgroupUsers.push(user);
      this.workgroup.availableWorkgroupUsers = this.workgroup.availableWorkgroupUsers.slice();
    });
  }
}
