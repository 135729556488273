import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { AuthenticationService } from '../../modules/identity/services/authentication.service';

@Component({
  selector: 'callcenter-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit, OnDestroy {
  public isAuthenticated: boolean = false;
  public collapsed: boolean = true;
  public isAdmin: boolean = false;

  private subscriptions = new Array<Subscription>();

  public get displayName() {
    const userInfo = this.authService.userInfo;
    return `${userInfo.firstName} ${userInfo.lastName}`;
  }

  constructor(private readonly authService: AuthenticationService, private readonly router: Router) { }

  public ngOnInit() {
    this.subscriptions.push(this.authService.isLoggedIn().subscribe(authenticated => {
      this.isAuthenticated = authenticated;
      if (authenticated) {
        this.isAdmin = this.authService.claimsPrincipal.hasRole('Administrator') || this.authService.claimsPrincipal.hasRole('SystemAdministrator');
      }
    }));
  }

  ngOnDestroy() {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }

  public toggleMenu() {
    this.collapsed = !this.collapsed;
  }

  public logout() {
    this.authService.logout();
    this.router.navigate(['/']);
  }
}
