import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RegistrationComponent } from './components/registration/registration.component';
import { CoreModule } from '@roctavian-abstractions/core';
import { SecurityModule } from '../security/security.module';

@NgModule({
  declarations: [RegistrationComponent],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    CoreModule,
    SecurityModule
  ],
  exports: [
    RegistrationComponent
  ]
})
export class RegisterModule { }
