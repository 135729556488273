import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { CoreModule, ErrorsModule, MaterialModule } from '@roctavian-abstractions/core';
import { CreatePermissionComponent } from './components/create-permission/create-permission.component';
import { EditPermissionComponent } from './components/edit-permission/edit-permission.component';
import { PermissionFormComponent } from './components/permission-form/permission-form.component';
import { PermissionListComponent } from './components/permission-list/permission-list.component';
import { PermissionToggleGroupListComponent } from './components/permission-toggle-group-list/permission-toggle-group-list.component';
import { PermissionToggleGroupComponent } from './components/permission-toggle-group/permission-toggle-group.component';
import { PermissionToggleComponent } from './components/permission-toggle/permission-toggle.component';

@NgModule({
  imports: [CommonModule, CoreModule, MaterialModule, FormsModule, ErrorsModule],
  declarations: [
    PermissionToggleComponent,
    PermissionToggleGroupComponent,
    PermissionToggleGroupListComponent,
    PermissionFormComponent,
    CreatePermissionComponent,
    PermissionListComponent,
    EditPermissionComponent,
  ],
  exports: [
    PermissionToggleComponent,
    PermissionToggleGroupComponent,
    PermissionToggleGroupListComponent,
    PermissionFormComponent,
    CreatePermissionComponent,
    PermissionListComponent,
    EditPermissionComponent,
  ],
})
export class PermissionsModule {}
