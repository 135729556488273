import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator, PageEvent } from '@angular/material';
import { Router } from '@angular/router';
import { Outcome } from '@roctavian-abstractions/web';
import { AuthenticationService } from 'apps/roctavian-eumea-callcenter-portal/src/app/modules/identity/services/authentication.service';
import { Task, TaskListItem, TaskPriority, TaskStatus } from '../../../../models';
import { TaskClient } from './../../../../clients/task.client';
import { TaskManagementService } from './../../../../services/task-management.service';

@Component({
  selector: 'roctavian-abstractions-task-list',
  templateUrl: './task-list.component.html',
  styleUrls: ['./task-list.component.scss']
})
export class TaskListComponent implements OnInit {
  @ViewChild(MatPaginator, { static: false }) public paginator: MatPaginator;

  public page = 1;
  public size = 5;
  public tasks: TaskListItem[] = [];
  public allTasks: TaskListItem[] = [];
  public total = 0;
  public isTableLoading = false;
  public filterText: string;

  public showOnlyMyTasks: boolean = false;
  public showClosedTasks: boolean = true;

  constructor(
    private router: Router,
    private service: TaskManagementService,
    private taskClient: TaskClient,
    private authService: AuthenticationService
  ) {
    this.filterText = this.service.currentFilterText;
    this.page = this.service.currentPage;
    this.size = this.service.pageSize;

    this.service.loading.subscribe(loading => {
      this.isTableLoading = loading;
    });

    this.service.total.subscribe(total => {
      this.total = total;
    });

    this.service.tasks.subscribe(tasks => {
      this.tasks = tasks;
      this.allTasks = tasks;
    });

    this.service.setPage(this.page, this.size, this.filterText);
  }

  public ngOnInit() {
    this.paginator._intl.itemsPerPageLabel = 'Tasks Per Page:';
  }

  /**
   * Filters the task list by the provided text.
   *
   * @param text The text to filter by.
   */
  public handleKeyup(text: string) {
    this.page = 1;
    this.filterText = text;
    this.service.setPage(this.page, this.size, text);
  }

  /**
   * Requests a new page of workgroups.
   *
   * @param event The page event.
   */
  public handlePage(event: PageEvent) {
    this.page = event.pageIndex + 1;
    this.service.setPage(event.pageIndex + 1, this.size, this.filterText);
  }

  /**
   * Toggles whether or not to show only tasks assigned to the logged in user.
   */
  public handleMyTasksToggle() {
    const username = this.authService.userInfo.username;
    if (this.showOnlyMyTasks) {
      this.tasks = this.tasks.filter(t => t.assignedUser === username).slice();
    } else {
      this.tasks = this.allTasks.slice();
    }
  }

  /**
   * Toggles whether or not to show only open tasks.
   */
  public handleOpenTasksToggle() {
    if (this.showClosedTasks) {
      this.tasks = this.allTasks.slice();
    } else {
      this.tasks = this.tasks.filter(t => t.statusIdentity === TaskStatus.Open).slice();
    }
  }

  /**
   * Deletes the task and updates the array.
   *
   * @param taskIdentity The identity of the task to delete.
   */
  public onDelete(taskIdentity: string) {
    this.taskClient.deleteTask(taskIdentity).subscribe((result: Outcome) => {
      this.tasks = this.tasks.filter(t => t.taskIdentity !== taskIdentity);
    });
  }

  /**
   * Closes the task and updates the array.
   *
   * @param taskIdentity The identity of the task to close.
   */
  public onClose(taskIdentity: string) {
    this.taskClient.closeTask(taskIdentity).subscribe((result: Outcome) => {
      const taskToUpdate = this.tasks.find(t => t.taskIdentity === taskIdentity);
      const index = this.tasks.indexOf(taskToUpdate);

      taskToUpdate.closedDate = result.value;
      taskToUpdate.statusIdentity = TaskStatus.Closed;

      this.tasks[index] = taskToUpdate;
    });
  }

  /**
   * Navigates to the details page of the provided task.
   *
   * @param task The selected task.
   */
  public onRowSelected(task: Task): void {
    this.router.navigate([`/tasks/${task.taskIdentity}`]);
  }

  /**
   * Returns a class that is applied to the table
   * depending on the priority of the task.
   *
   * @param priority The task priority.
   */
  public getPriorityColor(priority: TaskPriority): string {
    switch (priority) {
      case TaskPriority.High:
        return 'warn';
      case TaskPriority.Low:
        return 'secondary';
      case TaskPriority.Medium:
        return 'priority-medium';
    }
    return null;
  }

  public getDisplayStatus(task: TaskListItem) {
    switch (task.statusIdentity) {
      case TaskStatus.Closed:
        return 'Closed';
      case TaskStatus.InProgress:
        return 'In Progress';
      case TaskStatus.Open:
        return 'Open';
    }
  }

  public isClosed(task: TaskListItem) {
    return task.statusIdentity === TaskStatus.Closed;
  }
}
