import { Component, OnInit, Input, OnDestroy, OnChanges, SimpleChanges, Output, EventEmitter } from '@angular/core';
import { PaginatedList } from '@roctavian-abstractions/web';
import { BehaviorSubject, Subscription, EMPTY } from 'rxjs';
import { debounceTime, distinctUntilChanged, switchMap } from 'rxjs/operators';
import { PageEvent, MatSort, MatCheckboxChange } from '@angular/material';
import { PrescriberDelegate } from '../../../prescriber-delegate/models/prescriber-delegate.model';
import { UtilityService, PagedQuery } from '../../../common';
import { PrescriberService } from '../../services';
import { Prescriber, PrescriberDelegatePagedQuery } from '../../models';

@Component({
  selector: 'prescriber-associate-new-prescriber-delegate-list',
  templateUrl: './associate-new-prescriber-delegate-list.component.html',
  styleUrls: ['./associate-new-prescriber-delegate-list.component.scss']
})
export class AssociateNewPrescriberDelegateListComponent implements OnInit, OnDestroy, OnChanges {
  @Input() prescriber: Prescriber;
  @Output() public prescriberDelegateSelected = new EventEmitter<PrescriberDelegate>();
  public selectedPrescriberDelegate: PrescriberDelegate;

  public pagedQuery = new PagedQuery(1, 10, null, 'id', true);

  public prescriberDelegatePagedCollection: PaginatedList<PrescriberDelegate>;
  public isLoading = false;
  public filterText$ = new BehaviorSubject<string>(null);

  private subscriptions = new Array<Subscription>();

  public displayedColumns = [
    'id',
    'stakeholder.firstName',
    'stakeholder.lastName',
    'stakeholder.address.country.name',
    'stakeholder.email',
    'stakeholder.phone'
  ];

  constructor(public utilityService: UtilityService, private prescriberService: PrescriberService) {
    this.subscriptions.push(this.filterText$
      .pipe(
        debounceTime(1000),
        distinctUntilChanged(),
        switchMap(searchText => {
          if (this.pagedQuery.filterText == searchText) {
            return EMPTY;
          }

          this.pagedQuery.filterText = searchText;
          this.pagedQuery.pageIndex = 1;
          this.refreshList();
          return EMPTY;
        })
      )
      .subscribe());
  }

  ngOnDestroy() {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
    this.prescriberService.associatePrescriberDelegatePagedListSubject.next(new PaginatedList<PrescriberDelegate>());
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (
      !changes.prescriber ||
      !changes.prescriber.currentValue ||
      changes.prescriber.currentValue === changes.prescriber.previousValue
    ) {
      return;
    }

    this.refreshList();
  }

  private refreshList() {
    if (!this.prescriber) {
      return;
    }

    this.prescriberService.setAssociatePrescriberDelegatePage(this.pagedQuery, this.prescriber.id);
  }

  ngOnInit() {
    this.subscriptions.push(this.prescriberService.associatePrescriberDelegatePagedListSubject.subscribe(
      collection => {
        this.prescriberDelegatePagedCollection = collection;
      }
    ));

    this.subscriptions.push(this.prescriberService.isLoadingSubject.subscribe(isLoading => {
      this.isLoading = isLoading;
    }));
  }

  public onPageEvent(pageEvent: PageEvent) {
    this.pagedQuery.pageIndex = pageEvent.pageIndex + 1;
    this.pagedQuery.pageSize = pageEvent.pageSize;
    this.refreshList();
  }

  public onSortEvent(sort: MatSort) {
    switch (sort.active) {
      case 'id':
        this.pagedQuery.sortColumn = 'stakeholder.externalId';
        break;

      default:
        this.pagedQuery.sortColumn = sort.active;
        break;
    }

    this.pagedQuery.isSortDesc = sort.direction == 'desc';
    this.pagedQuery.pageIndex = 1;
    this.refreshList();
  }

  public selectPrescriberDelegate(prescriberDelegate: PrescriberDelegate) {
    if (this.selectedPrescriberDelegate === prescriberDelegate) {
      this.selectedPrescriberDelegate = null;
      this.prescriberDelegateSelected.emit(null);
      return;
    }

    this.selectedPrescriberDelegate = prescriberDelegate;
    this.prescriberDelegateSelected.emit(this.selectedPrescriberDelegate);
  }
}
