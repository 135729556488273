import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'roctavian-eumea-callcenter-portal-lab-kit-order-list-page',
  templateUrl: './lab-kit-order-list-page.component.html',
  styleUrls: ['./lab-kit-order-list-page.component.scss']
})
export class LabKitOrderListPageComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
