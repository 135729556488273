/**
 * This class encapsulates the parameters required
 * to change an authenticated user's password.
 */
export class ChangePasswordModel {
  /**
   * Initializes a new instance of the change password model class.
   *
   * @param currentPassword The user's current password.
   * @param newPassword The new password to set.
   * @param confirmationPassword The matching confirmation password.
   */
  constructor(public currentPassword: string, public newPassword: string, public confirmationPassword: string) {}
}
