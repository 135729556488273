import { Subscription } from 'rxjs';
import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { Prescriber } from '../../models';
import { PrescriberService } from '../../services';
import { LabListComponent } from '../../components';

@Component({
  selector: 'roctavian-eumea-callcenter-portal-prescriber-lab-list-page',
  templateUrl: './prescriber-lab-list-page.component.html',
  styleUrls: ['./prescriber-lab-list-page.component.css']
})
export class PrescriberLabListPageComponent implements OnDestroy {
  public prescriber: Prescriber;
  private subscriptions = new Array<Subscription>();
  @ViewChild(LabListComponent, { static: false }) public labListComponent: LabListComponent;

  constructor(private prescriberService: PrescriberService) {
    this.subscriptions.push(this.prescriberService.selectedPrescriberSubject.subscribe(
      prescriber => (this.prescriber = prescriber)
    ));
  }

  ngOnDestroy() {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }

  public showAssociateLab = false;

  public beginAssociation() {
    this.showAssociateLab = true;
  }

  public onLabAssociated() {
    this.showAssociateLab = false;
    this.labListComponent.refreshList();
  }

  public onCancelAssociation() {
    this.showAssociateLab = false;
  }
}
