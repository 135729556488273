import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AdminAuthenticationGuard } from './guards/admin-authentication.guard';
import { AdministrationDashboardPageComponent } from './pages/administration-dashboard-page/administration-dashboard-page.component';
import { AdministrationPageComponent } from './pages/administration-page/administration-page.component';
import { CreatePermissionPageComponent } from './pages/create-permission-page/create-permission-page.component';
import { CreateRolePageComponent } from './pages/create-role-page/create-role-page.component';
import { CreateUserPageComponent } from './pages/create-user-page/create-user-page.component';
import { EditPermissionPageComponent } from './pages/edit-permission-page/edit-permission-page.component';
import { EditRolePageComponent } from './pages/edit-role-page/edit-role-page.component';
import { PermissionManagerPageComponent } from './pages/permission-manager-page/permission-manager-page.component';
import { RoleManagerPageComponent } from './pages/role-manager-page/role-manager-page.component';
import { UserDetailsPageComponent } from './pages/user-details-page/user-details-page.component';
import { UserEditPageComponent } from './pages/user-edit-page/user-edit-page.component';
import { UserManagerPageComponent } from './pages/user-manager-page/user-manager-page.component';

const ROUTES: Routes = [
  {
    path: 'administration',
    component: AdministrationPageComponent,
    canActivate: [AdminAuthenticationGuard],
    children: [
      { path: '', redirectTo: 'users', pathMatch: 'full' },
      { path: 'dashboard', component: AdministrationDashboardPageComponent },
      { path: 'users', component: UserManagerPageComponent },
      { path: 'users/details/:identity', component: UserDetailsPageComponent },
      { path: 'users/edit/:identity', component: UserEditPageComponent },
      { path: 'users/create', component: CreateUserPageComponent },
      { path: 'roles', component: RoleManagerPageComponent },
      { path: 'roles/create', component: CreateRolePageComponent },
      { path: 'roles/edit/:identity', component: EditRolePageComponent },
      { path: 'permissions', component: PermissionManagerPageComponent },
      { path: 'permissions/create', component: CreatePermissionPageComponent },
      { path: 'permissions/edit/:identity', component: EditPermissionPageComponent },
    ],
  },
];

@NgModule({
  imports: [CommonModule, RouterModule.forChild(ROUTES)],
  exports: [RouterModule],
})
export class AdminRoutingModule {}
