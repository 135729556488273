import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MAT_DIALOG_DEFAULT_OPTIONS, MatProgressSpinnerModule } from '@angular/material';
import { RouterModule } from '@angular/router';
import { CoreDirectivesModule, MaterialModule, PipeModule } from '@roctavian-abstractions/core';
import { IdentityModule } from '../../../identity/identity.module';
import { SharedModule } from '../../../shared/shared.module';
import { StakeholderCommonModule } from '../common';
import { InstitutionModule } from '../institution';
import {
  AddPrescriberComponent,
  AssociateLabComponent,
  AssociateNewLabListComponent,
  LabListComponent,
  PrescriberFormComponent,
  PrescriberListComponent,
  PrescriberLookupComponent,
  PrescriberPatientListComponent,
  ViewPrescriberComponent,
  ViewPrescriberDialogComponent
} from './components';
import { AssociateNewPrescriberDelegateListComponent } from './components/associate-new-prescriber-delegate-list/associate-new-prescriber-delegate-list.component';
import { AssociatePrescriberDelegateComponent } from './components/associate-prescriber-delegate/associate-prescriber-delegate.component';
import { DisassociateLabDialogComponent } from './components/disassociate-lab-dialog/disassociate-lab-dialog.component';
import { DisassociatePrescriberDelegateDialogComponent } from './components/disassociate-prescriber-delegate-dialog/disassociate-prescriber-delegate-dialog.component';
import { LabUserDelegateListComponent } from './components/lab-user-delegate-list/lab-user-delegate-list.component';
import { PrescriberDelegateListComponent } from './components/prescriber-delegate-list/prescriber-delegate-list.component';
import { PrescriberInstitutionListComponent } from './components/prescriber-institution-list/prescriber-institution-list.component';
import {
  AddPrescriberPageComponent,
  PrescriberAttestationPageComponent,
  PrescriberDetailMasterPageComponent,
  PrescriberEditPageComponent,
  PrescriberLabListPageComponent,
  PrescriberListPageComponent,
  PrescriberPatientListPageComponent,
  PrescriberStatusPageComponent,
  PrescriberSummaryPageComponent
} from './pages';
import { PrescriberDelegateListPageComponent } from './pages/prescriber-delegate-list-page/prescriber-delegate-list-page.component';
import { PrescriberDocumentsPageComponent } from './pages/prescriber-documents-page/prescriber-documents-page.component';
import { PrescriberLabUserDelegateListPageComponent } from './pages/prescriber-lab-user-delegate-list-page/prescriber-lab-user-delegate-list-page.component';
import { PrescriberRoutingModule } from './prescriber-routing.module';

@NgModule({
  declarations: [
    PrescriberListPageComponent,
    PrescriberListComponent,
    ViewPrescriberDialogComponent,
    ViewPrescriberComponent,
    PrescriberLookupComponent,
    AddPrescriberPageComponent,
    AddPrescriberComponent,
    PrescriberDetailMasterPageComponent,
    ViewPrescriberDialogComponent,
    PrescriberSummaryPageComponent,
    PrescriberFormComponent,
    ViewPrescriberDialogComponent,
    PrescriberEditPageComponent,
    PrescriberAttestationPageComponent,
    PrescriberStatusPageComponent,
    PrescriberPatientListPageComponent,
    PrescriberPatientListComponent,
    PrescriberLabListPageComponent,
    LabListComponent,
    AssociateLabComponent,
    AssociateNewLabListComponent,
    PrescriberInstitutionListComponent,
    DisassociateLabDialogComponent,
    PrescriberLabUserDelegateListPageComponent,
    LabUserDelegateListComponent,
    PrescriberDelegateListComponent,
    PrescriberDelegateListPageComponent,
    AssociatePrescriberDelegateComponent,
    AssociateNewPrescriberDelegateListComponent,
    DisassociatePrescriberDelegateDialogComponent,
    PrescriberDocumentsPageComponent
  ],
  imports: [
    CommonModule,
    IdentityModule,
    RouterModule,
    MaterialModule,
    FormsModule,
    HttpClientModule,
    ReactiveFormsModule,
    PipeModule,
    PrescriberRoutingModule,
    CoreDirectivesModule,
    StakeholderCommonModule,
    SharedModule,
    InstitutionModule,
    MatProgressSpinnerModule
  ],
  exports: [ViewPrescriberComponent],
  providers: [{ provide: MAT_DIALOG_DEFAULT_OPTIONS, useValue: { hasBackdrop: false } }],
  entryComponents: [
    ViewPrescriberDialogComponent,
    DisassociateLabDialogComponent,
    DisassociatePrescriberDelegateDialogComponent
  ]
})
export class PrescriberModule {}
