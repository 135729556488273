import { Subscription } from 'rxjs';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { Prescriber } from '../../models';
import { PrescriberService } from '../../services';
import { Institution } from '../../../institution';
import { Stakeholder, Address } from '../../../common';

@Component({
  selector: 'roctavian-eumea-callcenter-portal-prescriber-summary-page',
  templateUrl: './prescriber-summary-page.component.html',
  styleUrls: ['./prescriber-summary-page.component.css']
})
export class PrescriberSummaryPageComponent implements OnDestroy {
  public prescriber: Prescriber;
  private subscriptions = new Array<Subscription>();

  constructor(private prescriberService: PrescriberService) {
    this.subscriptions.push(this.prescriberService.selectedPrescriberSubject.subscribe(
      prescriber => {
        this.prescriber = prescriber;
      }
    ));
  }

  ngOnDestroy() {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }
}
