import { AppTranslationService, CountryClient, Country } from '@roctavian-abstractions/core';
import { Component, OnInit, EventEmitter, Output, Input, SimpleChanges, OnChanges, OnDestroy } from '@angular/core';
import { Form, FormBuilder, FormGroupDirective, FormControl, Validators, FormGroup } from '@angular/forms';
import { SystemKeyService, SystemKeyModel } from '@roctavian-abstractions/core';
import { Subscription } from 'rxjs';

@Component({
  selector: 'stakeholder-address-form',
  templateUrl: './address-form.component.html',
  styleUrls: ['./address-form.component.css']
})
export class AddressFormComponent implements OnInit, OnChanges, OnDestroy {
  @Input() public title = this.translate.getTranslation('Common.AddressForm.Title');
  @Input() public showTitle = false;

  @Input() public countryLabel = this.translate.getTranslation('Common.AddressForm.Country');
  @Input() public showCountry = true;
  @Input() public overrideRequireCountry = false;
  @Input() public overrideSoftRequireCountry = false;

  @Input() public nameLabel = this.translate.getTranslation('Common.AddressForm.Name');
  @Input() public showName = true;
  @Input() public overrideNameSoftRequired = false;
  @Input() public overrideRequireName = false;

  @Input() public address1Label = this.translate.getTranslation('Common.AddressForm.Address1');
  @Input() public showAddress1 = true;

  @Input() public address2Label = this.translate.getTranslation('Common.AddressForm.Address2');
  @Input() public showAddress2 = true;

  @Input() public cityLabel = this.translate.getTranslation('Common.AddressForm.City');
  @Input() public showCity = true;

  @Input() public postalCodeLabel = this.translate.getTranslation('Common.AddressForm.PostalCode');
  @Input() public showPostalCode = true;

  @Input() public addressFormGroupName = 'address';
  @Input() public areFieldsRequired = false;
  @Input() public areFieldsSoftRequired = false;

  @Output() public countryChanged = new EventEmitter<string>();

  private subscriptions = new Array<Subscription>();
  public countryList: Country[] = [];

  public form: FormGroup;

  constructor(
    private formBuilder: FormBuilder,
    public parentFormGroupDirective: FormGroupDirective,
    private translate: AppTranslationService,
    private countryClient: CountryClient
  ) { }

  ngOnInit() {
    this.buildForm();
    this.subscriptions.push(this.countryClient.getSupportedCountryList().subscribe(countryOutcome => this.countryList = countryOutcome.value));
  }

  ngOnDestroy() {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }

  private buildForm() {
    this.form = this.formBuilder.group({
      countryId: new FormControl(null, []),
      name: new FormControl(null, []),
      address1: new FormControl(null, []),
      address2: new FormControl(null, []),
      city: new FormControl(null, []),
      postalCode: new FormControl(null, [])
    });

    this.refreshValidations(this.areFieldsRequired);

    this.parentFormGroupDirective.form.addControl(this.addressFormGroupName, this.form);
    this.form.setParent(this.parentFormGroupDirective.form);
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (!this.form || !this.form.controls) {
      return;
    }

    if (changes.areFieldsRequired) {
      this.refreshValidations(changes.areFieldsRequired.currentValue);
    }
  }

  private refreshValidations(areFieldsRequired: boolean) {
    this.form.get('address1').clearValidators();
    this.form.get('city').clearValidators();
    this.form.get('postalCode').clearValidators();
    this.form.get('countryId').clearValidators();
    this.form.get('name').clearValidators();

    if (this.overrideRequireCountry) {
      this.form.get('countryId').setValidators(Validators.required);
    }

    if (this.overrideRequireName) {
      this.form.get('name').setValidators(Validators.required);
    }

    if (!areFieldsRequired) {
      return;
    }

    if (this.showAddress1) {
      this.form.get('address1').setValidators([Validators.required]);
    }

    if (this.showCity) {
      this.form.get('city').setValidators([Validators.required]);
    }

    if (this.showPostalCode) {
      this.form.get('postalCode').setValidators([Validators.required]);
    }
  }

  public onCountryChange(obj) {
    this.countryChanged.emit(obj.value);
  }
}
