import { OnDestroy, ViewChild } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import { Patient } from '../../models';
import { Subscription } from 'rxjs';
import { PatientService } from '../../services';
import { PatientLabTestListComponent } from '../../components/patient-lab-test-list/patient-lab-test-list.component';

@Component({
  selector: 'roctavian-eumea-callcenter-portal-patient-lab-test-page',
  templateUrl: './patient-lab-test-page.component.html',
  styleUrls: ['./patient-lab-test-page.component.scss']
})
export class PatientLabTestPageComponent implements OnInit, OnDestroy {
  public patient: Patient;
  private subscriptions = new Array<Subscription>();
  @ViewChild(PatientLabTestListComponent, { static: false })
  public patientLabTestListComponent: PatientLabTestListComponent;

  constructor(private service: PatientService) { }

  ngOnInit() {
    this.subscriptions.push(this.service.selectedPatientSubject.subscribe(
      patient => (this.patient = patient)
    ));
  }

  ngOnDestroy() {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }
}
