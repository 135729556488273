import { Injectable, OnDestroy } from '@angular/core';
import { DocumentInterruptSource, Idle, StorageInterruptSource } from '@ng-idle/core';
import { ConfigService, MessageService } from '@roctavian-abstractions/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { SessionManagementEventHandler } from '../session-management-event.handler';
import { AuthenticationService } from './authentication.service';

/**
 * Provides a session management service for an application, allowing it to log
 * an authenticated user out of the application after a defined amount of time.
 * This should be configured in the app.component.ts of an application by calling
 * enableSessionManagement(). The session timeout value is stored in the config.json file
 * using the key "sessionTimeout". This value is stored as minutes, and if it is not found
 * in the config file, a default value of 15 minutes is used.
 */
@Injectable({
  providedIn: 'root'
})
export class SessionManagementService implements OnDestroy {
  /**
   * Whether or not the session service has been started.
   */
  get sessionStatus() {
    return this.hasSessionStarted.asObservable();
  }

  /**
   * Whether or not the session has been configured.
   */
  private hasSessionBeenConfigured: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  /**
   * Whether or not the session has started.
   */
  private hasSessionStarted: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  /**
   * The timeout value parsed from the config file.
   */
  private timeoutValue: string;

  /**
   * Subject used to unsubscribe from all subscriptions when the session
   * is disabled or the service is destroyed.
   */
  private unsubscribe: Subject<boolean> = new Subject<boolean>();

  /**
   * Initializes the session management service.
   *
   * @param authService The authentication service.
   * @param configService The config service.
   * @param idle The idle service.
   * @param messageService The message service.
   */
  constructor(
    private authService: AuthenticationService,
    private configService: ConfigService,
    private idle: Idle,
    private sessionEventHandler: SessionManagementEventHandler,
    private messageService: MessageService
  ) {}

  /**
   * Configures and starts the session management service.
   */
  public enableSessionManagement(): void {
    this.authService
      .isLoggedIn()
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(isLoggedIn => {
        if (!this.hasSessionBeenConfigured.value) {
          this.configureSessionManagement();
          this.hasSessionBeenConfigured.next(true);
        }

        this.hasSessionBeenConfigured.pipe(takeUntil(this.unsubscribe)).subscribe(isConfigured => {
          if (isConfigured) {
            if (isLoggedIn && !this.hasSessionStarted.value) {
              this.idle.watch();
              this.hasSessionStarted.next(true);
            } else if (!isLoggedIn && this.hasSessionStarted) {
              this.idle.stop();
              this.hasSessionStarted.next(false);
            }
          }
        });
      });
  }

  /**
   * Disables the session management service.
   */
  public disableSessionManagement(): void {
    this.idle.stop();
    this.messageService.dismiss();
    this.hasSessionStarted.next(false);
    this.hasSessionBeenConfigured.next(false);

    // Unsubscribe from subscriptions:
    this.ngOnDestroy();
  }

  /**
   * Configures up the idle service with the specified timeout and interrupts.
   */
  private configureSessionManagement(): void {
    // Set the configured timeout value in seconds:
    this.configureTimeoutValue();
    const timeoutValue: number = +this.timeoutValue * 60;

    // Consider the user idle when they have not done anything in the past second:
    this.idle.setIdle(1);

    // Set the timeout value in seconds (countdown occurs once the user goes idle after the above configuration):
    this.idle.setTimeout(timeoutValue);

    // Set-up the idle interrupt sources:
    const interruptSources: DocumentInterruptSource[] = [
      new DocumentInterruptSource('keydown mousedown mousewheel touchstart touchmove scroll DOMMouseScroll'),
      new StorageInterruptSource()
    ];

    this.idle.setInterrupts(interruptSources);

    // Show a message when your session is about to timeout:
    this.idle.onTimeoutWarning.pipe(takeUntil(this.unsubscribe)).subscribe(secondsUntilTimeout => {
      if (secondsUntilTimeout === 60) {
        this.messageService.openIndefinitely('Due to inactivity, you will be logged out shortly.');
      }
    });

    // dismiss any open messages when the user is no longer idle
    this.idle.onIdleEnd.pipe(takeUntil(this.unsubscribe)).subscribe(() => this.messageService.dismiss());

    // When a user has timed out, log them out via the authentication service and dismiss any open messages:
    this.idle.onTimeout.pipe(takeUntil(this.unsubscribe)).subscribe(() => {
      this.sessionEventHandler.handleTimeout();
    });
  }

  /**
   * Configures the session timeout value by pulling it from the config file.
   * If no value can be parsed, a default value of 15 minutes is provided.
   */
  private configureTimeoutValue() {
    this.timeoutValue = this.configService.get('sessionTimeout');
    if (!this.timeoutValue) {
      this.timeoutValue = '15';
    }
  }

  /**
   * Completes the unsubscribe subject.
   */
  public ngOnDestroy(): void {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }
}
