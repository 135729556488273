import { Component, EventEmitter, Input, Output } from '@angular/core';

export class PermissionFormSubmitEvent {
  constructor(name: string, description: string, group: string) {
    this.name = name;
    this.description = description;
    this.group = group;
  }
  public name: string;
  public description: string;
  public group: string;
}

@Component({
  selector: 'roctavian-abstractions-permission-form',
  templateUrl: './permission-form.component.html',
  styleUrls: ['./permission-form.component.scss'],
})
export class PermissionFormComponent {
  @Input() disabled = false;
  @Input() buttonText = 'Create Permission';
  @Output() submitted = new EventEmitter<PermissionFormSubmitEvent>();

  @Input() public name: string;
  @Input() public description: string;
  @Input() public group: string;

  constructor() {}


  public handleFormSubmission() {
    const event = new PermissionFormSubmitEvent(this.name, this.description, this.group);
    this.submitted.emit(event);
  }
}
