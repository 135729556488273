import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material';
import { Router } from '@angular/router';
import { MessageService } from '@roctavian-abstractions/core';

import { Outcome } from '@roctavian-abstractions/web';
import { RolesClient } from 'apps/roctavian-eumea-callcenter-portal/src/app/modules/identity/clients/roles.client';
import { UserClient } from 'apps/roctavian-eumea-callcenter-portal/src/app/modules/identity/clients/user.client';
import { Role, SimplifiedUser } from 'apps/roctavian-eumea-callcenter-portal/src/app/modules/identity/models';
import { UpdateUserModel } from 'apps/roctavian-eumea-callcenter-portal/src/app/modules/identity/models/update-user.model';
import { ConfirmResetDialogComponent } from '../confirm-reset-dialog/confirm-reset-dialog.component';
import { EditUserSubmitEvent } from '../edit-user-form/edit-user-form.component';

@Component({
  selector: 'roctavian-abstractions-edit-user',
  templateUrl: './edit-user.component.html',
  styleUrls: ['./edit-user.component.scss'],
})
export class EditUserComponent implements OnInit {
  public form: FormGroup;
  public allRoles: Role[] = [];
  public userRoles: Role[] = [];
  public messages: string[] = [];
  @Input() public user: SimplifiedUser;
  @Input() public disabled = false;

  constructor(
    private userClient: UserClient,
    private roleClient: RolesClient,
    private formBuilder: FormBuilder,
    private service: MessageService,
    private matDialog: MatDialog,
    private router: Router
  ) {
    this.roleClient.getRoles().subscribe(roles => {
      this.allRoles = roles;
    });
  }
  ngOnInit(): void {
    this.userRoles = this.getSelectedUserRoles();
    this.form = this.formBuilder.group({
      username: [this.user.userName, Validators.required],
      email: [this.user.email, [Validators.required, Validators.email]],
      firstName: [this.user.firstName, Validators.required],
      lastName: [this.user.lastName, Validators.required],
      roles: [this.userRoles, Validators.required],
    });
  }

  public getSelectedUserRoles() {
    const userRoles: Role[] = [];
    this.user.userRoles.forEach(userRole => {
      userRoles.push(userRole.role);
    });
    return userRoles;
  }

  public compare(c1: any, c2: any) {
    // eslint-disable-next-line eqeqeq
    return c1 && c2 && c1 == c2.id;
  }

  public handleLock() {
    this.user.locked = true;
    this.user = Object.assign(this.user, {});
    this.userClient.lockUser(this.user.id).subscribe(
      (outcome: Outcome) => {
        this.service.open('The user account has been locked.', 5000);
      },
      () => {
        this.user.locked = false;
        this.user = Object.assign(this.user, {});
        this.service.open('An error occurred when locking the user account.', 5000);
      }
    );
  }

  public handleUnlock() {
    this.user.locked = false;
    this.user = Object.assign(this.user, {});
    this.userClient.unlockUser(this.user.id).subscribe(
      (outcome: Outcome) => {
        this.service.open('The user account has been unlocked.', 5000);
      },
      () => {
        this.user.locked = true;
        this.user = Object.assign(this.user, {});
        this.service.open('An error occurred when unlocking the user account.', 5000);
      }
    );
  }

  public handleReset() {
    const dialogRef = this.matDialog.open(ConfirmResetDialogComponent, {
      hasBackdrop: true,
      disableClose: true,
      width: '500px',
    });

    dialogRef.afterClosed().subscribe((result: number) => {
      if (result === 1) {
        this.userClient.initiateReset(this.user.id).subscribe(
          () => {
            this.service.open('The forgot password email has been sent.', 5000);
          },
          () => {
            this.service.open(
              'An error occurred when initiating the password reset process.',
              5000
            );
          }
        );
      }
    });
  }

  public submit(form: FormGroup) {
    const event = new EditUserSubmitEvent();
    event.username = form.value.username;
    event.firstName = form.value.firstName;
    event.lastName = form.value.lastName;
    event.email = form.value.email;
    if (typeof form.value.roles[0] === 'object') {
      event.roles = form.value.roles.map(role => role.id);
    } else {
      event.roles = form.value.roles;
    }
    this.handleEditUser(event);
  }

  public handleEditUser(user: EditUserSubmitEvent) {
    this.disabled = true;
    const model = new UpdateUserModel();
    model.userId = this.user.id;
    model.username = user.username;
    model.firstName = user.firstName;
    model.lastName = user.lastName;
    model.email = user.email;
    model.roles = user.roles;

    this.userClient.updateUser(model).subscribe(
      outcome => {
        this.disabled = false;
        if (!outcome.failure) {
          this.router.navigate(['/administration/users']);
        }
      },
      response => {
        this.disabled = false;
        this.messages = response.error.messages;
      }
    );
  }
}
