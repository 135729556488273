import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ConfigService } from '@roctavian-abstractions/core';
import { Outcome } from '@roctavian-abstractions/web';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ChangePasswordModel } from '../models/change-password.model';
import { UpdateUserDetailsModel } from '../models/update-user-details.model';
import { ChangeUsernameModel } from './../models/change-username.model';

/**
 * A client used to communicate with the account management API.
 */
@Injectable({
  providedIn: 'root',
})
export class AccountManagementClient {
  /**
   * Initializes a new instance of the account management client.
   *
   * @param client The http client.
   */
  constructor(private client: HttpClient, private configService: ConfigService) {}

  /**
   * Updates the details for the authenticated user.
   *
   * @param model The user details model.
   * @returns An observable containing an outcome indicating whether the user details wer updated.
   */
  public updateUserDetails(model: UpdateUserDetailsModel): Observable<Outcome> {
    return this.client
      .post<Outcome>(
        `${this.configService.get('apiUrl')}/identity/accountmanagement/details`,
        model
      )
      .pipe(
        catchError((response: HttpErrorResponse) => {
          if (response instanceof HttpErrorResponse) {
            const outcome: Outcome = Object.assign(new Outcome(), response.error);
            return throwError(outcome);
          }
        })
      );
  }

  /**
   * Updates the password for the authenticated user.
   *
   * @param model The change password model.
   * @returns An observable containing an outcome indicating whether password was changed successfully.
   */
  public changePassword(model: ChangePasswordModel): Observable<Outcome> {
    return this.client
      .post<Outcome>(
        `${this.configService.get('apiUrl')}/identity/accountmanagement/change`,
        model
      )
      .pipe(
        catchError((response: HttpErrorResponse) => {
          if (response instanceof HttpErrorResponse) {
            const outcome: Outcome = Object.assign(new Outcome(), response.error);
            return throwError(outcome);
          }
        })
      );
  }

  /**
   * Updates the username for the authenticated user.
   *
   * @param model The change username model.
   * @returns An observable containing an outcome indicating whether the username was updated successfully.
   */
  public changeUsername(model: ChangeUsernameModel): Observable<Outcome> {
    return this.client
      .post<Outcome>(
        `${this.configService.get('apiUrl')}/identity/accountmanagement/username`,
        model
      )
      .pipe(
        catchError((response: HttpErrorResponse) => {
          if (response instanceof HttpErrorResponse) {
            const outcome: Outcome = Object.assign(new Outcome(), response.error);
            return throwError(outcome);
          }
        })
      );
  }
}
