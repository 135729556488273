/**
 * Class used to represent a user of a workgroup.
 */
export class WorkgroupUser {
  constructor(
    workgroupUserIdentity?: string,
    workgroupIdentity?: string,
    userIdentity?: string,
    username?: string,
    firstName?: string,
    lastName?: string,
    email?: string,
    addDate?: Date
  ) {
    this.workgroupUserIdentity = workgroupUserIdentity;
    this.workgroupIdentity = workgroupIdentity;
    this.userIdentity = userIdentity;
    this.username = username;
    this.firstName = firstName;
    this.lastName = lastName;
    this.email = email;
    this.addDate = addDate;
  }
  public workgroupUserIdentity?: string;
  public workgroupIdentity?: string;
  public userIdentity?: string;
  public username?: string;
  public firstName?: string;
  public lastName?: string;
  public email?: string;
  public addDate?: Date;
}
