import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MaterialModule, CoreModule } from "@roctavian-abstractions/core";
import { ChangeUsernameComponent } from "./components/change-username/change-username.component";
import { ChangeUsernameFormComponent } from "./components/change-username-form/change-username-form.component";

@NgModule({
  imports: [CommonModule, CoreModule, FormsModule, MaterialModule, ReactiveFormsModule],
  declarations: [ChangeUsernameComponent, ChangeUsernameFormComponent],
  exports: [ChangeUsernameComponent, ChangeUsernameFormComponent]
})
export class UsernameModule {}
