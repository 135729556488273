import { Component, OnInit } from '@angular/core';
import { Patient } from '../../models';

@Component({
  selector: 'roctavian-eumea-callcenter-portal-add-patient-page',
  templateUrl: './add-patient-page.component.html',
  styleUrls: ['./add-patient-page.component.css']
})
export class AddPatientPageComponent implements OnInit {
  public patient: Patient;

  constructor() {}

  ngOnInit() {}

  public onContinueWithPatient(patient: Patient) {
    this.patient = patient;
  }
}
