import { AppTranslationService } from '@roctavian-abstractions/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { MessageService } from '@roctavian-abstractions/core';
import { Outcome } from '@roctavian-abstractions/web';
import { StatusTypes, UtilityService } from '../../../common';
import { Patient } from '../../models';
import { PatientService } from '../../services';
import { PatientClient } from '../../clients';
import { Subscription } from 'rxjs';
import { Router } from '@angular/router';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'roctavian-eumea-callcenter-portal-patient-edit-page',
  templateUrl: './patient-edit-page.component.html',
  styleUrls: ['./patient-edit-page.component.css']
})
export class PatientEditPageComponent implements OnInit, OnDestroy {
  public form: FormGroup;
  public patient: Patient;
  public isProcessing = false;
  public errorMessages = new Array<string>();
  public statusTypes = StatusTypes;

  private subscriptions = new Array<Subscription>();

  constructor(
    private formBuilder: FormBuilder,
    private patientService: PatientService,
    private patientClient: PatientClient,
    private messageService: MessageService,
    private translate: AppTranslationService,
    public utilityService: UtilityService,
    private router: Router,
    private datePipe: DatePipe
  ) { }

  ngOnDestroy() {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }

  ngOnInit() {
    this.form = this.formBuilder.group({});

    this.subscriptions.push(
      this.patientService.selectedPatientSubject.subscribe(patient => {
        this.patient = patient;
        if (!this.patient) {
          return;
        }

        setTimeout(() => {
          this.form.patchValue({
            ...(this.patient || {})
          });
        });
      })
    );
  }

  public submit() {
    this.form.markAllAsTouched();

    if (this.form.invalid) {
      return;
    }

    this.isProcessing = true;
    this.errorMessages = [];

    const updatedPatient: Patient = this.form.getRawValue();
    updatedPatient.id = this.patient.id;
    updatedPatient.birthDate = this.datePipe.transform(this.form.get('birthDate').value, 'MM/dd/yyyy');

    this.subscriptions.push(
      this.patientClient.updatePatient(updatedPatient).subscribe(
        outcome => {
          this.isProcessing = false;
          if (outcome.failure) {
            this.handleError(outcome);
            return;
          }

          this.messageService.open(this.translate.getTranslation('Patient.PatientEditPage.SuccessfullyUpdated'), 5000);
          this.patientService.selectPatient(this.patient.id, true);
          this.router.navigate(['/patient', this.patient.id]);
        },
        (error: Outcome) => {
          this.isProcessing = false;
          this.handleError(error);
        }
      )
    );
  }

  private handleError(error: Outcome) {
    if (!error || !error.messages || error.messages.length === 0) {
      this.errorMessages = [this.translate.getTranslation('Common.ErrorProcessingRequest')];
    } else {
      this.errorMessages = error.messages;
    }
  }
}
