/* eslint-disable max-len */
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ConfigService, InterceptorSkipErrorHeader } from '@roctavian-abstractions/core';
import { Observable } from 'rxjs';
import { TokenResponse } from '../models';

/**
 * A client used to communicate with the token API.
 */
@Injectable({
  providedIn: 'root'
})
export class TokenClient {
  /**
   * Initializes a new instance of the token client.
   *
   * @param client The http client.
   */
  constructor(private client: HttpClient, private configService: ConfigService) { }

  /**
   * Requests a new token from the token endpoint given a username and
   * password. If successful, a token response is returned containing
   * the token and expiration. If a bad request is returned the token
   * endpoint will return an outcome indicating what what went wrong.
   *
   * @param username The username.
   * @param password The password.
   * @returns A token response.
   */
  public requestToken(username: string, password: string, token: string): Observable<TokenResponse> {
    const headers = new HttpHeaders().set(InterceptorSkipErrorHeader, '');
    return this.client.post<TokenResponse>(`${this.configService.get('apiUrl')}/identity/token`, { username, password, token }, { headers });
  }
}
