import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ConfigService } from '@roctavian-abstractions/core';
import { Outcome } from '@roctavian-abstractions/web';
import { Observable } from 'rxjs';
import { CreateRoleModel, Role, UpdateRoleModel } from '../models';

/**
 * A client used to communicate with the roles API.
 */
@Injectable({
  providedIn: 'root'
})
export class RolesClient {
  /**
   * Initializes a new instance of the roles client.
   *
   * @param client The http client.
   */
  constructor(private client: HttpClient, private configService: ConfigService) { }

  /**
   * Creates a new role.
   *
   * @param model The create role model.
   * @returns An outcome indicating whether the role was created successfully.
   */
  public createRole(model: CreateRoleModel): Observable<Outcome> {
    return this.client.post<Outcome>(`${this.configService.get('apiUrl')}/identity/role`, model);
  }

  /**
   * Updates the role.
   *
   * @param identity The role identity.
   * @param model The update role model.
   * @returns An outcome indicating whether the roles was updated successfully.
   */
  public updateRole(identity: string, model: UpdateRoleModel): Observable<Outcome> {
    return this.client.put<Outcome>(`${this.configService.get('apiUrl')}/identity/role/${identity}`, model);
  }

  /**
   * Returns all the roles defined in the application.
   *
   * @returns An array of roles.
   */
  public getRoles(): Observable<Role[]> {
    return this.client.get<Role[]>(`${this.configService.get('apiUrl')}/identity/role`);
  }

  /**
   * Returns the specified role.
   *
   * @param roleIdentity The role identity.
   * @returns The role with the matching role identity.
   */
  public getRole(identity: string): Observable<Role> {
    return this.client.get<Role>(`${this.configService.get('apiUrl')}/identity/role/${identity}`);
  }
}
