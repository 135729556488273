import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { RoctavianAuthenticationGuard } from '../shared/guards/roctavian-authentication.guard';
import { EditTaskPageComponent } from './pages/edit-task-page/edit-task-page.component';
import { ManageWorkgroupPageComponent } from './pages/manage-workgroup-page/manage-workgroup-page.component';
import { NewTaskPageComponent } from './pages/new-task-page/new-task-page.component';
import { NewWorkgroupPageComponent } from './pages/new-workgroup-page/new-workgroup-page.component';
import { TaskDashboardPageComponent } from './pages/task-dashboard-page/task-dashboard-page.component';
import { TaskManagerPageComponent } from './pages/task-manager-page/task-manager-page.component';
import { WorkgroupDashboardPageComponent } from './pages/workgroup-dashboard-page/workgroup-dashboard-page.component';

const ROUTES = [
  {
    path: 'tasks',
    component: TaskManagerPageComponent,
    canActivate: [RoctavianAuthenticationGuard],
    children: [
      { path: '', redirectTo: 'dashboard', pathMatch: 'full' },
      { path: 'dashboard', component: TaskDashboardPageComponent },
      { path: 'new', component: NewTaskPageComponent },
      { path: 'workgroup', component: WorkgroupDashboardPageComponent },
      { path: 'workgroup/new', component: NewWorkgroupPageComponent },
      { path: 'workgroup/:id', component: ManageWorkgroupPageComponent },
      { path: ':id', component: EditTaskPageComponent }
    ]
  }
];

@NgModule({
  imports: [CommonModule, RouterModule.forChild(ROUTES)],
  exports: [RouterModule]
})
export class TasksRoutingModule {}
