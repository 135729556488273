import { Component, OnInit, Input } from '@angular/core';
import { LabKitOrder } from '../../models/lab-kit-order.model';
import { UtilityService } from '../../../common';
import { AppTranslationService } from '@roctavian-abstractions/core';

@Component({
  selector: 'view-lab-kit-order',
  templateUrl: './view-lab-kit-order.component.html',
  styleUrls: ['./view-lab-kit-order.component.scss']
})
export class ViewLabKitOrderComponent {
  @Input() public labNamedLabel = this.translate.getTranslation('LabKitOrder.LabName');
  @Input() public nationalIdLabel = this.translate.getTranslation('LabKitOrder.NationalId');
  @Input() public quantityLabel = this.translate.getTranslation('LabKitOrder.Quantity');
  @Input() public processedLabel = this.translate.getTranslation('LabKitOrder.Processed');

  @Input() public labKitOrder: LabKitOrder;

  constructor(public utilityService: UtilityService, private translate: AppTranslationService) { }
}
