import { Component, OnInit, OnDestroy, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { Patient, PatientDuplicateCheckPagedQuery } from '../../models';
import { PaginatedList } from '@roctavian-abstractions/web';
import { Subscription, BehaviorSubject, EMPTY } from 'rxjs';
import { PatientClient } from '../../clients';
import { UtilityService, PagedQuery } from '../../../common';
import { AppTranslationService } from '@roctavian-abstractions/core';
import { Router } from '@angular/router';
import { PageEvent, MatSort } from '@angular/material';
import { PatientService } from '../../services';
import { debounceTime, distinctUntilChanged, switchMap } from 'rxjs/operators';

@Component({
  selector: 'patient-lookup',
  templateUrl: './patient-lookup.component.html',
  styleUrls: ['./patient-lookup.component.scss']
})
export class PatientLookupComponent implements OnInit, OnDestroy {
  public form: FormGroup;
  @Output() public continueWithPatient = new EventEmitter<Patient>();
  public duplicatePatientCollection = new PaginatedList<Patient>();
  public pagedQuery = new PatientDuplicateCheckPagedQuery(1, 10, null, 'id', true, null, null);
  public filterText$ = new BehaviorSubject<string>(null);

  public errorMessage: string;
  public warningMessage: string;
  public isLoading = false;
  public displayedColumns = [
    'id',
    'stakeholder.firstName',
    'stakeholder.lastName',
    'stakeholder.address.country',
    'birthDate',
    'stakeholder.statuses.statusType',
    'stakeholder.statuses.addDate'
  ];

  private subscriptions = new Array<Subscription>();

  public currentDate = new Date();

  constructor(
    private formBuilder: FormBuilder,
    private service: PatientService,
    public utilityService: UtilityService,
    private translate: AppTranslationService,
    private router: Router
  ) { }

  public ngOnInit() {
    this.buildForm();

    this.subscriptions.push(this.service.pagedDuplicatePatientListSubject.subscribe(
      pagedCollection => {
        this.duplicatePatientCollection = pagedCollection;

        if (!this.form.get('stakeholder.lastName').value) {
          return;
        }

        if (this.isDuplicateFound) {
          this.warningMessage = this.translate.getTranslation('Patient.PatientLookup.PatientExists');
        } else {
          this.continueWithPatient.emit(this.form.getRawValue());
        }
      }
    ));

    this.subscriptions.push(this.filterText$
      .pipe(
        debounceTime(1000),
        distinctUntilChanged(),
        switchMap(searchText => {
          if (this.pagedQuery.filterText == searchText) {
            return EMPTY;
          }

          this.pagedQuery.filterText = searchText;
          this.pagedQuery.pageIndex = 1;
          this.refreshDuplicateList();
          return EMPTY;
        })
      )
      .subscribe());

    this.subscriptions.push(this.service.isLoadingSubject.subscribe(
      isLoading => (this.isLoading = isLoading)
    ));
  }

  ngOnDestroy() {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
    this.service.pagedDuplicatePatientListSubject.next(new PaginatedList<Patient>());
  }

  private buildForm() {
    this.form = this.formBuilder.group({
      birthDate: new FormControl(null),
      stakeholder: this.formBuilder.group({
        lastName: new FormControl(null, Validators.required)
      })
    });
  }

  public submit() {
    this.errorMessage = null;
    this.warningMessage = null;

    this.form.markAllAsTouched();
    if (this.form.invalid) {
      return;
    }

    if (this.isDuplicateFound) {
      this.continueWithPatient.emit(this.form.getRawValue());
      return;
    }

    this.pagedQuery.lastName = this.form.get('stakeholder.lastName').value;
    this.pagedQuery.birthDate = this.form.get('birthDate').value;
    this.refreshDuplicateList();
  }

  private refreshDuplicateList() {
    this.service.setDuplicatePatientsPage(this.pagedQuery);
  }

  public cancelLookup() {
    if (this.isDuplicateFound) {
      this.duplicatePatientCollection = new PaginatedList<Patient>();
      this.errorMessage = null;
      this.warningMessage = null;
      this.pagedQuery = new PatientDuplicateCheckPagedQuery(1, 10, null, 'id', true, null, null);
      this.filterText$.next(null);
      return;
    } else {
      this.router.navigate(['/patient']);
    }
  }

  public onPageEvent(pageEvent: PageEvent) {
    this.pagedQuery.pageIndex = pageEvent.pageIndex - 1;
    this.pagedQuery.pageSize = pageEvent.pageSize;
    this.refreshDuplicateList();
  }

  public onSortEvent(sort: MatSort) {
    this.pagedQuery.sortColumn = sort.active;
    this.pagedQuery.isSortDesc = sort.direction == 'desc';
    this.pagedQuery.pageIndex = 1;
    this.refreshDuplicateList();
  }

  public get isDuplicateFound(): boolean {
    return (
      (this.duplicatePatientCollection &&
        this.duplicatePatientCollection.items &&
        this.duplicatePatientCollection.items.length > 0) ||
      this.pagedQuery.filterText !== null
    );
  }
}
