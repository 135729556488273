import {
  ChangeDetectionStrategy, Component,
  EventEmitter, Input, OnInit, Output
} from '@angular/core';
import { Role } from 'apps/roctavian-eumea-callcenter-portal/src/app/modules/identity/models';

@Component({
  selector: 'roctavian-abstractions-role-card-list',
  templateUrl: './role-card-list.component.html',
  styleUrls: ['./role-card-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RoleCardListComponent implements OnInit {
  @Input() public roles: Role[];
  @Output() public selected = new EventEmitter<number>();

  constructor() {}

  public ngOnInit() {}

  public handleCardSelected(identity: number) {
    this.selected.emit(identity);
  }
}
