import { CommonModule, DatePipe } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MAT_DIALOG_DEFAULT_OPTIONS, MatProgressSpinnerModule } from '@angular/material';
import { RouterModule } from '@angular/router';
import { CoreDirectivesModule, MaterialModule, PipeModule } from '@roctavian-abstractions/core';
import { NgxMaterialTimepickerModule } from 'ngx-material-timepicker';
import { IdentityModule } from '../../../identity/identity.module';
import { SharedModule } from '../../../shared/shared.module';
import { StakeholderCommonModule } from '../common';
import { AddExternalLabTestComponent } from './components/add-external-lab-test/add-external-lab-test.component';
import { DeleteExternalLabTestDialogComponent } from './components/delete-external-lab-test-dialog/delete-external-lab-test-dialog.component';
import { ExternalLabTestFormComponent } from './components/external-lab-test-form/external-lab-test-form.component';
import { ExternalLabTestListComponent } from './components/external-lab-test-list/external-lab-test-list.component';
import { ViewExternalLabTestDialogComponent } from './components/view-external-lab-test-dialog/view-external-lab-test-dialog.component';
import { ViewExternalLabTestComponent } from './components/view-external-lab-test/view-external-lab-test.component';
import { ExternalLabTestRoutingModule } from './external-lab-test-routing.module';
import { AddExternalLabTestPageComponent } from './pages/add-external-lab-test-page/add-external-lab-test-page.component';
import { EditExternalLabTestPageComponent } from './pages/edit-external-lab-test-page/edit-external-lab-test-page.component';
import { ExternalLabTestDetailMasterPageComponent } from './pages/external-lab-test-detail-master-page/external-lab-test-detail-master-page.component';
import { ExternalLabTestListPageComponent } from './pages/external-lab-test-list-page/external-lab-test-list-page.component';
import { ExternalLabTestSummaryPageComponent } from './pages/external-lab-test-summary-page/external-lab-test-summary-page.component';



@NgModule({
  declarations: [
    DeleteExternalLabTestDialogComponent,
    ViewExternalLabTestDialogComponent,
    ExternalLabTestListPageComponent,
    AddExternalLabTestPageComponent,
    ExternalLabTestDetailMasterPageComponent,
    ExternalLabTestSummaryPageComponent,
    EditExternalLabTestPageComponent,
    AddExternalLabTestComponent,
    ExternalLabTestFormComponent,
    ExternalLabTestListComponent,
    ViewExternalLabTestComponent,
  ],
  imports: [
    CommonModule,
    IdentityModule,
    RouterModule,
    MaterialModule,
    FormsModule,
    HttpClientModule,
    ReactiveFormsModule,
    PipeModule,
    ExternalLabTestRoutingModule,
    CoreDirectivesModule,
    StakeholderCommonModule,
    SharedModule,
    NgxMaterialTimepickerModule,
    MatProgressSpinnerModule
  ],
  providers: [{ provide: MAT_DIALOG_DEFAULT_OPTIONS, useValue: { hasBackdrop: false } }, DatePipe],
  entryComponents: [ViewExternalLabTestDialogComponent, DeleteExternalLabTestDialogComponent]
})
export class ExternalLabTestModule {}
