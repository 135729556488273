import { Component, OnInit } from '@angular/core';
import { PrescriberService } from '../../services';

@Component({
  selector: 'roctavian-eumea-callcenter-portal-prescriber-list-page',
  templateUrl: './prescriber-list-page.component.html',
  styleUrls: ['./prescriber-list-page.component.css']
})
export class PrescriberListPageComponent implements OnInit {
  constructor(private prescriberService: PrescriberService) {}

  ngOnInit() {}
}
