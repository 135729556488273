import { BehaviorSubject, Subscription, EMPTY } from 'rxjs';
import { Component, OnInit, Input, SimpleChanges, OnChanges, OnDestroy } from '@angular/core';
import { MatDialog, PageEvent, MatSort } from '@angular/material';
import { PaginatedList } from '@roctavian-abstractions/web';
import { debounceTime, distinctUntilChanged, switchMap } from 'rxjs/operators';
import { PagedQuery, UtilityService } from '../../../common';
import { Prescriber, LabuserDelegate, LabUser } from '../../models';
import { LabUserService } from '../../services';
import { LabuserPagedQuery } from '../../models/labuser-paged-query.model';

@Component({
  selector: 'labuser-prescriber-list',
  templateUrl: './prescriber-list.component.html',
  styleUrls: ['./prescriber-list.component.scss']
})
export class PrescriberListComponent implements OnInit, OnChanges, OnDestroy {
  @Input() public labUser: LabUser;

  public pagedQuery = new LabuserPagedQuery(1, 10, null, 'prescriber.stakeholder.lastName', false, null, null, null);

  public filterText$ = new BehaviorSubject<string>(null);
  public prescriberPagedCollection: PaginatedList<LabuserDelegate>;
  public isLoading = false;
  private subscriptions = new Array<Subscription>();

  public displayedColumns = [
    'id',
    'prescriber.stakeholder.firstName',
    'prescriber.stakeholder.lastName',
    'prescriber.nationalId',
    'prescriber.stakeholder.statuses.statusType',
    'prescriber.stakeholder.statuses.addDate',
    'addDate',
    'addUser',
    'menu'
  ];

  constructor(
    public utilityService: UtilityService,
    private labUserService: LabUserService,
    private matDialog: MatDialog
  ) {
    this.subscriptions.push(this.filterText$
      .pipe(
        debounceTime(1000),
        distinctUntilChanged(),
        switchMap(searchText => {
          if (this.pagedQuery.filterText == searchText) {
            return EMPTY;
          }

          this.pagedQuery.filterText = searchText;
          this.pagedQuery.pageIndex = 1;
          this.refreshList();
          return EMPTY;
        })
      )
      .subscribe());
  }

  ngOnDestroy() {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
    this.labUserService.prescriberPagedListSubject.next(new PaginatedList<LabuserDelegate>());
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (
      !changes.labUser ||
      !changes.labUser.currentValue ||
      changes.labUser.currentValue === changes.labUser.previousValue
    ) {
      return;
    }

    this.refreshList();
  }

  public refreshList() {
    if (!this.labUser) {
      return;
    }
    this.labUserService.setPrescriberListPage(this.pagedQuery, this.labUser.id);
  }

  ngOnInit() {
    this.subscriptions.push(this.labUserService.prescriberPagedListSubject.subscribe(
      collection => {
        this.prescriberPagedCollection = collection;
      }
    ));

    this.subscriptions.push(this.labUserService.isLoadingSubject.subscribe(isLoading => {
      this.isLoading = isLoading;
    }));
  }

  public onPageEvent(pageEvent: PageEvent) {
    this.pagedQuery.pageIndex = pageEvent.pageIndex + 1;
    this.pagedQuery.pageSize = pageEvent.pageSize;
    this.refreshList();
  }

  public onSortEvent(sort: MatSort) {
    switch (sort.active) {
      case 'id':
        this.pagedQuery.sortColumn = 'prescriber.stakeholder.externalId';
        break;
      case 'prescriber.stakeholder.statuses.statusType':
        this.pagedQuery.sortColumn = 'prescriber.stakeholder.statuses.FirstOrDefault(x => x.IsCurrent).statusType';
        break;
      case 'prescriber.stakeholder.statuses.addDate':
        this.pagedQuery.sortColumn = 'prescriber.stakeholder.statuses.FirstOrDefault(x => x.IsCurrent).addDate';
        break;

      default:
        this.pagedQuery.sortColumn = sort.active;
        break;
    }

    this.pagedQuery.isSortDesc = sort.direction == 'desc';
    this.pagedQuery.pageIndex = 1;
    this.refreshList();
  }
}
