import { Subscription } from 'rxjs';
import { Component, OnInit, OnDestroy, Output, EventEmitter, Inject } from '@angular/core';
import { PrescriberLab } from '../../models';
import { FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { PrescriberClient } from '../../clients';
import { MessageService } from '@roctavian-abstractions/core';
import { AppTranslationService } from '@roctavian-abstractions/core';
import { Outcome } from '@roctavian-abstractions/web';

@Component({
  selector: 'roctavian-eumea-callcenter-portal-disassociate-lab-dialog',
  templateUrl: './disassociate-lab-dialog.component.html',
  styleUrls: ['./disassociate-lab-dialog.component.scss']
})
export class DisassociateLabDialogComponent implements OnInit, OnDestroy {
  public prescriberLab: PrescriberLab;
  public form = new FormGroup({});
  @Output() public labDisassociated = new EventEmitter();
  public errorMessages: string[] = [];
  private subscriptions = new Array<Subscription>();

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public reference: MatDialogRef<DisassociateLabDialogComponent>,
    private client: PrescriberClient,
    private messageService: MessageService,
    private translate: AppTranslationService
  ) {
    this.prescriberLab = data.prescriberLab;
  }

  public ngOnInit() { }

  ngOnDestroy() {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }

  public removeAssociation() {
    this.errorMessages = [];

    this.subscriptions.push(this.client
      .disassociateLab(this.prescriberLab.prescriber.id, this.prescriberLab.lab.id)
      .subscribe(
        outcome => {
          if (outcome.success) {
            this.messageService.open(
              this.translate.getTranslation('Prescriber.DisassociateLabDialog.SuccessfullyDisassociated'),
              3000
            );
            this.labDisassociated.emit();
            this.reference.close();
            return;
          }

          this.errorMessages = outcome.messages;
        },
        (error: Outcome) => {
          this.messageService.open(this.translate.getTranslation('Common.ErrorProcessingRequest'), 5000);
        }
      ));
  }
}
