import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { Outcome } from "@roctavian-abstractions/web";
import { MessageService } from "@roctavian-abstractions/core";
import { map } from "rxjs/operators";
import { TwoFactorCodeSubmittedEvent } from "../../modules/two-factor/components";
import { AuthenticationService } from "../../services/authentication.service";
import { AuthenticationEventHandler } from "../../authentication-event.handler";

@Component({
  selector: "roctavian-abstractions-two-factor-verification-page",
  templateUrl: "./two-factor-verification-page.component.html",
  styleUrls: ["./two-factor-verification-page.component.scss"]
})
export class TwoFactorVerificationPageComponent implements OnInit {
  public disabled = false;
  public errorMessages: string[] = [];

  public username: string;
  public password: string;

  constructor(
    private route: ActivatedRoute,
    private messageService: MessageService,
    private service: AuthenticationService,
    private handler: AuthenticationEventHandler
  ) {}

  /**
   * Initializes the two-factor verification page. This page is given hidden
   * state containing the username and password trying to log in.
   */
  public ngOnInit() {
    this.route.paramMap.pipe(map(() => window.history.state)).subscribe(state => {
      this.username = state.username;
      this.password = state.password;
    });
  }

  public handleFormSubmission(event: TwoFactorCodeSubmittedEvent) {
    this.requestToken(this.username, this.password, event.token);
  }

  public clearMessages(): void {
    this.errorMessages = [];
  }

  /**
   * Requests a token from the endpoint to authenticate the user.
   * @param username The username.
   * @param password The password.
   */
  private requestToken(username: string, password: string, token: string) {
    this.service.login(username, password, token).subscribe(
      () => {
        this.disabled = false;
        this.handler.handleSuccessfulLogin();
      },
      (outcome: Outcome) => {
        this.disabled = false;
        this.clearMessages();

        if (outcome instanceof Outcome && outcome) {
          this.errorMessages = this.errorMessages.concat(outcome.messages);
          return;
        }

        const message = "An error occurred when submitting your request. Please try again.";
        this.messageService.open(message, 5000);
      }
    );
  }
}
