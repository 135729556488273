import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { LabTestListPageComponent } from './pages/lab-test-list-page/lab-test-list-page.component';
import { AddLabTestPageComponent } from './pages/add-lab-test-page/add-lab-test-page.component';
import { LabTestDetailMasterPageComponent } from './pages/lab-test-detail-master-page/lab-test-detail-master-page.component';
import { LabTestSummaryPageComponent } from './pages/lab-test-summary-page/lab-test-summary-page.component';
import { LabTestStatusPageComponent } from './pages/lab-test-status-page/lab-test-status-page.component';
import { EditLabTestPageComponent } from './pages/edit-lab-test-page/edit-lab-test-page.component';
import { LabTestDocumentsPageComponent } from './pages/lab-test-documents-page/lab-test-documents-page.component';
import { RoctavianAuthenticationGuard } from '../../../shared/guards/roctavian-authentication.guard';

const ROUTES = [
  {
    path: 'lab-test',
    canActivate: [RoctavianAuthenticationGuard],
    children: [
      { path: '', redirectTo: 'list', pathMatch: 'full' },
      { path: 'list', component: LabTestListPageComponent },
      { path: 'add', component: AddLabTestPageComponent },
      {
        path: ':id',
        component: LabTestDetailMasterPageComponent,
        children: [
          { path: '', redirectTo: 'summary', pathMatch: 'full' },
          { path: 'summary', component: LabTestSummaryPageComponent },
          { path: 'status', component: LabTestStatusPageComponent },
          { path: 'edit', component: EditLabTestPageComponent },
          { path: 'documents', component: LabTestDocumentsPageComponent }
        ]
      }
    ]
  }
];

@NgModule({
  imports: [CommonModule, RouterModule.forChild(ROUTES)],
  exports: [RouterModule]
})
export class LabTestRoutingModule { }
