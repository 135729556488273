import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Outcome } from '@roctavian-abstractions/web';
import { ConfigService } from '@roctavian-abstractions/core';

@Injectable({
  providedIn: 'root'
})
export class TaskTypeClient {
  /**
   * Initializes a new instance of the task type service.
   *
   * @param client The http client.
   */
  constructor(private client: HttpClient, private configService: ConfigService) { }

  /**
   * Returns all task types configured in the application.
   *
   * @returns An outcome containing an array of task types.
   */
  public getTaskTypes(): Observable<Outcome> {
    return this.client.get<Outcome>(`${this.configService.get('apiUrl')}/task/type`);
  }

  /**
   * Returns all task types that are configured for the given entity type.
   *
   * @param entityTypeIdentity The entity type identity.
   * @returns An outcome containing the task types associated to the specified entity type.
   */
  public getTaskTypesForEntityType(entityTypeIdentity: number): Observable<Outcome> {
    return this.client.get<Outcome>(`${this.configService.get('apiUrl')}/task/type/entity/${entityTypeIdentity}`);
  }

  /**
   * Returns the requested task type.
   *
   * @param taskTypeIdentity the identity of the task type
   * @returns An outcome containing the task type if it exists.
   */
  public getTaskType(taskTypeIdentity: number): Observable<Outcome> {
    return this.client.get<Outcome>(`${this.configService.get('apiUrl')}/task/type/${taskTypeIdentity}`);
  }
}
