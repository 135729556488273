import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MessageService } from '@roctavian-abstractions/core';

@Component({
  selector: 'roctavian-abstractions-create-permission-page',
  templateUrl: './create-permission-page.component.html',
  styleUrls: ['./create-permission-page.component.scss'],
})
export class CreatePermissionPageComponent implements OnInit {
  constructor(private router: Router, private service: MessageService) {}

  public ngOnInit() {}

  public handlePermissionSaved() {
    this.service.open('Permission created successfully', 4000);
    this.router.navigate(['/administration/permissions']);
  }
}
