import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'roctavian-eumea-callcenter-portal-lab-list-page',
  templateUrl: './lab-list-page.component.html',
  styleUrls: ['./lab-list-page.component.css']
})
export class LabListPageComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
