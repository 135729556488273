import { Component, EventEmitter, Input, Output, ChangeDetectionStrategy, OnChanges } from "@angular/core";
import { MessageService } from "@roctavian-abstractions/core";
import { ForgotPasswordFormSubmitEvent } from "./../forgot-password-form/forgot-password-form.component";
import { SecurityQuestionClient } from "../../../../clients/security-question.client";
import { AccountClient } from "../../../../clients/account.client";
import { ForgotPasswordRequestModel, SecurityQuestionAnswerModel } from "./../../../../models";

@Component({
  selector: "roctavian-abstractions-forgot-password",
  templateUrl: "./forgot-password.component.html",
  styleUrls: ["./forgot-password.component.scss"]
})
export class ForgotPasswordComponent implements OnChanges {
  public disabled = false;

  public group1Questions: any[] = [];
  public group2Questions: any[] = [];

  public answer1: string;
  public selectedQuestion1: number;
  public answer2: string;
  public selectedQuestion2: number;
  public showQuestionsRequiredMessage = false;
  @Input() public requireSecurityQuestions = false;
  @Input() public enableRecaptcha = false;

  public questions: any[];

  @Output() public reset = new EventEmitter();

  constructor(
    private service: MessageService,
    private client: AccountClient,
    private securityClient: SecurityQuestionClient
  ) {}

  public ngOnChanges(): void {
    if (this.requireSecurityQuestions) {
      // Make a request to get a list of the security questions:
      this.securityClient.getSecurityQuestions().subscribe(questions => {
        this.questions = questions;
        this.group1Questions = questions.filter(q => q.group === 1);
        this.group2Questions = questions.filter(q => q.group === 2);
      });
    }
  }

  public securityQuestionsAnswered() {
    if (this.answer1 && this.answer2 && this.selectedQuestion1 && this.selectedQuestion2) {
      return true;
    }
    return false;
  }

  public handleForgotPasswordSubmission(event: ForgotPasswordFormSubmitEvent) {
    if (this.requireSecurityQuestions && !this.securityQuestionsAnswered()) {
      this.showQuestionsRequiredMessage = true;
      return;
    }

    this.disabled = true;

    const model = new ForgotPasswordRequestModel();
    model.username = event.username;
    model.recaptchaToken = event.recaptchaToken;

    if (this.requireSecurityQuestions) {
      model.answers.push(new SecurityQuestionAnswerModel(this.selectedQuestion1, this.answer1));
      model.answers.push(new SecurityQuestionAnswerModel(this.selectedQuestion2, this.answer2));
    }

    this.client.forgotPassword(model).subscribe(
      () => {
        this.disabled = false;
        this.reset.emit();
      },
      () => {
        this.disabled = false;
        const message = "An error occurred when submitting your request. Please try again.";
        this.service.open(message, 5000);
      }
    );
  }
}
