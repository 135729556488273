export enum Endpoints {
  attestation = 'api/attestations',
  document = 'api/documents',
  documentLab = 'api/document-labs',
  documentLabTest = 'api/document-lab-tests',
  documentPatient = 'api/document-patients',
  documentPrescriber = 'api/document-prescribers',
  documentStorage = 'api/document-storages',
  institution = 'api/institutions',
  lab = 'api/labs',
  labKitOrder = 'api/lab-kit-orders',
  labTest = 'api/lab-tests',
  externalLabTest = 'api/external-lab-tests',
  labUser = 'api/lab-users',
  patient = 'api/patients',
  prescriber = 'api/prescribers',
  prescriberDelegate = 'api/prescriber-delegates',
  status = 'api/stati',
}
