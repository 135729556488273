import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog, MatSort, PageEvent } from '@angular/material';
import { Router } from '@angular/router';
import { AppTranslationService, MessageService } from '@roctavian-abstractions/core';
import { Outcome, PaginatedList } from '@roctavian-abstractions/web';
import { BehaviorSubject, EMPTY, Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged, switchMap } from 'rxjs/operators';
import { UtilityService } from '../../../common';
import { FileExportService } from '../../../common/services/file-export.service';
import { PrescriberClient } from '../../clients';
import { Prescriber } from '../../models';
import { PrescriberPagedQuery } from '../../models/prescriber-paged-query.model';
import { PrescriberService } from '../../services';
import { ViewPrescriberDialogComponent } from '../view-prescriber-dialog/view-prescriber-dialog.component';

@Component({
  selector: 'prescriber-list',
  templateUrl: './prescriber-list.component.html',
  styleUrls: ['./prescriber-list.component.css']
})
export class PrescriberListComponent implements OnInit, OnDestroy {
  public pagedQuery = new PrescriberPagedQuery(1, 10, null, 'id', true, null, null, null);

  public filterText$ = new BehaviorSubject<string>(null);
  public prescriberPagedCollection: PaginatedList<Prescriber>;
  public isLoading = false;
  private subscriptions = new Array<Subscription>();
  private exportFileName = this.translate.getTranslation('Prescriber.PrescriberList.ExportFileName');

  public displayedColumns = [
    'id',
    'stakeholder.firstName',
    'stakeholder.lastName',
    'institution.stakeholder.address.name',
    'nationalId',
    'institution.stakeholder.address.address1',
    'institution.stakeholder.address.city',
    'institution.stakeholder.address.country.name',
    'stakeholder.attestations.signatureDate',
    'stakeholder.statuses.statusType',
    'stakeholder.statuses.addDate',
    'menu'
  ];

  constructor(
    private prescriberService: PrescriberService,
    private router: Router,
    public utilityService: UtilityService,
    private matDialog: MatDialog,
    private prescriberClient: PrescriberClient,
    private messageService: MessageService,
    private translate: AppTranslationService,
    private fileExportService: FileExportService
  ) { }

  private refreshList() {
    this.prescriberService.setPage(this.pagedQuery);
  }

  ngOnInit() {
    this.subscriptions.push(this.filterText$
      .pipe(
        debounceTime(1000),
        distinctUntilChanged(),
        switchMap(searchText => {
          if (this.pagedQuery.filterText == searchText) {
            return EMPTY;
          }

          this.pagedQuery.filterText = searchText;
          this.pagedQuery.pageIndex = 1;
          this.refreshList();
          return EMPTY;
        })
      )
      .subscribe());

    this.subscriptions.push(this.prescriberService.prescriberPagedListSubject.subscribe(
      collection => {
        this.prescriberPagedCollection = collection;
      }
    ));

    this.subscriptions.push(this.prescriberService.isLoadingSubject.subscribe(isLoading => {
      this.isLoading = isLoading;
    }));

    this.refreshList();
  }

  ngOnDestroy() {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
    this.prescriberService.prescriberPagedListSubject.next(new PaginatedList<Prescriber>());
  }

  public onRowSelected(prescriber: Prescriber) {
    this.router.navigate(['/prescriber', prescriber.id]);
  }

  public viewPrescriber(prescriberId: string) {

    this.subscriptions.push(this.prescriberClient.getPrescriber(prescriberId).subscribe(
      outcome => {
        const dialogRef = this.matDialog.open(ViewPrescriberDialogComponent, {
          hasBackdrop: true,
          disableClose: true,
          data: {
            prescriber: outcome.value
          },
          width: '1000px'
        });
      },
      error => {
        this.messageService.open(this.translate.getTranslation('Common.ErrorProcessingRequest'), 3000);
      }
    ));
  }

  public onPageEvent(pageEvent: PageEvent) {
    this.pagedQuery.pageIndex = pageEvent.pageIndex + 1;
    this.pagedQuery.pageSize = pageEvent.pageSize;
    this.refreshList();
  }

  public onSortEvent(sort: MatSort) {
    switch (sort.active) {
      case 'id':
        this.pagedQuery.sortColumn = 'stakeholder.externalId';
        break;
      case 'stakeholder.attestations.signatureDate':
        this.pagedQuery.sortColumn = 'stakeholder.attestations.FirstOrDefault(x => x.IsCurrent).signatureDate';
        break;
      case 'stakeholder.statuses.statusType':
        this.pagedQuery.sortColumn = 'stakeholder.statuses.FirstOrDefault(x => x.IsCurrent).statusType';
        break;
      case 'stakeholder.statuses.addDate':
        this.pagedQuery.sortColumn = 'stakeholder.statuses.FirstOrDefault(x => x.IsCurrent).addDate';
        break;

      default:
        this.pagedQuery.sortColumn = sort.active;
        break;
    }

    this.pagedQuery.isSortDesc = sort.direction == 'desc';
    this.pagedQuery.pageIndex = 1;
    this.refreshList();
  }

  public downloadExportFile() {
    this.subscriptions.push(
      this.prescriberClient.getExportFile().subscribe(
        data => this.fileExportService.downloadFile(data, 'text/csv', this.exportFileName),
        (error: Outcome) => {
          if (!error.messages[0] == null || !error.messages[0] == undefined || error.messages.length !== 0) {
            this.messageService.open(error.messages[0], 3000);
          }
        }
      )
    );
  }
}
