import {
  ChangeDetectionStrategy, Component,
  EventEmitter,
  Input,
  OnChanges,
  Output
} from '@angular/core';
import { MatSlideToggleChange } from '@angular/material';
import { PermissionViewModel } from '../permission-toggle/permission-toggle.component';
import { PermissionToggleEvent } from './../permission-toggle/permission-toggle.component';

export class PermissionListToggleEvent {
  constructor(identities: string[], checked: boolean) {
    this.identities = identities;
    this.checked = checked;
  }
  public identities: string[];
  public checked: boolean;
}

@Component({
  selector: 'roctavian-abstractions-permission-toggle-group',
  templateUrl: './permission-toggle-group.component.html',
  styleUrls: ['./permission-toggle-group.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PermissionToggleGroupComponent implements OnChanges {
  @Input() public permissions: PermissionViewModel[] = [];

  @Input() public group: string;

  public checked = false;

  @Output() public toggled = new EventEmitter<PermissionToggleEvent>();

  @Output() public allToggled = new EventEmitter<PermissionListToggleEvent>();

  constructor() {}

  public ngOnChanges() {
    if (!this.permissions) {
      return;
    }

    // Determine if every permission in the group is enabled/disabled.
    // If so, set the list toggle accordingly.
    this.checked = this.permissions.every(permission => permission.enabled);
  }

  /**
   * Handles the toggle all event of a list.
   *
   * @param event The material slide toggle change event.
   */
  public handleListToggle(event: MatSlideToggleChange) {
    // Emit the toggle all event passing in all the identities under the group:
    const identities = this.permissions.map(permission => permission.permissionIdentity);
    const toggleAllEvent = new PermissionListToggleEvent(identities, event.checked);
    this.allToggled.emit(toggleAllEvent);
  }

  /**
   * Handles the permission toggle event.
   *
   * @param event The permission toggle event.
   */
  public handleToggle(event: PermissionToggleEvent) {
    this.toggled.emit(event);
  }
}
