import { Component, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Permission } from '../../../identity/models';
import { PermissionManagementService } from '../../services/permission-management.service';

@Component({
  selector: 'roctavian-abstractions-permission-manager-page',
  templateUrl: './permission-manager-page.component.html',
  styleUrls: ['./permission-manager-page.component.scss'],
})
export class PermissionManagerPageComponent implements OnDestroy {
  public permissions: Permission[];
  public permissionCount: number;

  public groupedPermissions: Permission[][];

  public filterText: string;
  public unsubscribe: Subject<boolean> = new Subject<boolean>();

  constructor(private router: Router, private service: PermissionManagementService) {
    this.filterText = this.service.getFilterText();
    this.service.permissions$.pipe(takeUntil(this.unsubscribe)).subscribe(permissions => {
      this.permissions = permissions.slice();
      var set = new Set();
      var groups = this.permissions.map(p => p.group);
      groups.forEach(group => {
        set.add(group);
      });
      var groupedArray = [];
      set.forEach(item => {
        groupedArray.push(this.permissions.filter(p => p.group === item).slice());
      });

      this.groupedPermissions = groupedArray.slice();
    });

    this.service.unfilteredPermissionCount$
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(permissionCount => {
        this.permissionCount = permissionCount;
      });

    this.service.refreshPermissions();
  }

  public handleKeyup(text: string) {
    this.service.filterPermissions(text);
  }

  public handleDelete(identity: string) {
    this.service.deletePermission(identity).subscribe();
  }

  public handleEdit(identity: string) {
    this.router.navigate(['administration/permissions/edit', identity]);
  }

  public ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }
}
