/* eslint-disable @typescript-eslint/naming-convention */
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ConfigService } from '@roctavian-abstractions/core';
import { Outcome } from '@roctavian-abstractions/web';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

/**
 * A client used to communicate with the two-factor authentication API.
 */
@Injectable({
  providedIn: 'root'
})
export class TwoFactorClient {
  /**
   * Initializes a new instance of the two-factor client.
   *
   * @param client The http client.
   */
  constructor(private client: HttpClient, private configService: ConfigService) { }

  public getTwoFactorStatusAnonymous(username: string, password: string): Observable<any> {
    return this.client.post<Outcome>(`${this.configService.get('apiUrl')}/identity/twofactor/status`, { username, password });
  }

  /**
   * Gets the two-factor status for the authenticated user.
   *
   * @returns An outcome containing the two-factor details for the user.
   */
  public getTwoFactorStatus(): Observable<Outcome> {
    return this.client.get<Outcome>(`${this.configService.get('apiUrl')}/identity/twofactor/status`).pipe(
      catchError((response: HttpErrorResponse) => {
        if (response instanceof HttpErrorResponse) {
          const outcome: Outcome = Object.assign(new Outcome(), response.error);
          return throwError(outcome);
        }
      })
    );
  }

  /**
   * Generates an authenticator key in order to enable two-factor authentication.
   *
   * @returns An outcome containing two-factor initialization details.
   */
  public generateAuthenticatorKey(): Observable<Outcome> {
    return this.client.get<Outcome>(`${this.configService.get('apiUrl')}/identity/twofactor/initiate`).pipe(
      catchError((response: HttpErrorResponse) => {
        if (response instanceof HttpErrorResponse) {
          const outcome: Outcome = Object.assign(new Outcome(), response.error);
          return throwError(outcome);
        }
      })
    );
  }

  /**
   * Enables two-factor authentication for the authenticated user.
   * A valid two-factor authentication token is required. This token
   * is generated using the authenticator key.
   *
   * @param token The two-factor authentication token.
   * @returns An outcome containing a list of recovery codes.
   */
  public enableTwoFactorAuthentication(token: string): Observable<Outcome> {
    return this.client.post<Outcome>(`${this.configService.get('apiUrl')}/identity/twofactor/enable`, { VerificationToken: token }).pipe(
      catchError((response: HttpErrorResponse) => {
        if (response instanceof HttpErrorResponse) {
          const outcome: Outcome = Object.assign(new Outcome(), response.error);
          return throwError(outcome);
        }
      })
    );
  }

  /**
   * Disables two-factor authentication for the authenticated user.
   *
   * @returns An outcome indicating whether the operation was successful.
   */
  public disableTwoFactorAuthentication(): Observable<Outcome> {
    return this.client.post<Outcome>(`${this.configService.get('apiUrl')}/identity/twofactor/disable`, {}).pipe(
      catchError((response: HttpErrorResponse) => {
        if (response instanceof HttpErrorResponse) {
          const outcome: Outcome = Object.assign(new Outcome(), response.error);
          return throwError(outcome);
        }
      })
    );
  }
}
