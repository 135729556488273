import { Component, OnInit, ViewChild } from "@angular/core";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { Outcome } from "@roctavian-abstractions/web";
import { SubmittedErrorStateMatcher } from "@roctavian-abstractions/core";
import { TwoFactorClient } from "./../../../../clients/two-factor.client";
import {
  TwoFactorStatusDetails,
  TwoFactorInitializationDetails,
  EnableTwoFactorAuthenticationDetails
} from "../../../../models";

@Component({
  selector: "roctavian-abstractions-two-factor",
  templateUrl: "./two-factor.component.html",
  styleUrls: ["./two-factor.component.scss"]
})
export class TwoFactorComponent implements OnInit {
  public isLoading = true;
  public buttonEnabled = true;
  public showGeneralError = false;
  public twoFactorDetails: TwoFactorStatusDetails;
  public enableTwoFactorFormGroup: FormGroup;
  public authCodeDetails: TwoFactorInitializationDetails = new TwoFactorInitializationDetails();
  public verificationKey: string;
  public showError = false;
  public errorMessage: string;
  public showRecoveryCodes = false;
  public recoveryCodes: string[];
  public matcher = new SubmittedErrorStateMatcher();

  @ViewChild("stepper", { static: false }) public stepper;

  public showStepper = true;

  constructor(public twoFactorClient: TwoFactorClient, public formBuilder: FormBuilder) { }

  /**
   * Initializes the form group and gets the user's current two factor authentication status.
   */
  public ngOnInit() {
    this.enableTwoFactorFormGroup = this.formBuilder.group({
      code: ["", Validators.required]
    });

    this.twoFactorClient.getTwoFactorStatus().subscribe(data => {
      this.isLoading = false;
      this.twoFactorDetails = data.value;

      if (this.twoFactorDetails.isTwoFactorEnabled) {
        this.showStepper = false;
      }
    });
  }

  /**
   * Generates a QR code to allow an authenticator to be attached to the logged in users' account.
   * Once a QR code is successfully generated, move to the next step in the process
   */
  public generateQrCode() {
    this.buttonEnabled = false;
    this.twoFactorClient.generateAuthenticatorKey().subscribe(
      data => {
        const details: TwoFactorInitializationDetails = data.value;
        this.authCodeDetails = details;
        this.buttonEnabled = true;
        this.stepper.next();
      },
      error => {
        this.showGeneralError = true;
        this.stepper.reset();
      }
    );
  }

  /**
   * Enables two factor authentication for the logged in users' account.
   * This must be done after an authenticator is attached.
   */
  public enableTwoFactor() {
    this.buttonEnabled = false;

    this.twoFactorClient.enableTwoFactorAuthentication(this.enableTwoFactorFormGroup.get("code").value).subscribe(
      data => {
        const details: EnableTwoFactorAuthenticationDetails = data.value;

        this.recoveryCodes = details.recoveryCodes;
        this.twoFactorDetails.isTwoFactorEnabled = true;
        this.buttonEnabled = true;
        this.stepper.next();
        this.enableTwoFactorFormGroup.reset();
      },
      (error: Outcome) => {
        this.buttonEnabled = true;
        this.showError = true;
        this.errorMessage = error.messages[0] || "Verification code is not valid";
      }
    );
  }

  /**
   * Handles the event when two-factor authentication is disabled
   * by resetting the state of the stepper and component.
   */
  public onTwoFactorDisabled() {
    this.twoFactorDetails.isTwoFactorEnabled = false;
    this.showStepper = true;
    if (this.stepper) {
      this.stepper.reset();
    }
  }

  public getFormattedRecoveryCodes() {
    if (!this.recoveryCodes || this.recoveryCodes.length === 0) {
      return null;
    }
    return this.recoveryCodes.join(",");
  }
}
