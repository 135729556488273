export class AuthenticationHistoryRecord {
  public authenticationHistoryIdentity: number;
  public username: string;
  public userIdentity: number;
  public actionType: number;
  public actionDate: Date;
  public actionResult: number;
  public thirdParty: boolean;
  public userAgent: string;
  public actionTypeDisplay: string;
  public actionResultDisplay: string;
}
