import { Component, OnInit, OnDestroy } from "@angular/core";
import { Subject } from "rxjs";
import { ActivatedRoute } from "@angular/router";
import { takeUntil } from "rxjs/operators";
import { Location } from "@angular/common";

@Component({
  selector: "roctavian-abstractions-edit-permission-page",
  templateUrl: "./edit-permission-page.component.html",
  styleUrls: ["./edit-permission-page.component.scss"]
})
export class EditPermissionPageComponent implements OnInit, OnDestroy {
  public identity: number;
  public unsubscribe: Subject<boolean> = new Subject<boolean>();

  constructor(private route: ActivatedRoute, private location: Location) {}

  public ngOnInit() {
    this.route.params.pipe(takeUntil(this.unsubscribe)).subscribe(params => {
      this.identity = params["identity"];
    });
  }

  public ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  public handleSuccessfulUpdate() {
    this.location.back();
  }
}
