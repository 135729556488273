import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatSort, PageEvent } from '@angular/material';
import { ActivatedRoute } from '@angular/router';
import { ContentTypes, DocumentTypes } from '@app/core/enums';
import { DownloadDocument, documentTypeNames } from '@app/core/models';
import { DocumentStorageService } from '@app/core/services';
import { Document } from '@app/stakeholder/models';
import { PaginatedList } from '@roctavian-abstractions/web';
import { FileUploadMessageService } from 'apps/roctavian-eumea-callcenter-portal/src/app/modules/shared/components/file-upload';
import { BehaviorSubject, EMPTY, Subject } from 'rxjs';
import {
  debounceTime,
  distinctUntilChanged,
  switchMap,
  takeUntil,
  tap,
} from 'rxjs/operators';
import { UtilityService } from '../../../common';
import { DocumentPatientQuery } from '../../models';
import { DocumentPatient } from '../../models/document-patient.model';
import { PatientService } from '../../services';

@Component({
  selector: 'roctavian-us-patient-match-portal-patient-documents-page',
  templateUrl: './patient-documents-page.component.html',
  styleUrls: ['./patient-documents-page.component.css'],
})
export class PatientDocumentsPageComponent implements OnInit, OnDestroy {
  private readonly destroyed$ = new Subject();
  private patientId: string;

  displayedColumns = ['id', 'document.documentType', 'addDate', 'menu'];
  documentTypes = documentTypeNames;
  filterText$ = new BehaviorSubject<string>(null);
  isLoading = false;
  pagedCollection: PaginatedList<DocumentPatient>;
  pagedQuery = new DocumentPatientQuery(1, 10, null, null, true);
  selectedDocumentType: DocumentTypes;

  constructor(
    private readonly documentStorageService: DocumentStorageService,
    private readonly fileUploadMessageService: FileUploadMessageService,
    private readonly patientService: PatientService,
    private readonly route: ActivatedRoute,
    public readonly utilityService: UtilityService
  ) {
    this.route.parent.paramMap.pipe(takeUntil(this.destroyed$)).subscribe(params => {
      const id = params.get('id');
      this.patientId = id;
      this.pagedQuery.patientId = id;
    });

    this.filterText$
      .pipe(
        takeUntil(this.destroyed$),
        debounceTime(1000),
        distinctUntilChanged(),
        switchMap(searchText => {
          this.pagedQuery.filterText = searchText;
          this.pagedQuery.pageIndex = 1;
          this.refreshList();
          return EMPTY;
        })
      )
      .subscribe();
  }

  ngOnInit() {
    this.fileUploadMessageService.uploaded$
      .pipe(
        takeUntil(this.destroyed$),
        tap(document => this.associateDocument(document))
      )
      .subscribe();

    this.patientService.pagedPatientDocumentsListSubject
      .pipe(
        takeUntil(this.destroyed$),
        tap(collection => (this.pagedCollection = collection))
      )
      .subscribe();

    this.patientService.isLoadingSubject
      .pipe(
        takeUntil(this.destroyed$),
        tap(isLoading => (this.isLoading = isLoading))
      )
      .subscribe();

    this.patientService.addDocumentToPatientSubject
      .pipe(
        takeUntil(this.destroyed$),
        tap(outcome => {
          if (outcome != null) {
            this.refreshList();
          }
        })
      )
      .subscribe();
  }

  ngOnDestroy() {
    this.destroyed$.unsubscribe();
  }

  onRowSelected(document: Document) {
    this.viewDocument(document);
  }

  onDocumentTypeSelected(documentType: DocumentTypes) {
    this.selectedDocumentType = documentType;
  }


  viewDocument(document: Document) {
    let fileType = ContentTypes[document.documentPath.split('.').slice(-1)[0]];
    if (fileType === null || fileType === undefined){
      fileType = ContentTypes.pdf;
    }
      const storageDocument: DownloadDocument = {
        contentType: fileType,
        documentType: DocumentTypes.patientDocument,
        fileName: document.documentPath,
      };

      this.documentStorageService
        .download(storageDocument)
        .pipe(
          takeUntil(this.destroyed$),
          tap(url => window.open(url, '_blank'))
        )
        .subscribe();
    }

  onPageEvent(pageEvent: PageEvent) {
    this.pagedQuery.pageIndex = pageEvent.pageIndex + 1;
    this.pagedQuery.pageSize = pageEvent.pageSize;
    this.refreshList();
  }

  onSortEvent(sort: MatSort) {
    this.pagedQuery.sortColumn = sort.active;
    this.pagedQuery.isSortDesc = sort.direction === 'desc';
    this.pagedQuery.pageIndex = 1;
    this.refreshList();
  }

  private associateDocument(document: Document) {
    this.patientService.addDocumentToPatient(this.patientId, document);
  }

  private refreshList() {
    this.patientService.setDocumentsPage(this.pagedQuery);
  }
}
