export enum AttestationTypes {
  Unknown = -1,

  PrescriberRegistration = 1,

  LabUserRegistration = 2,

  LabUserDelegateRegistration = 3,

  PrescriberDelegateRegistration = 4,
  PatientRegistration = 5
}
