import { Component } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'roctavian-abstractions-user-manager-page',
  templateUrl: './user-manager-page.component.html',
  styleUrls: ['./user-manager-page.component.scss'],
})
export class UserManagerPageComponent {
  constructor(private router: Router) {}

  /**
   * Handles the event that is emitted when
   * a user is selected from the user grid.
   *
   * @param identity The user identity.
   */
  public handleViewUserSelected(identity: number) {
    this.router.navigate([`/administration/users/details/${identity}`]);
  }

  /**
   * Handles the event that is emitted when
   * a user is selected from the user grid.
   *
   * @param identity The user identity.
   */
  public handleEditUserSelected(identity: number) {
    this.router.navigate([`/administration/users/edit/${identity}`]);
  }
}
