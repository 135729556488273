import { Component, Input } from '@angular/core';
import { AppTranslationService } from '@roctavian-abstractions/core';
import { UtilityService } from '../../../common/services/utility.service';
import { PrescriberDelegate } from '../../models/prescriber-delegate.model';

@Component({
  selector: 'view-prescriber-delegate',
  templateUrl: './view-prescriber-delegate.component.html',
  styleUrls: ['./view-prescriber-delegate.component.scss']
})
export class ViewPrescriberDelegateComponent {
  @Input() public title = this.translate.getTranslation('PrescriberDelegate.ViewPrescriberDelegate.Title');
  @Input() public showTitle = true;

  @Input() public attestationLabel = this.translate.getTranslation(
    'PrescriberDelegate.ViewPrescriberDelegate.Attestation'
  );
  @Input() public attestationDateLabel = this.translate.getTranslation(
    'PrescriberDelegate.ViewPrescriberDelegate.AttestationDate'
  );

  @Input() public prescriberDelegate: PrescriberDelegate;

  constructor(public utilityService: UtilityService, private translate: AppTranslationService) { }
}
