import { MessageService } from "@roctavian-abstractions/core";
import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { AccountClient } from "../../clients/account.client";
import { SendEmailConfirmationMessageModel } from "../../models";

@Component({
  selector: "roctavian-abstractions-resend-confirmation-email-page",
  templateUrl: "./resend-confirmation-email-page.component.html",
  styleUrls: ["./resend-confirmation-email-page.component.scss"]
})
export class ResendConfirmationEmailPageComponent implements OnInit {
  public disabled = false;
  public form: FormGroup;
  public showSuccessMessage = false;

  constructor(private builder: FormBuilder, private client: AccountClient, private service: MessageService) {}

  public ngOnInit() {
    this.form = this.builder.group({
      username: ["", Validators.required]
    });
  }

  public handleFormSubmission(form: FormGroup) {
    if (!form.valid) {
      return;
    }
    const username = form.get("username").value;
    const model = new SendEmailConfirmationMessageModel(username);

    this.disabled = true;
    this.client.sendEmailConfirmationMessage(model).subscribe(
      () => {
        this.disabled = false;
        this.showSuccessMessage = true;
      },
      () => {
        this.disabled = false;
        const message = "An error occurred when submitting your request. Please try again.";
        this.service.open(message, 5000);
      }
    );
  }
}
