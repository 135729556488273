import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { NgModule } from '@angular/core';
import { LabListPageComponent } from './pages/lab-list-page/lab-list-page.component';
import { AddLabPageComponent } from './pages/add-lab-page/add-lab-page.component';
import { LabDetailMasterPageComponent } from './pages/lab-detail-master-page/lab-detail-master-page.component';
import { LabSummaryPageComponent } from './pages/lab-summary-page/lab-summary-page.component';
import { LabStatusPageComponent } from './pages/lab-status-page/lab-status-page.component';
import { LabEditPageComponent } from './pages/lab-edit-page/lab-edit-page.component';
import { LabPrescriberListPageComponent } from './pages/lab-prescriber-list-page/lab-prescriber-list-page.component';
import { LabUserListPageComponent } from './pages/lab-user-list-page/lab-user-list-page.component';
import { RoctavianAuthenticationGuard } from '../../../shared/guards/roctavian-authentication.guard';
import { LabDocumentsPageComponent } from './pages/lab-documents-page/lab-documents-page.component';

const ROUTES = [
  {
    path: 'lab',
    canActivate: [RoctavianAuthenticationGuard],
    children: [
      { path: '', redirectTo: 'list', pathMatch: 'full' },
      { path: 'list', component: LabListPageComponent },
      { path: 'add', component: AddLabPageComponent },
      {
        path: ':id',
        component: LabDetailMasterPageComponent,
        children: [
          { path: '', redirectTo: 'summary', pathMatch: 'full' },
          { path: 'summary', component: LabSummaryPageComponent },
          { path: 'status', component: LabStatusPageComponent },
          { path: 'edit', component: LabEditPageComponent },
          { path: 'document', component: LabDocumentsPageComponent},
          { path: 'prescriber', component: LabPrescriberListPageComponent },
          { path: 'users', component: LabUserListPageComponent }
        ]
      }
    ]
  }
];

@NgModule({
  imports: [CommonModule, RouterModule.forChild(ROUTES)],
  exports: [RouterModule]
})
export class LabRoutingModule { }
