import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { MessageService } from '@roctavian-abstractions/core';
import { AuthenticationService } from './modules/identity/services/authentication.service';
import { SessionManagementEventHandler } from './modules/identity/session-management-event.handler';

@Injectable()
export class RoctavianSessionManagementEventHandler extends SessionManagementEventHandler {
    constructor(
        private router: Router,
        private authService: AuthenticationService,
        private messageService: MessageService
    ) {
        super();

    }

    public handleTimeout() {
        this.authService.logout();
        this.messageService.dismiss();
        this.router.navigate(['/']);
    }
}
