﻿import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

@Injectable()
export class ForbiddenInterceptor implements HttpInterceptor {
  constructor(private readonly router: Router) {}
  /**
   * Intercepts forbidden responses and navigates the user to the forbidden page.
   *
   * @param request The request.
   * @param next The next request.
   * @returns An observable containing an http event.
   */
  public intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      catchError(error => {
        if (error instanceof HttpErrorResponse && error.status === 403) {
          this.router.navigate(['/forbidden']);
        }
        return throwError(error);
      })
    );
  }
}
