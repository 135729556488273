import { Subscription } from 'rxjs';
import { Router } from '@angular/router';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { MessageService } from '@roctavian-abstractions/core';
import { AppTranslationService } from '@roctavian-abstractions/core';
import { Outcome } from '@roctavian-abstractions/web';
import { StatusTypes, UtilityService } from '../../../common';
import { Lab } from '../../models';
import { LabService } from '../../services';
import { LabClient } from '../../clients';

@Component({
  selector: 'roctavian-eumea-callcenter-portal-lab-edit-page',
  templateUrl: './lab-edit-page.component.html',
  styleUrls: ['./lab-edit-page.component.css']
})
export class LabEditPageComponent implements OnInit, OnDestroy {
  public form: FormGroup;
  public lab: Lab;
  public isProcessing = false;
  public errorMessages = new Array<string>();
  public statusTypes = StatusTypes;

  private subscriptions = new Array<Subscription>();

  constructor(
    private formBuilder: FormBuilder,
    private labService: LabService,
    private labClient: LabClient,
    private messageService: MessageService,
    private translate: AppTranslationService,
    public utilityService: UtilityService,
    private router: Router
  ) {
    this.subscriptions.push(this.labService.selectedLabSubject.subscribe(lab => {
      this.lab = lab;

      if (!this.lab) {
        return;
      }

      setTimeout(() => {
        this.form.patchValue(this.lab);
      });
    }));
  }

  ngOnInit() {
    this.form = this.formBuilder.group({});
  }

  ngOnDestroy() {
    this.subscriptions.forEach(subscription => {
      subscription.unsubscribe();
    });
  }

  public submit() {
    this.form.markAllAsTouched();

    if (this.form.invalid) {
      return;
    }

    this.isProcessing = true;
    this.errorMessages = [];

    const lab: Lab = this.form.getRawValue();
    lab.id = this.lab.id;

    this.subscriptions.push(this.labClient.updateLab(lab).subscribe(
      outcome => {
        this.isProcessing = false;
        if (outcome.failure) {
          this.errorMessages = outcome.messages;
          return;
        }

        this.messageService.open(this.translate.getTranslation('Lab.LabEditPage.SuccessfullyUpdated'), 5000);
        this.labService.selectLab(this.lab.id, true);
        this.router.navigate(['/lab', this.lab.id]);
      },
      (error: Outcome) => {
        this.isProcessing = false;
        this.errorMessages = error.messages;
      }
    ));
  }
}
