import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { AppTranslationService, MessageService } from '@roctavian-abstractions/core';
import { Outcome } from '@roctavian-abstractions/web';
import { Subscription } from 'rxjs';
import { AttestationTypes, StakeholderTypes } from '../../../common';
import { PrescriberDelegateClient } from '../../clients/prescriber-delegate.client';
import { PrescriberDelegate } from '../../models/prescriber-delegate.model';

@Component({
  selector: 'add-prescriber-delegate',
  templateUrl: './add-prescriber-delegate.component.html',
  styleUrls: ['./add-prescriber-delegate.component.scss']
})
export class AddPrescriberDelegateComponent implements OnInit, OnDestroy {
  public form: FormGroup;
  public errorMessages: string[];
  public isProcessing = false;

  private subscriptions = new Array<Subscription>();

  constructor(
    private formBuilder: FormBuilder,
    private client: PrescriberDelegateClient,
    private messageService: MessageService,
    private router: Router,
    private translate: AppTranslationService
  ) { }

  public ngOnInit() {
    this.form = this.formBuilder.group({});
  }

  ngOnDestroy() {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }

  public submit() {
    this.errorMessages = [];

    this.form.markAllAsTouched();
    if (this.form.invalid) {
      return;
    }

    this.isProcessing = true;
    this.subscriptions.push(
      this.client.createPrescriberDelegate(this.getModel()).subscribe(
        outcome => {
          this.isProcessing = false;
          if (outcome.failure) {
            this.handleError(outcome);
            return;
          }

          this.messageService.open(
            this.translate.getTranslation('PrescriberDelegate.AddPrescriberDelegate.SuccessfullySaved'),
            5000
          );
          this.router.navigate(['/prescriber-delegate']);
        },
        (error: Outcome) => {
          this.isProcessing = false;
          this.handleError(error);
        }
      )
    );
  }

  public getModel(): PrescriberDelegate {
    const formValue = this.form.getRawValue();
    const prescriberDelegate: PrescriberDelegate = formValue;
    prescriberDelegate.stakeholder.stakeholderType = StakeholderTypes.PrescriberDelegate;

    const attestation = formValue['stakeholder']['attestation'];
    let newDate = new Date(attestation.signatureDate)
    let currentTime = new Date();
    newDate.setHours(currentTime.getHours());
    newDate.setMinutes(currentTime.getMinutes());
    newDate.setSeconds(currentTime.getSeconds());
    newDate.setMilliseconds(currentTime.getMilliseconds());   
    newDate.setMinutes(newDate.getMinutes() + newDate.getTimezoneOffset());
    attestation.signatureDate = newDate;
    attestation.attestationType = AttestationTypes.PrescriberDelegateRegistration;

    prescriberDelegate.stakeholder.attestations = [attestation];

    return prescriberDelegate;
  }

  private handleError(error: Outcome) {
    if (!error || !error.messages || error.messages.length === 0) {
      this.errorMessages = [this.translate.getTranslation('Common.ErrorProcessingRequest')];
    } else {
      this.errorMessages = error.messages;
    }
  }
}
