import { Component, EventEmitter, Inject, OnDestroy, OnInit, Output } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { AppTranslationService, MessageService } from '@roctavian-abstractions/core';
import { Subscription } from 'rxjs';
import { ExternalLabTestClient } from '../../clients/external-lab-test.client';
import { ExternalLabTest } from '../../models/external-lab-test.model';

@Component({
  selector: 'delete-external-lab-test-dialog',
  templateUrl: './delete-external-lab-test-dialog.component.html',
  styleUrls: ['./delete-external-lab-test-dialog.component.scss']
})
export class DeleteExternalLabTestDialogComponent implements OnInit, OnDestroy {
  public ageRangeLabel = this.translate.getTranslation('ExternalLabTest.ViewExternalLabTest.AgeRange');
  public countryLabel = this.translate.getTranslation('ExternalLabTest.ViewExternalLabTest.Country');
  public testResultLabel = this.translate.getTranslation('ExternalLabTest.ViewExternalLabTest.TestResult');
  public testTypeLabel = this.translate.getTranslation('ExternalLabTest.ViewExternalLabTest.TestType');

  public isProcessing = false;

  public externalLabTest: ExternalLabTest;
  @Output() public externalLabTestDeleted = new EventEmitter();

  private subscriptions = new Array<Subscription>();

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public reference: MatDialogRef<DeleteExternalLabTestDialogComponent>,
    private client: ExternalLabTestClient,
    private messageService: MessageService,
    private translate: AppTranslationService
  ) {
    this.externalLabTest = data.externalLabTest;
  }

  public ngOnInit() { }

  ngOnDestroy() {
    this.subscriptions.forEach(subscription => {
      subscription.unsubscribe();
    });
  }

  public submit() {
    this.isProcessing = true;

    this.client.deleteExternalLabTest(this.externalLabTest.id).subscribe(
      outcome => {
        this.isProcessing = false;
        if (!outcome.success) {
          this.messageService.open(this.translate.getTranslation('Common.ErrorProcessingRequest'), 3000);
          return;
        }

        this.messageService.open(this.translate.getTranslation('ExternalLabTest.DeleteExternalLabTest.DeleteSuccessMessage'), 3000);
        this.externalLabTestDeleted.emit();
        this.reference.close();
      },
      error => {
        this.isProcessing = false;
        this.messageService.open(this.translate.getTranslation('Common.ErrorProcessingRequest'), 3000);
      }
    );
  }
}
