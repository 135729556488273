import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'roctavian-abstractions-administration-page',
  templateUrl: './administration-page.component.html',
  styleUrls: ['./administration-page.component.scss'],
})
export class AdministrationPageComponent implements OnInit {
  constructor() {}

  public ngOnInit() {}

  public getNavigationMode(): string {
    if (window.innerWidth < 750) {
      return 'over';
    }
    return 'side';
  }
}
