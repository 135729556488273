import { Component, Input, OnChanges, SimpleChanges, ChangeDetectionStrategy } from '@angular/core';
import { TaskAction } from '../../../../models';

@Component({
  selector: 'roctavian-abstractions-task-action-list',
  templateUrl: './task-action-list.component.html',
  styleUrls: ['./task-action-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TaskActionListComponent implements OnChanges {
  @Input() public taskActions: TaskAction[];

  /**
   * Sorts the task action array by add date when new changes are pushed
   * to the input.
   *
   * @param changes the input changes
   */
  public ngOnChanges(changes: SimpleChanges): void {
    this.sortActions();
  }

  /**
   * Sorts the task actions by add date desc.
   */
  public sortActions() {
    if (this.taskActions) {
      this.taskActions.sort((a1, a2) => {
        if (a1.addDate < a2.addDate) {return 1;}
        if (a1.addDate > a2.addDate) {return -1;}
        return 0;
      });
    }
  }

  /**
   * Returns a class name for the row to apply
   * if the task action is current.
   *
   * @param action the task action
   */
  public currentActionClass(action: TaskAction) {
    if (action.isCurrent) {
      return 'is-current-action';
    }
  }
}
