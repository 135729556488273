import { Component, Input, EventEmitter, Output, OnInit } from "@angular/core";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";

export class UserDetailsFormSubmitEvent {
  constructor(public firstName: string, public lastName: string, public email: string) {}
}

@Component({
  selector: "roctavian-abstractions-user-details-form",
  templateUrl: "./user-details-form.component.html",
  styleUrls: ["./user-details-form.component.scss"]
})
export class UserDetailsFormComponent implements OnInit {
  @Input() public disabled = false;
  @Input() public firstName: string;
  @Input() public lastName: string;
  @Input() public email: string;

  @Output() public submitted = new EventEmitter<UserDetailsFormSubmitEvent>();

  public form: FormGroup;

  constructor(private formBuilder: FormBuilder) {}

  public ngOnInit() {
    this.form = this.formBuilder.group({
      firstName: [this.firstName, Validators.required],
      lastName: [this.lastName, Validators.required],
      email: [this.email, Validators.required]
    });
  }

  public handleFormSubmission(form: FormGroup) {
    const firstName = form.get("firstName").value;
    const lastName = form.get("lastName").value;
    const email = form.get("email").value;

    this.submitted.emit(new UserDetailsFormSubmitEvent(firstName, lastName, email));
  }
}
