import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'application-error',
  templateUrl: './error.component.html',
  styleUrls: ['./error.component.css']
})
export class ErrorComponent implements OnInit {
  @Input() public errors = [];

  constructor() {}

  ngOnInit() {}
}
